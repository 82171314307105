import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useForm, SubmitHandler } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { FaUserCircle, FaEdit } from 'react-icons/fa';
import { useRecoilValue, useRecoilState } from 'recoil';
import { STATES } from '../types/constants';
import {
  prefixDataState,
  suffixDataState,
  genderDataState,
  raceDataState,
  userProfileState,
  currentRoleState,
} from '../services/state.service';
import {
  UserProfile,
  PrefixData,
  SuffixData,
  GenderData,
  RaceData,
  Role,
} from '../types/user.types';
import api from '../services/api.service';
import UserService from '../services/user.service';
import DisplayField from './generic/DisplayField';

const userCircle = { size: '4em', className: 'user-profile-picture' };

function PersonalProfileAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [userProfile, setUserProfileState] = useRecoilState<UserProfile>(userProfileState);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const prefix = useRecoilValue<PrefixData[]>(prefixDataState);
  const suffix = useRecoilValue<SuffixData[]>(suffixDataState);
  const gender = useRecoilValue<GenderData[]>(genderDataState);
  const race = useRecoilValue<RaceData[]>(raceDataState);
  const {
    setError,
    handleSubmit,
    register,
    reset,
    formState: {
      errors,
    },
  } = useForm<UserProfile>({
    defaultValues: userProfile,
  });
  const submitRef = useRef<HTMLButtonElement>(null);

  function displayPrefix(prefixId: number | string): string {
    let displayThisPrefix = '';
    for (let i = 0; i < prefix.length; i += 1) {
      if (prefix[i].id === prefixId) {
        displayThisPrefix = prefix[i].description;
      }
    }
    return displayThisPrefix;
  }

  function displaySuffix(suffixId: number | string): string {
    let displayThisSuffix = '';
    for (let i = 0; i < suffix.length; i += 1) {
      if (suffix[i].id === suffixId) {
        displayThisSuffix = suffix[i].description;
      }
    }
    return displayThisSuffix;
  }

  function generatePayload(data: UserProfile): UserProfile {
    const {
      prefix: prefixVar,
      suffix: suffixVar,
      gender: genderVar,
      race: raceVar,
      ...payload
    } = data;
    return {
      ...payload,
      prefix: prefixVar && prefixVar > 0 ? prefixVar : '',
      suffix: suffixVar && suffixVar > 0 ? suffixVar : '',
      gender: genderVar && genderVar > 0 ? genderVar : '',
      race: raceVar && raceVar > 0 ? raceVar : '',
    };
  }

  function Edit(): JSX.Element {
    const [show, setShow] = useState(false);

    const handleClose = (): void => { reset(); setShow(false); };
    const handleShow = (): void => setShow(true);

    const onSubmit: SubmitHandler<UserProfile> = (data: UserProfile) => {
      const payload = generatePayload(data);
      api.put('/user/profile/', payload).then(() => {
        UserService.GetProfile().then((profile: UserProfile) => {
          setUserProfileState(profile);
          if (profile.middleName === null) {
            setUserProfileState((p) => ({ ...p, middleName: '' }));
          }
          setShow(false);
        });
      }).catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'id' | 'email',
          { type: 'server', message: messages[name][0] },
        ));
      });
    };

    const prefixMenu = prefix?.map((option: PrefixData) => (
      <option key={option.id} value={option.id}>
        {option.description}
      </option>
    ));

    const suffixMenu = suffix?.map((option: SuffixData) => (
      <option key={option.id} value={option.id}>
        {option.description}
      </option>
    ));

    const genderMenu = gender?.map((option: GenderData) => (
      <option key={option.id} value={option.id}>
        {option.description}
      </option>
    ));

    const raceMenu = race?.map((option: RaceData) => (
      <option key={option.id} value={option.id}>
        {option.description}
      </option>
    ));

    const statesMenu = STATES?.map((option: string) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));

    return (
      <>
        <Button
          onClick={handleShow}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          <FaEdit />
          {` ${t('Edit')}`}
        </Button>

        <Modal fullscreen show={show} onHide={handleClose}>
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <div className="mx-auto mt-3">
              <div className="title">{t('Edit_Personal')}</div>
            </div>
            <Form className="mx-auto col-8" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <Form.Label className="bodyheader">
                  {t('Prefix')}
                </Form.Label>
                <Form.Select
                  {...register('prefix', { valueAsNumber: true })}
                >
                  <option key="" value="">
                    {t('Select_Prefix')}
                  </option>
                  {prefixMenu}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('First_Name')}
                </Form.Label>
                <Form.Control
                  {...register('firstName', { required: true })}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName && errors.firstName.message
                    ? errors.firstName.message
                    : t('FirstNameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Middle_Name')}
                </Form.Label>
                <Form.Control
                  {...register('middleName')}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Last_Name')}
                </Form.Label>
                <Form.Control
                  {...register('lastName', { required: true })}
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName && errors.lastName.message
                    ? errors.lastName.message
                    : t('LastNameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Suffix')}
                </Form.Label>
                <Form.Select
                  {...register('suffix', { valueAsNumber: true })}
                >
                  <option key="" value="">
                    {t('Select_Suffix')}
                  </option>
                  {suffixMenu}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Pronouns')}
                </Form.Label>
                <Form.Control
                  {...register('pronouns')}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('First_Address')}
                </Form.Label>
                <Form.Control
                  {...register('addressOne', { required: true })}
                  isInvalid={!!errors.addressOne}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.addressOne && errors.addressOne.message
                    ? errors.addressOne.message
                    : t('AddressOneRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Second_Address')}
                </Form.Label>
                <Form.Control
                  {...register('addressTwo')}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('City')}
                </Form.Label>
                <Form.Control
                  {...register('city', { required: true })}
                  isInvalid={!!errors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city && errors.city.message
                    ? errors.city.message
                    : t('CityRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('State')}
                </Form.Label>
                <Form.Select
                  {...register('state', { required: true })}
                >
                  <option key="" value="">
                    {t('Select_State')}
                  </option>
                  {statesMenu}
                </Form.Select>
                <div className="col-12 non-field-error">
                  {errors.state && t('StateRequired')}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Zip_Code')}
                </Form.Label>
                <Form.Control
                  {...register('zip', { required: true })}
                  isInvalid={!!errors.zip}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zip && errors.zip.message
                    ? errors.zip.message
                    : t('ZipRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Phone_Number')}
                </Form.Label>
                <Form.Control
                  {...register('phoneOne', { required: true })}
                  isInvalid={!!errors.phoneOne}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneOne && errors.phoneOne.message
                    ? errors.phoneOne.message
                    : t('PhoneOneRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Secondary_Phone')}
                </Form.Label>
                <Form.Control
                  {...register('phoneTwo')}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Email')}
                </Form.Label>
                <Form.Control
                  {...register('email', { required: true })}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email && errors.email.message
                    ? errors.email.message
                    : t('EmailRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              { currentRole.abbreviation === 'CS' && (
                <>
                  <hr />
                  <div className="text-center">
                    {t('Demographic_Privacy_Notice')}
                  </div>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Birth_Year')}
                    </Form.Label>
                    <Form.Control
                      {...register('birthYear', { required: true })}
                      isInvalid={!!errors.birthYear}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.birthYear && errors.birthYear.message
                        ? errors.birthYear.message
                        : t('BirthYearRequired')}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Gender')}
                    </Form.Label>
                    <Form.Select
                      {...register('gender', { required: true, valueAsNumber: true })}
                    >
                      <option key="" value="">
                        {t('Select_Gender')}
                      </option>
                      {genderMenu}
                    </Form.Select>
                    <div className="col-12 non-field-error">
                      {errors.gender && t('GenderRequired')}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3">
                      {t('Description_of_Gender')}
                    </Form.Label>
                    <Form.Control
                      {...register('genderDescription')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3">
                      {t('Race_Ethnicity')}
                    </Form.Label>
                    <Form.Select
                      {...register('race', { valueAsNumber: true })}
                    >
                      <option key="" value="">
                        {t('Select_Race')}
                      </option>
                      {raceMenu}
                    </Form.Select>
                  </Form.Group>
                </>
              )}
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex profilemodalfooter border-top-0">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => submitRef.current?.click()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaUserCircle />
        <span className="accordion-header-label">
          {t('ProfileInformation')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="row">
          <div className="col-sm-2">
            <IconContext.Provider value={userCircle}>
              <FaUserCircle />
            </IconContext.Provider>
          </div>
          <div className="col-sm-3">
            <DisplayField
              label={t('Name')}
              text={`
              ${displayPrefix(userProfile?.prefix)}
              ${userProfile?.firstName}
              ${userProfile?.middleName}
              ${userProfile?.lastName}
              ${displaySuffix(userProfile?.suffix)}
              `}
            />
          </div>
          <div className="col-sm-3">
            <DisplayField
              label={t('Email')}
              text={`${userProfile?.email}`}
            />
          </div>
          <div className="col-sm-3">
            <DisplayField
              label={t('Pronouns')}
              text={`${userProfile?.pronouns || ''}`}
            />
          </div>
          <div className="col-sm-1">
            {prefix[0]?.description !== '' ? Edit() : null}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2" />
          <div className="col-sm-3">
            <DisplayField
              label={t('Address')}
              text={`${userProfile?.addressOne ? userProfile.fullAddress : ''}`}
            />
          </div>
          <div className="col-sm-3">
            <DisplayField
              label={t('Phone_Number')}
              text={userProfile?.phoneOne}
            />
          </div>
          <div className="col-sm-3">
            <DisplayField
              label={t('Secondary_Phone')}
              text={userProfile?.phoneTwo}
            />
          </div>
        </div>
        <div className="col-sm-3">
          &nbsp;
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default PersonalProfileAccordionItem;

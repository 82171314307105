import { IData } from './generic.types';
import { MIN_SALARY } from './constants';
import { CompensationData, RequirementsData } from '../services/mdp.service';
import { ReferenceData } from './pdp.types';
import { MinistryProfile } from './ministry.types';
import { UserProfile } from './user.types';

export interface MDPData {
  id: string,
  organization: number | undefined,
  organizationName: string,
  organizationType: string,
  committee: number | undefined,
  submitted: boolean,
  authorized: boolean,
  acceptEEO: boolean,
  clerkApproval: boolean,
  comApproval: boolean,
  clcApproval: boolean,
  positionType: string,
  positionTitle: string,
  employmentType: string,
  hasMatches: boolean,
  released: string,
  city: string,
  state: string,
  callingStart: string
  openTo: string,
}

export interface MDPResultListData {
  id: string,
  positionType: number,
  positionTitle: string,
  experienceLevel: string,
  employmentType: string,
  organizationName: string,
  organizationType: string,
  presbytery: string,
  city: string,
  state: string,
  communityType: string,
  congregationSize: string,
  minimumExpectedSalary: number,
  clerkApproval: string,
  comApproval: string,
  status: string,
  deadline: string,
  released: string,
  pageNumber: number,
}

export interface MDPResponseData {
  data: MDPData,
}

export const MDPResultListDefaultData: MDPResultListData = {
  id: '',
  positionType: 0,
  positionTitle: '',
  employmentType: '',
  experienceLevel: '',
  organizationName: '',
  organizationType: '',
  presbytery: '',
  city: '',
  state: '',
  communityType: '',
  congregationSize: '',
  minimumExpectedSalary: 0,
  clerkApproval: '',
  comApproval: '',
  status: '',
  deadline: '',
  released: '',
  pageNumber: 1,
};

export const MDPDefaultData: MDPData = {
  id: '',
  organization: undefined,
  organizationName: '',
  organizationType: '',
  committee: undefined,
  submitted: false,
  authorized: false,
  acceptEEO: false,
  clerkApproval: false,
  comApproval: false,
  clcApproval: false,
  positionType: '',
  positionTitle: '',
  employmentType: '',
  hasMatches: false,
  released: '',
  city: '',
  state: '',
  callingStart: '',
  openTo: '',
};

export interface FavoriteData {
  id: string
}

export const FavoriteDefaultData: FavoriteData = {
  id: '',
};

export interface FavoriteResponseData {
  data: FavoriteData[]
}

export interface MDPListResponseData {
  data: MDPData[],
}

export interface MDPResultListResponseData {
  data: {
    options: MDPResultListData[],
    totalPages: number,
    totalResults: number
  }
}

export interface MDPDetailsResponseData {
  data: MDPResultListData[],
}

export interface MinistryRequirementsData {
  id: number | undefined,
  vision: string,
  tasks: string,
  initial: boolean,
}

export interface MinistryRequirementsResponseData {
  data: MinistryRequirementsData[],
}

export interface PNCChairData extends IData {
  id: number,
  mdp: string | undefined,
  name: string | undefined,
  prefPhone: string | undefined,
  altPhone: string | undefined,
  fax: string | undefined,
  email: string | undefined,
  addressOne: string | undefined,
  addressTwo: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zip: number | undefined,
}

export interface SelfReferralContactData extends IData {
  EPDetails: SelfReferralData | undefined,
  COMDetails: SelfReferralData | undefined,
}

export interface SelfReferralData extends IData {
  name: string,
  phone: string,
  email: string,
}

export const PNCChairDefaultData: PNCChairData = {
  id: 0,
  mdp: undefined,
  name: undefined,
  prefPhone: undefined,
  altPhone: undefined,
  fax: undefined,
  email: undefined,
  addressOne: undefined,
  addressTwo: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
};

export const SelfReferralContactDefaultData:SelfReferralContactData = {
  EPDetails: undefined,
  COMDetails: undefined,
};

export interface SelfReferralContactResponseData {
  data: {
    options: SelfReferralContactData
  }
}

export interface PNCChairResponseData {
  data: PNCChairData[],
}

export interface ImportPNCCHairResponseData {
  data: {
    options: PNCChairData,
  }
}

export interface MDPReferenceData extends IData {
  refId: number | undefined,
  mdp: number | undefined,
  name: string,
  relation: string,
  phone: string,
  email: string,
}

export const MDPReferenceDefaultData: MDPReferenceData = {
  refId: undefined,
  mdp: undefined,
  name: '',
  relation: '',
  phone: '',
  email: '',
};

export interface MDPReferenceResponseData {
  data: MDPReferenceData[],
}

export interface CertificateData {
  id: string,
  description: string,
}

export interface CertificateResponseData {
  data: {
    options: CertificateData[],
  }
}

export interface MDPNarrativeData {
  id: number,
  mdp: number,
  question: string,
  displayOrder: number,
  response: string | undefined,
}

export interface MDPNarrativesResponseData {
  data: MDPNarrativeData[]
}

export interface PositionTypes {
  id: number,
}

export interface MatchData {
  id: number,
  mdp: number,
  employmentType: string,
  positionTypes: any,
  maximumSalary: number,
  matchCharacteristics: MatchCharacteristicData[],
  noMatchingWithinPresbytery: boolean,
  pdps: PDPMatchData[],
}

export interface MatchResponseData {
  data: MatchData[]
}

export const MatchDefaultData: MatchData = {
  id: 0,
  mdp: 0,
  employmentType: '',
  positionTypes: [],
  maximumSalary: MIN_SALARY,
  matchCharacteristics: [],
  noMatchingWithinPresbytery: false,
  pdps: [],
};

export interface PDPMatchData {
  id: number,
  pdp: number,
  match: number,
  organizationName: string,
  city: string,
  state: string,
  employmentType: string,
  mdpEmploymentType: string,
  positionTypes: [],
  mdpPositionType: string,
  mdpPositionTitle: string,
  mdpExperienceLevel: string,
  statusId: number,
  status: string,
  statusKey: string,
  score: number,
  mdpId: number,
  note: string,
  referredBy: string,
  selfReferred: boolean,
  selectedAction: number,
  selectedActionKey: string,
  callingStart: string,
}

export interface PDPMatchResponseData {
  data: PDPMatchData[]
}

export interface PDPMatchListResponseData {
  data: {
    'options': PDPMatchData[]
  }
}

export const PDPMatchDefaultData: PDPMatchData = {
  id: 0,
  pdp: 0,
  match: 0,
  organizationName: '',
  city: '',
  state: '',
  employmentType: '',
  mdpEmploymentType: '',
  positionTypes: [],
  mdpPositionType: '',
  mdpPositionTitle: '',
  mdpExperienceLevel: '',
  statusId: 0,
  status: '',
  statusKey: '',
  score: 0,
  mdpId: 0,
  note: '',
  referredBy: '',
  selfReferred: false,
  selectedAction: 0,
  selectedActionKey: '',
  callingStart: '',
};

export interface MatchCharacteristicData {
  id: number,
  characteristic: number,
  characteristicType: string,
  desiredScore: number,
  description: string,
  question: string,
}

export interface PresbyteryMatchingOptionData {
  id: number,
  matchingChoice: string | undefined,
  matchByCommittee: (number | undefined)[],
  matchByOrdainedPositions: boolean | undefined,
}

export const PresbyteryMatchingOptionDefaultData: PresbyteryMatchingOptionData = {
  id: 0,
  matchingChoice: '',
  matchByCommittee: [],
  matchByOrdainedPositions: undefined,
};

export interface PresbyteryMatchingOptionResponseData {
  data: {
    options: PresbyteryMatchingOptionData,
  }
}

export const MatchCharacteristicDefaultData: MatchCharacteristicData = {
  id: 0,
  characteristic: 0,
  characteristicType: '',
  desiredScore: 0,
  description: '',
  question: '',
};

export interface MatchActionResponse {
  data: {
    options: MatchAction[],
  }
}

export interface MatchAction {
  id: number,
  description: string,
  actionKey: string,
}

export const MatchActionDefaultData: MatchAction = {
  id: 0,
  description: '',
  actionKey: '',
};

export interface ActionData {
  mdpId: number,
  pdpId: number,
  action: number,
  actionKey: string,
  statusKey: string,
  accepted: string,
  start: string,
}

export const ActionDefaultData: ActionData = {
  mdpId: 0,
  pdpId: 0,
  action: 0,
  actionKey: '',
  statusKey: '',
  accepted: '',
  start: '',
};

export interface ActionDataResponseData {
  data: {
    options: ActionData[],
  }
}

export interface OpportunitySearchQueryData {
  positionType: number,
  city: string,
  state: string,
  presbytery: string,
  church: string,
  intercultural: string,
  pageNumber: number
  sortMDPs: boolean
}

export interface ViewMDPData {
  requirements: RequirementsData,
  compensation: CompensationData,
  ministryRequirements: MinistryRequirementsData,
  references: ReferenceData,
  chairContact: PNCChairData,
  ministryProfile: MinistryProfile,
  narratives: MDPNarrativeData[],
  yokedMinistries: MinistryProfile[],
  releasedDate: string,
}

export interface ViewMDPResponseData {
  data: ViewMDPData,
}

export interface ReferData {
  pdp_id: string,
  mdp_id: string,
}

export interface PresbyteryInfoData {
  presbytery: string,
  users: UserProfile[] | undefined,
}

export const PresbyteryInfoDefaultData: PresbyteryInfoData = {
  presbytery: '',
  users: [],
};

export interface PresbyteryInfoResponseData {
  data: {
    options: PresbyteryInfoData,
  }
}

export interface MDPOptionalLinkData extends IData {
  id: number,
  mdp: number,
  linkTitle: string,
  linkDescription: string,
  linkUrl: string,
}

export interface MDPOptionalLinkResponseData {
  data: MDPOptionalLinkData[],
}

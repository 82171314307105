import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { STATUS_CHOICES } from '../types/constants';
import {
  PDPMatchData,
} from '../types/mdp.types';

type PDPReferralsProps = {
  pdp: number,
  referrals: PDPMatchData[],
}

function PDPReferrals({
  pdp,
  referrals,
}: PDPReferralsProps): JSX.Element {
  const { t } = useTranslation();

  function renderMatchStatus(matchData: PDPMatchData): string {
    const callingDate = new Date(matchData.callingStart);
    const current = new Date();

    if (matchData.status === t('Referred')) {
      return `${t('Referred_By')} ${matchData.referredBy}`;
    }
    if (matchData.status === t('Requires_Action') && matchData.selfReferred) {
      return t('Self_Referred');
    }
    if (matchData.status === STATUS_CHOICES.callCompleted) {
      if (callingDate && (callingDate > current)) {
        return t('Calling_Notify_Pending');
      }
      return t('Calling_Notify_Completed');
    }

    return matchData.status;
  }

  return (
    <tbody>
      {referrals?.map((referral: any) => (
        referral.options.length > 0 && referral.options[0].pdp === pdp && (
          referral.options?.map((option: any) => (
            <tr key={option.mdpId} className="dividinglines">
              <td>
                <Link to={`/mdp/${option.mdpId}/view/`} target="_blank">
                  {option.mdpId}
                </Link>
              </td>
              <td>
                {option.mdpPositionType ? option.mdpPositionType : ''}
                {option.mdpPositionTitle ? ` (${option.mdpPositionTitle})` : ''}
              </td>
              <td>{option.organizationName ? option.organizationName : '' }</td>
              <td>{option.city ? option.city : '' }</td>
              <td>{option.state ? option.state : '' }</td>
              <td>{renderMatchStatus(option)}</td>
            </tr>
          ))
        )
      ))}
    </tbody>
  );
}

export default PDPReferrals;

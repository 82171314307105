import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useRecoilValue } from 'recoil';
import Accordion from 'react-bootstrap/Accordion';
import { Button } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../services/api.service';
import StatementOfFaithTemplateModal from './StatementOfFaithTemplateModal';
import OptionalLinksTemplateModal from './OptionalLinksTemplateModal';
import CompetencySurveyModal from './CompetencySurveyModal';
import WorkExperienceTemplateModal from './WorkExperienceTemplateModal';
import ServiceToTheChurchTemplateModal from './ServiceToTheChurchTemplateModal';
import {
  StatementModalData,
  StatementModalResponseData,
  statementDefault,
} from '../types/pdp.types';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import { userProfileState } from '../services/state.service';
import { UserProfile } from '../types/user.types';

function PersonalDiscernmentProfileAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const currentUser = useRecoilValue<UserProfile>(userProfileState);
  const [currentStatement, setCurrentStatement] = useState<StatementModalData>(statementDefault);
  const [showCompetencySurvey, setShowCompetencySurvey] = useState(false);
  const [showStatementOfFaith, setShowStatementofFaith] = useState(false);
  const [showOptionalLinks, setShowOptionalLinks] = useState(false);
  const [showWorkExperience, setShowWorkExperience] = useState(false);
  const [showService, setShowService] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    api.get('/profile/statement/').then(
      (response: StatementModalResponseData) => {
        if (response.data) {
          setCurrentStatement(response.data[0]);
        }
      },
    );
  }, [refresh]);

  const saveStatement = useCallback((result: boolean, statement: StatementModalData): void => {
    if (statement && result) {
      api.put(`/profile/statement/${statement.id}/`, statement)
        .then(() => {
          setRefresh((r: boolean) => !r);
        });
      setShowStatementofFaith(false);
    } else {
      setShowStatementofFaith(false);
    }
  }, [currentStatement]);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaUserCircle />
        <span className="accordion-header-label">
          {t('DiscernmentProfile')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          {t('DiscernmentProfileDescription')}
        </div>
        <div className="row">
          <CompetencySurveyModal
            close={() => setShowCompetencySurvey(false)}
            show={showCompetencySurvey}
          />
          <StatementOfFaithTemplateModal
            statement={currentStatement}
            show={showStatementOfFaith}
            saveStatement={saveStatement}
          />
          <OptionalLinksTemplateModal
            show={showOptionalLinks}
            hide={() => setShowOptionalLinks(false)}
          />
          <WorkExperienceTemplateModal
            show={showWorkExperience}
            hide={() => setShowWorkExperience(false)}
          />
          <ServiceToTheChurchTemplateModal
            show={showService}
            hide={() => setShowService(false)}
          />
          {currentUser.ordained && (
            <div className="col-sm-4">
              <Button
                type="button"
                className="my-4 ms-1 text-start text-primary bg-white border-0"
                onClick={() => {
                  setShowCompetencySurvey(true);
                }}
              >
                {t('My_Competency_Survey')}
              </Button>
              <div className="col-sm-1" />
            </div>
          )}
          <div className="col-sm-4">
            <Button
              type="button"
              className="my-4 ms-1 text-start text-primary bg-white border-0"
              onClick={() => { setShowStatementofFaith(true); }}
            >
              {t('Statement_Required_Edit')}
            </Button>
            <div className="col-sm-1" />
          </div>
          <div className="col-sm-4">
            <Button
              type="button"
              className="my-4 ms-1 text-start text-primary bg-white border-0"
              onClick={() => { setShowOptionalLinks(true); }}
            >
              {t('Optional_Links_Edit')}
            </Button>
            <div className="col-sm-1" />
          </div>
          <div className="col-sm-4">
            <Button
              type="button"
              className="my-4 ms-1 text-start text-primary bg-white border-0"
              onClick={() => { setShowWorkExperience(true); }}
            >
              {t('Work_Experience_Edit')}
            </Button>
            <div className="col-sm-1" />
          </div>
          <div className="col-sm-4">
            <Button
              type="button"
              className="my-4 ms-1 text-start text-primary bg-white border-0"
              onClick={() => { setShowService(true); }}
            >
              {t('Service_To_Church_Edit')}
            </Button>
            <div className="col-sm-1" />
          </div>
          <div className="col-sm-4">
            <Button
              type="button"
              className="my-4 ms-1 text-start text-primary bg-white border-0"
              onClick={() => { navigate('/educationwizard', { replace: true }); }}
            >
              {t('Education_Edit')}
            </Button>
            <div className="col-sm-1" />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed(CLC_FUNCTION.callSeeker)(PersonalDiscernmentProfileAccordionItem);

import {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
} from 'react-bootstrap';
import { debounce } from 'lodash';
import PDPService from '../services/pdp.service';
import {
  GeographicalOptionData,
  PresbyteryData,
  GeographicalOptionResponseData,
  GeographicalOptionDefaultData,
} from '../types/pdp.types';
import SanitizeHTML from '../services/html.service';
import {
  pdpStepState,
  geographicalOptionState,
  presbyteryOptionsState,
  statesListOptionsState,
} from '../services/state.service';
import { useStepValid } from '../hooks/useStepValid';
import { SelectOption, StepProps } from '../types/generic.types';

function GeographicalOptions({
  id,
}: StepProps): JSX.Element {
  const { t } = useTranslation();
  const [geoOptions, setGeoOptions] = useRecoilState<GeographicalOptionData>(geographicalOptionState);
  const presbyteryOptions = useRecoilValue<PresbyteryData[]>(presbyteryOptionsState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);
  const [refresh, setRefresh] = useState(false);

  const requiredFields = useMemo(() => (
    [
      'geoLocation',
    ]
  ), []);
  useStepValid('geographicaloptions', geoOptions, requiredFields, pdpStepState);

  useEffect(() => {
    if (geoOptions.geoLocation === 'Search By State') {
      setGeoOptions((p: any) => ({
        ...p,
        pdp: id,
        searchByPresbytery: [],
      }));
    }

    if (geoOptions.geoLocation === 'Search By Presbytery') {
      setGeoOptions((p: any) => ({
        ...p,
        pdp: id,
        searchByState: [],
      }));
    }
  }, [geoOptions.geoLocation]);

  const importGeographicalOptions = (): Promise<void> => (
    PDPService.GetGeographicalOptions(true).then(
      (response: GeographicalOptionResponseData) => (setGeoOptions((p: any) => ({
        ...p,
        pdp: id,
        geoLocation: response.data[0].geoLocation,
        searchByState: response.data[0].searchByState,
        searchByPresbytery: response.data[0].searchByPresbytery,
      }))),
    )
  );

  useEffect(() => {
    PDPService.GetGeographicalOptions(false, id).then(
      (response: GeographicalOptionResponseData) => {
        if (response.data.length > 0) {
          setGeoOptions(response.data[0]);
        } else {
          setGeoOptions(GeographicalOptionDefaultData);
        }
      },
    );
  }, [refresh]);

  const statesCheckboxes = statesListOptions.map((option: any) => (
    <Form.Check
      type="checkbox"
      checked={geoOptions.searchByState?.includes(option.id)}
      id={`states-option-${option.id}`}
      key={`states-option-${option.id}`}
      label={option.name}
      className="genericbutton ms-3 mt-2"
      onChange={() => (
        geoOptions.searchByState?.includes(option.id)
          ? setGeoOptions((p: any) => ({
            ...p, searchByState: geoOptions.searchByState.filter((item: any) => item !== option.id),
          }))
          : setGeoOptions((p: any) => ({
            ...p, searchByState: [...geoOptions.searchByState, option.id],
          }))
      )}
    />
  ));

  const presbyteryCheckboxes = presbyteryOptions.map((option: any) => (
    <Form.Check
      type="checkbox"
      checked={geoOptions.searchByPresbytery?.includes(option.number)}
      id={`presbytery-option-${option.number}`}
      key={`presbytery-option-${option.number}`}
      label={option.name}
      className="genericbutton ms-3 mt-2"
      onChange={() => (
        geoOptions.searchByPresbytery?.includes(option.number)
          ? setGeoOptions((p: any) => ({
            ...p, searchByPresbytery: geoOptions.searchByPresbytery.filter((item: any) => item !== option.number),
          }))
          : setGeoOptions((p: any) => ({
            ...p, searchByPresbytery: [...geoOptions.searchByPresbytery, option.number],
          }))
      )}
    />
  ));

  const saveService = (result: boolean, data: GeographicalOptionData): void => {
    if (result && data) {
      if (data.id > 0) {
        PDPService.UpdateGeographicalOptions(data).then(() => {
          setRefresh((r: boolean) => !r);
        });
      } else {
        PDPService.CreateGeographicalOptions(data).then(() => {
          setRefresh((r: boolean) => !r);
        });
      }
    }
  };

  useEffect(() => {
    const debouncedSave = debounce(() => {
      if (geoOptions.pdp > 0) {
        saveService(true, geoOptions);
      }
    }, 1000);

    debouncedSave();

    // Cleanup function to cancel the debounced call if the component unmounts
    return () => debouncedSave.cancel();
  }, [geoOptions.searchByState.length, geoOptions.searchByPresbytery.length]);

  return (
    <div className="col-lg-8 col-12 mx-auto">
      <div className="mx-auto">
        <div className="title">
          {t('PDP.Geographical_Options')}
        </div>
        <div className="mb-3">
          <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
        </div>
        <div className="d-flex">
          <Button
            className="mb-3 createbutton"
            variant="primary"
            size="sm"
            onClick={importGeographicalOptions}
          >
            {t('CopyFromMyProfile')}
          </Button>
        </div>
        <div>
          <Form.Group
            className="mb-4"
          >
            <Form.Label
              className="col-12 mb-3 bodyheader required"
            >
              {t('Geographic_Choices')}
            </Form.Label>
            <Form.Label
              className="col-12 mb-3 bodyheader"
            >
              {t('Geographic_Choices_Instructions')}
            </Form.Label>
            <Form.Check
              type="radio"
              checked={geoOptions.geoLocation === 'Search By State'}
              label={t('Search_By_State')}
              value="Search By State"
              onChange={(e: any) => setGeoOptions((p: any) => ({
                ...p, geoLocation: 'Search By State',
              }))}
            />
            {geoOptions.geoLocation === 'Search By State' && (
              <div
                className="offset-md-1 mb-3 border rounded-3 state-checkbox-scroll"
              >
                {statesCheckboxes}
              </div>
            )}
            <Form.Check
              type="radio"
              checked={geoOptions.geoLocation === 'Search By Presbytery'}
              label={t('Search_By_Presbytery')}
              value="Search By Presbytery"
              onChange={(e: any) => setGeoOptions((p: any) => ({
                ...p, geoLocation: 'Search By Presbytery',
              }))}
            />
            {geoOptions.geoLocation === 'Search By Presbytery' && (
              <div
                className="offset-md-1 border rounded-3 state-checkbox-scroll"
              >
                {presbyteryCheckboxes}
              </div>
            )}
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default GeographicalOptions;

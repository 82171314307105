import { t } from 'i18next';
import {
  ChangeEvent,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { tryParseInt } from '../../utils';
import { SelectOption } from '../../types/generic.types';

// A generic CLC react hook form component that allows
// a user to register a dropdown list of items

type InputProps = {
  required?: boolean
  defaultData?: any,
  formLabel: string,
  inputName: string,
  options: any,
  register: any,
  error?: boolean,
} & DefaultProps
type DefaultProps = Partial<typeof defaultProps>

const defaultProps = {
  required: false,
  defaultData: undefined,
  error: false,
};

function CLCFormSelect({
  required,
  defaultData,
  formLabel,
  inputName,
  options,
  register,
  error,
}: InputProps): JSX.Element {
  const [selectedOption, setSelectedOption] = useState<any>(defaultData);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedOption({
      ...selectedOption,
      id: tryParseInt(event.target.value),
      value: event.target.options[event.target.selectedIndex].text,
    });
  };

  const dropDownMenu = options?.map((option: SelectOption) => (
    <option key={`option-type-${option.id}`} value={option.id}>
      {option.value}
    </option>
  ));

  return (
    <Form.Group>
      <Form.Label className={`${required ? 'required' : ''} bodyheader`}>
        {formLabel}
      </Form.Label>
      <Form.Select
        value={selectedOption?.id ? selectedOption?.id : ''}
        {...register(inputName, { required })}
        isInvalid={error}
        onChange={handleSelectChange}
      >
        <option key="" value="">
          {`-- ${t('Select')} ${formLabel} --`}
        </option>
        {dropDownMenu}
      </Form.Select>
    </Form.Group>
  );
}

CLCFormSelect.defaultProps = defaultProps;

export default CLCFormSelect;

import api from './api.service';

export interface SurveyResponsePayload {
  surveyId: number,
  question: number,
  response: string,
}

const CompleteSurvey = (): Promise<void> => (api.post('/completesurvey/'));

const UpdateResponse = (data: SurveyResponsePayload): Promise<void> => {
  const payload = new FormData();
  payload.append('surveyId', data.surveyId.toString());
  payload.append('question', data.question.toString());
  payload.append('response', data.response);
  return api.post('/survey/', payload);
};

const exports = {
  UpdateResponse,
  CompleteSurvey,
};

export default exports;

import {
  useState,
  useEffect,
  Suspense,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { InputTags } from 'react-bootstrap-tagsinput';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FormStepProps } from '../../types/generic.types';
import { STATES } from '../../types/constants';
import api from '../../services/api.service';
import TrainingCertsCredsTable from './TrainingCertCredsTable';
import {
  trainingCertificationState,
} from '../../services/state.service';
import {
  TrainingCertificationData,
  TrainingCertificationDefaultValue,
  TrainingCertificationResponseData,
  IntroductionAdvancedTypeData,
  IntroductionAdvancedTypeResponseData,
} from '../../types/education.types';
import { tryParseInt } from '../../utils';
import OtherTrainingModal from './OtherTrainingModal';

function TrainingCertification({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [trainingCertification, setTrainingCertification] = useRecoilState<TrainingCertificationData>(
    trainingCertificationState,
  );
  const [showNewEditOtherTraining, setShowNewEditOtherTraining] = useState(false);
  const [introductionAdvancedOptions, setIntroductionAdvancedOptions] = useState<IntroductionAdvancedTypeData[]>([]);
  const [charLimitWarning, setCharLimitWarning] = useState<string>('');

  const {
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<TrainingCertificationData>();
  watch('introAdvOne');
  watch('introAdvTwo');
  const currentOtherTraining = watch('otherTraining');

  useEffect(() => {
    api.get('/introductionadvancedtypes/').then(
      (response: IntroductionAdvancedTypeResponseData) => {
        setIntroductionAdvancedOptions(response.data.options);
        api.get('/profile/trainingcertification/').then(
          (r: TrainingCertificationResponseData) => {
            if (r.data[0]) {
              setTrainingCertification(r.data[0]);
              if (r.data[0].introAdvOne === null) {
                setTrainingCertification((l) => ({ ...l, introAdvOne: '' }));
              }
              if (r.data[0].introAdvTwo === null) {
                setTrainingCertification((l) => ({ ...l, introAdvTwo: '' }));
              }
            } else {
              setTrainingCertification(TrainingCertificationDefaultValue);
            }
          },
        );
      },
    );
  }, []);

  useEffect(() => {
    // reset form with user data
    if (trainingCertification) reset(trainingCertification);
  }, [trainingCertification]);

  const IntroductionAdvancedTypeMenu = introductionAdvancedOptions?.map((option: IntroductionAdvancedTypeData) => (
    <option key={`introductionAdvanced-${option.id}`} value={option.id}>
      {option.description}
    </option>
  ));

  function generatePayload(data: TrainingCertificationData): TrainingCertificationData {
    const {
      id,
      introAdvOne,
      countryOne,
      cityOne,
      stateOne,
      yearOne,
      introAdvTwo,
      countryTwo,
      cityTwo,
      stateTwo,
      yearTwo,
      transitionalMinistryTraining,
      trainingCertCredentials,
      otherTraining,
      ...payload
    } = data;
    return {
      ...payload,
      id: id && id > 0 ? id : 0,
      introAdvOne: introAdvOne && introAdvOne > 0 ? introAdvOne : '',
      countryOne: countryOne && countryOne !== '' ? countryOne : '',
      cityOne: cityOne && cityOne !== '' ? cityOne : '',
      stateOne: stateOne && stateOne !== '' ? stateOne : '',
      yearOne: yearOne && yearOne !== '' ? yearOne : '',
      introAdvTwo: introAdvTwo && introAdvTwo > 0 ? introAdvTwo : '',
      countryTwo: countryTwo && countryTwo !== '' ? countryTwo : '',
      cityTwo: cityTwo && cityTwo !== '' ? cityTwo : '',
      stateTwo: stateTwo && stateTwo !== '' ? stateTwo : '',
      yearTwo: yearTwo && yearTwo !== '' ? yearTwo : '',
      transitionalMinistryTraining,
      trainingCertCredentials: trainingCertCredentials && trainingCertCredentials.length > 0
        ? trainingCertCredentials : [],
      otherTraining: otherTraining && otherTraining !== '' ? otherTraining : '',
    };
  }

  const onSubmit: SubmitHandler<TrainingCertificationData> = (data: TrainingCertificationData) => {
    const payload = generatePayload(data);
    if (payload.id && payload.id > 0) {
      api.put(`/profile/trainingcertification/${payload.id}/`, payload).then(() => {
        formSubmitted();
      });
    } else {
      api.post('profile/trainingcertification/', payload).then(() => {
        formSubmitted();
      });
    }
  };

  const statesMenu = STATES?.map((option: string) => (
    <option key={option} value={option}>
      {option}
    </option>
  ));

  function tagLimit(tag: string): any {
    if (tag && tag.length > 50) {
      setCharLimitWarning(t('Char_Limit_Error'));
      return false;
    }
    setCharLimitWarning(t(''));
    return true;
  }

  const closeNewEditOtherTraining = useCallback((result: boolean, tag: string[]): void => {
    if (result && tag) {
      setShowNewEditOtherTraining(false);
      setTrainingCertification(
        (p: any) => ({ ...p, otherTraining: p.otherTraining.concat(','.concat(tag[0])).replace(/^,+/, '') }),
      );
    } else {
      setShowNewEditOtherTraining(false);
    }
  }, []);

  return (
    <div className="container-fluid mt-3">
      <div className="col-12 mb-5 text-center">
        <div className="title text-center">{t('Training_Certification_Title')}</div>
        <div>
          {t('TrainingCertification_Header')}
        </div>
        <div className="col-12 mb-3 text-center non-field-error">
          {charLimitWarning}
        </div>
      </div>
      <Form id="trainingcertification-form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id')} />
        <div className="row">
          <div className="col-12 col-md-8 mx-auto mb-2">
            <Form.Check
              type="checkbox"
              label={t('Transitional_Ministry_Training')}
              checked={trainingCertification.transitionalMinistryTraining}
              onChange={() => setTrainingCertification((l) => (
                {
                  ...l,
                  transitionalMinistryTraining: !trainingCertification.transitionalMinistryTraining,
                  introAdvOne: '',
                  countryOne: '',
                  cityOne: '',
                  yearOne: '',
                  stateOne: '',
                  introAdvTwo: '',
                  countryTwo: '',
                  cityTwo: '',
                  yearTwo: '',
                  stateTwo: '',
                }
              ))}
              maxLength={500}
            />
            {trainingCertification.transitionalMinistryTraining && (
              <>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label>
                    {t('Week_One_Introductory_by')}
                  </Form.Label>
                  <Form.Select
                    {...register('introAdvOne', { required: true })}
                    isInvalid={!!errors.introAdvOne}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, introAdvOne: e.target.value ? tryParseInt(e.target.value) : '' }),
                      );
                    }}
                  >
                    <option key="" value="">
                      {t('Select_Introductory')}
                    </option>
                    {IntroductionAdvancedTypeMenu}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.introAdvOne && errors.introAdvOne.message
                      ? errors.introAdvOne.message
                      : t('FieldRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label className="bodyheader mt-3">
                    {t('At_City')}
                  </Form.Label>
                  <Form.Control
                    {...register('cityOne', { required: true })}
                    isInvalid={!!errors.cityOne}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, cityOne: e.target.value }),
                      );
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cityOne && errors.cityOne.message
                      ? errors.cityOne.message
                      : t('CityRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label className="bodyheader mt-3">
                    {t('At_State')}
                  </Form.Label>
                  <Form.Select
                    {...register('stateOne', { required: true })}
                    isInvalid={!!errors.stateOne}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, stateOne: e.target.value }),
                      );
                    }}
                  >
                    <option key="" value="">
                      {t('Select_State')}
                    </option>
                    {statesMenu}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label className="bodyheader mt-3">
                    {t('At_Country')}
                  </Form.Label>
                  <Form.Control
                    {...register('countryOne')}
                    isInvalid={!!errors.countryOne}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, countryOne: e.target.value }),
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label className="bodyheader mt-3">
                    {t('In_Year')}
                  </Form.Label>
                  <Form.Control
                    {...register('yearOne', { valueAsNumber: true, required: true })}
                    isInvalid={!!errors.yearOne}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, yearOne: e.target.value }),
                      );
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.yearOne && errors.yearOne.message
                      ? errors.yearOne.message
                      : t('YearRequired')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-11 mx-auto mt-4 mb-1">
                  <Form.Label>
                    {t('Week_Two_Advanced_by')}
                  </Form.Label>
                  <Form.Select
                    {...register('introAdvTwo')}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, introAdvTwo: e.target.value ? tryParseInt(e.target.value) : '' }),
                      );
                    }}
                  >
                    <option key="" value="">
                      {t('Select_Advanced')}
                    </option>
                    {IntroductionAdvancedTypeMenu}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label className="bodyheader mt-3">
                    {t('At_City')}
                  </Form.Label>
                  <Form.Control
                    {...register('cityTwo')}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, cityTwo: e.target.value }),
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label className="bodyheader mt-3">
                    {t('At_State')}
                  </Form.Label>
                  <Form.Select
                    {...register('stateTwo')}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, stateTwo: e.target.value }),
                      );
                    }}
                  >
                    <option key="" value="">
                      {t('Select_State')}
                    </option>
                    {statesMenu}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-1">
                  <Form.Label className="bodyheader mt-3">
                    {t('At_Country')}
                  </Form.Label>
                  <Form.Control
                    {...register('countryTwo')}
                    isInvalid={!!errors.countryTwo}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, countryTwo: e.target.value }),
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-11 mx-auto mb-2">
                  <Form.Label className="bodyheader mt-3">
                    {t('In_Year')}
                  </Form.Label>
                  <Form.Control
                    {...register('yearTwo', { valueAsNumber: true })}
                    onChange={(e) => {
                      setTrainingCertification(
                        (l) => ({ ...l, yearTwo: e.target.value }),
                      );
                    }}
                  />
                </Form.Group>
              </>
            )}
          </div>
          <Form.Group className="col-12 col-md-8 mx-auto mb-3">
            <div className="border border-bottom-0 rounded">
              <Suspense fallback={
                (
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                )
              }
              >
                <TrainingCertsCredsTable />
              </Suspense>
            </div>
          </Form.Group>
          <OtherTrainingModal
            show={showNewEditOtherTraining}
            callback={closeNewEditOtherTraining}
          />
          <Form.Group className="col-12 col-md-8 mx-auto mt-4 mb-3">
            <div className="row mb-3">
              <div className="col-3">
                <Form.Label className="bodyheader">
                  {t('Other_Training')}
                </Form.Label>
              </div>
              <div className="col-9 text-end">
                <Button
                  onClick={() => {
                    setShowNewEditOtherTraining(true);
                  }}
                  className="ms-auto createbutton"
                  variant="primary"
                  size="sm"
                  active
                >
                  {`+ ${t('Add_Other_Training')}`}
                </Button>
              </div>
            </div>
            <InputTags
              values={
                currentOtherTraining
                && currentOtherTraining.length > 0
                  ? (
                    currentOtherTraining.split(',')
                  ) : (
                    undefined
                  )
              }
              onTags={(value) => {
                tagLimit(value.values[value.values.length - 1]);
                setValue('otherTraining', value.values.toString());
                setTrainingCertification((p: any) => ({ ...p, otherTraining: value.values.toString() }));
              }}
            />
          </Form.Group>
        </div>
      </Form>
    </div>
  );
}

export default TrainingCertification;

import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
  useFieldArray,
  useFormState,
} from 'react-hook-form';
import {
  Col,
  Form,
  Row,
} from 'react-bootstrap';

import api from '../services/api.service';
import { mdpStepState, mdpState } from '../services/state.service';

import {
  MDPData,
  MDPReferenceData,
  MDPReferenceDefaultData,
  MDPReferenceResponseData,
} from '../types/mdp.types';
import { StepWizardData, StepProps } from '../types/generic.types';
import { validEmail } from '../utils';
import DeleteButton from './generic/DeleteButton';

type ReferencesForm = {
  references: MDPReferenceData[],
}

function MDPReferences({
  submitRef,
}: StepProps): JSX.Element {
  const [mdp] = useRecoilState<MDPData>(mdpState);
  const [, setSteps] = useRecoilState<StepWizardData[]>(mdpStepState);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    trigger,
    control,
  } = useForm<ReferencesForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const { touchedFields, isValid, errors } = useFormState<ReferencesForm>({
    control,
    name: 'references',
  });

  const {
    fields,
    update,
  } = useFieldArray<ReferencesForm>({
    control,
    name: 'references',
  });

  const onSubmit: SubmitHandler<ReferencesForm> = async (data: ReferencesForm) => {
    data.references.forEach((reference: MDPReferenceData) => {
      const ref = { ...reference, mdp: mdp.id };
      if (reference.refId) {
        if (
          !ref.name
          && !ref.relation
          && !ref.phone
          && !ref.email
        ) {
          api.delete(`/mdp/${mdp.id}/ministryreference/${reference.refId}/`);
        } else {
          api.put(`/mdp/${mdp.id}/ministryreference/${reference.refId}/`, ref);
        }
      } else if (
        ref.name
        && ref.relation
        && ref.phone
        && ref.email
      ) {
        api.post(`/mdp/${mdp.id}/ministryreference/`, ref);
      }
    });
  };

  // https://react-hook-form.com/api/usefieldarray
  const watchReferences = watch('references');
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchReferences[index],
  }));

  // Stray from generic useStepValid hook because
  // this relies on two separate states to determine validity
  useEffect(() => {
    setSteps((p: StepWizardData[]) => (
      p.map((b: StepWizardData) => {
        if (b.key === 'references') {
          return { ...b, isValid };
        }

        return b;
      })
    ));
  }, [isValid]);

  useEffect(() => {
    if (fields) trigger('references');
  }, [trigger, fields]);

  // Get data on first render
  useEffect(() => {
    api.get(`/mdp/${mdp.id}/ministryreference/`).then(
      (response: MDPReferenceResponseData) => {
        [0, 1, 2].forEach((key: number) => {
          update(key, response.data[key] || MDPReferenceDefaultData);
        });
      },
    );
  }, []);

  return (
    <div>
      <Row className="title text-center">
        <Col>{t('References')}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={8} className="mb-4">{t('MDP_References_Description')}</Col>
      </Row>
      <Row className="justify-content-center mb-4 d-none d-md-flex">
        <Col md={3} xs={12} className="fw-bold">{t('Name')}</Col>
        <Col md={2} xs={12} className="fw-bold">{t('Relation')}</Col>
        <Col md={2} xs={12} className="fw-bold">{t('Phone')}</Col>
        <Col md={3} xs={12} className="fw-bold">{t('Email')}</Col>
        <Col md={1} xs={4} />
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {controlledFields.map((field, index) => {
          const values = getValues(`references.${index}`);
          const required = ['name', 'relation', 'phone', 'email'].some((f) => (
            values && values[f] !== ''
          ));

          return (
            <Row key={`references-${field.id}`} className="justify-content-center mb-4">
              <Col xs={12} md={3}>
                <Form.Control
                  type="text"
                  {...register(`references.${index}.name` as const, {
                    required: {
                      value: index === 0 || required,
                      message: `${t('Name')} ${t('IsRequired')}`,
                    },
                  })}
                  isInvalid={touchedFields.references?.[index]?.name
                    && !!errors?.references?.[index]?.name?.message}
                  className="mb-2"
                  placeholder={t('Name')}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="mb-2"
                >
                  {String(errors?.references?.[index]?.name?.message)}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={2}>
                <Form.Control
                  type="text"
                  {...register(`references.${index}.relation` as const, {
                    required: {
                      value: index === 0 || required,
                      message: `${t('Relation')} ${t('IsRequired')}`,
                    },
                  })}
                  maxLength={100}
                  isInvalid={touchedFields.references?.[index]?.relation
                    && !!errors?.references?.[index]?.relation?.message}
                  className="mb-2"
                  placeholder={t('Relation')}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="mb-2"
                >
                  {String(errors?.references?.[index]?.relation?.message)}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={2}>
                <Form.Control
                  type="text"
                  {...register(`references.${index}.phone` as const, {
                    required: {
                      value: index === 0 || required,
                      message: `${t('Phone')} ${t('IsRequired')}`,
                    },
                  })}
                  isInvalid={touchedFields.references?.[index]?.phone
                    && !!errors?.references?.[index]?.phone?.message}
                  className="mb-2"
                  placeholder={t('Phone')}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="mb-2"
                >
                  {String(errors?.references?.[index]?.phone?.message)}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={3}>
                <Form.Control
                  type="text"
                  {...register(`references.${index}.email` as const, {
                    required: {
                      value: index === 0 || required,
                      message: `${t('Email')} ${t('IsRequired')}`,
                    },
                    pattern: {
                      value: validEmail,
                      message: t('InvalidEmail'),
                    },
                  })}
                  isInvalid={touchedFields.references?.[index]?.email
                    && !!errors?.references?.[index]?.email?.message}
                  className="mb-2"
                  placeholder={t('Email')}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className="mb-2"
                >
                  {String(errors?.references?.[index]?.email?.message)}
                </Form.Control.Feedback>
              </Col>
              <Form.Control
                type="hidden"
                {...register(`references.${index}.refId` as const)}
              />
              <Col xs={12} md={1} className="d-flex flex-row justify-content-center align-items-center gap-2">
                <DeleteButton
                  label=""
                  handler={() => {
                    update(index, { ...MDPReferenceDefaultData, refId: field.refId });
                  }}
                />
                <div className="d-block d-md-none">{t('RemoveAbove')}</div>
              </Col>
            </Row>
          );
        })}
        <button
          ref={submitRef}
          type="submit"
          style={{ display: 'none' }}
          aria-label="submit"
        />
      </Form>
    </div>

  );
}

export default MDPReferences;

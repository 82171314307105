import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Form,
} from 'react-bootstrap';
import {
  MIN_SCORE,
  MAX_SCORE,
  GAP_SCORE,
} from '../types/constants';
import { MatchData, MatchCharacteristicData } from '../types/mdp.types';
import { matchState } from '../services/state.service';

function ManageMatchingModalSliders(): JSX.Element {
  const { t } = useTranslation();
  const [match, setMatch] = useRecoilState<MatchData>(matchState);

  return (
    <div className="mx-auto">
      <div className="row">
        <div className="col-xs-12">
          <div className="title">{t('MDPMatchCriteriaTitle')}</div>
          <div>{t('MDPMatchCriteriaDescription')}</div>
        </div>
      </div>
      {match.matchCharacteristics?.map((option: MatchCharacteristicData) => (
        <div key={option.question + option.characteristic} className="my-3">
          {option.question !== 'None' && (
            <Form.Group className="text-start">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <span className="match-characteristics-label">{option.question}</span>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="row">
                    <div className="col-5">{t('Match_Slider_Less_Desired')}</div>
                    <div className="col-5 text-end">{t('Match_Slider_Highly_Desired')}</div>
                  </div>
                  <div className="row">
                    <div className="col-10">
                      <Form.Range
                        value={option.desiredScore}
                        min={MIN_SCORE}
                        max={MAX_SCORE}
                        step={GAP_SCORE}
                        onChange={(e: any) => setMatch((p: any) => ({
                          ...p,
                          matchCharacteristics:
                          p.matchCharacteristics.map((mc: any) => (mc.id === option.id ? ({
                            ...mc, desiredScore: parseInt(e.target.value, 10),
                          }) : (mc))),
                        }))}
                      />
                    </div>
                    <div className="col-2">
                      <span className="desired-score-label">{`${option.desiredScore}`}</span>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </Form.Group>
          )}
        </div>
      ))}
    </div>
  );
}

export default ManageMatchingModalSliders;

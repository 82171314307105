import GeneralInfo from '../components/ministry/GeneralInfo';
import ChurchAttendance from '../components/ministry/ChurchAttendance';
import EthnicInfo from '../components/ministry/EthnicInfo';

export const MinistryProfileSteps = [
  {
    key: 'generalinfo',
    label: 'GeneralInfo',
    isValid: true,
    StepComponent: GeneralInfo,
  },
  {
    key: 'churchattendance',
    label: 'ChurchAttendance',
    isValid: true,
    StepComponent: ChurchAttendance,
  },
  {
    key: 'ethnicinfo',
    label: 'EthnicInfo',
    isValid: true,
    StepComponent: EthnicInfo,
  },
];

import {
  useState,
  useEffect,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import {
  SearchCommitteeData,
  SearchCommitteeResponseData,
} from '../types/ministry.types';
import {
  presbyteryMatchingState,
  currentRoleState,
} from '../services/state.service';
import {
  PresbyteryMatchingOptionData,
  PresbyteryMatchingOptionResponseData,
} from '../types/mdp.types';
import { PRESBYTERY_MATCHING_OPTIONS } from '../types/constants';
import api from '../services/api.service';
import { Role } from '../types/user.types';

function PresbyteryMatching(): JSX.Element {
  const { t } = useTranslation();
  const [committees, setCommittees] = useState<SearchCommitteeData[]>([]);
  const [matchingOptions, setMatchingOptions] = useRecoilState<PresbyteryMatchingOptionData>(presbyteryMatchingState);
  const currentRole = useRecoilValue<Role>(currentRoleState);

  const {
    register,
    reset,
  } = useForm<PresbyteryMatchingOptionData>();

  const handlePresbyteryMatchingChange = (payload: PresbyteryMatchingOptionData): void => {
    if (payload.id > 0) {
      if (payload.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.committee) {
        if (payload.matchByCommittee.length > 0) {
          api.post(`presbyterymatching/${currentRole.organizationId}/`, payload);
        }
      } else {
        api.post(`presbyterymatching/${currentRole.organizationId}/`, payload);
      }
    }
  };

  const searchCommitteeToggles = committees.map((option: any) => (
    <Form.Check
      type="switch"
      checked={matchingOptions.matchByCommittee?.includes(option.id)}
      id={`committee-option-${option.id}`}
      key={`committee-option-${option.id}`}
      label={option.name}
      className="genericbutton ms-3 mt-2 custom-switch"
      onChange={() => {
        setMatchingOptions((prevState: any) => {
          const updatedMatchByCommittee = prevState.matchByCommittee?.includes(option.id)
            ? prevState.matchByCommittee.filter((item: any) => item !== option.id)
            : [...prevState.matchByCommittee, option.id];
          const updatedState = {
            ...prevState,
            matchByCommittee: updatedMatchByCommittee,
          };
          handlePresbyteryMatchingChange(updatedState);
          return updatedState;
        });
      }}
    />
  ));

  useEffect(() => {
    if (currentRole.id > 0) {
      api.get(`presbyterymatching/${currentRole.organizationId}/`)
        .then(
          (response: PresbyteryMatchingOptionResponseData) => {
            if (response.data.options) {
              setMatchingOptions(response.data.options);
            }
          },
        );
      api.get(`/presbyterycommittees/${currentRole.organizationId}/`)
        .then(
          (response: SearchCommitteeResponseData) => {
            if (response.data.committees.length > 0) {
              setCommittees(response.data.committees);
            } else {
              setCommittees([]);
            }
          },
        );
      reset(matchingOptions);
    }
  }, [currentRole]);

  return (
    <div>
      <Form>
        <Form.Group
          className="mb-4"
        >
          <Form.Label
            className="col-12 mb-3 bodyheader"
          >
            {t('Presbytery_Matching')}
            <OverlayTrigger
              placement="right"
              overlay={(
                <Tooltip id="tooltip-presbytery-matching">
                  {t('Presbytery_Matching_Tooltip')}
                </Tooltip>
              )}
            >
              <span>
                <FaQuestionCircle className="ms-2 top-align-tooltip" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Check
            type="radio"
            checked={
              matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.none
            }
            label={t('Match_By_None')}
            {...register('matchingChoice')}
            value={PRESBYTERY_MATCHING_OPTIONS.none}
            onChange={() => {
              setMatchingOptions((prevState: any) => {
                const updatedState = {
                  ...prevState,
                  matchingChoice: PRESBYTERY_MATCHING_OPTIONS.none,
                  matchByOrdainedPositions: undefined,
                  matchByCommittee: [],
                };
                handlePresbyteryMatchingChange(updatedState);
                return updatedState;
              });
            }}
          />
          <Form.Check
            type="radio"
            checked={matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.ordained}
            label={t('Match_By_Ordained')}
            {...register('matchingChoice')}
            value={PRESBYTERY_MATCHING_OPTIONS.ordained}
            onChange={() => {
              setMatchingOptions((prevState: any) => {
                const updatedState = {
                  ...prevState,
                  matchingChoice: PRESBYTERY_MATCHING_OPTIONS.ordained,
                  matchByOrdainedPositions: true,
                  matchByCommittee: [],
                };
                handlePresbyteryMatchingChange(updatedState);
                return updatedState;
              });
            }}
          />
          <Form.Check
            type="radio"
            checked={matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.committee}
            label={t('Match_By_Search_Committee')}
            {...register('matchingChoice')}
            value={PRESBYTERY_MATCHING_OPTIONS.committee}
            onChange={() => {
              setMatchingOptions((prevState: any) => {
                const updatedState = {
                  ...prevState,
                  matchingChoice: PRESBYTERY_MATCHING_OPTIONS.committee,
                  matchByOrdainedPositions: undefined,
                };
                handlePresbyteryMatchingChange(updatedState);
                return updatedState;
              });
            }}
          />
          {matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.committee && (
            <div
              className="offset-md-1 border rounded-3 state-checkbox-scroll"
            >
              {searchCommitteeToggles}
            </div>
          )}
        </Form.Group>
      </Form>
    </div>
  );
}

export default PresbyteryMatching;

import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Table,
  Button,
  InputGroup,
} from 'react-bootstrap';

import api from '../services/api.service';
import { pdpStepState, pdpState } from '../services/state.service';
import {
  InputValidationProps,
  PDPData,
  ReferenceData,
  ReferenceResponseData,
} from '../types/pdp.types';
import DeleteButton from './generic/DeleteButton';
import ConfirmModal from './generic/ConfirmModal';
import { StepWizardData, StepProps } from '../types/generic.types';
import { validEmail } from '../utils';

function InputWithValidation({
  field,
  option,
  edit,
  save,
  pattern,
  errorMessage,
}: InputValidationProps): JSX.Element {
  const [valid, setValid] = useState<boolean>(true);

  // Set up a callback to validate the input instead of useEffect
  // to only run on blur, not on each keystroke.
  const checkValid = async (ref: ReferenceData): Promise<boolean | never> => {
    if (!pattern.test(ref[field])) {
      setValid(false);
      throw new Error(errorMessage);
    } else {
      setValid(true);
      return true;
    }
  };

  return (
    <InputGroup hasValidation>
      <Form.Group>
        <Form.Control
          type="text"
          className={`rounded my-3 ${!valid ? 'input--error' : ''}`}
          value={option[field]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            edit(e, option.id, e.target.value, field);
          }}
          onBlur={() => {
            checkValid(option)
              .then(() => {
                save(option);
              });
          }}
          isValid={valid && option[field] !== ''}
          isInvalid={!valid}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    </InputGroup>
  );
}

function References({
  id,
}: StepProps): JSX.Element {
  const intitalReference = {
    id: 0,
    pdp: id,
    name: '',
    relation: '',
    phone: '',
    email: '',
  };

  const { t } = useTranslation();
  const [, setSteps] = useRecoilState<StepWizardData[]>(pdpStepState);
  const [references, setReferences] = useState<ReferenceData[]>([]);
  const [currentReference, setCurrentReference] = useState<ReferenceData>(intitalReference);
  const [refreshReferences, setRefreshReferences] = useState(false);
  const [pdp, setPDP] = useRecoilState<PDPData>(pdpState);
  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState<ReferenceData>(intitalReference);

  // Stray from generic useStepValid hook because
  // this relies on two separate states to determine validity
  useEffect(() => {
    const referenceFields = ['email', 'name', 'phone', 'relation'];
    const [first] = references;
    const hasReference = first ? referenceFields.every((field: string) => (
      first[field] !== undefined
      && first[field] !== null
      && first[field] !== ''
    )) : false;

    const EmailsValid = references.every((ref: ReferenceData) => (
      validEmail.test(ref.email)
    ));

    const refLenValid = references.length >= 3;

    const isAuthorized = !!pdp.authorized;

    if (
      hasReference
      && isAuthorized
      && EmailsValid
      && refLenValid
    ) {
      setSteps((p: StepWizardData[]) => (
        p.map((b: StepWizardData) => {
          if (b.key === 'references') {
            return { ...b, isValid: true };
          }

          return b;
        })
      ));
    } else {
      setSteps((p: StepWizardData[]) => (
        p.map((b: StepWizardData) => {
          if (b.key === 'references') {
            return { ...b, isValid: false };
          }

          return b;
        })
      ));
    }
  }, [references, pdp]);

  useEffect(() => {
    api.get(`/pdp/${id}/reference/`).then(
      (response: ReferenceResponseData) => (setReferences(response.data)),
    );
  }, [id, refreshReferences]);

  // @todo: Rework. This creates a new blank reference every
  // time the user clicks the add button. This probably
  // isn't the best way to do this, but it works for now.
  function makeNewReference(ref: ReferenceData): any {
    api.post(`/pdp/${ref.pdp}/reference/`, ref)
      .then(() => {
        setRefreshReferences((r: boolean) => !r);
      });
  }

  const deleteReference = useCallback((result: boolean): any => {
    if (result && toDelete) {
      api.delete(`/pdp/${toDelete.pdp}/reference/${toDelete.id}`)
        .then(() => {
          setShowDelete(false);
          setRefreshReferences((r: boolean) => !r);
        });
    } else {
      setShowDelete(false);
    }
  }, [toDelete]);

  const editReference = (
    e: ChangeEvent<HTMLInputElement>,
    identifier: number,
    val: string,
    field: string,
  ): void => {
    e.preventDefault();
    setReferences(references.map((r) => ((r.id === identifier ? { ...r, [field]: val } : r))));
  };

  const saveReference = (ref: ReferenceData): void => {
    api.put(`/pdp/${ref.pdp}/reference/${ref.id}/`, ref)
      .then(() => {
        setRefreshReferences((r: boolean) => !r);
      });
  };

  return (
    <div>
      <div className="title">
        {t('PDP.References')}
      </div>
      <div className="col-12 mx-auto mb-3">
        {t('PDP.References_Description')}
      </div>
      <div className="rounded-3">
        <Table striped responsive hover className="mb-3 linktable">
          <thead className="">
            <tr className="linktableheader reftable text-dark">
              <th>{t('Name')}</th>
              <th>{t('PDP.Relation')}</th>
              <th>{t('PDP.Phone')}</th>
              <th>{t('PDP.Email')}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {references.map((option: ReferenceData) => (
              <tr key={option.id} className="reftablecols">
                <td>
                  <Form.Control
                    tabIndex={0}
                    type="text"
                    className="rounded my-3"
                    value={option.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      editReference(e, option.id, e.target.value, 'name');
                    }}
                    onBlur={() => (saveReference(option))}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    className="rounded my-3"
                    value={option.relation}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      editReference(e, option.id, e.target.value, 'relation');
                    }}
                    onBlur={() => (saveReference(option))}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    className="rounded my-3"
                    value={option.phone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      editReference(e, option.id, e.target.value, 'phone');
                    }}
                    onBlur={() => (saveReference(option))}
                  />
                </td>
                <td>
                  <InputWithValidation
                    field="email"
                    option={option}
                    edit={editReference}
                    save={saveReference}
                    pattern={validEmail}
                    errorMessage={t('InvalidEmail')}
                  />
                </td>
                <td>
                  <div className="mt-3">
                    <DeleteButton
                      label=""
                      handler={() => {
                        setToDelete(option);
                        setShowDelete(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {references.length < 6
        ? (
          <Button
            onClick={() => {
              setCurrentReference(intitalReference);
              makeNewReference(currentReference);
            }}
            className="mb-3 d-flex"
            variant="primary"
            size="sm"
            active
          >
            {`+ ${t('PDP.Add_Ref')}`}
          </Button>
        )
        : null}
      <Form.Check
        type="checkbox"
        checked={pdp.authorized}
        className="genericbutton"
        name="pdpcheckbox"
        label={t('PDP.Reference_Authorization')}
        onChange={(e: any) => setPDP((p: any) => ({
          ...p, authorized: e.target.checked,
        }))}
      />
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('PDP.References_Confirmation_Message')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={deleteReference}
      />
    </div>
  );
}

export default References;

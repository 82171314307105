import { useTranslation } from 'react-i18next';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="footer text-center mb-2">
      <ul className="no-bullets inline-list">
        <div className="col-12">
          <li>
            <a
              id="Footer_FooterPrivacyHyperLink"
              href="https://oga.pcusa.org/privacy/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('Privacy_Policy')}
            </a>
          </li>
          <li>
            &nbsp;
            |
            &nbsp;
          </li>
          <li className="spaced-list">
            <a
              id="Footer_FooterTermsHyperLink"
              href="https://oga.pcusa.org/resource/terms-use/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('Terms_Of_Use')}
            </a>
          </li>
          <li>
            &nbsp;
            |
            &nbsp;
          </li>
          <li>
            <a
              id="Footer_FooterManualFormsHyperLink"
              href="https://oga.pcusa.org/section/mid-council-ministries/clc/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('CLC_Manual_Forms')}
            </a>
          </li>
        </div>
        <div className="col-12">
          <li>{t('Copyright')}</li>
        </div>
      </ul>
    </div>
  );
}

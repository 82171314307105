import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import Accordion from 'react-bootstrap/Accordion';
import {
  Button,
  Modal,
  Form,
} from 'react-bootstrap';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import {
  FaPlus,
  FaUsers,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION, MINISTRY_ORG_TYPE } from '../types/constants';
import {
  SearchCommitteeData,
  SearchCommitteeResponseData,
  SearchOrganizationData,
} from '../types/ministry.types';
import api from '../services/api.service';
import { currentRoleState, searchOrganizationState } from '../services/state.service';
import { Role } from '../types/user.types';
import SearchCommittee from './SearchCommittee';

type SearchCommitteeForm = {
  id: number,
  name: string,
  organization: number,
}

const SearchCommitteeFormDefault: SearchCommitteeForm = {
  id: 0,
  name: 'Search Committee',
  organization: 0,
};

function SearchCommitteesAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [committees, setCommittees] = useState<SearchCommitteeData[]>([]);
  const organizations = useRecoilValue<SearchOrganizationData[]>(searchOrganizationState);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const {
    handleSubmit,
    register,
    formState: {
      errors,
    },
  } = useForm<SearchCommitteeForm>({
    defaultValues: SearchCommitteeFormDefault,
  });

  useEffect(() => {
    api.get(`/searchcommittees/${currentRole.organizationId}/`)
      .then(
        (response: SearchCommitteeResponseData) => {
          if (response.data.committees.length > 0) {
            setCommittees(response.data.committees);
          } else {
            setCommittees([]);
          }
        },
      );
  }, [refresh, currentRole]);

  function Create(): JSX.Element {
    const handleClose = (): void => setShow(false);
    const handleShow = (): void => setShow(true);

    const onSubmit: SubmitHandler<SearchCommitteeForm> = (data: SearchCommitteeForm) => {
      const payload = new FormData();
      payload.append('name', data.name);
      api.post(`/searchcommittees/${data.id > 0 ? data.id : currentRole.organizationId}/`, payload)
        .then(() => setShow(false))
        .then(() => setRefresh((r: boolean) => !r));
    };

    const organization = organizations?.map((option: SearchOrganizationData) => (
      <option key={`${option.name}-${option.id}`} value={option.id}>
        {option.name}
      </option>
    ));

    return (
      <>
        <Button
          onClick={handleShow}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          <FaPlus />
          {` ${t('FormCommittee')}`}
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          className="modalstyle"
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <div className="mx-auto mt-3">
              <div className="title">{t('FormCommittee')}</div>
            </div>
            <Form className="mx-auto col-8" onSubmit={handleSubmit(onSubmit)}>
              {(currentRole.organizationType === MINISTRY_ORG_TYPE.presbytery
              || currentRole.organizationType === MINISTRY_ORG_TYPE.synod) && (
                <Form.Group>
                  <Form.Label className="bodyheader mt-3 required">
                    {t('Organization')}
                  </Form.Label>
                  <Form.Select
                    {...register('id')}
                  >
                    <option key={0} value={0}>
                      {t('Select_Organization')}
                    </option>
                    {organization}
                  </Form.Select>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Committee_Name')}
                </Form.Label>
                <Form.Control
                  {...register('name', { required: true })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name && errors.name.message
                    ? errors.name.message
                    : t('NameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="primary" onClick={() => submitRef.current?.click()}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function RenderCommittees(): JSX.Element {
    return (
      <>
        {committees?.map((com: SearchCommitteeData, index) => (
          <SearchCommittee
            key={`search-committee-${com.id}`}
            eventKey={index.toString()}
            committee={com}
            updateCallback={() => setRefresh((r: boolean) => !r)}
          />
        ))}
        <div />
      </>
    );
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaUsers />
        <span className="accordion-header-label">
          {t('SearchCommittees')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="mb-3 mx-auto mobiletable col-lg-12">
          <div className="d-flex">
            {currentRole.functions.indexOf(CLC_FUNCTION.createSearchCommittee) > -1 && Create()}
          </div>
          <Accordion
            className="accordion mb-3 mx-auto col-lg-12"
            defaultActiveKey={['0']}
            alwaysOpen
          >
            {currentRole && (
              <RenderCommittees />
            )}
          </Accordion>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed([
  CLC_FUNCTION.createSearchCommittee,
  CLC_FUNCTION.manageSearchCommittees,
  CLC_FUNCTION.viewMatches,
].join(','))(SearchCommitteesAccordionItem);

import {
  CommunityTypeResponseData,
  EmploymentTypeResponseData,
  HousingTypeResponseData,
  IExperienceLevelResponse,
  IPositionTypeResponse,
  PDPData,
  PDPListResponseData,
  ActivePDPListResponseData,
  PDPResponseData,
  SizeRangeResponseData,
  WorkData,
  WorkResponseData,
  ServiceData,
  ServiceResponseData,
  CongregationResponseData,
  GeographicalOptionData,
  GeographicalOptionResponseData,
  TrainingEducationResponseData,
} from '../types/pdp.types';
import { PDPCallingInfoResponseData } from '../types/profile.types';

import api from './api.service';

const GetPDPList = (): Promise<PDPListResponseData> => (
  api.get('/pdp/')
);

const GetActivePDPList = (): Promise<ActivePDPListResponseData> => (
  api.get('/activepdps/')
);

const CreatePDP = (pdp: PDPData): Promise<PDPResponseData> => (
  api.post('/pdp/', pdp)
);

const DeletePDP = (pdp: PDPData): Promise<PDPResponseData> => (
  api.delete(`/pdp/${pdp.id}/`)
);

const GetPDPData = (pdpId: string): Promise<PDPResponseData> => (
  api.get(`/pdp/${pdpId}/`)
);

const UpdatePDP = (pdp: PDPData): Promise<PDPListResponseData> => (
  api.put(`/pdp/${pdp.id}/`, pdp)
);

const GetHousingTypes = (): Promise<HousingTypeResponseData> => (
  api.get('/housingtypes/')
);

const GetEmploymentTypes = (): Promise<EmploymentTypeResponseData> => (
  api.get('/employmenttypes/')
);

const GetPositionTypes = (pdpId: string | undefined): Promise<IPositionTypeResponse> => (
  api.get(`/positiontypes/?pdpId=${pdpId}`)
);

const GetExperienceLevels = (): Promise<IExperienceLevelResponse> => (
  api.get('/experiencelevels/')
);

const GetService = (pdpId: number, copy: boolean): Promise<ServiceResponseData> => {
  let url = `/pdp/${pdpId}/service/`;
  if (copy) {
    url += '?import=true';
  }
  return api.get(url);
};

const CreateService = (service: ServiceData): Promise<ServiceResponseData> => (
  api.post(`/pdp/${service.pdp}/service/`, service)
);

const UpdateService = (service: ServiceData): Promise<ServiceResponseData> => (
  api.put(`/pdp/${service.pdp}/service/${service.id}/`, service)
);

const DeleteService = (service: ServiceData): Promise<ServiceData> => (
  api.delete(`/pdp/${service.pdp}/service/${service.id}/`)
);

const GetSizeRange = (): Promise<SizeRangeResponseData> => (
  api.get('/sizerange/')
);
const GetCommunityType = (): Promise<CommunityTypeResponseData> => (
  api.get('/communitytype/')
);

const GetWork = (pdpId: number, copy: boolean): Promise<WorkResponseData> => {
  let url = `/pdp/${pdpId}/workexperience/`;
  if (copy) {
    url += '?import=true';
  }
  return api.get(url);
};

const GetGeographicalOptions = (copy: boolean, pdpId?: number): Promise<GeographicalOptionResponseData> => {
  let url = '';
  if (copy) {
    url = '/profile/callinginfo/?import=true';
  } else {
    url = `/pdp/${pdpId}/geographicaloptions/`;
  }
  return api.get(url);
};

const CreateWork = (work: WorkData): Promise<WorkResponseData> => (
  api.post(`/pdp/${work.pdp}/workexperience/`, work)
);

const UpdateWork = (work: WorkData): Promise<WorkResponseData> => (
  api.put(`/pdp/${work.pdp}/workexperience/${work.id}/`, work)
);

const CreateGeographicalOptions = (geoOptions: GeographicalOptionData): Promise<GeographicalOptionData> => (
  api.post(`/pdp/${geoOptions.pdp}/geographicaloptions/`, geoOptions)
);

const UpdateGeographicalOptions = (geoOptions: GeographicalOptionData): Promise<GeographicalOptionData> => (
  api.put(`/pdp/${geoOptions.pdp}/geographicaloptions/${geoOptions.id}/`, geoOptions)
);

const DeleteWork = (work: WorkData): Promise<WorkData> => (
  api.delete(`/pdp/${work.pdp}/workexperience/${work.id}`)
);

const GetCongregation = (): Promise<CongregationResponseData> => (
  api.get('/congregation/')
);

const GetTrainingEducation = (pdpId: string | undefined): Promise<TrainingEducationResponseData> => (
  api.get(`/trainingeducation/${pdpId}/`)
);

const GetPDPCallingInfo = (matchId: string | undefined): Promise<PDPCallingInfoResponseData> => (
  api.get(`/pdpcallinginfo/${matchId}/`)
);

const exports = {
  GetPDPList,
  GetActivePDPList,
  GetPDPData,
  UpdatePDP,
  GetHousingTypes,
  GetEmploymentTypes,
  GetPositionTypes,
  GetExperienceLevels,
  CreatePDP,
  DeletePDP,
  GetService,
  CreateService,
  UpdateService,
  DeleteService,
  GetWork,
  CreateWork,
  UpdateWork,
  DeleteWork,
  GetSizeRange,
  GetCommunityType,
  GetCongregation,
  CreateGeographicalOptions,
  GetGeographicalOptions,
  UpdateGeographicalOptions,
  GetTrainingEducation,
  GetPDPCallingInfo,
};

export default exports;

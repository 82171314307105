import {
  useState,
  useEffect,
} from 'react';
import { useRecoilValue } from 'recoil';
import {
  Link,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Table,
  Accordion,
  Button,
  Modal,
} from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import withUserAllowed from './layout/withUserAllowed';
import {
  CLC_FUNCTION,
  SAVED_OPPORTUNITY_OPTIONS,
  REMOVE_OPPORTUNITY_OPTION,
  ATTESTATION_STATUSES,
} from '../types/constants';
import {
  MDPResultListData,
  MDPResultListResponseData,
  PDPMatchData,
  PDPMatchListResponseData,
} from '../types/mdp.types';
import {
  PDPData,
  ActivePDPListResponseData,
  AppliedPDPDefault,
  AppliedPDP,
} from '../types/pdp.types';
import {
  userProfileState,
} from '../services/state.service';
import { UserProfile } from '../types/user.types';
import { SelectOption, SelectOptionDefault } from '../types/generic.types';
import MDPService from '../services/mdp.service';
import PDPService from '../services/pdp.service';
import AlertModal from './generic/AlertModal';

function SavedOpportunitiesAccordionItem({
  eventKey,
}:{
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [list, setList] = useState<MDPResultListData[]>([]);
  const [pdplist, setPDPList] = useState<PDPData[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [actionData, setActionData] = useState<SelectOption>(SelectOptionDefault);
  const [appliedPDP, setAppliedPDP] = useState<AppliedPDP>(AppliedPDPDefault);
  const [showLoggedInDialogue, setShowLoggedInDialogue] = useState(false);
  const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
  const [showEccRestrictedDialogue, setShowEccRestrictedDialogue] = useState(false);
  const [showStatementOfFaithDialogue, setShowStatementOfFaithDialogue] = useState(false);
  const [statementOfFaithError, setStatementOfFaithError] = useState<boolean>(false);
  const [showDeadlineDialogue, setShowDeadlineDialogue] = useState(false);
  const [pdpMatchList, setPDPMatchList] = useState<PDPMatchData[]>([]);
  const userProfile = useRecoilValue<UserProfile>(userProfileState);

  useEffect(() => {
    MDPService.GetSavedOpportunities().then(
      (response: MDPResultListResponseData) => {
        setList(response.data.options);
      },
    );

    MDPService.GetAppliedPDPs()
      .then((response: PDPMatchListResponseData) => {
        setPDPMatchList(response.data.options);
      });
  }, [refresh]);

  function submitAppliedPDP(): void {
    if (appliedPDP.pdp) {
      MDPService.SubmitAppliedPDPs(appliedPDP).then(
        (response: any) => {
          setRefresh((r: boolean) => !r);
          if (response.data.toString() === '-1') {
            setShowEccRestrictedDialogue(true);
          }
          if (response.data.toString() === '-2') {
            setStatementOfFaithError(true);
            setShowStatementOfFaithDialogue(true);
          } else {
            setStatementOfFaithError(false);
            setShowSuccessDialogue(true);
          }
        },
      );
    }

    setShowLoggedInDialogue(false);

    setAppliedPDP((p) => ({ ...p, pdp: '' }));
  }

  function applyToMDP(mdpId: string): void {
    setShowLoggedInDialogue(true);
    PDPService.GetActivePDPList().then(
      (response: ActivePDPListResponseData) => {
        setPDPList(response.data.options);
        setAppliedPDP((p) => ({
          ...p,
          mdp: mdpId,
        }));
      },
    );
  }

  const handleActionChange = (status: string): void => {
    setActionData((p : any) => ({
      ...p,
      value: status,
    }));
  };

  const submitActionData = (mdp: MDPResultListData): void => {
    const mdpDeadline = new Date(mdp.deadline);
    mdpDeadline.setMinutes(mdpDeadline.getMinutes() + mdpDeadline.getTimezoneOffset());
    const current = new Date();
    if (actionData.value === t('Remove_Opportunity')) {
      MDPService.DeleteOpportunity(mdp.id).then(() => {
        setRefresh((r: boolean) => !r);
      });
    }

    if (actionData.value === t('Apply')) {
      if (current < mdpDeadline || mdp.deadline === '') {
        setShowLoggedInDialogue(true);
        PDPService.GetActivePDPList().then(
          (response: ActivePDPListResponseData) => {
            setPDPList(response.data.options);
            setAppliedPDP((p: any) => ({
              ...p,
              mdp: mdp.id,
            }));
          },
        );
        applyToMDP(mdp.id);
        setRefresh((r: boolean) => !r);
      } else {
        setShowDeadlineDialogue(true);
      }
    }
  };

  function renderOpportunityOptions(opportunityActions: string[], id: string): JSX.Element[] {
    return (
      (opportunityActions?.map((optionOpp: any) => (
        <option key={`opportunity-options-${id}-${optionOpp}`} value={optionOpp}>
          {t(optionOpp)}
        </option>
      )))
    );
  }

  function renderReferrals(): JSX.Element {
    return (
      <tbody>
        {list?.map((option: MDPResultListData) => (
          <tr key={`mdp-${option.id}`} className="dividinglines">
            <td>
              <Link to={`/mdp/${option.id}/view/`} target="_blank">
                {option.id}
              </Link>
            </td>
            <td>
              {option.positionType ? option.positionType : ''}
              {option.positionTitle ? ` (${option.positionTitle})` : ''}
            </td>
            <td>{option.experienceLevel ? option.experienceLevel : ''}</td>
            <td>{option.employmentType ? option.employmentType : ''}</td>
            <td>{option.organizationName ? option.organizationName : '' }</td>
            <td>{option.city ? option.city : '' }</td>
            <td>{option.state ? option.state : '' }</td>
            <td>{option.status ? option.status : '' }</td>
            <td className="mx-2">
              {userProfile.attestationStatus === ATTESTATION_STATUSES.passed && (
                <div className="table-action-display">
                  <div className="col-8">
                    <Form.Group className="col-12">
                      <Form.Select
                        defaultValue={actionData?.value || ''}
                        onChange={(e) => {
                          handleActionChange(
                            e.target.options[e.target.selectedIndex].text,
                          );
                        }}
                      >
                        <option key="" value="">
                          {t('Select_Action')}
                        </option>
                        {pdpMatchList[pdpMatchList.findIndex((j) => j.mdpId.toString() === option.id.toString())]?.mdpId.toString() === option.id.toString() ? (
                          renderOpportunityOptions(REMOVE_OPPORTUNITY_OPTION, option.id)
                        ) : (
                          renderOpportunityOptions(SAVED_OPPORTUNITY_OPTIONS, option.id)
                        )}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-4 ps-3">
                    <Button
                      onClick={() => submitActionData(option)}
                      className="mb-3 ms-auto createbutton"
                      variant="primary"
                      size="sm"
                      active
                    >
                      {(t('Submit'))}
                    </Button>
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <>
      <AlertModal
        show={showStatementOfFaithDialogue}
        title={t('Statement_Of_Faith_Required_Title')}
        description={t('Statement_Of_Faith_Required_Dialogue')}
        closeLabel={t('OK')}
        callback={() => setShowStatementOfFaithDialogue(!showStatementOfFaithDialogue)}
      />
      <AlertModal
        show={showDeadlineDialogue}
        title={t('Application_Deadline_Title')}
        description={t('Application_Deadline_Passed')}
        closeLabel={t('OK')}
        callback={() => setShowDeadlineDialogue(!showDeadlineDialogue)}
      />
      <AlertModal
        show={showEccRestrictedDialogue}
        title={t('Restricted_Position_Title')}
        description={t('Restricted_Position_Description')}
        closeLabel={t('OK')}
        callback={() => setShowEccRestrictedDialogue(!showEccRestrictedDialogue)}
      />
      <AlertModal
        show={showSuccessDialogue}
        title={t('Applied_Success_Title')}
        description={t('Applied_Success_Description')}
        closeLabel={t('OK')}
        callback={() => setShowSuccessDialogue(!showSuccessDialogue)}
      />
      <Modal
        show={showLoggedInDialogue}
        onHide={() => setShowLoggedInDialogue(false)}
        animation={false}
        className="modalstyle"
        backdrop="static"
      >
        {pdplist.length > 0 ? (
          <>
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="col-md-12 text-center my-5">
                <div className="title">{t('Active_PDPs')}</div>
              </div>
              <div className="col-md-12 text-center my-5">
                <Form.Select
                  onChange={(e) => setAppliedPDP((p) => ({
                    ...p,
                    pdp: e.target.value,
                  }))}
                >
                  <option key="" value="">{t('Select_PDP')}</option>
                  {(pdplist?.map((pdp: PDPData) => (
                    <option key={`${pdp.id}-active-pdp`} value={pdp.id}>
                      {`${pdp.id}: ${pdp.positionTypeDescription}`}
                    </option>
                  )))}
                </Form.Select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-md-12 text-center">
                <Button
                  className="px-5 "
                  variant="primary"
                  onClick={() => submitAppliedPDP()}
                >
                  {t('OK')}
                </Button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="col-md-12 text-center my-5">
                <div className="title">{t('No_Active_PDPs')}</div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-md-12 text-center">
                <Button
                  className="px-5 "
                  variant="primary"
                  onClick={() => submitAppliedPDP()}
                >
                  {t('OK')}
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          <FaStar />
          <span className="accordion-header-label">
            {t('Saved_Opportunities')}
          </span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="mb-3 mx-auto mobiletable col-lg-12">
            <div className="border border-dark rounded-3">
              <Table responsive hover className="mb-3 linktable">
                <thead>
                  <tr className="linktableheader">
                    <th>{t('Links_ID')}</th>
                    <th>{t('Position_Type_Title')}</th>
                    <th>{t('ExperienceLevel')}</th>
                    <th>{t('Employment_Type')}</th>
                    <th>{t('Organization')}</th>
                    <th>{t('City')}</th>
                    <th>{t('State')}</th>
                    <th>{t('Status')}</th>
                    <th>{t('Links_Table_Actions')}</th>
                  </tr>
                </thead>
                {list && renderReferrals()}
              </Table>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}

export default withUserAllowed(CLC_FUNCTION.callSeeker)(SavedOpportunitiesAccordionItem);

import {
  Dispatch,
  SetStateAction,
} from 'react';
import {
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Pagination({
  totalResults,
  totalPages,
  currentPage,
  setCurrentPage,
  renderPagination,
}: {
  totalResults: number,
  totalPages: number,
  currentPage: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  renderPagination: () => JSX.Element[]
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="pagination pagination-wrap">
      <Button
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      >
        {t('First')}
      </Button>
      <Button
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {t('Previous')}
      </Button>
      {renderPagination()}
      <Button
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {t('Next')}
      </Button>
      <Button
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      >
        {t('Last')}
      </Button>
      <div className="text-start fw-bold mx-3 mt-auto">
        {`${totalResults} ${t('Total_Results')}`}
      </div>
    </div>
  );
}

export default Pagination;

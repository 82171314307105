import { Spinner } from 'react-bootstrap';

function SuspenseLoading(): JSX.Element {
  return (
    <Spinner
      animation="border"
      size="sm"
    />
  );
}

export default SuspenseLoading;

import Modal from 'react-bootstrap/Modal';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { FaEdit } from 'react-icons/fa';
import api from '../services/api.service';
import EditButton from './generic/EditButton';
import DeleteButton from './generic/DeleteButton';
import {
  OptionalLinkModalData,
  OptionalLinkModalResponseData,
  optionalLinkDefault,
} from '../types/pdp.types';
import OptionalLinkModal from './OptionalLinkModal';
import ConfirmModal from './generic/ConfirmModal';

type OptionLinksTemplateModalProps = {
  show: boolean,
  hide: () => void,
}

function OptionLinksTemplateModal({
  show,
  hide,
}: OptionLinksTemplateModalProps): JSX.Element {
  const { t } = useTranslation();
  const [showNewEdit, setShowNewEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentOptionalLink, setCurrentOptionalLink] = useState<OptionalLinkModalData>(optionalLinkDefault);
  const [currentLink, setCurrentLink] = useState<OptionalLinkModalData>(currentOptionalLink);
  const [optionalLinkList, setOptionalLinkList] = useState<OptionalLinkModalData[]>([]);
  const [refreshLinks, setRefreshLinks] = useState(false);

  useEffect(() => {
    api.get('profile/optlinks/').then(
      (response: OptionalLinkModalResponseData) => {
        if (response.data[0]) {
          if (response.data[0].linkUrl == null) {
            response.data[0].linkUrl = '';
            setOptionalLinkList(response.data);
          } else {
            setOptionalLinkList(response.data);
          }
        } else {
          setOptionalLinkList(response.data);
        }
      },
    );
  }, [currentOptionalLink, refreshLinks]);

  function CreateOptionalLink(): void {
    api.get('profile/optlinks/').then(
      (response: OptionalLinkModalResponseData) => {
        if (response.data) {
          setCurrentOptionalLink(response.data[0]);
          setShowNewEdit(true);
        }
      },
    );
  }

  const closeNewEdit = useCallback((result: boolean, link: OptionalLinkModalData): void => {
    if (result && link) {
      if (link.id > 0) {
        api.put(`/profile/optlinks/${link.id}/`, link).then(() => {
          setShowNewEdit(false);
          setRefreshLinks((r: boolean) => !r);
        });
      } else {
        api.post('profile/optlinks/', link).then(() => {
          setShowNewEdit(false);
          setRefreshLinks((r: boolean) => !r);
        });
      }
    } else {
      setShowNewEdit(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentLink) {
      api.delete(`/profile/optlinks/${currentLink.id}/`).then(() => {
        setShowDelete(false);
        setRefreshLinks((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentLink]);

  return (
    <Modal fullscreen show={show} onHide={hide}>
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div className="title">
          {t('Modal_Optional_Links_Title')}
        </div>
        <div className="col-lg-8 col-12 mx-auto">
          {t('Modal_Optional_Links_Description')}
        </div>
        <div className="mb-3 mx-auto mobiletable col-lg-8">
          <div className="d-flex">
            <Button
              onClick={() => {
                CreateOptionalLink();
                setCurrentLink({
                  id: 0, user: 0, linkTitle: '', linkDescription: '', linkUrl: '',
                });
              }}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              <FaEdit />
              {` ${t('Add New Link')}`}
            </Button>
          </div>
          <div className="border border-dark rounded-3">
            <OptionalLinkModal
              show={showNewEdit}
              currentLink={currentLink}
              callback={closeNewEdit}
            />
            <ConfirmModal
              show={showDelete}
              title={t('PDP.Confirm')}
              description={t('PDP.Links_Confirmation_Message')}
              yesLabel={t('PDP.Delete')}
              noLabel={t('PDP.Cancel')}
              callback={closeDelete}
            />
            <Table responsive hover className="mb-3 linktable">
              <thead>
                <tr className="linktableheader">
                  <th>
                    {t('PDP.Links_Title')}
                  </th>
                  <th>
                    {t('PDP.Links_Description')}
                  </th>
                  <th>
                    {t('PDP.Links_URL')}
                  </th>
                  <th>
                    {t('PDP.Links_Table_Actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {optionalLinkList.map((option: OptionalLinkModalData) => (
                  <tr key={`link-${option.id}`} className="dividinglines">
                    <th>{option.linkTitle}</th>
                    <th>{option.linkDescription}</th>
                    <th>
                      <a
                        href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {option.linkUrl}
                      </a>
                    </th>
                    <th>
                      <div>
                        <EditButton
                          label=""
                          handler={() => {
                            setCurrentLink(option);
                            setShowNewEdit(true);
                          }}
                        />
                        &nbsp;
                        &nbsp;
                        <DeleteButton
                          label=""
                          handler={() => {
                            setCurrentLink(option);
                            setShowDelete(true);
                          }}
                        />
                      </div>
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex profilemodalfooter border-top-0">
        <Button variant="primary" onClick={hide}>
          {t('PDP.Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default OptionLinksTemplateModal;

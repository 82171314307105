import FormalEducationTemplate from '../components/education/FormalEducationTemplate';
import ContinuingEducationTemplate from '../components/education/ContinuingEducationTemplate';
import TrainingCertification from '../components/education/TrainingCertification';
import SkillsTemplate from '../components/education/SkillsTemplate';

export const EducationSteps = [
  {
    key: 'formaleducation',
    label: 'Formal Education',
    isValid: true,
    StepComponent: FormalEducationTemplate,
  },
  {
    key: 'continuingeducation',
    label: 'Continuing Education',
    isValid: true,
    StepComponent: ContinuingEducationTemplate,
  },
  {
    key: 'trainingcertification',
    label: 'Training/Certification',
    isValid: true,
    StepComponent: TrainingCertification,
  },
  {
    key: 'skills',
    label: 'Skills',
    isValid: true,
    StepComponent: SkillsTemplate,
  },
];

import {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Form, Button } from 'react-bootstrap';
import CLCInput from '../generic/CLCInput';

type OtherTrainingModalProps = {
  show: boolean,
  callback: (_result: boolean, _tag: string[]) => void,
}

interface Training {
  value: string,
}

const DefaultTrainingData: Training = {
  value: '',
};

interface Location {
  value: string,
}

const DefaultLocationData: Location = {
  value: '',
};

interface Year {
  value: string,
}

const DefaultYearData: Year = {
  value: '',
};

function OtherTrainingModal({
  show,
  callback,
}: OtherTrainingModalProps): JSX.Element {
  const { t } = useTranslation();
  const [tag, _] = useState<string[]>([]);
  const [training, setTraining] = useState<Training>(DefaultTrainingData);
  const [location, setLocation] = useState<Location>(DefaultLocationData);
  const [year, setYear] = useState<Year>(DefaultYearData);
  const [charLimitWarning, setCharLimitWarning] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);

  function buildTag(): void {
    let newtag = training.value;
    if (location.value) {
      newtag = `${newtag}-${location.value}`;
    }
    if (year.value) {
      newtag = `${newtag}-${year.value}`;
    }
    callback(true, [newtag]);
  }

  useEffect(() => {
    setTraining({ value: '' });
    setLocation({ value: '' });
    setYear({ value: '' });
  }, [show]);

  useEffect(() => {
    if (training.value.length + location.value.length + year.value.length < 50) {
      setValid(true);
      setCharLimitWarning(t(''));
    } else {
      setValid(false);
      setCharLimitWarning(t('Char_Limit_Error'));
    }
  }, [training.value, location.value, year.value]);

  return (
    <Modal
      show={show}
      onHide={() => callback(false, tag)}
      animation={false}
      className="modal"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Add Other Training')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 mb-3 text-center non-field-error">
          {charLimitWarning}
        </div>
        <Form noValidate>
          <Form.Group className="mb-3">
            <CLCInput
              data={training}
              formLabel={t('Other_Training')}
              stateSetter={setTraining}
              inputName="value"
              inputType="text"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <CLCInput
              data={location}
              formLabel={t('Academic_Institution')}
              stateSetter={setLocation}
              inputName="value"
              inputType="text"
              required={false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <CLCInput
              data={year}
              formLabel={t('Year')}
              stateSetter={setYear}
              inputName="value"
              inputType="text"
              required={false}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => (callback(false, tag))}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-6"
          onClick={() => (buildTag())}
          disabled={!valid}
        >
          {t('Add_Other_Training')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OtherTrainingModal;

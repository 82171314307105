import {
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Button, Table } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import EditButton from './generic/EditButton';
import DeleteButton from './generic/DeleteButton';
import api from '../services/api.service';
import {
  WorkExperienceModalData,
  WorkExperienceModalResponseData,
  workExperienceDefault,
  CommunityTypeData,
  CommunityTypeResponseData,
  SizeRangeData,
  SizeRangeResponseData,
} from '../types/pdp.types';
import ConfirmModal from './generic/ConfirmModal';
import PDPService from '../services/pdp.service';
import WorkExperienceEditModal from './WorkExperienceEditModal';

type WorkExperienceTemplateModalProps = {
  show: boolean,
  hide: () => void,
}

function WorkExperienceTemplateModal({
  show,
  hide,
}: WorkExperienceTemplateModalProps): JSX.Element {
  const { t } = useTranslation();
  const [showNewEdit, setShowNewEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentWorkExperience, setCurrentWorkExperience] = useState<WorkExperienceModalData>(workExperienceDefault);
  const [workExperienceList, setWorkExperienceList] = useState<WorkExperienceModalData[]>([]);
  const [refreshExperience, setRefreshExperience] = useState(false);
  const [sizeRange, setSizeRange] = useState<SizeRangeData[]>([]);
  const [communityType, setCommunityType] = useState<CommunityTypeData[]>([]);
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    api.get('profile/workexperience/').then(
      (response: WorkExperienceModalResponseData) => {
        if (response.data) {
          setWorkExperienceList(response.data);
        } else {
          setWorkExperienceList(response.data);
        }
      },
    );
  }, [refreshExperience]);

  useEffect(() => {
    PDPService.GetSizeRange().then(
      (response: SizeRangeResponseData) => (setSizeRange(response.data.options)),
    );
    PDPService.GetCommunityType().then(
      (response: CommunityTypeResponseData) => (setCommunityType(response.data.options)),
    );
  }, []);

  useEffect(() => {
    if (isNew) {
      setShowNewEdit(true);
      setIsNew(false);
    }
  }, [currentWorkExperience]);

  const closeNewEdit = useCallback((result: boolean, experience: WorkExperienceModalData): void => {
    if (result && experience) {
      if (experience.id > 0) {
        api.put(`/profile/workexperience/${experience.id}/`, experience).then(() => {
          setShowNewEdit(false);
          setRefreshExperience((r: boolean) => !r);
        });
      } else {
        api.post('profile/workexperience/', experience).then(() => {
          setShowNewEdit(false);
          setRefreshExperience((r: boolean) => !r);
        });
      }
    } else {
      setShowNewEdit(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentWorkExperience) {
      api.delete(`/profile/workexperience/${currentWorkExperience.id}/`).then(() => {
        setShowDelete(false);
        setRefreshExperience((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentWorkExperience]);

  return (
    <Modal fullscreen show={show} onHide={hide}>
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div className="title">
          {t('Modal_Work_Experience_Title')}
        </div>
        <div className="mb-3 mx-auto mobiletable col-lg-8">
          <div className="d-flex">
            <Button
              onClick={() => {
                setCurrentWorkExperience({
                  id: 0,
                  organization: '',
                  position: '',
                  name: '',
                  size: '',
                  country: '',
                  city: '',
                  state: '',
                  community: '',
                  start: '',
                  end: '',
                  current: false,
                  responsibilities: '',
                });
                setIsNew(true);
              }}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              <FaEdit />
              {` ${t('Modal_Work_Experience_Add')}`}
            </Button>
          </div>
          <div className="border border-dark rounded-3">
            <WorkExperienceEditModal
              show={showNewEdit}
              workExperience={currentWorkExperience}
              sizeRange={sizeRange}
              communityType={communityType}
              callback={closeNewEdit}
            />
            <ConfirmModal
              show={showDelete}
              title={t('PDP.Confirm')}
              description={t('PDP.Links_Confirmation_Message')}
              yesLabel={t('PDP.Delete')}
              noLabel={t('PDP.Cancel')}
              callback={closeDelete}
            />
            <Table responsive hover className="mb-3 linktable">
              <thead>
                <tr className="linktableheader">
                  <th>
                    {t('Work_Experience_Position')}
                  </th>
                  <th>
                    {t('Organization_Name')}
                  </th>
                  <th>
                    {t('City')}
                  </th>
                  <th>
                    {t('State')}
                  </th>
                  <th>
                    {t('Country')}
                  </th>
                  <th>
                    {t('PDP.Start_Date')}
                  </th>
                  <th>
                    {t('PDP.End_Date')}
                  </th>
                  <th>
                    {t('PDP.Links_Table_Actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {workExperienceList.map((option: WorkExperienceModalData) => (
                  <tr key={option.id} className="dividinglines">
                    <th>{option.position}</th>
                    <th>{option.name}</th>
                    <th>{option.city}</th>
                    <th>{option.state}</th>
                    <th>{option.country}</th>
                    <th>{option.start}</th>
                    <th>{option.end}</th>
                    <th>
                      <div>
                        <EditButton
                          label=""
                          handler={() => {
                            setCurrentWorkExperience(option);
                            setShowNewEdit(true);
                          }}
                        />
                        &nbsp;
                        &nbsp;
                        <DeleteButton
                          label=""
                          handler={() => {
                            setCurrentWorkExperience(option);
                            setShowDelete(true);
                          }}
                        />
                      </div>
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex profilemodalfooter border-top-0">
        <Button variant="primary" onClick={hide}>
          {t('PDP.Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkExperienceTemplateModal;

import {
  atom,
  selector,
  RecoilState,
  RecoilValueReadOnly,
} from 'recoil';
import {
  CongregationData,
  CongregationResponseData,
  EmploymentTypeData,
  EmploymentTypeResponseData,
  HousingTypeData,
  HousingTypeResponseData,
  IExperienceLevel,
  IExperienceLevelResponse,
  IPositionType,
  IPositionTypeResponse,
  PDPData,
  PDPDefaultData,
  PresbyteryData,
  PresbyteryResponseData,
  SynodData,
  SynodResponseData,
  FormalEducationData,
  FormalEducationDefaultData,
  GeographicalOptionData,
  GeographicalOptionDefaultData,
} from '../types/pdp.types';
import {
  MDPData,
  MDPDefaultData,
  MatchData,
  MatchDefaultData,
  MatchCharacteristicData,
  MatchCharacteristicDefaultData,
  PresbyteryMatchingOptionData,
  PresbyteryMatchingOptionDefaultData,
  PresbyteryInfoData,
  PresbyteryInfoDefaultData,
} from '../types/mdp.types';
import {
  UserProfile,
  UserProfileDefaultValue,
  Role,
  RoleDefaultValue,
  PrefixData,
  PrefixResponseData,
  RaceData,
  RaceResponseData,
  GenderData,
  GenderResponseData,
  SuffixData,
  SuffixResponseData,
} from '../types/user.types';
import {
  MinistryProfile,
  MinistryProfileDefaultValue,
  ContactTypeData,
  ContactTypeResponseData,
  AttestationNoteData,
  AttestationNoteResponseData,
  SearchOrganizationData,
} from '../types/ministry.types';
import {
  CallingInfoData,
  CallingInfoDefaultData,
} from '../types/profile.types';
import {
  SelectOptionsData,
  SelectOptionsResponseData,
} from '../types/selectoptions.types';
import {
  TrainingCertificationData,
  TrainingCertificationDefaultValue,
} from '../types/education.types';
import {
  StepWizardData,
  FormStepWizardData,
  SelectOption,
  StatesListOptionResponse,
} from '../types/generic.types';
import { PDPSteps } from '../constants/PDPSteps';
import { MDPSteps } from '../constants/MDPSteps';
import { ProfileSteps } from '../constants/ProfileSteps';
import { MinistryProfileSteps } from '../constants/MinistryProfileSteps';
import { EducationSteps } from '../constants/EducationSteps';
import api from './api.service';
import { IPositionTypeListResponse, IPositionTypeList } from './mdp.service';

export const isPortalLoginState: RecoilState<boolean> = atom<boolean>({
  key: 'isPortalLogin',
  default: false,
});

export const appLoadingState: RecoilState<boolean> = atom<boolean>({
  key: 'appLoadingState',
  default: true,
});

export const mdpPanelState: RecoilState<boolean> = atom<boolean>({
  key: 'mdpPanelState',
  default: false,
});

export const currentRoleState: RecoilState<Role> = atom<Role>({
  key: 'currentRole',
  default: RoleDefaultValue,
});

export const pdpPanelState: RecoilState<boolean> = atom<boolean>({
  key: 'pdpPanelState',
  default: false,
});

export const resubmissionState: RecoilState<boolean> = atom<boolean>({
  key: 'resubmissionState',
  default: false,
});

export const orgPanelState: RecoilState<boolean> = atom<boolean>({
  key: 'orgPanelState',
  default: false,
});

export const userProfileState: RecoilState<UserProfile> = atom<UserProfile>({
  key: 'userProfile',
  default: UserProfileDefaultValue,
});

export const ministryProfileState: RecoilState<MinistryProfile> = atom<MinistryProfile>({
  key: 'ministryProfile',
  default: MinistryProfileDefaultValue,
});

export const trainingCertificationState: RecoilState<TrainingCertificationData> = atom<TrainingCertificationData>({
  key: 'trainingCertification',
  default: TrainingCertificationDefaultValue,
});

export const callingInfoState: RecoilState<CallingInfoData> = atom<CallingInfoData>({
  key: 'callingInfo',
  default: CallingInfoDefaultData,
});

export const pdpState: RecoilState<PDPData> = atom<PDPData>({
  key: 'pdpData',
  default: PDPDefaultData,
});

export const mdpState: RecoilState<MDPData> = atom<MDPData>({
  key: 'mdpData',
  default: MDPDefaultData,
});

export const geographicalOptionState: RecoilState<GeographicalOptionData> = atom<GeographicalOptionData>({
  key: 'geographicalOptionData',
  default: GeographicalOptionDefaultData,
});

export const presbyteryMatchingState: RecoilState<PresbyteryMatchingOptionData> = atom<PresbyteryMatchingOptionData>({
  key: 'presbyteryMatchingData',
  default: PresbyteryMatchingOptionDefaultData,
});

export const matchState: RecoilState<MatchData> = atom<MatchData>({
  key: 'matchData',
  default: MatchDefaultData,
});

export const matchCharacteristicState: RecoilState<MatchCharacteristicData> = atom<MatchCharacteristicData>({
  key: 'matchCharacteristicData',
  default: MatchCharacteristicDefaultData,
});

export const formalEducationState: RecoilState<FormalEducationData> = atom<FormalEducationData>({
  key: 'formalEducationData',
  default: FormalEducationDefaultData,
});

export const pdpStepState: RecoilState<StepWizardData[]> = atom<StepWizardData[]>({
  key: 'pdpSteps',
  default: PDPSteps,
});

export const mdpStepState: RecoilState<StepWizardData[]> = atom<StepWizardData[]>({
  key: 'mdpSteps',
  default: MDPSteps,
});

export const profileStepsState: RecoilState<FormStepWizardData[]> = atom<FormStepWizardData[]>({
  key: 'profileSteps',
  default: ProfileSteps,
});

export const ministryProfileStepsState: RecoilState<FormStepWizardData[]> = atom<FormStepWizardData[]>({
  key: 'ministryProfileSteps',
  default: MinistryProfileSteps,
});

export const educationStepsState: RecoilState<FormStepWizardData[]> = atom<FormStepWizardData[]>({
  key: 'educationSteps',
  default: EducationSteps,
});

export const presbyteryInfoState: RecoilState<PresbyteryInfoData> = atom<PresbyteryInfoData>({
  key: 'presbyteryInfo',
  default: PresbyteryInfoDefaultData,
});

export const searchOrganizationState: RecoilState<SearchOrganizationData[]> = atom<SearchOrganizationData[]>({
  key: 'searchOrganization',
  default: [],
});

export const notifyOptionsState: RecoilValueReadOnly<SelectOptionsData[]> = selector<SelectOptionsData[]>({
  key: 'notifyOptions',
  get: async () => {
    const response: SelectOptionsResponseData = await api.get('/notifyoptions/');
    return response.data.options;
  },
});

export const eccStatusOptionsState: RecoilValueReadOnly<SelectOptionsData[]> = selector<SelectOptionsData[]>({
  key: 'eccStatusOptions',
  get: async () => {
    const response: SelectOptionsResponseData = await api.get('/ecclesiasticalstatus/');
    return response.data.options;
  },
});

export const synodOptionsState: RecoilValueReadOnly<SynodData[]> = selector<SynodData[]>({
  key: 'synodOptions',
  get: async () => {
    const response: SynodResponseData = await api.get('/synod/');
    return response.data.synods;
  },
});

export const presbyteryOptionsState: RecoilValueReadOnly<PresbyteryData[]> = selector<PresbyteryData[]>({
  key: 'presbyteryOptions',
  get: async () => {
    const response: PresbyteryResponseData = await api.get('/presbytery/');
    return response.data.presbyteries;
  },
});

export const statesListOptionsState: RecoilValueReadOnly<SelectOption[]> = selector<SelectOption[]>({
  key: 'statesListOption',
  get: async () => {
    const response: StatesListOptionResponse = await api.get('/states/');
    return response.data.states;
  },
});

export const congregationOptionsState: RecoilValueReadOnly<CongregationData[]> = selector<CongregationData[]>({
  key: 'congregationOptions',
  get: async () => {
    const response: CongregationResponseData = await api.get('/congregation/');
    return response.data.congregations;
  },
});

export const housingTypeOptionsState: RecoilValueReadOnly<HousingTypeData[]> = selector<HousingTypeData[]>({
  key: 'housingTypeOptions',
  get: async () => {
    const response: HousingTypeResponseData = await api.get('/housingtypes/');
    return response.data.options;
  },
});

export const employmentTypeOptionsState: RecoilValueReadOnly<EmploymentTypeData[]> = selector<EmploymentTypeData[]>({
  key: 'employmentTypeOptions',
  get: async () => {
    const response: EmploymentTypeResponseData = await api.get('/employmenttypes/');
    return response.data.options;
  },
});

export const experienceLevelOptionsState: RecoilValueReadOnly<IExperienceLevel[]> = selector<IExperienceLevel[]>({
  key: 'experienceLevelOptions',
  get: async () => {
    const response: IExperienceLevelResponse = await api.get('/experiencelevels/');
    return response.data.options;
  },
});

export const positionTypeListState: RecoilValueReadOnly<IPositionType[]> = selector<IPositionType[]>({
  key: 'positionTypes',
  get: async () => {
    const response: IPositionTypeResponse = await api.get('/positiontypes/');
    return response.data.options;
  },
});

export const allPositionTypeListState: RecoilValueReadOnly<IPositionTypeList[]> = selector<IPositionTypeList[]>({
  key: 'allPositionTypes',
  get: async () => {
    const response: IPositionTypeListResponse = await api.get('/positiontypelist/');
    return response.data.options;
  },
});

export const contactTypeListState: RecoilValueReadOnly<ContactTypeData[]> = selector<ContactTypeData[]>({
  key: 'contactTypes',
  get: async () => {
    const response: ContactTypeResponseData = await api.get('/contacttypes/');
    return response.data.options;
  },
});

export const prefixDataState: RecoilValueReadOnly<PrefixData[]> = selector<PrefixData[]>({
  key: 'prefixData',
  get: async () => {
    const response: PrefixResponseData = await api.get('/prefix/');
    return response.data.options;
  },
});

export const suffixDataState: RecoilValueReadOnly<SuffixData[]> = selector<SuffixData[]>({
  key: 'suffixData',
  get: async () => {
    const response: SuffixResponseData = await api.get('/suffix/');
    return response.data.options;
  },
});

export const genderDataState: RecoilValueReadOnly<GenderData[]> = selector<GenderData[]>({
  key: 'genderData',
  get: async () => {
    const response: GenderResponseData = await api.get('/gender/');
    return response.data.options;
  },
});

export const raceDataState: RecoilValueReadOnly<RaceData[]> = selector<RaceData[]>({
  key: 'raceData',
  get: async () => {
    const response: RaceResponseData = await api.get('/race/');
    return response.data.options;
  },
});

export const attestationNoteDataState: RecoilValueReadOnly<AttestationNoteData[]> = selector<AttestationNoteData[]>({
  key: 'AttestationNoteData',
  get: async () => {
    const response: AttestationNoteResponseData = await api.get('/attestationnotes/');
    return response.data.options;
  },
});

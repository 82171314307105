import {
  useEffect,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
import { useRecoilValue, useRecoilState } from 'recoil';
import MDPService from '../services/mdp.service';
import {
  MDPData,
  MDPResponseData,
  PDPMatchData,
  PDPMatchResponseData,
  MatchAction,
  MatchActionResponse,
  ActionData,
  ActionDefaultData,
  PresbyteryMatchingOptionData,
  PresbyteryMatchingOptionResponseData,
} from '../types/mdp.types';
import {
  mdpState,
  currentRoleState,
  presbyteryMatchingState,
} from '../services/state.service';
import ConfirmModal from '../components/generic/ConfirmModal';
import MDPMatchTable from '../components/MDPMatchTable';
import { Role } from '../types/user.types';
import {
  STATUS_CHOICES,
  PDP_DISCERNMENT_ACTIONS,
  MINISTRY_ORG_TYPE,
  PRESBYTERY_MATCHING_OPTIONS,
  POSITIONTYPE_OPENTO_OPTIONS,
} from '../types/constants';
import api from '../services/api.service';
import CLCInput from '../components/generic/CLCInput';
import { canRequestRematch } from '../utils';

function Matches(): JSX.Element {
  const params = useParams();
  const { t } = useTranslation();
  const [mdp, setMDP] = useRecoilState<MDPData>(mdpState);
  const [pdpMatches, setPDPMatches] = useState<PDPMatchData[]>([]);
  const [MatchActionOptions, setMatchActionOptionsData] = useState<MatchAction[]>([]);
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
  const [actionData, setActionData] = useState<ActionData>(ActionDefaultData);
  const [sort, setSort] = useState<boolean>(true);
  const [refresh, setRefresh] = useState(false);
  const [showMatchNotification, setShowMatchNotification] = useState(false);
  const [showMatchResponse, setShowMatchResponse] = useState(String);
  const [showNotifyCall, setShowNotifyCall] = useState(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [matchingOptions, setMatchingOptions] = useRecoilState<PresbyteryMatchingOptionData>(presbyteryMatchingState);

  const filterPresbyteryActionPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.requiresPresbyteryAction,
    );
    return outputList;
  };

  const filterPrebyteryNotConsideredPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.notConsideredByPresbytery,
    );
    return outputList;
  };

  const filterRecentPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.invitedToApply
      || i.statusKey === STATUS_CHOICES.referred
      || i.statusKey === STATUS_CHOICES.requiresAction
      || (i.selfReferred === true && i.statusKey === STATUS_CHOICES.requiresAction)
      || ((i.referredBy !== '' && i.referredBy !== null) && i.statusKey === STATUS_CHOICES.requiresAction),
    );

    return outputList;
  };

  const filterConsideredPDPs = (pdpMatchList: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchList];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.beingConsidered
      || i.statusKey === STATUS_CHOICES.acceptedinvitation
      || i.statusKey === STATUS_CHOICES.acceptedCall
      || i.statusKey === STATUS_CHOICES.offeredCall
      || i.statusKey === STATUS_CHOICES.callCompleted
      || i.statusKey === STATUS_CHOICES.callPending,
    );

    return outputList;
  };

  const filterNonConsideredPDPs = (pdpMatchlist: PDPMatchData[]) :PDPMatchData[] => {
    let outputList = [...pdpMatchlist];
    outputList = outputList.filter(
      (i) => i.statusKey === STATUS_CHOICES.notConsidered
      || i.statusKey === STATUS_CHOICES.noLongerConsidered
      || i.statusKey === STATUS_CHOICES.declinedWithdrawn
      || i.statusKey === STATUS_CHOICES.rejectedCall,
    );

    return outputList;
  };

  useEffect(() => {
    if (params.id) {
      MDPService.GetMDPData(params.id)
        .then((response: MDPResponseData) => {
          setMDP(response.data);
        });
      MDPService.GetPDPMatches(params.id)
        .then((response: PDPMatchResponseData) => {
          if (sort) {
            setPDPMatches(response.data.sort((a, b) => b.score - a.score));
          } else {
            setPDPMatches(response.data);
          }
        });
      MDPService.GetActionOptions()
        .then((response: MatchActionResponse) => {
          setMatchActionOptionsData(response.data.options);
        });
      api.get(`presbyterymatching/${currentRole.organizationId}/`)
        .then(
          (response: PresbyteryMatchingOptionResponseData) => {
            if (response.data.options) {
              setMatchingOptions(response.data.options);
            }
          },
        );
    }
  }, [params.id, setMDP, refresh, currentRole]);

  const submitActionData = (result: boolean) : void => {
    if (result && actionData) {
      MDPService.SubmitMatchAction(actionData).then(() => {
        MDPService.GetPDPMatches(actionData.mdpId.toString())
          .then((response: PDPMatchResponseData) => {
            setPDPMatches(response.data);
          });
      });
    }
    if (actionData.actionKey === PDP_DISCERNMENT_ACTIONS.beingConsidered.notifyACall) {
      setShowNotifyCall(false);
    } else {
      setShowConfirmSubmit(false);
    }
  };

  function sortByCompetencyScore(): void {
    if (!sort) {
      setSort(true);
    } else {
      setSort(false);
    }
    setRefresh((r: boolean) => !r);
  }

  function generateRematch(): void {
    MDPService.GetPDPRematches(mdp.id)
      .then((response: any) => {
        if (response.data === '0') {
          setShowMatchResponse(t('No_Match_Found') + response.data);
        } else {
          setShowMatchResponse(t('Matches_Found') + response.data);
        }

        setShowMatchNotification(true);
        setRefresh((r: boolean) => !r);
      });
  }

  return (
    <div className="container-fluid">
      <ConfirmModal
        show={showConfirmSubmit}
        title={t('PDP.Confirm')}
        description={t('Match_Confirmation')}
        yesLabel={t('PDP.Confirm')}
        noLabel={t('PDP.Cancel')}
        callback={submitActionData}
      />
      <Modal
        show={showNotifyCall}
        onHide={() => setShowNotifyCall(false)}
        animation={false}
        className="modalstyle"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Notify_Call')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 text-center my-5">
            <div>
              {t('Notify_Call_Description')}
            </div>
            <div className="mx-auto my-5 col-xs-12 col-sm-4">
              <CLCInput
                data={actionData}
                formLabel={t('PDP.Start_Date')}
                stateSetter={setActionData}
                inputName="start"
                inputType="date"
                maxLength={500}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 text-center">
            <Button
              className="px-5 "
              variant="primary"
              onClick={() => submitActionData(true)}
            >
              {t('OK')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showMatchNotification}
        onHide={() => setShowMatchNotification(false)}
        animation={false}
        className="modalstyle"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Request_Rematch_Results')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 text-center my-5">
            {showMatchResponse}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 text-center">
            <Button
              className="px-5 "
              variant="primary"
              onClick={() => (setShowMatchNotification(false))}
            >
              {t('OK')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="container-fluid">
        <div className="profile-panel accordion mb-3 mx-auto col-lg-11">
          <Link to="/" className="dashboard-link">
            <FaLongArrowAltLeft />
            <span>{t('Back_to_Dashboard')}</span>
          </Link>
          <div className="title text-center">
            {t('Matching_PDP_Results')}
          </div>
          <div className="mb-3 my-3 mx-auto mobiletable col-lg-12">
            <div className="row">
              <div className="col-6">
                <Form>
                  <Form.Check
                    checked={sort}
                    type="switch"
                    id="custom-switch"
                    label={t('Sort_By_Competency_Score')}
                    onChange={() => sortByCompetencyScore()}
                  />
                </Form>
              </div>
              {canRequestRematch(currentRole, matchingOptions, mdp) && (
                <div className="col-6">
                  <Button
                    onClick={() => generateRematch()}
                    disabled={pdpMatches.filter((i) => i.status === 'Requires action').length === 25}
                    className="mb-3 ms-auto createbutton d-flex"
                    variant="primary"
                    size="sm"
                    active
                  >
                    {`${t('Request_Rematch')}`}
                  </Button>
                </div>
              )}
            </div>

            {/* InfoHeader */}
            <div className="row my-4 matching-block">
              <div className="col-2">
                <div className="text-start fw-bold h6">
                  {t('View_Matches_MDP_ID')}
                </div>
                <div>
                  { mdp?.id }
                </div>
              </div>
              <div className="col-3">
                <div className="text-start fw-bold h6">
                  {t('Released_Date')}
                </div>
                <div>
                  {mdp?.released ? new Intl.DateTimeFormat('en-US').format(new Date(mdp.released)) : ''}
                </div>
              </div>
              <div className="col-4">
                <div className="text-start fw-bold h6">
                  {t('Organization_Name')}
                </div>
                <div>
                  { `${mdp?.organizationName} (${mdp?.city}, ${mdp?.state})` }
                </div>
              </div>
              <div className="col-3">
                <div className="text-start fw-bold h6">
                  {t('View_Matches_MDP_Position_Title')}
                </div>
                <div>
                  {mdp.positionType ? mdp.positionType : ''}
                  {mdp.positionTitle ? ` (${mdp.positionTitle})` : ''}
                </div>
              </div>
            </div>

            {/* Presbytery Matching */}
            {/* Current Recent PDP Matches */}
            {(currentRole.organizationType === MINISTRY_ORG_TYPE.presbytery
              && matchingOptions.matchingChoice !== PRESBYTERY_MATCHING_OPTIONS.none
              && (matchingOptions.matchByCommittee?.includes(mdp?.committee)
              || (matchingOptions.matchByOrdainedPositions
                && (mdp.openTo === POSITIONTYPE_OPENTO_OPTIONS.ordained
                  || mdp.openTo === POSITIONTYPE_OPENTO_OPTIONS.either)))) && (
                  <>
                    <br />
                    <br />
                    <br />
                    <Accordion
                      defaultActiveKey={['0', '1', '2']}
                      alwaysOpen
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Button style={{ background: '#D1FFBD' }}>
                          <span className="accordion-header-label">
                            {t('Requires_Presbytery_Action')}
                          </span>
                        </Accordion.Button>
                        <Accordion.Body>
                          <div className="border border-dark rounded-3">
                            <ConfirmModal
                              show={showConfirmSubmit}
                              title={t('PDP.Confirm')}
                              description={t('Match_Confirmation')}
                              yesLabel={t('PDP.Confirm')}
                              noLabel={t('PDP.Cancel')}
                              callback={submitActionData}
                            />
                            <div>
                              <MDPMatchTable
                                filteredPDPs={filterPresbyteryActionPDPs(pdpMatches)}
                                pdpMatches={pdpMatches}
                                setPDPMatches={setPDPMatches}
                                setActionData={setActionData}
                                mdp={mdp}
                                matchActionOptions={MatchActionOptions}
                                setShowConfirmSubmit={setShowConfirmSubmit}
                                setShowNotifyCall={setShowNotifyCall}
                                state="requires presbytery action"
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* PDPs no longer considered */}
                      <Accordion.Item eventKey="2">
                        <Accordion.Button style={{ background: '#D1FFBD' }}>
                          <span className="accordion-header-label">
                            {t('Presbytery_Not_Considered')}
                          </span>
                        </Accordion.Button>
                        <Accordion.Body>
                          <div className="border border-dark rounded-3">
                            <ConfirmModal
                              show={showConfirmSubmit}
                              title={t('PDP.Confirm')}
                              description={t('Match_Confirmation')}
                              yesLabel={t('PDP.Confirm')}
                              noLabel={t('PDP.Cancel')}
                              callback={submitActionData}
                            />
                            <div>
                              <MDPMatchTable
                                filteredPDPs={filterPrebyteryNotConsideredPDPs(pdpMatches)}
                                pdpMatches={pdpMatches}
                                setPDPMatches={setPDPMatches}
                                setActionData={setActionData}
                                mdp={mdp}
                                matchActionOptions={MatchActionOptions}
                                setShowConfirmSubmit={setShowConfirmSubmit}
                                setShowNotifyCall={setShowNotifyCall}
                                state="not considered by prebytery"
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br />
                    <br />
                    <br />
                  </>
            )}
          </div>
        </div>

        {/* Regular Matching */}
        <div className="profile-panel accordion mb-3 mx-auto col-lg-11">
          <div className="mb-3 my-3 mx-auto mobiletable col-lg-12">
            {/* PDP Match Results Panels */}
            {/* Current Recent PDP Matches */}
            <Accordion
              defaultActiveKey={['0', '1', '2']}
              alwaysOpen
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span className="accordion-header-label">
                    {t('PDP_Matches_Recent')}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="border border-dark rounded-3">
                    <ConfirmModal
                      show={showConfirmSubmit}
                      title={t('PDP.Confirm')}
                      description={t('Match_Confirmation')}
                      yesLabel={t('PDP.Confirm')}
                      noLabel={t('PDP.Cancel')}
                      callback={submitActionData}
                    />
                    <div>
                      <MDPMatchTable
                        filteredPDPs={filterRecentPDPs(pdpMatches)}
                        pdpMatches={pdpMatches}
                        setPDPMatches={setPDPMatches}
                        setActionData={setActionData}
                        mdp={mdp}
                        matchActionOptions={MatchActionOptions}
                        setShowConfirmSubmit={setShowConfirmSubmit}
                        setShowNotifyCall={setShowNotifyCall}
                        state="requires action"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* PDP Matches in review */}
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <span className="accordion-header-label">
                    {t('PDP_Matches_Review')}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="border border-dark rounded-3">
                    <ConfirmModal
                      show={showConfirmSubmit}
                      title={t('PDP.Confirm')}
                      description={t('Match_Confirmation')}
                      yesLabel={t('PDP.Confirm')}
                      noLabel={t('PDP.Cancel')}
                      callback={submitActionData}
                    />
                    <div>
                      <MDPMatchTable
                        filteredPDPs={filterConsideredPDPs(pdpMatches)}
                        pdpMatches={pdpMatches}
                        setPDPMatches={setPDPMatches}
                        setActionData={setActionData}
                        mdp={mdp}
                        matchActionOptions={MatchActionOptions}
                        setShowConfirmSubmit={setShowConfirmSubmit}
                        setShowNotifyCall={setShowNotifyCall}
                        state="discernment"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* PDPs no longer considered */}
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <span className="accordion-header-label">
                    {t('PDP_Matches_Not_Considered')}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="border border-dark rounded-3">
                    <ConfirmModal
                      show={showConfirmSubmit}
                      title={t('PDP.Confirm')}
                      description={t('Match_Confirmation')}
                      yesLabel={t('PDP.Confirm')}
                      noLabel={t('PDP.Cancel')}
                      callback={submitActionData}
                    />
                    <div>
                      <MDPMatchTable
                        filteredPDPs={filterNonConsideredPDPs(pdpMatches)}
                        pdpMatches={pdpMatches}
                        setPDPMatches={setPDPMatches}
                        setActionData={setActionData}
                        mdp={mdp}
                        matchActionOptions={MatchActionOptions}
                        setShowConfirmSubmit={setShowConfirmSubmit}
                        setShowNotifyCall={setShowNotifyCall}
                        state="not considered"
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Matches;

import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import ServiceModal from './ServiceModal';
import PDPService from '../services/pdp.service';
import {
  ServiceData,
  ServiceResponseData,
} from '../types/pdp.types';
import EditButton from './generic/EditButton';
import DeleteButton from './generic/DeleteButton';
import ConfirmModal from './generic/ConfirmModal';
import { StepProps } from '../types/generic.types';
import { useStepValid } from '../hooks/useStepValid';
import { pdpStepState } from '../services/state.service';
import SanitizeHTML from '../services/html.service';
import HelpPopup from './generic/HelpPopup';

function ServiceToTheChurch({
  id,
}: StepProps): JSX.Element {
  const initialServiceData = {
    id: 0,
    pdp: id,
    presbytery: '',
    presbyteryname: '',
    congregation: null,
    congregationname: '',
    organization: '',
    description: '',
    position: '',
    start: undefined,
    end: undefined,
    current: false,
  };

  const { t } = useTranslation();

  const [allServices, setAllServices] = useState<ServiceData[]>([]);
  const [refreshServiceData, setRefreshServiceData] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentService, setCurrentService] = useState<ServiceData>(initialServiceData);
  const [showNewEdit, setShowNewEdit] = useState(false);

  const requiredFields = useMemo(() => [], []);
  useStepValid('servicetothechurch', allServices, requiredFields, pdpStepState);

  const importService = (): Promise<void> => (
    PDPService.GetService(id, true).then(
      (response: ServiceResponseData) => (setAllServices(response.data)),
    )
  );

  useEffect(() => {
    PDPService.GetService(id, false).then(
      (response: ServiceResponseData) => (setAllServices(response.data)),
    );
  }, [id, refreshServiceData]);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentService) {
      PDPService.DeleteService(currentService).then(() => {
        setShowDelete(false);
        setRefreshServiceData((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentService]);

  const saveService = useCallback((result: boolean, service: ServiceData): void => {
    if (result && service) {
      if (service.id > 0) {
        PDPService.UpdateService(service).then(() => {
          setShowNewEdit(false);
          setRefreshServiceData((r: boolean) => !r);
        });
      } else {
        PDPService.CreateService(service).then(() => {
          setShowNewEdit(false);
          setRefreshServiceData((r: boolean) => !r);
        });
      }

      setCurrentService(initialServiceData);
    } else {
      setShowNewEdit(false);
    }
  }, [currentService]);

  return (
    <div>
      <ServiceModal
        show={showNewEdit}
        service={currentService}
        setService={setCurrentService}
        saveService={saveService}
      />
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('PDP.Service_Confirmation_Message')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <div>
        <div className="title col-4">
          {t('PDP.Service_to_the_Church')}
          <HelpPopup
            helpKey="service-to-the-church-definition"
            placement="right"
            trigger="click"
            content={t('Service_To_The_Greater_Church_Definition')}
          />
        </div>
        <div className="col-12 mx-auto">
          <SanitizeHTML html={t('PDP.Service_Description')} />
          <SanitizeHTML html={t('Copy_From_Profile_Description')} />
        </div>
        <br />
        <div className="d-flex">
          <Button
            className="mb-3 createbutton"
            variant="primary"
            size="sm"
            onClick={importService}
          >
            {t('CopyFromMyProfile')}
          </Button>
          <Button
            onClick={() => {
              setCurrentService(initialServiceData);
              setShowNewEdit(true);
            }}
            className="mb-3 ms-auto createbutton"
            variant="primary"
            size="sm"
            active
          >
            {`+ ${t('PDP.Service_Create_New')}`}
          </Button>
        </div>
        <div className="border border-dark rounded-3">
          <Table responsive hover className="mb-3 linktable">
            <thead>
              <tr className="linktableheader">
                <th>
                  {t('Work_Exp_Organization')}
                </th>
                <th>
                  {t('PDP.Position')}
                </th>
                <th>
                  {t('PDP.Start_Date')}
                </th>
                <th>
                  {t('PDP.End_Date')}
                </th>
                <th>
                  {t('Actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {allServices.map((service: ServiceData) => (
                <tr key={`service-${service.id}`} className="dividinglines">
                  <td>{service.organization}</td>
                  <td>{service.position}</td>
                  <td>{service.start?.toString()}</td>
                  <td>{service.end?.toString()}</td>
                  <td>
                    <div>
                      <EditButton
                        label=""
                        handler={() => {
                          setCurrentService(service);
                          setShowNewEdit(true);
                        }}
                      />
                    &nbsp;
                    &nbsp;
                      <DeleteButton
                        label=""
                        handler={() => {
                          setCurrentService(service);
                          setShowDelete(true);
                        }}
                      />
                      <HelpPopup
                        helpKey="tooltip-delete-pdp-service"
                        placement="left"
                        trigger="click"
                        content={t('Delete_From_PDP_Tooltip')}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br />
        </div>
        <br />
      </div>
    </div>
  );
}

export default ServiceToTheChurch;

import React, {
  MouseEventHandler,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react';

// Allows extension to access the keys by a string.
export interface IData {
  [ key: string ]: any;
}

export type StepProps = {
  id: number,
  steps: StepWizardData[],
  setStep: MouseEventHandler,
  submitRef: RefObject<HTMLButtonElement>,
  setActiveStep: Dispatch<SetStateAction<StepWizardData>>
};

export type StepWizardData = {
  key: string,
  label: string,
  isValid: boolean,
  StepComponent: React.ComponentType<StepProps> | null,
}

export const DefaultStepWizardData: StepWizardData = {
  key: '',
  label: '',
  isValid: false,
  StepComponent: null,
};

export type FormStepProps = {
  formSubmitted: () => void,
};

export type FormStepWizardData = {
  key: string,
  label: string,
  isValid: boolean,
  StepComponent: React.ComponentType<FormStepProps> | null,
}

export const DefaultFormStepWizardData: FormStepWizardData = {
  key: '',
  label: '',
  isValid: false,
  StepComponent: null,
};

export type ICallingInfoForm = {
  id: number,
  eccStatus: number | undefined,
  eccStatusDescription: string,
  presbytery: string | undefined,
  congregation: number | undefined,
  candidacyDate: string | undefined,
  ordinationDate: string | undefined,
  seeking: string,
  authorize: string,
  geoLocation: string | undefined,
  searchByState: number [],
  searchByPresbytery: number [],
}

export type SelectOption = {
  id: number,
  value: string
}

export const SelectOptionDefault: SelectOption = {
  id: 0,
  value: '',
};

export interface StatesListOptionResponse {
  data: {
    states: SelectOption[],
  }
}

export type SystemNotificationData = {
  date: string,
  message: string,
}
export interface SystemNotificationsResponse {
  data: {
    options: SystemNotificationData[],
  }
}

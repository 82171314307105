import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProgressBar, Button } from 'react-bootstrap';
import { StepWizardData } from '../../types/generic.types';
import useWindowSize from '../../hooks/useWindowSize';
import SuspenseLoading from './SuspenseLoading';
import ConfirmModal from './ConfirmModal';

type Props = {
  id: number
  steps: StepWizardData[],
  activeStep: StepWizardData,
  setActiveStep: Dispatch<SetStateAction<StepWizardData>>,
  formSubmit: () => void,
  handleStepChange: () => void,
}

function StepWizard({
  id,
  steps,
  activeStep,
  setActiveStep,
  formSubmit,
  handleStepChange,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const activeStepIndex = steps.findIndex((step) => step.key === activeStep?.key);
  const percentDone = ((activeStepIndex + 1) / steps.length) * 100;
  const { StepComponent = null } = activeStep;
  const [navWidth, setNavWidth] = useState<number>(0);
  const [showExit, setShowExit] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const navRef = useRef<HTMLDivElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);

  const prevStepClicked = (currentStep: number): void => {
    handleStepChange();
    setActiveStep(steps[currentStep - 1]);
  };

  const stepClicked = (currentStep: number): void => {
    setActiveStep(steps[currentStep]);
  };

  const nextStepClicked = (currentStep: number): void => {
    handleStepChange();
    setActiveStep(steps[currentStep + 1]);
  };

  const closeExit = useCallback((result: boolean): void => {
    if (result) {
      navigate('/');
    } else {
      setShowExit(false);
    }
  }, []);

  useEffect(() => {
    if (navRef.current) {
      setNavWidth(navRef.current.scrollWidth);
    }
  }, [navRef, width]);

  return (
    <div className="container-fluid">
      <ConfirmModal
        show={showExit}
        title={t('PDP.Confirm')}
        description={t('Form_Step_Exit')}
        yesLabel={t('Yes')}
        noLabel={t('PDP.Cancel')}
        callback={closeExit}
      />
      <div className="row">
        <div className="breadcrumb-progress mb-3">
          <div className="scrollbox">
            <div
              className="breadcrumb-progress__nav"
              ref={navRef}
            >
              {steps.map((step, index) => {
                const classes = ['breadcrumb-progress__item'];
                if (step.key === activeStep?.key) {
                  classes.push('breadcrumb-progress__item--active');
                }
                return (
                  <button
                    key={`breadcrumb-${step.label}`}
                    type="button"
                    className={classes.join(' ')}
                    onClick={() => stepClicked(index)}
                    data-step-key={step.key}
                    disabled={(!step.isValid || step.key === activeStep?.key)}
                  >
                    {t(step.label)}
                  </button>
                );
              })}
            </div>
            <div
              className="breadcrumb-progress__progression pb-3"
              style={{ width: `${navWidth}px` }}
            >
              <ProgressBar
                variant="secondary"
                now={percentDone}
              />
            </div>
          </div>
        </div>
        <div className="breadcrumb-progress__component-wrapper">
          <Suspense fallback={<SuspenseLoading />}>
            {StepComponent && (
              <StepComponent
                id={id}
                setStep={handleStepChange}
                submitRef={submitRef}
                setActiveStep={setActiveStep}
                steps={steps}
              />
            )}
          </Suspense>
        </div>
        <div className="breadcrumb-progress__footer">
          {activeStepIndex > 0 && (
            <Button
              className="previous-button btn-lg"
              onClick={() => prevStepClicked(activeStepIndex)}
            >
              {t('Previous')}
            </Button>
          )}
          <Button
            className="exit-button btn-lg"
            onClick={() => setShowExit(true)}
          >
            {t('Exit')}
          </Button>
          {activeStepIndex < steps.length - 1
            ? (
              <Button
                className="next-button btn-lg"
                onClick={() => {
                  if (submitRef.current) submitRef.current.click();
                  nextStepClicked(activeStepIndex);
                }}
                disabled={!steps[activeStepIndex].isValid}
              >
                {t('Save_Continue')}
              </Button>
            ) : (
              <Button
                className="next-button btn-lg"
                onClick={formSubmit}
                disabled={!steps[activeStepIndex].isValid}
              >
                {t('Submit')}
              </Button>
            )}
        </div>
      </div>
    </div>
  );
}

export default StepWizard;

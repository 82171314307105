import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';

import api from '../services/api.service';
import EditButton from './generic/EditButton';
import DeleteButton from './generic/DeleteButton';
import OptionalLinkModal from './OptionalLinkModal';
import ConfirmModal from './generic/ConfirmModal';
import { StepProps } from '../types/generic.types';
import { MDPOptionalLinkData, MDPOptionalLinkResponseData } from '../types/mdp.types';
import { useStepValid } from '../hooks/useStepValid';
import { mdpStepState } from '../services/state.service';
import SanitizeHTML from '../services/html.service';

function MDPOptionalLinks({
  id,
}: StepProps): JSX.Element {
  const { t } = useTranslation();
  const [optionalLink, setOptionalLink] = useState<MDPOptionalLinkData[]>([]);
  const [currentLink, setCurrentLink] = useState<MDPOptionalLinkData>({
    id: 0,
    mdp: id,
    linkTitle: '',
    linkDescription: '',
    linkUrl: '',
  });
  const [showNewEdit, setShowNewEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshLinks, setRefreshLinks] = useState(false);

  const requiredFields = useMemo(() => [], []);
  useStepValid('mdpoptionallinks', optionalLink, requiredFields, mdpStepState);

  useEffect(() => {
    api.get(`/mdp/${id}/mdpoptionallinks/`).then(
      (response: MDPOptionalLinkResponseData) => { (setOptionalLink(response.data)); },
    );
  }, [id, refreshLinks]);

  const closeNewEdit = useCallback((result: boolean, link: MDPOptionalLinkData): void => {
    if (result && link) {
      if (link.id > 0) {
        api.put(`/mdp/${link.mdp}/mdpoptionallinks/${link.id}/`, link).then(() => {
          setShowNewEdit(false);
          setRefreshLinks((r: boolean) => !r);
        });
      } else {
        api.post(`/mdp/${link.mdp}/mdpoptionallinks/`, link).then(() => {
          setShowNewEdit(false);
          setRefreshLinks((r: boolean) => !r);
        });
      }
    } else {
      setShowNewEdit(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentLink) {
      api.delete(`/mdp/${currentLink.mdp}/mdpoptionallinks/${currentLink.id}`).then(() => {
        setShowDelete(false);
        setRefreshLinks((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentLink]);

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-8">
      <div className="title">{t('PDP.Optional_Links')}</div>
      <div className="mb-3">
        <SanitizeHTML html={t('MDP_Optional_Links_Description')} />
      </div>
      <div className="d-flex">
        <Button
          onClick={() => {
            setCurrentLink({
              id: 0, mdp: id, linkTitle: '', linkDescription: '', linkUrl: '',
            });
            setShowNewEdit(true);
          }}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          {`+ ${t('MDP.Links_Create_New')}`}
        </Button>
      </div>
      <OptionalLinkModal
        show={showNewEdit}
        currentLink={currentLink}
        callback={closeNewEdit}
      />
      <ConfirmModal
        show={showDelete}
        title={t('MDP.Confirm')}
        description={t('MDP.Links_Confirmation_Message')}
        yesLabel={t('MDP.Delete')}
        noLabel={t('MDP.Cancel')}
        callback={closeDelete}
      />
      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>
                {t('MDP.Links_Title')}
              </th>
              <th>
                {t('MDP.Links_Description')}
              </th>
              <th>
                {t('MDP.Links_URL')}
              </th>
              <th>
                {t('MDP.Links_Table_Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {optionalLink.map((option: MDPOptionalLinkData) => (
              <tr key={`link-${option.id}`} className="dividinglines">
                <th>{option.linkTitle}</th>
                <th>{option.linkDescription}</th>
                <th>
                  <a
                    href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {option.linkUrl}
                  </a>
                </th>
                <th>
                  <div>
                    <EditButton
                      label=""
                      handler={() => {
                        setCurrentLink(option);
                        setShowNewEdit(true);
                      }}
                    />
                    &nbsp;
                    &nbsp;
                    <DeleteButton
                      label=""
                      handler={() => {
                        setCurrentLink(option);
                        setShowDelete(true);
                      }}
                    />
                  </div>
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default MDPOptionalLinks;

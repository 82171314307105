import { UserProfile, UserProfileDefaultValue } from '../types/user.types';
import { MinistryProfileResponseData } from '../types/ministry.types';
import api from './api.service';

export interface SurveyQuestionData {
  progress: string,
  headerText: string,
  introText: string,
  question: number,
  description: string,
  statement: string,
  responseOptions: string[][],
  response: string | null,
}

export interface SurveyData {
  surveyId: number,
  questions: SurveyQuestionData[],
  surveyCompleted: boolean,
}

export interface SurveyResponseData {
  data: SurveyData,
}

const GetSurvey = (): Promise<SurveyResponseData> => (
  api.get('/survey/')
);

const GetProfile = (): Promise<UserProfile> => (
  api.get('/user/profile/')
    .then((response: any) => (
      { ...response.data, ...({ isLoggedIn: true }) }
    ))
    .catch(() => UserProfileDefaultValue)
);

const UpdateProfileLanguage = (language: string): Promise<void> => (
  api.put('/user/profile/', { language })
);

const GetMinistryProfile = (orgId: number): Promise<MinistryProfileResponseData> => (
  api.get(`/ministryprofile/${orgId}/`)
);

const exports = {
  GetSurvey,
  GetProfile,
  GetMinistryProfile,
  UpdateProfileLanguage,
};

export default exports;

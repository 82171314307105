import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Accordion from 'react-bootstrap/Accordion';
import {
  Button,
  Table,
} from 'react-bootstrap';
import { FaUserCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import EditButton from './generic/EditButton';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import {
  AttestationListData,
  AttestationListResponseData,
  Role,
} from '../types/user.types';
import api from '../services/api.service';
import {
  currentRoleState,
} from '../services/state.service';
import AttestationModal from './AttestationModal';
import PrintButton from './generic/PrintButton';

function CallSeekerAttestationAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [attestations, setAttestations] = useState<AttestationListData[]>([]);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setShow(selectedUser > 0);
  }, [selectedUser]);

  useEffect(() => {
    api.get(`/attestations/${currentRole.organizationId}/`)
      .then(
        (response: AttestationListResponseData) => setAttestations(response.data.attestations),
      );
  }, [refresh, currentRole]);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaUserCheck />
        <span className="accordion-header-label">
          {t('CallSeekerAttestations')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        {(attestations && attestations.length > 0) ? (
          <div className="mb-3 mx-auto mobiletable col-lg-12">
            <div className="border border-dark rounded-3">
              <AttestationModal
                show={show}
                userId={selectedUser}
                callback={() => { setSelectedUser(() => 0); setRefresh((r) => !r); }}
              />
              <Table responsive hover className="mb-3 linktable">
                <thead>
                  <tr className="linktableheader">
                    <th>
                      {t('Name')}
                    </th>
                    <th>
                      {t('EcclesiasticalStatusAtAttestation')}
                    </th>
                    <th>
                      {t('AttestationStatus')}
                    </th>
                    <th>
                      {t('AttestationDate')}
                    </th>
                    <th>
                      {t('Actions')}
                    </th>
                    <th>
                      {t('AttestationNote')}
                    </th>
                    <th>
                      {t('AttestedBy')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attestations?.map((atl: AttestationListData) => (
                    <tr key={`attestations-${atl.id}`} className="dividinglines">
                      <td>{atl.name}</td>
                      <td>{atl.ecclesiasticalStatus}</td>
                      <td>
                        {atl.attestationStatus}
                        &nbsp;
                        { atl.attestationStatus !== t('Pending') && (
                          <EditButton
                            label=""
                            tooltip={t('UpdateAttestation')}
                            handler={() => setSelectedUser(() => atl.userId)}
                          />
                        )}
                      </td>
                      <td>{atl.attestedDate}</td>
                      <td>
                        { atl.attestationStatus === t('Pending') && (
                          <Button
                            variant="primary"
                            className="action-button mb-1"
                            onClick={() => setSelectedUser(() => atl.userId)}
                          >
                            {t('Attest')}
                          </Button>
                        )}
                        {/* Add button to view full pdp */}
                        {atl.pdpId && (
                          <PrintButton
                            label=""
                            handler={() => (
                              window.open(`/attestation/${atl.pdpId}/pdpdetails/`, '_blank')
                            )}
                          />
                        )}
                      </td>
                      <td>{atl.attestationNote}</td>
                      <td>{atl.attestedBy}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        ) : <div className="text-center">{t('NoUserProfilesToAttest')}</div>}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed([
  CLC_FUNCTION.attestCandidate, CLC_FUNCTION.attestNonOrdained, CLC_FUNCTION.attestOrdained,
].join(','))(CallSeekerAttestationAccordionItem);

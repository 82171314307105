import Form from 'react-bootstrap/Form';

// Form.Text is used as the label to match the FloatingLabel style
function DisplayField({ label, text }: { label: string, text: string }): JSX.Element {
  return (
    <Form.Group className="text-start">
      <Form.Text>
        {label}
      </Form.Text>
      {label && <br />}
      <Form.Label>
        {text}
      </Form.Label>
    </Form.Group>
  );
}

export default DisplayField;

import { useRecoilValue } from 'recoil';
import { currentRoleState } from '../../services/state.service';
import { Role } from '../../types/user.types';

const withUserAllowed = (clcFunction: string) => (Component: any) => (props: any) => {
  const currentRole = useRecoilValue<Role>(currentRoleState);

  const permissions = clcFunction.split(',').map((e) => e.trim());
  for (let i = 0; i < permissions.length; i += 1) {
    if (currentRole.functions.indexOf(permissions[i]) > -1) {
      return <Component {...props} />;
    }
  }
  return null;
};

export default withUserAllowed;

import {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForm, SubmitHandler } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import SanitizeHTML from '../../services/html.service';
import { FormStepProps } from '../../types/generic.types';
import { Role } from '../../types/user.types';
import api from '../../services/api.service';
import { tryParseInt } from '../../utils';
import {
  MinistryProfile,
  MinistryProfileResponseData,
  CongregationSizeData,
  CongregationSizeResponseData,
} from '../../types/ministry.types';
import {
  currentRoleState,
  ministryProfileState,
} from '../../services/state.service';

function ChurchAttendance({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [congregationSize, setCongregationSize] = useState<CongregationSizeData[]>([]);
  const [ministryProfile, setMinistryProfileState] = useRecoilState<MinistryProfile>(ministryProfileState);
  const currentRole = useRecoilValue<Role>(currentRoleState);

  const {
    setError,
    handleSubmit,
    reset,
    register,
    watch,
    formState: {
      errors,
    },
  } = useForm<MinistryProfile>({
    defaultValues: ministryProfile,
  });
  const currentAverageWorshipAttendance = watch('averageWorshipAttendance');
  const currentChurchSchoolAttendance = watch('churchSchoolAttendance');
  const currentCurriculum = watch('curriculum');

  useEffect(() => {
    api.get(`/ministryprofile/${currentRole.organizationId}/`).then(
      (response: MinistryProfileResponseData) => {
        setMinistryProfileState(response.data.options);
        reset(response.data.options);
      },
    );
    api.get('/congregationsize/').then(
      (response: CongregationSizeResponseData) => (
        setCongregationSize(response.data.options)
      ),
    );
  }, [setCongregationSize, setMinistryProfileState]);

  const onSubmit: SubmitHandler<MinistryProfile> = (data: MinistryProfile) => {
    api.put(`/ministryprofile/${currentRole.organizationId}/`, data)
      .then(() => {
        formSubmitted();
      })
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'congregationDetails' | 'averageWorshipAttendance' | 'churchSchoolAttendance' | 'curriculum',
          { type: 'server', message: messages[name][0] },
        ));
      });
  };

  const handleCongregationSizeChange = (id: number | undefined, description: string): void => {
    setMinistryProfileState((p : any) => ({
      ...p,
      congregationDetails: {
        id,
        description,
      },
    }));
  };

  const congregationSizeMenu = congregationSize?.map((option: CongregationSizeData) => (
    <option key={`congregation-size${option.id}`} value={option.id}>
      {option.description}
    </option>
  ));

  return (
    <div className="container-fluid mt-3">
      {ministryProfile.congregation ? (
        <>
          <div className="col-12 mb-5 text-center">
            <h1>
              {t('Church_Attendance_Header')}
            </h1>
            <div>
              <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
            </div>
          </div>
          <Form id="churchattendance-form" className="mx-auto col-5" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label className="bodyheader mt-3 required">
                {t('Congregation_Size')}
              </Form.Label>
              <Form.Select
                value={ministryProfile.congregationDetails?.id || ''}
                {...register('congregationSize', { required: true })}
                isInvalid={!!errors.congregationSize}
                onChange={(e) => handleCongregationSizeChange(
                  tryParseInt(e.target.value),
                  e.target.options[e.target.selectedIndex].text,
                )}
              >
                <option key="" value="">
                  {t('Select_Congregation_Size')}
                </option>
                {congregationSizeMenu}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label className="bodyheader mt-3 required">
                {t('Worship_Attendance')}
              </Form.Label>
              <Form.Control
                {...register('averageWorshipAttendance', { required: true })}
                isInvalid={!!errors.averageWorshipAttendance}
                defaultValue={currentAverageWorshipAttendance}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="bodyheader mt-3 required">
                {t('Church_School_Attendance')}
              </Form.Label>
              <Form.Control
                {...register('churchSchoolAttendance', { required: true })}
                isInvalid={!!errors.churchSchoolAttendance}
                defaultValue={currentChurchSchoolAttendance}
              />
              <Form.Control.Feedback type="invalid">
                {errors.churchSchoolAttendance && errors.churchSchoolAttendance.message
                  ? errors.churchSchoolAttendance.message
                  : t('ChurchSchoolAttendance_Required')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="text-start col-6 my-3">
              <Form.Label className="bodyheader required">
                {t('Ministry_Curriculum')}
              </Form.Label>
              <Form.Control
                style={{ width: 400 }}
                as="textarea"
                rows={6}
                maxLength={1000}
                {...register('curriculum', { required: true })}
                isInvalid={!!errors.curriculum}
                defaultValue={currentCurriculum}
              />
              <Form.Control.Feedback type="invalid">
                {errors.curriculum && errors.curriculum.message
                  ? errors.curriculum.message
                  : t('Curriculum_Required')}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </ >
      ) : (
        <>
          <div className="col-12 mb-5 text-center">
            <h4>
              {t('Not_Available')}
            </h4>
          </div>
          <Form id="churchattendance-form" className="mx-auto col-5" onSubmit={handleSubmit(onSubmit)} />
        </>
      )}

    </div>
  );
}

export default ChurchAttendance;

import { useEffect, useState } from 'react';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import api from '../../services/api.service';
import {
  userProfileState,
  currentRoleState,
} from '../../services/state.service';
import {
  Role,
  RoleDefaultValue,
  UserProfile,
  UserProfileDefaultValue,
} from '../../types/user.types';
import AuthService from '../../services/auth.service';

type ExpectedForm = {
  first_name: string,
  last_name: string,
  account_type: string,
  email: string,
  username: string,
  password1: string,
  password2: string,
}

interface ExpectedUserResponseData {
  data: ExpectedForm,
}

function Expected(): JSX.Element {
  const { t } = useTranslation();
  const [userProfile, setUserProfileState] = useRecoilState<UserProfile>(userProfileState);
  const setCurrentRoleState = useSetRecoilState(currentRoleState);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [nonFieldErrors, setNonFieldErrors] = useState<string>('');
  const [accountType, setAccountType] = useState<string>('');
  const { code } = useParams();
  const {
    setError,
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<ExpectedForm>();

  const onSubmit: SubmitHandler<ExpectedForm> = (data: ExpectedForm) => {
    clearErrors();
    setNonFieldErrors('');

    return api.post('/register/', data)
      .then(() => api.post(`/expected/${code}/`, {}))
      .then(() => setSubmitted(true))
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'first_name' | 'last_name' | 'email' | 'account_type' | 'username' | 'password1' | 'password2',
          { type: 'server', message: messages[name][0] },
        ));
        if (messages.non_field_errors) {
          setNonFieldErrors(messages.non_field_errors[0]);
        }
      });
  };

  useEffect(() => {
    if (userProfile.isLoggedIn) {
      AuthService.Logout();
      setUserProfileState(UserProfileDefaultValue);
      setCurrentRoleState(RoleDefaultValue);
    }
  }, [userProfile]);

  useEffect(() => {
    api.get(`/expected/${code}/`).then(
      (response: ExpectedUserResponseData) => {
        setAccountType(response.data.account_type);
        reset(response.data);
      },
    );
  }, [code]);

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 mb-3 text-center">
          {!submitted && (
            <h1>
              {accountType === 'caller'
                ? t('ExpectedHeader')
                : t('ExpectedCSHeader')}
            </h1>
          )}
          {submitted && (
            <h2>
              {t('SignUpSubmitted')}
            </h2>
          )}
        </div>
        {!submitted && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-3"
              controlId="formFirstname"
            >
              <Form.Label className="required">
                {t('FirstName')}
              </Form.Label>
              <Form.Control
                type="input"
                disabled
                {...register('first_name')}
              />
            </Form.Group>
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-3"
              controlId="formLastName"
            >
              <Form.Label className="required">
                {t('LastName')}
              </Form.Label>
              <Form.Control
                type="input"
                disabled
                {...register('last_name')}
              />
            </Form.Group>
            <input type="hidden" defaultValue="caller" {...register('account_type')} />
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-5"
              controlId="formEmail"
            >
              <Form.Label className="required">
                {t('Email')}
              </Form.Label>
              <Form.Control
                type="input"
                disabled
                isInvalid={!!errors.email}
                {...register('email')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message
                  ? errors.email.message
                  : t('InvalidEmail')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-3"
              controlId="formUsername"
            >
              <Form.Label className="required">
                {t('Username')}
              </Form.Label>
              <Form.Control
                type="input"
                isInvalid={!!errors.username}
                {...register('username', { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username && errors.username.message
                  ? errors.username.message
                  : t('InvalidUsername')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-3"
              controlId="formPassword"
            >
              <Form.Label className="required">
                {t('Password')}
              </Form.Label>
              <Form.Control
                type="password"
                isInvalid={!!errors.password1}
                {...register('password1', { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password1 && errors.password1.message
                  ? errors.password1.message
                  : t('Login.PasswordRequired')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-3"
              controlId="formPassword2"
            >
              <Form.Label className="required">
                {t('ConfirmPassword')}
              </Form.Label>
              <Form.Control
                type="password"
                isInvalid={!!errors.password2}
                {...register('password2', { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password2 && errors.password2.message
                  ? errors.password2.message
                  : t('Login.PasswordRequired')}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="col-12 mb-3 text-center non-field-error">
              {nonFieldErrors}
            </div>
            <div className="row text-center">
              <Button
                type="submit"
                variant="primary"
                className="login-submitbutton col-12 col-md-6 mb-3 mx-auto"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                ) : (
                  t('Submit')
                )}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
}

export default Expected;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import api from '../services/api.service';
import { userProfileState } from '../services/state.service';
import { UserProfile } from '../types/user.types';

interface ApplicantPositionData {
  position: string,
  available: string,
  first_call: string,
  seekers: string,
}

function ApplicantsPositions(): JSX.Element {
  const { t } = useTranslation();
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const [reportData, setReportData] = useState<ApplicantPositionData[]>([]);

  useEffect(() => {
    if (userProfile?.isLoggedIn) {
      api.get('applicantspositions/').then(
        (response: any) => setReportData(response.data.options),
      );
    }
  }, [userProfile]);

  return (
    <div className="container-fluid mt-5">
      <h1 className="text-center mb-5">
        {t('Applicants_Positions_Report')}
      </h1>
      <div>
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Position</th>
              <th scope="col">Available</th>
              <th scope="col">Open to First Call</th>
              <th scope="col">Seekers</th>
            </tr>
          </thead>
          <tbody>
            {reportData?.map((row: ApplicantPositionData) => (
              <tr key={`position-${row.position}`}>
                <td>{row.position}</td>
                <td>{row.available}</td>
                <td>{row.first_call}</td>
                <td>{row.seekers}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ApplicantsPositions;

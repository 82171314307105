import {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import SanitizeHTML from '../../services/html.service';
import { FormStepProps, SelectOption } from '../../types/generic.types';
import { Role } from '../../types/user.types';
import api from '../../services/api.service';
import CLCFormSelectState from '../generic/CLCFormSelectState';
import { tryParseInt } from '../../utils';
import {
  currentRoleState,
  ministryProfileState,
  presbyteryOptionsState,
  synodOptionsState,
  statesListOptionsState,
} from '../../services/state.service';
import {
  MinistryProfile,
  MinistryProfileResponseData,
  CommunityTypeData,
  CommunityTypeResponseData,
} from '../../types/ministry.types';
import { PresbyteryData, SynodData } from '../../types/pdp.types';

function GeneralInfo({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [ministryProfile, setMinistryProfileState] = useRecoilState<MinistryProfile>(ministryProfileState);
  const [communityType, setCommunityType] = useState<CommunityTypeData[]>([]);
  const presbyteryOptions = useRecoilValue<PresbyteryData[]>(presbyteryOptionsState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);
  const synodOptions = useRecoilValue<SynodData[]>(synodOptionsState);
  const currentRole = useRecoilValue<Role>(currentRoleState);

  const {
    setError,
    reset,
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<MinistryProfile>();

  useEffect(() => {
    api.get(`/ministryprofile/${currentRole.organizationId}/`).then(
      (response: MinistryProfileResponseData) => {
        const ministryData = { ...response.data.options };
        if (ministryData.interculturalMinistry === true) {
          ministryData.interculturalMinistry = 'Yes';
        } else if (ministryData.interculturalMinistry === false) {
          ministryData.interculturalMinistry = 'No';
        } else {
          ministryData.interculturalMinistry = '';
        }
        setMinistryProfileState(ministryData);
        reset(ministryData);
      },
    );
    api.get('/communitytypes/').then(
      (response: CommunityTypeResponseData) => (
        setCommunityType(response.data.options)
      ),
    );
  }, []);

  const handlePresbyteryChange = (number: string | undefined, name: string): void => {
    if (number) {
      setMinistryProfileState((p : any) => ({
        ...p,
        presbyteryDetails: {
          number,
          name,
        },
      }));
    } else {
      setMinistryProfileState((p : any) => ({
        ...p,
        presbyteryDetails: undefined,
      }));
    }
  };

  const handleSynodChange = (number: string | undefined, name: string): void => {
    if (number) {
      setMinistryProfileState((p : any) => ({
        ...p,
        synodDetails: {
          number,
          name,
        },
      }));
    } else {
      setMinistryProfileState((p : any) => ({
        ...p,
        synodDetails: undefined,
      }));
    }
  };

  const handleCommunityTypeChange = (id: number | undefined, description: string): void => {
    setMinistryProfileState((p : any) => ({
      ...p,
      communityDetails: {
        id,
        description,
      },
    }));
  };

  const onSubmit: SubmitHandler<MinistryProfile> = (data: MinistryProfile) => {
    const payload = { ...data };
    if (payload.interculturalMinistry === 'Yes') {
      payload.interculturalMinistry = true;
    } else if (payload.interculturalMinistry === 'No') {
      payload.interculturalMinistry = false;
    } else {
      payload.interculturalMinistry = undefined;
    }
    api.put(`/ministryprofile/${currentRole.organizationId}/`, payload)
      .then(() => {
        formSubmitted();
      })
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'ministryName' | 'addressOne' | 'city' | 'state' | 'zip' | 'email',
          { type: 'server', message: messages[name][0] },
        ));
      });
  };

  const communityTypeMenu = communityType?.map((option: CommunityTypeData) => (
    <option key={`community-type${option.id}`} value={option.id}>
      {option.description}
    </option>
  ));

  const presbyteryTypeMenu = presbyteryOptions?.map((option: PresbyteryData) => (
    <option key={`presbyteries-${option.number}`} value={option.number}>
      {option.name}
    </option>
  ));

  const synodTypeMenu = synodOptions?.map((option: SynodData) => (
    <option key={`synods-${option.number}`} value={option.number}>
      {option.name}
    </option>
  ));

  return (
    <div className="container-fluid mt-3">
      <div className="col-12 mb-5 text-center">
        <h1>
          {t('General_Information_Header')}
        </h1>
        <div>
          <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
        </div>
      </div>
      <Form id="generalinfo-form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id')} />
        <div className="row">
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('Ministry_Name')}
            </Form.Label>
            <Form.Control
              {...register('ministryName', { required: true })}
              isInvalid={!!errors.ministryName}
              defaultValue={ministryProfile.ministryName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ministryName && errors.ministryName.message
                ? errors.ministryName.message
                : t('MinistryName_Required')}
            </Form.Control.Feedback>
          </Form.Group>
          {!ministryProfile.congregation && (
            <>
              <Form.Group className="col-12 offset-md-3 col-md-6 mb-2">
                <Form.Label>
                  {t('Presbytery')}
                </Form.Label>
                <Form.Select
                  value={ministryProfile.presbyteryDetails?.number || ''}
                  {...register('presbyteryDetails.number')}
                  onChange={(e) => handlePresbyteryChange(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text,
                  )}
                >
                  <option key="" value="">
                    {t('SelectPresbytery')}
                  </option>
                  {presbyteryTypeMenu}
                </Form.Select>
              </Form.Group>
              <Form.Group className="col-12 offset-md-3 col-md-6 mb-2">
                <Form.Label>
                  {t('Synod')}
                </Form.Label>
                <Form.Select
                  value={ministryProfile.synodDetails?.number || ''}
                  {...register('synodDetails.number')}
                  onChange={(e) => handleSynodChange(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text,
                  )}
                >
                  <option key="" value="">
                    {t('Select_Synod')}
                  </option>
                  {synodTypeMenu}
                </Form.Select>
              </Form.Group>
            </>
          )}
          {ministryProfile.congregation && (
            <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
              <Form.Label className="bodyheader mt-3">
                {t('PDP.Community')}
              </Form.Label>
              <Form.Select
                value={ministryProfile.communityDetails?.id || ''}
                {...register('communityType')}
                onChange={(e) => handleCommunityTypeChange(
                  tryParseInt(e.target.value),
                  e.target.options[e.target.selectedIndex].text,
                )}
              >
                <option key="" value="">
                  {t('Select_Community_Type')}
                </option>
                {communityTypeMenu}
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('First_Address')}
            </Form.Label>
            <Form.Control
              {...register('addressOne', { required: true })}
              isInvalid={!!errors.addressOne}
              defaultValue={ministryProfile?.addressOne || ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors.addressOne && errors.addressOne.message
                ? errors.addressOne.message
                : t('AddressOneRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3">
              {t('Second_Address')}
            </Form.Label>
            <Form.Control
              {...register('addressTwo')}
              defaultValue={ministryProfile?.addressTwo || ''}
            />
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('City')}
            </Form.Label>
            <Form.Control
              {...register('city', { required: true })}
              isInvalid={!!errors.city}
              defaultValue={ministryProfile?.city || ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors.city && errors.city.message
                ? errors.city.message
                : t('CityRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-4">
            <CLCFormSelectState
              required
              data={ministryProfile}
              formLabel={t('State')}
              inputName="state"
              stateSetter={setMinistryProfileState}
              options={statesListOptions}
              register={register}
            />
            <Form.Control.Feedback type="invalid">
              {errors.state && errors.state.message
                ? errors.state.message
                : t('StateRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-4 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('Zip_Code')}
            </Form.Label>
            <Form.Control
              {...register('zip', { required: true })}
              isInvalid={!!errors.zip}
              defaultValue={ministryProfile?.zip || ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zip && errors.zip.message
                ? errors.zip.message
                : t('ZipRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('Preferred_Phone')}
            </Form.Label>
            <Form.Control
              {...register('preferredPhone', { required: true })}
              isInvalid={!!errors.preferredPhone}
              defaultValue={ministryProfile?.preferredPhone || ''}
            />
            <Form.Control.Feedback type="invalid">
              {errors.preferredPhone && errors.preferredPhone.message
                ? errors.preferredPhone.message
                : t('PreferredPhone_Required')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3">
              {t('Fax')}
            </Form.Label>
            <Form.Control
              {...register('fax')}
              defaultValue={ministryProfile?.fax || ''}
            />
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('Email')}
            </Form.Label>
            <Form.Control
              {...register('email', { required: true })}
              isInvalid={!!errors.email}
              defaultValue={ministryProfile?.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && errors.email.message
                ? errors.email.message
                : t('EmailRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3">
              {t('Ministry_Web_address')}
            </Form.Label>
            <Form.Control
              {...register('website')}
              defaultValue={ministryProfile?.website || ''}
            />
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader required mt-3">
              {t('AreYouAnInterculturalMinistry')}
            </Form.Label>
            <Form.Select
              {...register('interculturalMinistry', { required: true })}
              isInvalid={!!errors.interculturalMinistry}
            >
              <option key="" value="">
                {`-- ${t('Select')} Option --`}
              </option>
              <option key="yes" value="Yes">
                {t('Yes')}
              </option>
              <option key="no" value="No">
                {t('No')}
              </option>
            </Form.Select>
          </Form.Group>
          {ministryProfile.presbytery && (
            <Form.Group className="col-12 offset-md-3 col-md-6 mb-2">
              <Form.Check
                type="checkbox"
                id="no-matching-1"
                label={t('No_Matching_Within_Presbytery')}
                {...register('noMatchingWithinPresbytery')}
              />
            </Form.Group>
          )}
        </div>
      </Form>
    </div>
  );
}

export default GeneralInfo;

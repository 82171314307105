import {
  useState,
  useEffect,
} from 'react';
import { useRecoilState } from 'recoil';
import { profileStepsState } from '../services/state.service';
import { FormStepWizardData, DefaultFormStepWizardData } from '../types/generic.types';
import { ProfileSteps } from '../constants/ProfileSteps';
import FormStepWizard from '../components/generic/FormStepWizard';

function ProfileWizard(): JSX.Element {
  const [activeStep, setActiveStep] = useState<FormStepWizardData>(DefaultFormStepWizardData);
  const [steps, setSteps] = useRecoilState<FormStepWizardData[]>(profileStepsState);

  useEffect(() => {
    setSteps(ProfileSteps);
    setActiveStep(ProfileSteps[0]);
  }, []);

  return (
    <FormStepWizard
      steps={steps}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />
  );
}

export default ProfileWizard;

import axios from 'axios';
import i18n from './i18n.service';
import AuthService from './auth.service';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: any) => {
    const replacedConfig = config;
    const token = AuthService.GetLocalAccessToken();
    if (token) {
      replacedConfig.headers.Authorization = `Bearer ${token}`;
    }
    replacedConfig.headers['Accept-Language'] = i18n.language;
    return replacedConfig;
  },
  (error: any) => (
    Promise.reject(error)
  ),
);

instance.interceptors.response.use(
  (res: any) => (
    res
  ),
  async (err: any) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/token/obtain/' && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        if (originalConfig.url === '/user/profile/' && err.response.data.code === 'user_not_found') {
          AuthService.Logout();
          return Promise.reject(err);
        }
        if (originalConfig.url === '/token/refresh/') {
          AuthService.Logout();
          return Promise.reject(err);
        }

        const token = AuthService.GetLocalAccessToken();
        if (token) {
          originalConfig.retry = true;

          try {
            const rs = await instance.post('/token/refresh/', {
              refresh: AuthService.GetLocalRefreshToken(),
            });

            const { access } = rs.data;
            AuthService.UpdateLocalAccessToken(access);

            return instance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else {
          window.location.href = '/login';
        }
      }
    }

    return Promise.reject(err);
  },
);

export default instance;

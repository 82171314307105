export interface PersonalInfoData {
  id: number,
  prefix: string,
  firstName: string,
  middleName: string,
  lastName: string,
  pronouns: string,
  suffix: string,
  email: string,
  noComplaint: boolean | undefined,
  comments: string,
  certificationAccepted: boolean | undefined,
}

export interface PersonalInfoResponseData {
  data: PersonalInfoData[],
}

export const PersonalInfoDefaultData: PersonalInfoData = {
  id: 0,
  prefix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  pronouns: '',
  suffix: '',
  email: '',
  noComplaint: undefined,
  comments: '',
  certificationAccepted: undefined,
};

export interface ContactData {
  id: number,
  addressOne: string,
  addressTwo: string,
  city: string,
  state: string,
  zip: string,
  phoneOne: string,
  phoneTwo: string,
}

export interface ContactResponseData {
  data: ContactData[],
}

export const ContactDefaultData: ContactData = {
  id: 0,
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  phoneOne: '',
  phoneTwo: '',
};

export interface CallingInfoData {
  id: number,
  eccStatus: number | undefined,
  eccStatusValue: string | undefined,
  eccStatusDescription: string,
  presbytery: string | undefined,
  presbyteryDescription: string,
  presbyteryNonOrdainedDescription: string,
  congregation: number | undefined,
  congregationDescription: string,
  candidacyDate: string | undefined,
  ordinationDate: string | undefined,
  seeking: boolean | string | undefined,
  authorize: boolean | string | undefined,
  geoLocation: string | undefined,
  searchByState: number [],
  searchByPresbytery: number [],
  comments: string | undefined,
  noComplaint: boolean | undefined
  certificationAccepted: boolean | undefined
}

export interface CallingInfoResponseData {
  data: CallingInfoData[],
}

export interface PDPCallingInfoResponseData {
  data: {
    options: CallingInfoData[],
  }
}

export const CallingInfoDefaultData: CallingInfoData = {
  id: 0,
  eccStatus: undefined,
  eccStatusValue: '',
  eccStatusDescription: '',
  presbytery: undefined,
  presbyteryDescription: '',
  presbyteryNonOrdainedDescription: '',
  congregation: undefined,
  congregationDescription: '',
  candidacyDate: undefined,
  ordinationDate: undefined,
  seeking: undefined,
  authorize: undefined,
  geoLocation: undefined,
  searchByState: [],
  searchByPresbytery: [],
  comments: '',
  noComplaint: undefined,
  certificationAccepted: undefined,
};

export interface DemographicData {
  id: number,
  pronouns: string,
  birthYear: string,
  gender: string,
  genderDescription: string,
  race: string,
}

export interface DemographicResponseData {
  data: DemographicData[],
}

export const DemographicDefaultData: DemographicData = {
  id: 0,
  pronouns: '',
  birthYear: '',
  gender: '',
  genderDescription: '',
  race: '',
};

export interface EccStatusDisplay {
  id: number,
  status: string,
  description: string,
}
export interface EccStatusData {
  id: number,
  eccStatus: EccStatusDisplay,
  presbytery: string | undefined,
  congregation: number | undefined,
  candidacy_date: Date | undefined,
  ordination_date: Date | undefined,
}

export interface EccStatusResponseData {
  data: EccStatusData[],
}

export const EccStatusDefaultData: EccStatusData = {
  id: 0,
  eccStatus: { id: 0, status: '', description: '' },
  presbytery: undefined,
  congregation: undefined,
  candidacy_date: undefined,
  ordination_date: undefined,
};

export interface CompetencyData {
  labels: string[],
  values: number[],
}

export interface CompetencyResponseData {
  data: CompetencyData,
}

export const CompetencyDefaultData: CompetencyData = {
  labels: [],
  values: [],
};

export interface AttestationHistoryResponseData {
  data: AttestationHistoryData[],
}

export interface AttestationHistoryData {
  attestationDate: string,
  statusAtAttestation: string,
  attestationStatus: string,
  note: string,
  attestedBy: string,
}

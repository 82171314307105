import Accordion from 'react-bootstrap/Accordion';
import { FaBookmark } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Resources from '../pages/Resources';

function ResourceAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaBookmark />
        <span className="accordion-header-label">
          {t(' Resources')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="row">
          <Resources />
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default ResourceAccordionItem;

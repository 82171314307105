import PersonalInfo from '../components/profile/PersonalInfo';
import Contact from '../components/profile/Contact';
import CallingInfo from '../components/profile/CallingInfo';
import Demographic from '../components/profile/Demographic';
import Misconduct from '../components/profile/Misconduct';

export const ProfileSteps = [
  {
    key: 'personalinfo',
    label: 'PersonalInfo',
    isValid: true,
    StepComponent: PersonalInfo,
  },
  {
    key: 'contact',
    label: 'Contact',
    isValid: true,
    StepComponent: Contact,
  },
  {
    key: 'callinginfo',
    label: 'CallingInfo',
    isValid: true,
    StepComponent: CallingInfo,
  },
  {
    key: 'misconduct',
    label: 'PDP.Misconduct',
    isValid: true,
    StepComponent: Misconduct,
  },
  {
    key: 'demographic',
    label: 'Demographic',
    isValid: true,
    StepComponent: Demographic,
  },
];

import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import api from '../../services/api.service';
import {
  ContinuingEducationData,
  ContinuingEducationResponseData,
  ContinuingEducationDefaultData,
} from '../../types/pdp.types';
import ContinuingEducationModal from './ContinuingEducationModal';
import EditButton from '../generic/EditButton';
import DeleteButton from '../generic/DeleteButton';
import ConfirmModal from '../generic/ConfirmModal';
import { FormStepProps } from '../../types/generic.types';
import SanitizeHTML from '../../services/html.service';

function ContinuingEducationTemplate({ formSubmitted }: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [allContEducationData, setAllContEducationData] = useState<ContinuingEducationData[]>([]);
  const [currentContEducation, setCurrentContEducation] = useState<ContinuingEducationData>(ContinuingEducationDefaultData);
  const [showNewEdit, setShowNewEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshEducationData, setRefreshEducationData] = useState(false);
  interface EmptyForm {result: boolean}
  const { handleSubmit } = useForm<EmptyForm>();

  useEffect(() => {
    api.get('profile/continuingeducation/').then(
      (response: ContinuingEducationResponseData) => {
        setAllContEducationData(response.data);
      },
    );
  }, [currentContEducation, refreshEducationData]);

  const closeNewEdit = useCallback((result: boolean, education: ContinuingEducationData): void => {
    if (result && education) {
      if (education.id > 0) {
        api.put(`/profile/continuingeducation/${education.id}/`, education).then(() => {
          setShowNewEdit(false);
          setRefreshEducationData((r: boolean) => !r);
        });
      } else {
        api.post('profile/continuingeducation/', education).then(() => {
          setShowNewEdit(false);
          setRefreshEducationData((r: boolean) => !r);
        });
      }
    } else {
      setShowNewEdit(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentContEducation) {
      api.delete(`/profile/continuingeducation/${currentContEducation.id}/`).then(() => {
        setShowDelete(false);
        setRefreshEducationData((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentContEducation]);

  const onSubmit: SubmitHandler<EmptyForm> = () => {
    formSubmitted();
  };

  return (
    <div>
      <ContinuingEducationModal
        show={showNewEdit}
        selectedEducation={currentContEducation}
        callback={closeNewEdit}
      />
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('Formal_Education_Confirmation_Message')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <div className="title text-center">
        {t('Continuing_Education')}
      </div>
      <br />
      <div className="d-flex">
        <Button
          onClick={() => {
            setCurrentContEducation({
              id: 0,
              text: '',
            });
            setShowNewEdit(true);
          }}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          {`+ ${t('Continuing_Education_Add')}`}
        </Button>
      </div>
      <div className="border border-dark rounded-3">
        <Form id="continuingeducation-form" onSubmit={handleSubmit(onSubmit)}>
          <Table responsive hover className="mb-3 linktable">
            <thead>
              <tr className="linktableheader">
                <th className="w-20">
                  {t('Continuing_Education_Description')}
                </th>
                <th>
                  {t('PDP.Links_Table_Actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {allContEducationData.map((education: ContinuingEducationData) => (
                <tr key={education.id} className="dividinglines">
                  <th>
                    <SanitizeHTML html={education.text} />
                  </th>
                  <th>
                    <div>
                      <EditButton
                        label=""
                        handler={() => {
                          setCurrentContEducation(education);
                          setShowNewEdit(true);
                        }}
                      />
                      &nbsp;
                      &nbsp;
                      <DeleteButton
                        label=""
                        handler={() => {
                          setCurrentContEducation(education);
                          setShowDelete(true);
                        }}
                      />
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
        <br />
      </div>
      <br />
    </div>
  );
}

export default ContinuingEducationTemplate;

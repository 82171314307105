import { ChangeEvent } from 'react';
import {
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaQuestionCircle } from 'react-icons/fa';
import { FunctionRolesData, RoleFunctionData, AllowedRoles } from '../types/presbyterypermissions.types';

type FunctionRolesTableProps = {
  functionRoles: FunctionRolesData[],
  currentRoleFunctions: RoleFunctionData[],
  handleToggleChange: (_e: ChangeEvent<HTMLInputElement>, _functionId: string) => void,
};

function FunctionRolesTable({
  functionRoles,
  currentRoleFunctions,
  handleToggleChange,
}: FunctionRolesTableProps): JSX.Element {
  const { t } = useTranslation();

  function roleActive(roleId: number, functionRole: FunctionRolesData, roleFunctions: RoleFunctionData[]): boolean {
    const roleFunction = roleFunctions.find((role) => role.role_functions.roleId === roleId);
    if (roleFunction) {
      return roleFunction.role_functions.functionId.includes(functionRole.id);
    }
    return false;
  }

  function FunctionRoleTooltip({ functionRole, ...tooltipProps }: { functionRole: FunctionRolesData, [key: string]: any }): JSX.Element {
    return (
      <OverlayTrigger
        placement="right"
        overlay={(
          <Tooltip id={`tooltip-${functionRole.id}`} {...tooltipProps}>
            {functionRole.description}
          </Tooltip>
        )}
      >
        <span>
          <FaQuestionCircle className="ms-2 top-align-tooltip" />
        </span>
      </OverlayTrigger>
    );
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th className="function-column">{t('Function')}</th>
          <th className="allowed-roles-column">{t('Allowed_Roles')}</th>
        </tr>
      </thead>
      <tbody>
        {functionRoles.map((functionRole: FunctionRolesData) => (
          <tr key={functionRole.id}>
            <td className="wrap">
              {functionRole.function_display_name}
              <FunctionRoleTooltip functionRole={functionRole} />
            </td>
            <td>
              <ul className="ps-0">
                {functionRole.allowedRoles.filter(
                  (allowedRole: AllowedRoles) => currentRoleFunctions.some(
                    (roleFunction: RoleFunctionData) => roleFunction.role_functions.roleId === allowedRole.id,
                  ),
                ).map((allowedRole: AllowedRoles) => (
                  <Form key={`${functionRole.id}-${allowedRole.id.toString()}`}>
                    <Form.Check
                      type="switch"
                      className="custom-switch"
                      id={`${functionRole.function}-${allowedRole.id.toString()}`}
                      key={`${functionRole.function}-${allowedRole.id.toString()}`}
                      label={allowedRole.clcRole}
                      checked={roleActive(allowedRole.id, functionRole, currentRoleFunctions)}
                      disabled={allowedRole.disabled}
                      onChange={(e) => handleToggleChange(e, functionRole.id.toString())}
                    />
                  </Form>
                ))}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default FunctionRolesTable;

import {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Form, Table } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  InterculturalCompData,
  InterculturalCompResponseData,
  MinistryProfile,
  MinistryProfileResponseData,
} from '../../types/ministry.types';
import { Role } from '../../types/user.types';
import api from '../../services/api.service';
import { tryParseInt } from '../../utils';
import { FormStepProps } from '../../types/generic.types';
import DisplayField from '../generic/DisplayField';
import {
  currentRoleState,
  ministryProfileState,
} from '../../services/state.service';

function EthnicInfo({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [interculturalComp, setInterculturalComp] = useState<InterculturalCompData[]>([]);
  const [ministryProfile, setMinistryProfileState] = useRecoilState<MinistryProfile>(ministryProfileState);
  const [confirmError, setConfirmError] = useState<string>('');
  const currentRole = useRecoilValue<Role>(currentRoleState);
  let properPercentage = false;

  const {
    reset,
    handleSubmit,
  } = useForm<InterculturalCompData[]>({
    defaultValues: interculturalComp,
  });

  useEffect(() => {
    api.get(`/interculturalcomp/${currentRole.organizationId}/`).then(
      (response: InterculturalCompResponseData) => {
        setInterculturalComp(response.data.options);
      },
    );
    api.get(`/ministryprofile/${currentRole.organizationId}/`).then(
      (response: MinistryProfileResponseData) => {
        setMinistryProfileState(response.data.options);
      },
    );
  }, [setInterculturalComp]);

  useEffect(() => {
    if (interculturalComp) reset(interculturalComp);
  }, [interculturalComp, confirmError]);

  const handlePercentageUpdate = (id: number, percentage: number | undefined): void => {
    setInterculturalComp((p: any) => (
      p.map((pt: any) => {
        if (pt.race === id) {
          return { ...pt, percentage };
        }
        return pt;
      })
    ));
  };

  const countInterculturalPercentage = (data: InterculturalCompData[]): boolean => {
    let counter = 0;
    for (let i = 0; i < data.length; i += 1) {
      counter += data[i].percentage;
    }
    if (counter === 100) {
      return true;
    }
    setConfirmError(t('Percentage_Error'));
    return false;
  };

  const onSubmit: SubmitHandler<InterculturalCompData[]> = (data: InterculturalCompData[]) => {
    properPercentage = countInterculturalPercentage(interculturalComp);
    if (properPercentage) {
      api.put(`/interculturalcomp/${currentRole.organizationId}/`, data)
        .then(() => {
          formSubmitted();
        });
    }
  };

  return (
    <div className="container-fluid mt-3">
      {ministryProfile.congregation ? (
        <>
          <div className="col-12 mb-5 text-center">
            <h1>
              {t('Ethnic_Information_Header')}
            </h1>
            <div>
              {t('Ethnic_Information_Description')}
            </div>
          </div>
          <div className="col-12 mb-3 text-center non-field-error">
            {confirmError}
          </div>
          <Table responsive className="mb-0">
            <thead>
              <tr>
                <th>
                  {t('Race_Ethnicity')}
                </th>
                <th>
                  {t('InterculturalComp_Percentage')}
                </th>
              </tr>
            </thead>
            <tbody>
              {interculturalComp.map((option: any) => (
                <tr key={option.race}>
                  <td>
                    <DisplayField
                      label=""
                      text={option.raceDescription}
                    />
                  </td>
                  <td>
                    <Form id="ethnicinfo-form" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Control
                        defaultValue={option.percentage}
                        onChange={(e) => {
                          handlePercentageUpdate(option.race, tryParseInt(e.target.value));
                        }}
                        type="number"
                      />
                    </Form>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <div className="col-12 mb-5 text-center">
            <h4>
              {t('Not_Available')}
            </h4>
          </div>
          <Form id="ethnicinfo-form" onSubmit={handleSubmit(onSubmit)} />
        </>
      )}
    </div>
  );
}

export default EthnicInfo;

import {
  useEffect,
  useState,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import api from '../services/api.service';
import {
  FunctionRolesResponseData,
  RoleFunctionResponseData,
  FunctionRolesData,
  RoleFunctionData,
} from '../types/presbyterypermissions.types';
import { PRESBYTERY_PERMISSIONS_OPTIONS } from '../types/constants';
import FunctionRolesTable from '../components/FunctionRolesTable';
import PresbyteryMatching from '../components/PresbyteryMatching';
import DashboardButton from '../components/generic/DashboardButton';
import { currentRoleState, userProfileState } from '../services/state.service';
import UserService from '../services/user.service';
import { Role, UserProfile } from '../types/user.types';

function PresbyteryPermissions(): JSX.Element {
  const [functionRoles, setFunctionRoles] = useState<FunctionRolesData[]>([]);
  const [currentRoleFunctions, setCurrentRoleFunctions] = useState<RoleFunctionData[]>([]);
  const setUserProfileState = useSetRecoilState<UserProfile>(userProfileState);
  const setCurrentRoleState = useSetRecoilState<Role>(currentRoleState);
  const attestFunctions = [
    PRESBYTERY_PERMISSIONS_OPTIONS.attestFunctions.attestOrdained,
    PRESBYTERY_PERMISSIONS_OPTIONS.attestFunctions.attestNonOrdained,
    PRESBYTERY_PERMISSIONS_OPTIONS.attestFunctions.attestCandidate,
  ];
  const otherFunctions = [
    PRESBYTERY_PERMISSIONS_OPTIONS.other_functions.approveCOS,
    PRESBYTERY_PERMISSIONS_OPTIONS.other_functions.createSearchComm,
  ];
  const mdpFunctions = [
    PRESBYTERY_PERMISSIONS_OPTIONS.mdpFunctions.manageMDP,
    PRESBYTERY_PERMISSIONS_OPTIONS.mdpFunctions.approveMDP,
    PRESBYTERY_PERMISSIONS_OPTIONS.mdpFunctions.manageMatching,
  ];
  const presbyteryFunctions = [
    PRESBYTERY_PERMISSIONS_OPTIONS.presbyteryFunctions.presbyteryPermissions,
  ];
  const { t } = useTranslation();

  useEffect(() => {
    api.get('functionroles/').then(
      (response: FunctionRolesResponseData) => {
        if (response.data.options) {
          setFunctionRoles(response.data.options);
        }
      },
    );
    api.get('rolefunctions/').then(
      (response: RoleFunctionResponseData) => {
        if (response.data.options) {
          setCurrentRoleFunctions(response.data.options);
        }
      },
    );
  }, []);

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>, functionId: string): void => {
    const idParts = e.target.id.match(/-(\d+)$/);
    const roleId = idParts ? idParts[1] : null;

    const payload = {
      functionId,
      roleId,
      active: e.target.checked,
    };
    if (payload) {
      api.put('rolefunctions/', payload)
        .then(() => {
          // Update profile after role function change, to reflect changes in UI
          UserService.GetProfile()
            .then((profile) => {
              setUserProfileState(profile);
              const role = profile.roles.find((p) => p.id === profile.currentRole);
              if (role) setCurrentRoleState(role);
            });

          api.get('rolefunctions/').then(
            (r: RoleFunctionResponseData) => {
              if (r.data.options) {
                setCurrentRoleFunctions(r.data.options);
              }
            },
          );
        });
    }
  };

  return (
    <div className="container-fluid presbytery-permissions">
      <DashboardButton />
      <div className="title text-center">{t('Presbytery_Permissions')}</div>
      <p className="text-center">{t('Presbytery_Permissions_Description')}</p>
      <Row>
        <Col lg={6} xs={12}>
          <div>
            <FunctionRolesTable
              functionRoles={
                functionRoles.filter(
                  (p: FunctionRolesData) => attestFunctions.includes(p.function),
                ).sort((a, b) => attestFunctions.indexOf(a.function) - attestFunctions.indexOf(b.function))
              }
              currentRoleFunctions={currentRoleFunctions}
              handleToggleChange={handleToggleChange}
            />
          </div>
          <hr />
          <div>
            <FunctionRolesTable
              functionRoles={
                functionRoles.filter(
                  (p: FunctionRolesData) => otherFunctions.includes(p.function),
                ).sort((a, b) => otherFunctions.indexOf(a.function) - otherFunctions.indexOf(b.function))
              }
              currentRoleFunctions={currentRoleFunctions}
              handleToggleChange={handleToggleChange}
            />
          </div>
          <hr />
          <div>
            <FunctionRolesTable
              functionRoles={
                functionRoles.filter(
                  (p: FunctionRolesData) => mdpFunctions.includes(p.function),
                ).sort((a, b) => mdpFunctions.indexOf(a.function) - mdpFunctions.indexOf(b.function))
              }
              currentRoleFunctions={currentRoleFunctions}
              handleToggleChange={handleToggleChange}
            />
          </div>
        </Col>
        <Col lg={6} xs={12}>
          <div>
            <FunctionRolesTable
              functionRoles={
                functionRoles.filter(
                  (p: FunctionRolesData) => presbyteryFunctions.includes(p.function),
                ).sort((a, b) => presbyteryFunctions.indexOf(a.function) - presbyteryFunctions.indexOf(b.function))
              }
              currentRoleFunctions={currentRoleFunctions}
              handleToggleChange={handleToggleChange}
            />
          </div>
          <div>
            <PresbyteryMatching />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PresbyteryPermissions;

import {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Form, Button } from 'react-bootstrap';
import CLCInput from '../generic/CLCInput';
import CLCSelect from '../generic/CLCSelect';
import {
  ProficiencyData,
  Skill,
  DefaultSkillData,
  Proficiency,
  DefaultProficiencyData,
} from '../../types/pdp.types';

type SkillsModalProps = {
  show: boolean,
  proficiencies: ProficiencyData[];
  title: string
  inputLabel: string
  callback: (_result: boolean, _tag: string[]) => void,
}

function SkillsModal({
  show,
  proficiencies,
  title,
  inputLabel,
  callback,
}: SkillsModalProps): JSX.Element {
  const { t } = useTranslation();
  const [tag, _] = useState<string[]>([]);
  const [skill, setSkill] = useState<Skill>(DefaultSkillData);
  const [proficiency, setProficiency] = useState<Proficiency>(DefaultProficiencyData);
  const [charLimitWarning, setCharLimitWarning] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);

  function buildTag(skillset: Skill, exp: Proficiency): void {
    callback(true, [skillset.value.replace(/\s/g, '').concat('-'.concat(exp.value.trim()))]);
  }

  useEffect(() => {
    setSkill({ value: '' });
    setProficiency({ value: '' });
  }, [show]);

  useEffect(() => {
    if (skill.value && proficiency.value) {
      if (skill.value.length + proficiency.value.length < 50) {
        setValid(true);
        setCharLimitWarning(t(''));
      } else {
        setValid(false);
        setCharLimitWarning(t('Char_Limit_Error'));
      }
    } else {
      setValid(false);
    }
  }, [skill, proficiency]);

  return (
    <Modal
      show={show}
      onHide={() => callback(false, tag)}
      animation={false}
      className="modal"
      backdrop="static"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t(title)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 mb-3 text-center non-field-error">
          {charLimitWarning}
        </div>
        <Form noValidate>
          <Form.Group className="mb-3">
            <CLCInput
              data={skill}
              formLabel={t(inputLabel)}
              stateSetter={setSkill}
              inputName="value"
              inputType="text"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <CLCSelect
              data={proficiency}
              formLabel={t('Select_Proficiency')}
              inputName="value"
              stateSetter={setProficiency}
              options={proficiencies}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => (callback(false, tag))}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-6"
          onClick={() => (buildTag(skill, proficiency))}
          disabled={!valid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SkillsModal;

import {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Suspense,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProgressBar, Button } from 'react-bootstrap';
import { FormStepWizardData } from '../../types/generic.types';
import useWindowSize from '../../hooks/useWindowSize';
import SuspenseLoading from './SuspenseLoading';

type Props = {
  steps: FormStepWizardData[],
  activeStep: FormStepWizardData,
  setActiveStep: Dispatch<SetStateAction<FormStepWizardData>>,
}

function FormStepWizard({
  steps,
  activeStep,
  setActiveStep,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const activeStepIndex = steps.findIndex((step) => step.key === activeStep?.key);
  const percentDone = ((activeStepIndex + 1) / steps.length) * 100;
  const { StepComponent = null } = activeStep;
  const [navWidth, setNavWidth] = useState<number>(0);
  const { width } = useWindowSize();
  const navRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const stepClicked = useCallback((currentStep: number) => {
    setActiveStep(steps[currentStep]);
  }, []);

  const nextStepClicked = useCallback((currentStep: number) => {
    if (currentStep === steps.length - 1) {
      navigate('/');
    }
    setActiveStep(steps[currentStep + 1]);
  }, []);

  const prevStepClicked = useCallback((currentStep: number) => {
    if (currentStep > 0) {
      setActiveStep(steps[currentStep - 1]);
    }
  }, []);

  useEffect(() => {
    if (navRef.current) {
      setNavWidth(navRef.current.scrollWidth);
    }
  }, [navRef, width]);

  function renderButtons(step: FormStepWizardData, index: number, classes: string[]): JSX.Element {
    return (
      <button
        key={`breadcrumb-${step.label}`}
        type="button"
        className={classes.join(' ')}
        onClick={() => stepClicked(index)}
        data-step-key={step.key}
      >
        {t(step.label)}
      </button>
    );
  }

  function renderSteps(items: FormStepWizardData[], activeItem: FormStepWizardData): JSX.Element {
    return (
      <>
        {items.map((step, index) => {
          const classes = ['breadcrumb-progress__item'];
          if (step.key === activeItem?.key) {
            classes.push('breadcrumb-progress__item--active');
          }
          return renderButtons(step, index, classes);
        })}
      </>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="breadcrumb-progress mb-3">
          <div className="scrollbox">
            <div
              className="breadcrumb-progress__nav"
              ref={navRef}
            >
              {renderSteps(steps, activeStep)}
            </div>
            <div
              className="breadcrumb-progress__progression pb-3"
              style={{ width: `${navWidth}px` }}
            >
              <ProgressBar
                variant="secondary"
                now={percentDone}
              />
            </div>
          </div>
        </div>
        <div className="breadcrumb-progress__component-wrapper">
          {StepComponent && (
            <Suspense fallback={<SuspenseLoading />}>
              <StepComponent
                formSubmitted={() => nextStepClicked(activeStepIndex)}
              />
            </Suspense>
          )}
        </div>
        <div className="breadcrumb-progress__footer">
          {activeStepIndex > 0 && (
            <Button
              className="previous-button btn-lg"
              onClick={() => prevStepClicked(activeStepIndex)}
            >
              {t('Previous')}
            </Button>
          )}
          {activeStepIndex > -1 && (
            <Button
              className="next-button btn-lg"
              form={`${steps[activeStepIndex].key}-form`}
              type="submit"
            >
              {activeStepIndex < steps.length - 1
                ? t('Save_Continue')
                : t('Submit')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormStepWizard;

import {
  useEffect,
  useState,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  PDPMatchData,
  PDPMatchDefaultData,
} from '../types/mdp.types';

type MDPNotesModalProps = {
  matchNote: PDPMatchData,
  show: boolean,
  saveMDPNotes: (_result: boolean, _statement: PDPMatchData) => void,
}

function MDPNotesModal({
  matchNote,
  show,
  saveMDPNotes,
}: MDPNotesModalProps): JSX.Element {
  const { t } = useTranslation();
  const [pdpMatchNote, setPDPMatchNote] = useState<PDPMatchData>(PDPMatchDefaultData);

  useEffect(() => {
    setPDPMatchNote(matchNote);
  }, [matchNote]);

  return (
    <Modal
      show={show}
      onHide={() => saveMDPNotes(false, pdpMatchNote)}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('Edit_Note')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className="mb-3">
            <div className="my-4">
              <Form.Group className="text-start col-6">
                <Form.Control
                  style={{ width: 450 }}
                  as="textarea"
                  rows={6}
                  maxLength={1500}
                  value={pdpMatchNote?.note || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPDPMatchNote((p: any) => ({
                    ...p, note: e.target.value,
                  }))}
                />
              </Form.Group>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => saveMDPNotes(false, pdpMatchNote)}>
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-3"
          onClick={() => saveMDPNotes(true, pdpMatchNote)}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MDPNotesModal;

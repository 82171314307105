import {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import SanitizeHTML from '../../services/html.service';
import { FormStepProps } from '../../types/generic.types';
import api from '../../services/api.service';
import { validEmail } from '../../utils';
import {
  PersonalInfoData,
  PersonalInfoDefaultData,
  PersonalInfoResponseData,
} from '../../types/profile.types';
import {
  PrefixData,
  PrefixResponseData,
  SuffixData,
  SuffixResponseData,
} from '../../types/user.types';

function PersonalInfo({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoData>(PersonalInfoDefaultData);
  const [prefix, setPrefix] = useState<PrefixData[]>([]);
  const [suffix, setSuffix] = useState<SuffixData[]>([]);
  const {
    setError,
    reset,
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<PersonalInfoData>();

  useEffect(() => {
    api.get('/prefix/').then(
      (response: PrefixResponseData) => (setPrefix(response.data.options)),
    );
    api.get('/suffix/').then(
      (response: SuffixResponseData) => (setSuffix(response.data.options)),
    );
  }, [setPrefix, setSuffix]);

  const onSubmit: SubmitHandler<PersonalInfoData> = (data: PersonalInfoData) => (
    api.put(`/profile/personalinfo/${data.id}/`, data)
      .then(() => {
        formSubmitted();
      })
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'id' | 'firstName' | 'lastName' | 'middleName' | 'prefix' | 'suffix' | 'email',
          { type: 'server', message: messages[name][0] },
        ));
      })
  );

  const prefixMenu = prefix?.map((option: PrefixData) => (
    <option key={option.id} value={option.id}>
      {option.description}
    </option>
  ));

  const suffixMenu = suffix?.map((option: SuffixData) => (
    <option key={option.id} value={option.id}>
      {option.description}
    </option>
  ));

  useEffect(() => {
    // reset form with user data
    if (personalInfo) reset(personalInfo);
  }, [personalInfo]);

  useEffect(() => {
    api.get('/profile/personalinfo/').then((response: PersonalInfoResponseData) => {
      setPersonalInfo(response.data[0]);
    });
  }, []);

  return (
    <div className="container-fluid mt-3">
      <div className="col-12 mb-5 text-center">
        <h1>
          {t('PersonalInfoHeader')}
        </h1>
        <div className="mb-3">
          <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
        </div>
      </div>
      <Form id="personalinfo-form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id')} />
        <div className="row">
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3">
              {t('Prefix')}
            </Form.Label>
            <Form.Select
              {...register('prefix')}
            >
              <option key="" value="">
                {t('Select_Prefix')}
              </option>
              {prefixMenu}
            </Form.Select>
          </Form.Group>
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formFirstName"
          >
            <Form.Label className="required">
              {t('FirstName')}
            </Form.Label>
            <Form.Control
              type="input"
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName && errors.firstName.message
                ? errors.firstName.message
                : t('FirstNameRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formMiddleName"
          >
            <Form.Label>
              {t('Middle_Name')}
            </Form.Label>
            <Form.Control
              type="input"
              isInvalid={!!errors.middleName}
              {...register('middleName')}
            />
          </Form.Group>
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formLastName"
          >
            <Form.Label className="required">
              {t('LastName')}
            </Form.Label>
            <Form.Control
              type="input"
              isInvalid={!!errors.lastName}
              {...register('lastName', { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName && errors.lastName.message
                ? errors.lastName.message
                : t('LastNameRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3">
              {t('Suffix')}
            </Form.Label>
            <Form.Select
              {...register('suffix')}
            >
              <option key="" value="">
                {t('Select_Suffix')}
              </option>
              {suffixMenu}
            </Form.Select>
          </Form.Group>
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formPronouns"
          >
            <Form.Label>
              {t('Pronouns')}
            </Form.Label>
            <Form.Control
              type="input"
              {...register('pronouns')}
            />
          </Form.Group>
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formEmail"
          >
            <Form.Label className="required">
              {t('Email')}
            </Form.Label>
            <Form.Control
              type="input"
              isInvalid={!!errors.email}
              {...register('email', { required: true, pattern: validEmail })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email && errors.email.message
                ? errors.email.message
                : t('EmailRequired')}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Form>
    </div>
  );
}

export default PersonalInfo;

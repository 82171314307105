import Modal from 'react-bootstrap/Modal';
import {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Form, Table, Button } from 'react-bootstrap';
import { InterculturalCompData, InterculturalCompResponseData } from '../types/ministry.types';
import DisplayField from './generic/DisplayField';
import api from '../services/api.service';
import { tryParseInt } from '../utils';

type InterculturalCompTemplateModalProps = {
  orgId: number,
  show: boolean,
  saveInterculturalComp: (_result: boolean, _data: InterculturalCompData[]) => void,
}

function InterculturalCompTemplateModal({
  orgId,
  show,
  saveInterculturalComp,
}: InterculturalCompTemplateModalProps): JSX.Element {
  const { t } = useTranslation();
  const [interculturalComp, setInterculturalComp] = useState<InterculturalCompData[]>([]);
  const [confirmError, setConfirmError] = useState<string>('');
  const {
    reset,
  } = useForm<InterculturalCompData[]>({
    defaultValues: interculturalComp,
  });

  useEffect(() => {
    if (show) {
      api.get(`/interculturalcomp/${orgId}/`).then(
        (response: InterculturalCompResponseData) => {
          setInterculturalComp(response.data.options);
        },
      );
      setConfirmError('');
    }
  }, [show]);

  useEffect(() => {
    if (interculturalComp) reset(interculturalComp);
  }, [interculturalComp, confirmError]);

  const handlePercentageUpdate = (id: number, percentage: number | undefined): void => {
    setInterculturalComp((p: any) => (
      p.map((pt: any) => {
        if (pt.race === id) {
          return { ...pt, percentage };
        }
        return pt;
      })
    ));
  };

  const countInterculturalPercentage = (data: InterculturalCompData[]): void => {
    let counter = 0;
    for (let i = 0; i < data.length; i += 1) {
      counter += data[i].percentage;
    }
    if (counter === 100) {
      saveInterculturalComp(true, interculturalComp);
      setConfirmError('');
    } else {
      setConfirmError(t('Percentage_Error'));
    }
  };

  return (
    <Modal backdrop="static" size="lg" show={show} onHide={() => { saveInterculturalComp(false, interculturalComp); setConfirmError(''); }}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('Ministry_Intercultural_Comp')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 mb-3 text-center non-field-error">
          {confirmError}
        </div>
        <Table responsive className="mb-0">
          <thead>
            <tr>
              <th>
                {t('Race_Ethnicity')}
              </th>
              <th>
                {t('InterculturalComp_Percentage')}
              </th>
            </tr>
          </thead>
          <tbody>
            {interculturalComp.map((option: any) => (
              <tr key={option.race}>
                <td>
                  <DisplayField
                    label=""
                    text={option.raceDescription}
                  />
                </td>
                <td>
                  <Form>
                    <Form.Control
                      defaultValue={option.percentage}
                      onChange={(e) => handlePercentageUpdate(option.race, tryParseInt(e.target.value))}
                      type="number"
                    />
                  </Form>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Button variant="secondary" onClick={() => saveInterculturalComp(false, interculturalComp)}>
          {t('PDP.Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => countInterculturalPercentage(interculturalComp)}
        >
          {t('Panel_Modal_Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default InterculturalCompTemplateModal;

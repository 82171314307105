import { useTranslation } from 'react-i18next';
import {
  useState,
  useEffect,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form, Button } from 'react-bootstrap';
import { FormalEducationData } from '../../types/pdp.types';
import { STATES } from '../../types/constants';
import CLCInput from '../generic/CLCInput';
import CLCSelect from '../generic/CLCSelect';

type FormalEducationModalProps = {
  show: boolean,
  selectedEducation: FormalEducationData,
  callback: (_result: boolean, _education: FormalEducationData,) => void,
}

function FormalEducationModal({
  show,
  selectedEducation,
  callback,
}: FormalEducationModalProps): JSX.Element {
  const { t } = useTranslation();
  const [education, setEducation] = useState<FormalEducationData>(selectedEducation);
  const [error, setError] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    setEducation(selectedEducation);
  }, [selectedEducation]);

  useEffect(() => {
    const validateDates = (): boolean => {
      if (education.current && education.end) {
        setError(t('Clear_End_Date_If_Current'));
        return false;
      }

      if (education.start && education.end) {
        const start = new Date(education.start);
        const end = new Date(education.end);

        if (start > end) {
          setError(t('End_Date_Must_Be_After_Start_Date'));
          return false;
        }
      }

      setError(t(''));
      return true;
    };

    const validDates = validateDates();

    if (
      education.credentials
      && education.school
      && education.city
      && (education.state || education.country)
      && validDates
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [education]);

  return (
    <Modal
      show={show}
      onHide={() => callback(false, education)}
      animation={false}
      className="modal"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {education.id > 0 ? t('Formal_Education_Edit') : t('Formal_Education_New')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className="mb-3">
            <CLCInput
              data={education}
              formLabel={t('Formal_Education_credentials')}
              stateSetter={setEducation}
              inputName="credentials"
              inputType="text"
            />
            <CLCInput
              data={education}
              formLabel={t('Formal_Education_school')}
              inputName="school"
              stateSetter={setEducation}
              inputType="text"
            />
            <div className="row">
              <div className="col-4">
                <CLCInput
                  data={education}
                  formLabel={t('City')}
                  stateSetter={setEducation}
                  inputName="city"
                  maxLength={500}
                />
              </div>
              <div className="col-4">
                <CLCSelect
                  required={education.country === null || education.country === ''}
                  data={education}
                  formLabel={t('State')}
                  inputName="state"
                  stateSetter={setEducation}
                  options={STATES}
                />
              </div>
              <div className="col-4">
                <CLCInput
                  required={false}
                  data={education}
                  formLabel={t('Country')}
                  stateSetter={setEducation}
                  inputName="country"
                  maxLength={500}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <CLCInput
                  data={education}
                  formLabel={t('PDP.Start_Date')}
                  stateSetter={setEducation}
                  inputName="start"
                  inputType="date"
                />
              </div>
              <div className="col-4">
                <CLCInput
                  data={education}
                  formLabel={t('PDP.End_Date')}
                  stateSetter={setEducation}
                  inputName="end"
                  inputType="date"
                  required={!education.current}
                />
              </div>
              <div className="col-3">
                <Form.Label className={`fw-bold${education.end ? '' : ' required'}`}>
                  {t('PDP.Current')}
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={education.current}
                  onChange={() => setEducation((l) => ({ ...l, current: !education.current }))}
                  maxLength={500}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Form.Text className="text-danger">{error}</Form.Text>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => (callback(false, education))}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-3"
          onClick={() => (callback(true, education))}
          disabled={!valid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FormalEducationModal;

import {
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Button,
  Modal,
  Form,
  Table,
} from 'react-bootstrap';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import Accordion from 'react-bootstrap/Accordion';
import { FaUserCircle, FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import { OrganizationMemberData, OrganizationMemberResponseData } from '../types/ministry.types';
import api from '../services/api.service';
import {
  currentRoleState,
  orgPanelState,
} from '../services/state.service';
import { Role } from '../types/user.types';

type OrganizationMemberForm = {
  id: string,
  first_name: string,
  last_name: string,
  email: string,
  role: string,
  organization: string,
  expected: string
  isEdit: string,
  scRole: string,
  epRole: string,
  comRole: string,
  cpmRole: string,
  scRolePrimary: string,
  epRolePrimary: string,
  comRolePrimary: string,
  cpmRolePrimary: string,
};

export const OrganizationMemberFormDefault: OrganizationMemberForm = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  organization: '',
  expected: '',
  isEdit: '',
  scRole: '',
  epRole: '',
  comRole: '',
  cpmRole: '',
  scRolePrimary: '',
  epRolePrimary: '',
  comRolePrimary: '',
  cpmRolePrimary: '',
};

type InviteModelProp = {
  member: OrganizationMemberForm,
};

function OrganizationMembersAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [members, setMembers] = useState<OrganizationMemberData[]>([]);
  const [currentMember, setCurrentMember] = useState<OrganizationMemberForm>(OrganizationMemberFormDefault);
  const [refresh, setRefresh] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleClose = (): void => setShowInvite(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const refreshPanel = useRecoilValue(orgPanelState);
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    formState: {
      errors,
    },
  } = useForm<OrganizationMemberForm>();
  const scRole = watch('scRole');
  const epRole = watch('epRole');
  const comRole = watch('comRole');
  const cpmRole = watch('cpmRole');
  const scRolePrimary = watch('scRolePrimary');
  const epRolePrimary = watch('epRolePrimary');
  const comRolePrimary = watch('comRolePrimary');
  const cpmRolePrimary = watch('cpmRolePrimary');

  useEffect(() => {
    api.get(`/organizationmembers/${currentRole.organizationId}/`)
      .then(
        (response: OrganizationMemberResponseData) => {
          if (response.data.members.length > 0) {
            setMembers(response.data.members);
          }
        },
      );
  }, [refresh, refreshPanel, currentRole]);

  useEffect(() => {
    if (isNew) {
      setShowInvite(true);
      setIsNew(false);
    }

    if (isEdit) {
      setShowInvite(true);
      setIsEdit(false);
    }

    reset(currentMember);
  }, [currentMember]);

  function handlePrimaryFieldChange(role: string): void {
    if (role === 'scRole') {
      setValue('scRolePrimary', '');
    }
    if (role === 'epRole') {
      setValue('epRolePrimary', '');
    }
    if (role === 'comRole') {
      setValue('comRolePrimary', '');
    }
    if (role === 'cpmRole') {
      setValue('cpmRolePrimary', '');
    }
  }

  function getActions(member: OrganizationMemberData): JSX.Element {
    return (
      <Button
        variant="secondary"
        className="action-button"
        onClick={() => {
          setCurrentMember((p: any) => ({
            ...p,
            id: member.id,
            first_name: member.firstName,
            last_name: member.lastName,
            email: member.email.toLowerCase(),
            role: member.orgRoles,
            organization: currentRole.organizationId.toString(),
            expected: member.expected,
            isEdit: 'True',
            scRole: member.isSC ? 'SC' : '',
            epRole: member.isEP ? 'EP' : '',
            comRole: member.isCOM ? 'COM' : '',
            cpmRole: member.isCPM ? 'CPM' : '',
            scRolePrimary: member.isSCPrimary ? 'Yes' : '',
            epRolePrimary: member.isEPPrimary ? 'Yes' : '',
            comRolePrimary: member.isCOMPrimary ? 'Yes' : '',
            cpmRolePrimary: member.isCPMPrimary ? 'Yes' : '',
          }));
          setIsEdit(true);
        }}
      >
        {t('Update')}
      </Button>
    );
  }

  function generateRole(): string {
    const roles: string[] = [];
    if (scRole) {
      roles.push('SC');
    }
    if (epRole) {
      roles.push('EP');
    }
    if (comRole) {
      roles.push('COM');
    }
    if (cpmRole) {
      roles.push('CPM');
    }
    return roles.join(',');
  }

  function generatePrimaryRole(): string {
    const roles: string[] = [];
    if (scRolePrimary === 'Yes') {
      roles.push('SC');
    }
    if (epRolePrimary === 'Yes') {
      roles.push('EP');
    }
    if (comRolePrimary === 'Yes') {
      roles.push('COM');
    }
    if (cpmRolePrimary === 'Yes') {
      roles.push('CPM');
    }
    return roles.join(',');
  }

  function Invite({ member }: InviteModelProp): JSX.Element {
    const onSubmit: SubmitHandler<OrganizationMemberForm> = (data: OrganizationMemberForm) => {
      const payload = new FormData();

      if (member.isEdit !== 'True') {
        payload.append('first_name', data.first_name);
        payload.append('last_name', data.last_name);
        payload.append('email', data.email.toLowerCase());
        payload.append('role', generateRole());
        payload.append('primaryRole', generatePrimaryRole());
        payload.append('organization', currentRole.organizationId.toString());

        api.post(`/organizationmembers/${currentRole.organizationId}/`, payload)
          .then(() => {
            setShowInvite(false);
            setRefresh((r: boolean) => !r);
          });
      } else {
        payload.append('id', member.id.toString());
        payload.append('organization', currentRole.organizationId.toString());
        payload.append('role', generateRole());
        payload.append('primaryRole', generatePrimaryRole());
        payload.append('expected', member.expected ? 'True' : 'False');

        api.post('/updateorganizationmember/', payload)
          .then(() => {
            setShowInvite(false);
            setRefresh((r: boolean) => !r);
          });
      }
    };

    return (
      <>
        <Button
          onClick={() => {
            setCurrentMember({
              id: member.id.toString(),
              first_name: '',
              last_name: '',
              email: '',
              role: '',
              organization: '',
              expected: member.expected ? 'True' : 'False',
              isEdit: 'False',
              scRole: '',
              epRole: '',
              comRole: '',
              cpmRole: '',
              scRolePrimary: '',
              epRolePrimary: '',
              comRolePrimary: '',
              cpmRolePrimary: '',

            });
            setIsNew(true);
          }}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          <FaPlus />
          {` ${t('Invite')}`}
        </Button>

        <Modal
          show={showInvite}
          onHide={handleClose}
          animation={false}
          className="modalstyle modal-width"
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <div className="mx-auto mt-3">
              <div className="title">
                {member.isEdit !== 'True' ? t('InviteOrganizationMember') : t('EditOrganizationMember')}
              </div>
            </div>
            <Form className="mx-auto col-12" onSubmit={handleSubmit(onSubmit)}>
              {}
              <div className="row">
                <div className="col-8 offset-2">
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('First_Name')}
                    </Form.Label>
                    <Form.Control
                      {...register('first_name', { required: true })}
                      isInvalid={!!errors.first_name}
                      disabled={member?.isEdit === 'True'}
                      defaultValue={member?.first_name || ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.first_name && errors.first_name.message
                        ? errors.first_name.message
                        : t('FirstNameRequired')}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Last_Name')}
                    </Form.Label>
                    <Form.Control
                      {...register('last_name', { required: true })}
                      isInvalid={!!errors.last_name}
                      disabled={member?.isEdit === 'True'}
                      defaultValue={member?.last_name || ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.last_name && errors.last_name.message
                        ? errors.last_name.message
                        : t('LastNameRequired')}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Email')}
                    </Form.Label>
                    <Form.Control
                      {...register('email', { required: true })}
                      isInvalid={!!errors.email}
                      disabled={member?.isEdit === 'True'}
                      defaultValue={member?.email || ''}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email && errors.email.message
                        ? errors.email.message
                        : t('EmailRequired')}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Role')}
                    </Form.Label>
                    <div className="row">
                      <div className="col-8">
                        <Form.Check
                          className="mx-5"
                          id="SC"
                          label={t('Stated_Clerk')}
                          type="checkbox"
                          value="SC"
                          {...register('scRole', { onChange: () => handlePrimaryFieldChange('scRole') })}
                        />
                      </div>
                      <div className="col-4">
                        <Form.Group>
                          {scRole && (
                            <Form.Select
                              {...register('scRolePrimary')}
                            >
                              <option key="" value="">
                                {t('Not_Primary')}
                              </option>
                              <option key="yes" value="Yes">
                                {t('Primary')}
                              </option>
                            </Form.Select>
                          )}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8">
                        <Form.Check
                          className="mx-5"
                          id="EP"
                          label={t('Executive_Presbyter')}
                          type="checkbox"
                          value="EP"
                          {...register('epRole', { onChange: () => handlePrimaryFieldChange('epRole') })}
                        />
                      </div>
                      <div className="col-4">
                        <Form.Group>
                          {epRole && (
                            <Form.Select
                              {...register('epRolePrimary')}
                            >
                              <option key="" value="">
                                {t('Not_Primary')}
                              </option>
                              <option key="yes" value="Yes">
                                {t('Primary')}
                              </option>
                            </Form.Select>
                          )}
                        </Form.Group>
                      </div>
                    </div>

                    {currentRole.organizationType === 'presbytery' && (
                      <>
                        <div className="row">
                          <div className="col-8">
                            <Form.Check
                              className="mx-5"
                              id="COM"
                              label={t('Committee_On_Ministry')}
                              type="checkbox"
                              value="COM"
                              {...register('comRole', { onChange: () => handlePrimaryFieldChange('comRole') })}
                            />
                          </div>
                          <div className="col-4">
                            <Form.Group>
                              {comRole && (
                                <Form.Select
                                  {...register('comRolePrimary')}
                                >
                                  <option key="" value="">
                                    {t('Not_Primary')}
                                  </option>
                                  <option key="yes" value="Yes">
                                    {t('Primary')}
                                  </option>
                                </Form.Select>
                              )}
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-8">
                            <Form.Check
                              className="mx-5"
                              id="CPM"
                              label={t('Committee_On_Preparation_For_Ministry')}
                              type="checkbox"
                              value="CPM"
                              {...register('cpmRole', { onChange: () => handlePrimaryFieldChange('cpmRole') })}
                            />
                          </div>
                          <div className="col-4">
                            <Form.Group>
                              {cpmRole && (
                                <Form.Select
                                  {...register('cpmRolePrimary')}
                                >
                                  <option key="" value="">
                                    {t('Not_Primary')}
                                  </option>
                                  <option key="yes" value="Yes">
                                    {t('Primary')}
                                  </option>
                                </Form.Select>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Group>
                </div>
              </div>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="primary" onClick={() => submitRef.current?.click()}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaUserCircle />
        <span className="accordion-header-label">
          {t('OrganizationMembers')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="mb-3 mx-auto mobiletable col-lg-12">
          {(currentRole.organizationType === 'presbytery' || currentRole.organizationType === 'synod') && (
            <div className="d-flex">
              <Invite
                member={currentMember}
              />
            </div>
          )}
          <div className="border border-dark rounded-3">
            <Table responsive hover className="mb-3 linktable">
              <thead>
                <tr className="linktableheader">
                  <th>
                    {t('Name')}
                  </th>
                  <th>
                    {t('Email')}
                  </th>
                  <th>
                    {t('Role')}
                  </th>
                  <th>
                    {t('Status')}
                  </th>
                  <th>
                    {t('Actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {members?.map((member: OrganizationMemberData) => (
                  <tr key={member.id} className="dividinglines">
                    <td>
                      {member.firstName}
                      &nbsp;
                      {member.lastName}
                    </td>
                    <td>{member.email}</td>
                    <td>{member.orgRoles}</td>
                    <td>{member.expected ? t('Invited') : t('Active')}</td>
                    <td>
                      {(currentRole.organizationType === 'presbytery' || currentRole.organizationType === 'synod') && !member.isReadOnly && (getActions(member))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed(CLC_FUNCTION.manageOrganization)(OrganizationMembersAccordionItem);

import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  FaCheck,
  FaTimes,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';
import PDPService from '../services/pdp.service';
import {
  PDPData,
  PDPDefaultData,
  PDPListResponseData,
  PDPResponseData,
} from '../types/pdp.types';
import EditButton from '../components/generic/EditButton';
import DeleteButton from '../components/generic/DeleteButton';
import ConfirmModal from '../components/generic/ConfirmModal';
import api from '../services/api.service';
import PDPReferrals from '../components/PDPReferrals';
import { PersonalInfoData, PersonalInfoDefaultData, PersonalInfoResponseData } from '../types/profile.types';
import {
  pdpPanelState,
} from '../services/state.service';
import { STATUS_CHOICES } from '../types/constants';
import AlertModal from '../components/generic/AlertModal';

function PDPPanel(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [list, setList] = useState<PDPData[]>([]);
  const [currentPDP, setCurrentPDP] = useState<PDPData>(PDPDefaultData);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [showRows, setShowRows] = useState<boolean[]>([]);
  const [referrals, setReferrals] = useState<any[]>([]);
  const [showMisconductDialogue, setShowMisconductDialogue] = useState(false);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoData>(PersonalInfoDefaultData);
  const refreshPanel = useRecoilValue(pdpPanelState);

  const handleToggleRow = (index: number): void => {
    setShowRows(showRows.map((show, i) => (i === index ? !show : show)));
  };

  useEffect(() => {
    PDPService.GetPDPList().then(
      (response: PDPListResponseData) => {
        setList(response.data);
        setShowRows(Array(response.data.length).fill(true));
        const fetchData = async (): Promise<void> => {
          const newData = await Promise.all(
            response.data.map(async (item: PDPData) => {
              const referralsResponse = await api.get(`/matchedpdp/${item.id}/`);
              return referralsResponse.data;
            }),
          );
          setReferrals(newData);
        };
        fetchData();
      },
    );

    api.get('/profile/personalinfo/').then((response: PersonalInfoResponseData) => {
      setPersonalInfo(response.data[0]);
    });
  }, [refreshList, refreshPanel]);

  function makeNewPDP(item: PDPData): void {
    if (personalInfo.certificationAccepted && (personalInfo.noComplaint
        || (!personalInfo.noComplaint && personalInfo.comments.length > 0))) {
      PDPService.CreatePDP(item).then((response: PDPResponseData) => {
        navigate(`/pdp/${(response.data.id)}/`);
      });
    } else {
      setShowMisconductDialogue(true);
    }
  }

  function pdpStatus(active: boolean | undefined, submitted: boolean): string {
    if (submitted && active) {
      return t('PDP_Status_Active');
    }
    if (submitted && !active) {
      return t('PDP_Status_Inactive');
    }

    return t('PDP_Status_In_Progress');
  }

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentPDP) {
      PDPService.DeletePDP(currentPDP).then(() => {
        setShowDelete(false);
        setRefreshList((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentPDP]);

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-12">
      <div className="d-flex">
        <Button
          onClick={() => (makeNewPDP(PDPDefaultData))}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          {`+ ${t('PDP.Links_Create_Pdp')}`}
        </Button>
      </div>
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('PDP.Links_Confirmation_Message')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <AlertModal
        show={showMisconductDialogue}
        title={t('Misconduct_Form_Required_Title')}
        description={t('Misconduct_Form_Required_Dialogue')}
        closeLabel={t('OK')}
        callback={() => setShowMisconductDialogue(!showMisconductDialogue)}
      />

      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>
                {t('PDP_ID')}
              </th>
              <th>
                {t('PDP.Position_Type_Title')}
              </th>
              <th>
                {t('Employment_Status')}
              </th>
              <th>
                {t('No_Matching_Within_Presbytery_Title')}
              </th>
              <th>
                {t('PDP.Links_Table_Status')}
              </th>
              <th>
                {t('PDP.Links_Table_Actions')}
              </th>
              <th>
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((pdp: PDPData, index: number) => (
              <React.Fragment key={pdp.id}>
                <tr className="dividinglines">
                  <td>
                    <Link to={`/pdp/${pdp.id}/view/`} target="_blank">
                      {pdp.id}
                    </Link>
                  </td>
                  <td>{pdp.positionTypeDescription}</td>
                  <td>{pdp.employmentType?.description}</td>
                  <td>{pdp.noMatchingWithinPresbytery ? <FaCheck /> : <FaTimes /> }</td>
                  <td>{pdpStatus(pdp.active, pdp.submitted)}</td>
                  <td>
                    <div>
                      {referrals[index]?.options[0]?.statusKey !== STATUS_CHOICES.callCompleted && (
                        <>
                          <EditButton
                            label=""
                            handler={() => (
                              navigate(`/pdp/${(pdp.id)}/`)
                            )}
                          />
                          &nbsp;
                          &nbsp;
                          <DeleteButton
                            label=""
                            handler={() => {
                              setCurrentPDP(pdp);
                              setShowDelete(true);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    {referrals[index] && (
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="left"
                        overlay={(
                          <Tooltip id="edit-button-tooltip">
                            {t('MDPs_Applied')}
                          </Tooltip>
                        )}
                      >
                        <Button onClick={() => handleToggleRow(index)}>
                          {showRows[index] ? <FaChevronUp /> : <FaChevronDown />}
                        </Button>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
                {referrals[index] && !showRows[index] && (
                  <tr key={`ref-${pdp.id}`}>
                    <td colSpan={7}>
                      <Table striped responsive hover className="mb-3 linktable referraltable">
                        <thead>
                          <tr className="referraltableheader">
                            <th>{t('Match_MDP_ID')}</th>
                            <th>{t('Position_Type_Title')}</th>
                            <th>{t('Organization')}</th>
                            <th>{t('City')}</th>
                            <th>{t('State')}</th>
                            <th>{t('Status')}</th>
                          </tr>
                        </thead>
                        <PDPReferrals
                          pdp={pdp.id}
                          referrals={referrals}
                        />
                      </Table>
                      <hr className="mdpdivider strongdivider my-3" />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PDPPanel;

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ConfirmModal({
  show, title, description, yesLabel, noLabel, callback,
} : {
  show: boolean,
  title: string,
  description: string,
  yesLabel: string,
  noLabel: string,
  callback: (_result: boolean) => void,
}): JSX.Element {
  return (
    <Modal
      show={show}
      onHide={() => callback(false)}
      animation={false}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {description}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => (callback(true))}>
          {yesLabel}
        </Button>
        <Button variant="danger" onClick={() => (callback(false))}>
          {noLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;

import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';

import api from '../services/api.service';
import EditButton from './generic/EditButton';
import DeleteButton from './generic/DeleteButton';
import OptionalLinkModal from './OptionalLinkModal';
import ConfirmModal from './generic/ConfirmModal';
import { StepProps } from '../types/generic.types';
import { OptionalLinkData, OptionalLinkResponseData } from '../types/pdp.types';
import { useStepValid } from '../hooks/useStepValid';
import { pdpStepState } from '../services/state.service';
import SanitizeHTML from '../services/html.service';

function OptionalLinks({
  id,
}: StepProps): JSX.Element {
  const { t } = useTranslation();
  const [optionalLink, setOptionalLink] = useState<OptionalLinkData[]>([]);
  const [currentLink, setCurrentLink] = useState<OptionalLinkData>({
    id: 0,
    pdp: id,
    linkTitle: '',
    linkDescription: '',
    linkUrl: '',
  });
  const [showNewEdit, setShowNewEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshLinks, setRefreshLinks] = useState(false);

  const requiredFields = useMemo(() => [], []);
  useStepValid('optionallinks', optionalLink, requiredFields, pdpStepState);

  const importOptionalLinks = (): Promise<void> => (
    api.get(`/pdp/${id}/optionallinks/?import=true`).then(
      (response: OptionalLinkResponseData) => (setOptionalLink(response.data)),
    )
  );

  useEffect(() => {
    api.get(`/pdp/${id}/optionallinks/`).then(
      (response: OptionalLinkResponseData) => (setOptionalLink(response.data)),
    );
  }, [id, refreshLinks]);

  const closeNewEdit = useCallback((result: boolean, link: OptionalLinkData): void => {
    if (result && link) {
      if (link.id > 0) {
        api.put(`/pdp/${link.pdp}/optionallinks/${link.id}/`, link).then(() => {
          setShowNewEdit(false);
          setRefreshLinks((r: boolean) => !r);
        });
      } else {
        api.post(`/pdp/${link.pdp}/optionallinks/`, link).then(() => {
          setShowNewEdit(false);
          setRefreshLinks((r: boolean) => !r);
        });
      }
    } else {
      setShowNewEdit(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentLink) {
      api.delete(`/pdp/${currentLink.pdp}/optionallinks/${currentLink.id}`).then(() => {
        setShowDelete(false);
        setRefreshLinks((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentLink]);

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-8">
      <div className="title">{t('PDP.Optional_Links')}</div>
      <div className="mb-3">
        <SanitizeHTML html={t('PDP_Optional_Links_Description')} />
        <SanitizeHTML html={t('Copy_From_Profile_Description')} />
      </div>
      <div className="d-flex">
        <Button
          className="mb-3 createbutton"
          variant="primary"
          size="sm"
          onClick={importOptionalLinks}
        >
          {t('CopyFromMyProfile')}
        </Button>
        <Button
          onClick={() => {
            setCurrentLink({
              id: 0, pdp: id, linkTitle: '', linkDescription: '', linkUrl: '',
            });
            setShowNewEdit(true);
          }}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          {`+ ${t('PDP.Links_Create_New')}`}
        </Button>
      </div>
      <OptionalLinkModal
        show={showNewEdit}
        currentLink={currentLink}
        callback={closeNewEdit}
      />
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('PDP.Links_Confirmation_Message')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <div className="border border-dark rounded-3">
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>
                {t('PDP.Links_Title')}
              </th>
              <th>
                {t('PDP.Links_Description')}
              </th>
              <th>
                {t('PDP.Links_URL')}
              </th>
              <th>
                {t('PDP.Links_Table_Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {optionalLink.map((option: OptionalLinkData) => (
              <tr key={`link-${option.id}`} className="dividinglines">
                <th>{option.linkTitle}</th>
                <th>{option.linkDescription}</th>
                <th>
                  <a
                    href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {option.linkUrl}
                  </a>
                </th>
                <th>
                  <div>
                    <EditButton
                      label=""
                      handler={() => {
                        setCurrentLink(option);
                        setShowNewEdit(true);
                      }}
                    />
                    &nbsp;
                    &nbsp;
                    <DeleteButton
                      label=""
                      handler={() => {
                        setCurrentLink(option);
                        setShowDelete(true);
                      }}
                    />
                  </div>
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default OptionalLinks;

export interface TrainingCertCredentialsData {
  trainingCert: number,
  certificate: number,
  institution: string,
  year: string,
}

export interface TrainingCertificationData {
  id: number,
  introAdvOne: string | number | undefined,
  countryOne: string,
  cityOne: string,
  stateOne: string,
  yearOne: string,
  introAdvTwo: string | number | undefined,
  countryTwo: string,
  cityTwo: string,
  stateTwo: string,
  yearTwo: string,
  transitionalMinistryTraining: boolean,
  trainingCertCredentials: TrainingCertCredentialsData[],
  otherTraining: string,
}

export const TrainingCertificationDefaultValue: TrainingCertificationData = {
  id: 0,
  introAdvOne: undefined,
  countryOne: '',
  cityOne: '',
  stateOne: '',
  yearOne: '',
  introAdvTwo: undefined,
  countryTwo: '',
  cityTwo: '',
  stateTwo: '',
  yearTwo: '',
  transitionalMinistryTraining: false,
  trainingCertCredentials: [],
  otherTraining: '',
};

export interface TrainingCertificationResponseData {
  data: TrainingCertificationData[]
}

export interface IntroductionAdvancedTypeData {
  id: number,
  description: string,
}

export interface IntroductionAdvancedTypeResponseData {
  data: {
    options: IntroductionAdvancedTypeData[],
  }
}

export interface Training {
  value: string[],
}

export const DefaultTrainingData: Training = {
  value: [],
};

import {
  useState,
  useEffect,
} from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { FormStepProps } from '../../types/generic.types';
import api from '../../services/api.service';
import { userProfileState } from '../../services/state.service';
import UserService from '../../services/user.service';
import SanitizeHTML from '../../services/html.service';
import {
  DemographicData,
  DemographicResponseData,
} from '../../types/profile.types';
import {
  GenderData,
  GenderResponseData,
  RaceData,
  RaceResponseData,
  UserProfile,
} from '../../types/user.types';

function Demographic({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const setUserProfileState = useSetRecoilState<UserProfile>(userProfileState);
  const [demographic, setDemographic] = useState<DemographicData>();
  const [gender, setGender] = useState<GenderData[]>([]);
  const [race, setRace] = useState<RaceData[]>([]);
  const {
    setError,
    reset,
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<DemographicData>();

  const onSubmit: SubmitHandler<DemographicData> = (data: DemographicData) => (
    api.put(`/profile/demographic/${data.id}/`, data)
      .then(() => {
        UserService.GetProfile().then((profile: UserProfile) => {
          setUserProfileState(profile);
          formSubmitted();
        });
      })
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'id' | 'birthYear' | 'gender' | 'race',
          { type: 'server', message: messages[name][0] },
        ));
      })
  );

  useEffect(() => {
    // reset form with user data
    if (demographic) {
      if (demographic.birthYear === '0') {
        demographic.birthYear = '';
      }
      reset(demographic);
    }
  }, [demographic]);

  useEffect(() => {
    api.get('/profile/demographic/').then((response: DemographicResponseData) => {
      setDemographic(response.data[0]);
    });

    api.get('/gender/').then(
      (response: GenderResponseData) => (setGender(response.data.options)),
    );

    api.get('/race/').then(
      (response: RaceResponseData) => (setRace(response.data.options)),
    );
  }, [setGender, setRace]);

  const genderMenu = gender?.map((option: GenderData) => (
    <option key={option.id} value={option.id}>
      {option.description}
    </option>
  ));

  const raceMenu = race?.map((option: RaceData) => (
    <option key={option.id} value={option.id}>
      {option.description}
    </option>
  ));

  return (
    <div className="container-fluid mt-3">
      <div className="col-12 mb-3 text-center">
        <h1>
          {t('Registration_Demographic_Title')}
        </h1>
        <div>
          <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
        </div>
        <div className="mt-2 text-center">
          {t('Demographic_Privacy_Notice')}
        </div>
      </div>
      <Form id="demographic-form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id')} />
        <div className="row">
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formDemographics"
          >
            <Form.Label className="mt-3 required">
              {t('Birth_Year')}
            </Form.Label>
            <Form.Control
              type="input"
              isInvalid={!!errors.birthYear}
              {...register('birthYear')}
            />
            <Form.Control.Feedback type="invalid">
              {errors.birthYear && errors.birthYear.message
                ? errors.birthYear.message
                : t('FieldRequired')}
            </Form.Control.Feedback>

            <Form.Label className="mt-3 required">
              {t('Gender')}
            </Form.Label>
            <Form.Select
              isInvalid={!!errors.gender}
              {...register('gender')}
            >
              <option key="" value="">
                {t('Select_Gender')}
              </option>
              {genderMenu}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.gender && t('FieldRequired')}
            </Form.Control.Feedback>

            <Form.Label className="mt-3">
              {t('Gender Description')}
            </Form.Label>
            <Form.Control
              type="input"
              {...register('genderDescription')}
            />

            <Form.Label className="mt-3 required">
              {t('Race_Ethnicity')}
            </Form.Label>
            <Form.Select
              isInvalid={!!errors.race}
              {...register('race')}
            >
              <option key="" value="">
                {t('Select_Race')}
              </option>
              {raceMenu}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.gender && t('FieldRequired')}
            </Form.Control.Feedback>

          </Form.Group>
        </div>
      </Form>
    </div>
  );
}

export default Demographic;

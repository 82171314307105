import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { FaQuestionCircle } from 'react-icons/fa';
import UserService, { SurveyData, SurveyResponseData } from '../services/user.service';
import SurveyService from '../services/survey.service';
import { UserProfile } from '../types/user.types';
import { userProfileState } from '../services/state.service';
import AlertModal from '../components/generic/AlertModal';

function handleRadioClick(surveyId: number, question: any, response: string): void {
  SurveyService.UpdateResponse({ surveyId, question, response });
}

function ButtonAnswer(
  surveyId: number,
  questionObject: any,
  disabled: boolean,
  index: number,
  handleRadioChange: any,
): JSX.Element {
  const { question, responseOptions, response } = questionObject;

  return (
    <Form className="form">
      {responseOptions.map((type: any) => (
        <div key={`${question}-${type[0]}-${type[1]}`} className="radiospacer">
          <Form.Check
            inline
            type="radio"
            id={`${question}-${type[0]}`}
            label={`${type[1]}`}
            disabled={disabled}
            defaultChecked={response === type[0]}
            onChange={() => handleRadioChange(index)}
            onClick={() => handleRadioClick(surveyId, question, type[0])}
            className="radio"
            name={question}
          />
        </div>
      ))}
    </Form>
  );
}

function Survey(): JSX.Element {
  const { t } = useTranslation();
  const [content, setContent] = useState<SurveyData>({
    questions: [], surveyId: 0, surveyCompleted: false,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [latestAnsweredQuestion, setLatestAnsweredQuestion] = useState(0);
  const [submitSurveyEnabled, setSubmitSurveyEnabled] = useState(false);
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const navigate = useNavigate();
  const setUserProfileState = useSetRecoilState<UserProfile>(userProfileState);

  function handleRadioChange(index: number): void {
    if (index >= latestAnsweredQuestion) {
      setLatestAnsweredQuestion(index + 1);
      if (index + 1 === content.questions.length) {
        setSubmitSurveyEnabled(true);
      }
    }
    document.querySelector(`#buttonanswer${index}`)?.scrollIntoView();
  }

  function submitSurvey(): void {
    SurveyService.CompleteSurvey().then(() => {
      setSurveySubmitted((r: boolean) => true);
    });
  }

  function saveExit(): void {
    UserService.GetProfile()
      .then((profile) => {
        setUserProfileState(profile);
      })
      .then(() => navigate('/'));
  }

  function activated(index: any): string {
    return (
      (index <= latestAnsweredQuestion)
        ? 'activated'
        : 'deactivated'
    );
  }

  function questionsdata(): any {
    return content.questions.map((item: any, index: any) => [
      <div
        key={`intro${item.question}-${item.statement}`}
        id={`intro${item.question}-${item.statement}`}
        className={`text-center ${activated(index)}`}
      >
        {item.introText && (
          <div className="sectionheader" id={`intro${index}`}>
            <span>{item.introText}</span>
            <br />
            <span>{item.headerText}</span>
            <br />
            <br />
          </div>
        )}
        <div className="itemstatement" key={`itemstatement${item.question}`} id={`statement${index}`}>
          {`${item.question}. ${item.statement}`}
          <OverlayTrigger
            key={`tooltip${item.question}`}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">{item.description}</Tooltip>}
          >
            <span className="statement-tooltip">
              <FaQuestionCircle />
            </span>
          </OverlayTrigger>
        </div>
        <div id={`buttonanswer${item.question}`} className="text-center">
          {ButtonAnswer(content.surveyId, item, index > latestAnsweredQuestion || readOnly, index, handleRadioChange)}
        </div>
      </div>,
      <hr key={`separator${item.question}-${item.statement}`} className="col-lg-8 col-12 mx-auto separator" />,
    ]);
  }

  function progressBar(): any {
    return (
      <div id={`progess${latestAnsweredQuestion}`} className="progresslabel">
        {(latestAnsweredQuestion < content.questions.length)
          ? (content.questions[latestAnsweredQuestion]?.progress)
          : (latestAnsweredQuestion === content.questions.length)
          && (content.questions[latestAnsweredQuestion - 1]?.progress)}
        <ProgressBar now={(latestAnsweredQuestion / content.questions.length) * 100} className="progressbar" />
      </div>
    );
  }

  useEffect(() => {
    UserService.GetSurvey().then(
      (response: SurveyResponseData) => {
        setContent(
          response.data,
        );
        setReadOnly(response.data.surveyCompleted);
        let latest = 0;
        for (let i = 0; i < response.data.questions.length; i += 1) {
          if (response.data.questions[i].response) {
            latest += 1;
          } else {
            break;
          }
        }
        setLatestAnsweredQuestion(latest);
        if (latest === response.data.questions.length) {
          setSubmitSurveyEnabled(true);
        }
        setIsLoaded(true);
        document.querySelector(`#buttonanswer${latest - 1}`)?.scrollIntoView();
      },
      () => {
        setContent({
          questions: [], surveyId: 0, surveyCompleted: false,
        });
        setIsLoaded(true);
      },
    );
  }, []);

  if (!isLoaded) {
    return <Spinner animation="border" size="sm" />;
  }

  return (
    <div>
      <AlertModal
        show={surveySubmitted}
        title={t('SurveySubmitted')}
        description={t('SurveySubmittedDescription')}
        closeLabel={t('OK')}
        callback={() => {
          setSurveySubmitted((r: boolean) => !r);
          UserService.GetProfile()
            .then((profile) => {
              setUserProfileState(profile);
            })
            .then(() => navigate('/'));
        }}
      />
      <div className="container-fluid">
        <div className="wholeprogress">
          <Link to="/" onClick={saveExit} className="saveexit">{readOnly ? t('Exit') : t('Save & Exit')}</Link>
          {progressBar()}
        </div>
        <div className="text-center">
          {questionsdata()}
        </div>
        <div className="text-center mb-3">
          <Button className="primary" disabled={!submitSurveyEnabled || readOnly} onClick={submitSurvey}>
            {t('SubmitSurvey')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Survey;

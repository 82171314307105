import {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Form from 'react-bootstrap/Form';
import api from '../../services/api.service';
import SanitizeHTML from '../../services/html.service';
import { FormStepProps } from '../../types/generic.types';
import { CallingInfoData, CallingInfoResponseData } from '../../types/profile.types';
import {
  callingInfoState,
} from '../../services/state.service';

interface MisconductData {
  id: number,
  noComplaint: boolean | string | undefined,
  comments: string | undefined,
  certificationAccepted: boolean | undefined,
}

interface MisconductResponseData {
  data: MisconductData[],
}

function Misconduct({
  formSubmitted,
}: FormStepProps): JSX.Element | null {
  const { t } = useTranslation();
  const [eccStatus, setEccStatus] = useRecoilState<CallingInfoData>(callingInfoState);
  const {
    setError,
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<MisconductData>();
  const currentComplaint = watch('noComplaint');

  useEffect(() => {
    if (currentComplaint === 'Yes') {
      setValue('comments', '');
    }
  }, [currentComplaint]);

  useEffect(() => {
    api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
      setEccStatus(response.data[0]);
    });
  }, []);

  useEffect(() => {
    api.get('/profile/personalinfo/').then((response: MisconductResponseData) => {
      if (response.data[0].noComplaint != null) {
        if (response.data[0].noComplaint) {
          response.data[0].noComplaint = 'Yes';
        } else {
          response.data[0].noComplaint = 'No';
        }
      }
      reset(response.data[0]);
    });
  }, []);

  const onSubmit: SubmitHandler<MisconductData> = (data: MisconductData) => (
    api.put(`/profile/personalinfo/${data.id}/`, data)
      .then(() => {
        formSubmitted();
      })
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'id' | 'noComplaint' | 'comments' | 'certificationAccepted',
          { type: 'server', message: messages[name][0] },
        ));
      })
  );

  return (
    <div>
      {eccStatus.eccStatusValue !== 'member' ? (
        <>
          <div className="mb-3 mx-auto mobiletable col-lg-8">
            <div className="title">{t('PDP.Misconduct')}</div>
            <div>
              <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
            </div>
          </div>
          <div className="border border-dark col-lg-8 col-12 mx-auto my-3 rounded p-3 text-start">
            <div className="misconducttitle text-start my-2 fw-bold">
              {t('PDP.Misconduct_Cert_Title')}
            </div>
            <SanitizeHTML html={t('PDP.Sexual_Misconduct_Self_Cert')} />
          </div>
          <div className="col-8 required pdpsurveyheader mx-auto">
            {t('PDP.Please_Select')}
          </div>
          <Form id="misconduct-form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="col-lg-8 col-12 mx-auto">
              <Form.Check
                type="radio"
                id="misconduct-complaint-1"
                label={t('PDP.Misconduct_Certify')}
                className="misconductalignradio"
                {...register('noComplaint', { required: true })}
                value="Yes"
              />
              <Form.Check
                type="radio"
                label={t('PDP.Misconduct_Unable')}
                id="misconduct-complaint-2"
                className="misconductalignradio"
                {...register('noComplaint', { required: true })}
                value="No"
              />
              {
                currentComplaint === 'No'
                && (
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder={t('PDP.Misconduct_Placeholder')}
                    className="ms-4 mb-4 complaintbox"
                    {...register('comments')}
                  />
                )
              }
            </Form.Group>
            <div className="col-lg-8 col-12 mx-auto text-start">
              <SanitizeHTML
                className="asteriskparagraph"
                html={t('PDP.Sustained_and_Pending')}
              />
            </div>
            <div className="border border-dark col-lg-8 col-12 mx-auto my-3 p-3 text-start fw-bold rounded">
              {t('PDP.Misconduct_Authorization')}
            </div>
            <div className="col-8 text-start mx-auto">
              <div className="bg-info text-white required ps-2 my-2">
                {t('PDP.Please_Accept')}
              </div>
              <div>
                <Form.Check
                  inline
                  type="checkbox"
                  id="misconductCheck"
                  label={t('PDP.Misconduct_Certification')}
                  {...register('certificationAccepted', { required: true })}
                />
              </div>
            </div>
          </Form>
        </>
      ) : (
        <>
          <div className="col-12 mb-5 text-center">
            <h4>
              {t('Misconduct_Required_Notice')}
            </h4>
          </div>
          <Form id="misconduct-form" onSubmit={handleSubmit(onSubmit)} />
        </>
      )}
    </div>
  );
}

export default Misconduct;

import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegStar, FaStar } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { IconContext } from 'react-icons';

const star = { className: 'linkactions save-button-display', size: '40' };

function StarButton({
  handler,
  label,
  isSaved,
}: {
  handler: () => void,
  label: string,
  isSaved: boolean
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="left"
      overlay={(
        <Tooltip id="{key}-tooltip">
          {t('Save_Opportunity')}
        </Tooltip>
      )}
    >
      <div>
        <Button variant="link" className="linkactions" onClick={handler}>
          <IconContext.Provider value={star}>
            {isSaved ? (<FaStar />) : (<FaRegStar />)}
          </IconContext.Provider>
          {label && (<span>{label}</span>)}
        </Button>
      </div>
    </OverlayTrigger>
  );
}

export default StarButton;

import {
  useState,
  useEffect,
} from 'react';
import { useRecoilState } from 'recoil';
import { educationStepsState } from '../services/state.service';
import { FormStepWizardData, DefaultFormStepWizardData } from '../types/generic.types';
import { EducationSteps } from '../constants/EducationSteps';
import FormStepWizard from '../components/generic/FormStepWizard';

function EducationWizard(): JSX.Element {
  const [activeStep, setActiveStep] = useState<FormStepWizardData>(DefaultFormStepWizardData);
  const [steps, setSteps] = useRecoilState<FormStepWizardData[]>(educationStepsState);

  useEffect(() => {
    setSteps(EducationSteps);
    setActiveStep(EducationSteps[0]);
  }, []);

  return (
    <FormStepWizard
      steps={steps}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />
  );
}

export default EducationWizard;

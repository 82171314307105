import { Role } from './types/user.types';
import { MDPData, PresbyteryMatchingOptionData } from './types/mdp.types';
import { CLC_FUNCTION, MINISTRY_ORG_TYPE, PRESBYTERY_MATCHING_OPTIONS } from './types/constants';

export const tryParseInt = (input: string): number | undefined => {
  try {
    const result = parseInt(input, 10);
    if (!Number.isNaN(result)) {
      return result;
    }
  } catch {
    // ignore
  }

  return undefined;
};

// Regex expressions for checking if user input is valid
export const validUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/; // eslint-disable-line
export const validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export function BooleanToOnOff(val: boolean | undefined): string {
  if (val === true) return 'on';
  if (val === false) return 'off';
  return '';
}

export function OnOffToBoolean(val: string): boolean | undefined {
  if (val === 'on') return true;
  if (val === 'off') return false;
  return undefined;
}

export function cleanText(val: string): string {
  if (val && val.length > 0) {
    return val.replace(/<\/?[^>]+>/g, '').replace(/\s+/g, ' ').replace(/&nbsp;/g, '');
  }
  return '';
}

export function canRequestRematch(currentRole: Role, matchingOptions: PresbyteryMatchingOptionData, mdp: MDPData): boolean {
  if ((currentRole.organizationType === MINISTRY_ORG_TYPE.presbytery && currentRole.functions.indexOf(CLC_FUNCTION.manageMatching) > -1)
    && (matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.none || (matchingOptions.matchByCommittee && matchingOptions.matchByCommittee.length > 0) || matchingOptions.matchByOrdainedPositions)) {
    return true;
  }

  if ((currentRole.organizationType === MINISTRY_ORG_TYPE.congregation && currentRole.functions.indexOf(CLC_FUNCTION.manageMatching) > -1)
    && (matchingOptions.matchingChoice === PRESBYTERY_MATCHING_OPTIONS.none || !matchingOptions.matchByCommittee?.includes(mdp?.committee))) {
    return true;
  }
  return false;
}

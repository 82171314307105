import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Form, Table } from 'react-bootstrap';
import {
  MatchData,
} from '../types/mdp.types';
import { currentRoleState, matchState, allPositionTypeListState } from '../services/state.service';
import { Role } from '../types/user.types';
import { CLC_FUNCTION } from '../types/constants';
import SuspenseLoading from './generic/SuspenseLoading';
import { IPositionTypeList } from '../services/mdp.service';

interface IData {
  id: number,
  positionType: string,
}

type PositionTypeRowProps = {
  data: IData,
  match: MatchData,
  setMatch: Dispatch<SetStateAction<MatchData>>,
  advancedSearch: boolean,
}

function PositionTypeRow({
  data,
  match,
  setMatch,
  advancedSearch,
}: PositionTypeRowProps): JSX.Element {
  const targetIndex = match.positionTypes.indexOf(data.id);

  const handlePositionClick = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setMatch((p: any) => ({
        ...p,
        positionTypes: p.positionTypes.concat(data.id),
      }));
    } else {
      setMatch((p: any) => ({
        ...p,
        positionTypes: p.positionTypes.filter((pt: any) => pt !== data.id),
      }));
    }
  }, [setMatch, match]);

  return (
    <tr>
      <td>
        <Form.Check
          checked={targetIndex !== -1}
          label={data.positionType}
          onChange={handlePositionClick}
          disabled={!advancedSearch}
        />
      </td>
    </tr>
  );
}

function MDPPositionTypeTable(): JSX.Element {
  const { t } = useTranslation();
  const [match, setMatch] = useRecoilState<MatchData>(matchState);
  const positionTypeList = useRecoilValue<IPositionTypeList[]>(allPositionTypeListState);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);

  useEffect(() => {
    setAdvancedSearch(currentRole.functions.indexOf(CLC_FUNCTION.advancedSearch) > -1);
  }, [currentRole]);

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Table responsive className="mb-0">
        <tbody>
          {positionTypeList.map((option: any) => (
            (advancedSearch || match.positionTypes.indexOf(option.id) > -1) && (
              <PositionTypeRow
                key={option.id}
                data={option}
                setMatch={setMatch}
                match={match}
                advancedSearch={advancedSearch}
              />
            )
          ))}
        </tbody>
      </Table>
    </Suspense>
  );
}

export default MDPPositionTypeTable;

import {
  useState,
  useEffect,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FaChurch, FaEdit } from 'react-icons/fa';
import { useForm, SubmitHandler } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { Role } from '../types/user.types';
import api from '../services/api.service';
import DisplayField from './generic/DisplayField';
import { CLC_FUNCTION, MINISTRY_ORG_TYPE } from '../types/constants';
import withUserAllowed from './layout/withUserAllowed';
import { tryParseInt } from '../utils';
import InterculturalCompTemplateModal from './InterculturalCompTemplateModal';
import {
  MinistryProfile,
  MinistryProfileResponseData,
  CongregationSizeData,
  CongregationSizeResponseData,
  CommunityTypeData,
  CommunityTypeResponseData,
  InterculturalCompData,
} from '../types/ministry.types';
import {
  currentRoleState,
  ministryProfileState,
} from '../services/state.service';

function MinistryProfileAccordionItem({ eventKey }: { eventKey: string }): JSX.Element {
  const { t } = useTranslation();
  const [congregationSize, setCongregationSize] = useState<CongregationSizeData[]>([]);
  const [communityType, setCommunityType] = useState<CommunityTypeData[]>([]);
  const [ministryProfile, setMinistryProfileState] = useRecoilState<MinistryProfile>(ministryProfileState);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [show, setShow] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showInterculturalComp, setShowInterculturalComp] = useState<boolean>(false);
  const [disableMinistryFields, setDisableMinistryFields] = useState<boolean>(false);
  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: {
      errors,
    },
  } = useForm<MinistryProfile>({
    defaultValues: ministryProfile,
  });
  const currentAverageWorshipAttendance = watch('averageWorshipAttendance');
  const currentChurchSchoolAttendance = watch('churchSchoolAttendance');
  const currentCurriculum = watch('curriculum');
  const submitRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    api.get(`/ministryprofile/${currentRole.organizationId}/`).then(
      (response: MinistryProfileResponseData) => {
        const ministryData = { ...response.data.options };
        if (ministryData.interculturalMinistry === true) {
          ministryData.interculturalMinistry = 'Yes';
        } else if (ministryData.interculturalMinistry === false) {
          ministryData.interculturalMinistry = 'No';
        } else {
          ministryData.interculturalMinistry = '';
        }
        setMinistryProfileState(ministryData);
        const disabled = ministryData.congregation.length === 0
          && (ministryData.presbytery.length > 0 || ministryData.synod.length > 0);
        setDisableMinistryFields(disabled);
        reset(ministryData);
      },
    );
  }, [currentRole, refresh]);

  useEffect(() => {
    api.get('/congregationsize/').then(
      (response: CongregationSizeResponseData) => (
        setCongregationSize(response.data.options)
      ),
    );
    api.get('/communitytypes/').then(
      (response: CommunityTypeResponseData) => (
        setCommunityType(response.data.options)
      ),
    );
  }, []);

  const saveInterculturalComp = (result: boolean, data: InterculturalCompData[]): void => {
    if (data && result) {
      api.put(`/interculturalcomp/${currentRole.organizationId}/`, data)
        .then(() => setRefresh((r: boolean) => !r));
    }
    setShowInterculturalComp(false);
  };

  function Edit(): JSX.Element {
    const handleClose = (): void => setShow(false);
    const handleShow = (): void => setShow(true);

    const onSubmit: SubmitHandler<MinistryProfile> = (data: MinistryProfile) => {
      const payload = { ...data };
      if (payload.interculturalMinistry === 'Yes') {
        payload.interculturalMinistry = true;
      } else if (payload.interculturalMinistry === 'No') {
        payload.interculturalMinistry = false;
      } else {
        payload.interculturalMinistry = undefined;
      }
      api.put(`/ministryprofile/${currentRole.organizationId}/`, payload)
        .then(() => setShow(false))
        .then(() => api.get(`/ministryprofile/${currentRole.organizationId}/`))
        .then((response: MinistryProfileResponseData) => (setMinistryProfileState(response.data.options)));
    };

    const handleCongregationSizeChange = (id: number | undefined, description: string): void => {
      setMinistryProfileState((p : any) => ({
        ...p,
        congregationDetails: {
          id,
          description,
        },
      }));
    };

    const handleCommunityTypeChange = (id: number | undefined, description: string): void => {
      setMinistryProfileState((p : any) => ({
        ...p,
        communityDetails: {
          id,
          description,
        },
      }));
    };

    const congregationSizeMenu = congregationSize?.map((option: CongregationSizeData) => (
      <option key={`congregation-size${option.id}`} value={option.id}>
        {option.description}
      </option>
    ));

    const communityTypeMenu = communityType?.map((option: CommunityTypeData) => (
      <option key={`community-type${option.id}`} value={option.id}>
        {option.description}
      </option>
    ));

    return (
      <>
        <Button
          onClick={handleShow}
          className="mb-3 ms-auto createbutton nowrap"
          variant="primary"
          size="sm"
          active
        >
          <FaEdit />
          {` ${t('Edit')}`}
        </Button>

        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t('Edit_Ministry_Profile')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="mx-auto col-8" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Ministry_Name')}
                </Form.Label>
                <Form.Control
                  {...register('ministryName', { required: true })}
                  isInvalid={!!errors.ministryName}
                  defaultValue={ministryProfile.ministryName}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('First_Address')}
                </Form.Label>
                <Form.Control
                  value={ministryProfile.addressOne}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Second_Address')}
                </Form.Label>
                <Form.Control
                  value={ministryProfile.addressTwo}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('City')}
                </Form.Label>
                <Form.Control
                  value={ministryProfile.city}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('State')}
                </Form.Label>
                <Form.Control
                  value={ministryProfile.state}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Zip_Code')}
                </Form.Label>
                <Form.Control
                  value={ministryProfile.zip}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Email')}
                </Form.Label>
                <Form.Control
                  {...register('email', { required: true })}
                  defaultValue={ministryProfile.email}
                  disabled={disableMinistryFields}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Preferred_Phone')}
                </Form.Label>
                <Form.Control
                  {...register('preferredPhone', { required: true })}
                  defaultValue={ministryProfile.preferredPhone}
                  disabled={disableMinistryFields}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Ministry_Alt_phn_email')}
                </Form.Label>
                <Form.Control
                  {...register('altPhoneEmail')}
                  defaultValue={ministryProfile.altPhoneEmail}
                  disabled={disableMinistryFields}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Fax')}
                </Form.Label>
                <Form.Control
                  {...register('fax')}
                  defaultValue={ministryProfile.fax}
                  disabled={disableMinistryFields}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3">
                  {t('Ministry_Web_address')}
                </Form.Label>
                <Form.Control
                  {...register('website', { required: true })}
                  defaultValue={ministryProfile.website}
                  disabled={disableMinistryFields}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader required mt-3">
                  {t('AreYouAnInterculturalMinistry')}
                </Form.Label>
                <Form.Select
                  {...register('interculturalMinistry', { required: true })}
                  isInvalid={!!errors.interculturalMinistry}
                >
                  <option key="" value="">
                    {`-- ${t('Select')} Option --`}
                  </option>
                  <option key="yes" value="Yes">
                    {t('Yes')}
                  </option>
                  <option key="no" value="No">
                    {t('No')}
                  </option>
                </Form.Select>
              </Form.Group>
              {/* Congregation Info */}
              {ministryProfile?.congregation && (
                <>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Congregation_Size')}
                    </Form.Label>
                    <Form.Select
                      value={ministryProfile.congregationDetails?.id || ''}
                      {...register('congregationSize', { required: true })}
                      onChange={(e) => handleCongregationSizeChange(
                        tryParseInt(e.target.value),
                        e.target.options[e.target.selectedIndex].text,
                      )}
                    >
                      <option key="" value="">
                        {t('Select_Congregation_Size')}
                      </option>
                      {congregationSizeMenu}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3">
                      {t('Worship_Attendance')}
                    </Form.Label>
                    <Form.Control
                      {...register('averageWorshipAttendance', { required: true })}
                      isInvalid={!!errors.averageWorshipAttendance}
                      defaultValue={currentAverageWorshipAttendance}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Church_School_Attendance')}
                    </Form.Label>
                    <Form.Control
                      {...register('churchSchoolAttendance', { required: true })}
                      isInvalid={!!errors.churchSchoolAttendance}
                      defaultValue={currentChurchSchoolAttendance}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3 required">
                      {t('Ministry_Curriculum')}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      maxLength={1000}
                      {...register('curriculum', { required: true })}
                      isInvalid={!!errors.curriculum}
                      defaultValue={currentCurriculum}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="bodyheader mt-3">
                      {t('PDP.Community')}
                    </Form.Label>
                    <Form.Select
                      value={ministryProfile.communityDetails?.id || ''}
                      {...register('communityType')}
                      onChange={(e) => handleCommunityTypeChange(
                        tryParseInt(e.target.value),
                        e.target.options[e.target.selectedIndex].text,
                      )}
                    >
                      <option key="" value="">
                        {t('Select_Community_Type')}
                      </option>
                      {communityTypeMenu}
                    </Form.Select>
                  </Form.Group>
                </>
              )}
              {ministryProfile?.congregation && ministryProfile?.congregation.length === 0 && ministryProfile.presbytery && (
                <Form.Group>
                  <Form.Check
                    className="mt-3"
                    type="checkbox"
                    id="no-matching-1"
                    label={t('No_Matching_Within_Presbytery')}
                    {...register('noMatchingWithinPresbytery')}
                  />
                </Form.Group>
              )}
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => submitRef.current?.click()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaChurch />
        <span className="accordion-header-label">{t('MinistryProfile')}</span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-sm-none d-flex justify-content-end">
          <div className="ml-auto">
            {Edit()}
          </div>
        </div>
        <div className="row">
          <InterculturalCompTemplateModal
            orgId={currentRole.organizationId}
            show={showInterculturalComp}
            saveInterculturalComp={saveInterculturalComp}
          />
          <div className="d-none d-sm-block col-sm-1 col-md-2">
            &nbsp;
          </div>
          <div className="col-sm-3 col-md-3">
            <DisplayField
              label={t('Ministry_Name')}
              text={ministryProfile.ministryName}
            />
          </div>
          <div className="col-sm-3 col-md-3">
            {ministryProfile.presbytery && (
              <DisplayField
                label={t('Presbytery')}
                text={ministryProfile.presbytery}
              />
            )}
          </div>
          <div className="col-sm-3 col-md-3">
            <DisplayField
              label={t('Ministry_Synod')}
              text={ministryProfile.synod}
            />
          </div>
          <div className="d-none d-sm-block col-sm-2 col-md-1">
            {
              (currentRole.functions.indexOf(CLC_FUNCTION.manageOrganization) > -1
              || currentRole.functions.indexOf(CLC_FUNCTION.manageCongregationProfile) > -1) && Edit()
            }
          </div>
        </div>
        <div className="row">
          <div className="d-none d-sm-block col-sm-1 col-md-2">
            &nbsp;
          </div>
          <div className="col-sm-3 col-md-3 overflow-wrap">
            <DisplayField
              label={t('Email')}
              text={ministryProfile.email}
            />
          </div>
          <div className="col-sm-3 col-md-3">
            <DisplayField
              label={t('Preferred_Phone')}
              text={ministryProfile.preferredPhone}
            />
          </div>
          <div className="col-sm-3 col-md-3 overflow-wrap">
            <DisplayField
              label={t('Ministry_Web_address')}
              text={ministryProfile.website}
            />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-sm-block col-sm-1 col-md-2">
            &nbsp;
          </div>
          <div className="col-sm-3 col-md-3">
            <DisplayField
              label={t('Ministry_Mailing_address')}
              text={`
              ${ministryProfile.addressOne ? ministryProfile.addressOne : ''}
              ${ministryProfile.addressTwo ? ministryProfile.addressTwo : ''}
              `}
            />
          </div>
          <div className="col-sm-3 col-md-3">
            <DisplayField
              label={t('Ministry_Alt_phn_email')}
              text={ministryProfile.altPhoneEmail}
            />
          </div>
          {ministryProfile.congregation && (
            <div className="col-sm-3 col-md-3">
              <DisplayField
                label={t('PDP.Community')}
                text={ministryProfile.communityDetails?.description || ''}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="d-none d-sm-block col-sm-1 col-md-2">
            &nbsp;
          </div>
          <div className="col-sm-3 col-md-3">
            <DisplayField
              label={t('InterculturalMinistry')}
              text={ministryProfile.interculturalMinistryDescription}
            />
          </div>
          {ministryProfile.congregation && (
            <>
              <div className="col-sm-3 col-md-3">
                <DisplayField
                  label={t('Ministry_Org_Size')}
                  text={ministryProfile.congregationDetails?.description || ''}
                />
              </div>
              <div className="col-sm-3 col-md-3">
                <DisplayField
                  label={t('Ministry_Curriculum')}
                  text={ministryProfile.curriculum}
                />
              </div>
            </>
          )}
        </div>
        <div className="row">
          {ministryProfile.congregation && (
            <>
              <div className="d-none d-sm-block col-sm-1 col-md-2">
                &nbsp;
              </div>
              <div className="col-sm-3 col-md-3">
                <Button
                  type="button"
                  className="text-start text-primary bg-white border-0 ps-0"
                  onClick={() => { setShowInterculturalComp(true); }}
                >
                  {t('Ministry_Intercultural_Comp')}
                  &nbsp;
                  <FaEdit />
                </Button>
                <div>
                  {ministryProfile.interculturalComp}
                </div>
              </div>
            </>
          )}
          {currentRole.organizationType === MINISTRY_ORG_TYPE.presbytery
          && currentRole.functions.indexOf(CLC_FUNCTION.presbyteryPermissions) > -1 && (
            <>
              <div className="d-none d-sm-block col-sm-1 col-md-2">
                &nbsp;
              </div>
              <div className="col-sm-3 col-md-3">
                <Link to="/presbytery-permissions/" replace>
                  {t('Manage_Presbytery_Permissions')}
                </Link>
              </div>
            </>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed([
  CLC_FUNCTION.manageOrganization,
  CLC_FUNCTION.manageCongregationProfile,
  CLC_FUNCTION.presbyteryPermissions,
].join(','))(MinistryProfileAccordionItem);

import OptionalLinks from '../components/OptionalLinks';
import Preferences from '../components/Preferences';
import References from '../components/References';
import Review from '../components/Review';
import ServiceToTheChurch from '../components/ServiceToTheChurch';
import WorkExperience from '../components/WorkExperience';
import Narrative from '../components/PDPNarratives';
import GeographicalOptions from '../components/GeographicalOptions';

export const PDPSteps = [
  {
    key: 'preferences',
    label: 'PDP.Preferences',
    isValid: false,
    StepComponent: Preferences,
  },
  {
    key: 'geographicaloptions',
    label: 'PDP.Geographical_Options',
    isValid: false,
    StepComponent: GeographicalOptions,
  },
  {
    key: 'workexperience',
    label: 'PDP.Work_Experience',
    isValid: false,
    StepComponent: WorkExperience,
  },
  {
    key: 'servicetothechurch',
    label: 'PDP.Service_to_the_Church',
    isValid: false,
    StepComponent: ServiceToTheChurch,
  },
  {
    key: 'narratives',
    label: 'PDP.Narratives',
    isValid: false,
    StepComponent: Narrative,
  },
  {
    key: 'optionallinks',
    label: 'PDP.Optional_Links',
    isValid: false,
    StepComponent: OptionalLinks,
  },
  {
    key: 'references',
    label: 'PDP.References',
    isValid: false,
    StepComponent: References,
  },
  {
    key: 'review',
    label: 'PDP.Review',
    isValid: false,
    StepComponent: Review,
  },
];

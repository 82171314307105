import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  Suspense,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Form, Table } from 'react-bootstrap';
import api from '../services/api.service';
import PDPService from '../services/pdp.service';
import {
  PDPData,
  IPositionType,
  IExperienceLevel,
} from '../types/pdp.types';
import {
  CallingInfoResponseData,
} from '../types/profile.types';
import {
  experienceLevelOptionsState,
  positionTypeListState,
  pdpState,
} from '../services/state.service';
import HelpPopup from './generic/HelpPopup';
import SanitizeHTML from '../services/html.service';
import SuspenseLoading from './generic/SuspenseLoading';

interface IData {
  id: number,
  positionType: string,
  definition: string,
}

type PositionTypeRowProps = {
  data: IData,
  pdp: PDPData,
  setPDP: Dispatch<SetStateAction<PDPData>>,
  experienceLevels: IExperienceLevel[],
}

function PositionTypeRow({
  data,
  pdp,
  setPDP,
  experienceLevels,
}: PositionTypeRowProps): JSX.Element {
  const targetIndex = pdp.positionTypes.findIndex((pt) => pt.positionType === data.id);
  const position = pdp.positionTypes[targetIndex];

  const handlePositionClick = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setPDP((p: any) => ({
        ...p,
        positionTypes: p.positionTypes.concat({
          pdp: pdp.id,
          positionType: data.id,
          experienceLevel: experienceLevels[0].id,
        }),
      }));
    } else {
      setPDP((p: any) => ({
        ...p,
        positionTypes: pdp.positionTypes.filter((pt: any) => pt.positionType !== position.positionType),
      }));
    }
  }, [setPDP, pdp, experienceLevels]);

  const handleExperienceChange = useCallback((e: ChangeEvent<HTMLSelectElement>): void => {
    setPDP((p: any) => ({
      ...p,
      positionTypes: pdp.positionTypes.map((pt: any) => (
        pt.positionType === position.positionType ? (
          { ...pt, experienceLevel: e.target.value }
        ) : (
          pt
        )
      )),
    }));
  }, [setPDP, pdp]);

  return (
    <tr>
      <td>
        <Form.Check
          defaultChecked={targetIndex !== -1}
          label={data.positionType}
          onChange={handlePositionClick}
        />
      </td>
      <td>
        {targetIndex !== -1 && (
          <Form.Select
            value={position?.experienceLevel}
            onChange={handleExperienceChange}
          >
            {(experienceLevels?.map((level: IExperienceLevel) => (
              <option key={level.id} value={level.id}>
                {level.displayName}
              </option>
            )))}
          </Form.Select>
        )}
      </td>
      <td>
        {data.definition && (
          <HelpPopup
            helpKey="tooltip-experience-level"
            placement="right"
            trigger="click"
            content={<div>{data.definition}</div>}
          />
        )}
      </td>
    </tr>
  );
}

function PreferencesTable(): JSX.Element {
  const { t } = useTranslation();
  const [pdp, setPDP] = useRecoilState<PDPData>(pdpState);
  const positionTypeList = useRecoilValue<IPositionType[]>(positionTypeListState);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const [filteredExpLevels, setFilteredExpLevels] = useState<IExperienceLevel[]>([]);

  useEffect(() => {
    const list = [...experienceLevels];
    list.splice(list.findIndex((p) => p.value === 'No Experience/First Ordained Call'), 1);
    setFilteredExpLevels(list);

    api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
      if (response.data[0].eccStatusValue === 'candidate') {
        setFilteredExpLevels(list.filter((p) => p.value === 'First Ordained Call'));
      }
    });
  }, [experienceLevels]);

  function renderPositionTypes(expLevels: IExperienceLevel[]): JSX.Element {
    return (
      <Suspense fallback={<SuspenseLoading />}>
        {positionTypeList.map((option: any) => (
          <PositionTypeRow
            key={option.id}
            data={option}
            setPDP={setPDP}
            pdp={pdp}
            experienceLevels={expLevels}
          />
        ))}
      </Suspense>
    );
  }

  return (
    <Table responsive className="mb-0">
      <thead>
        <tr>
          <th>
            {t('PDP.Position_Type_Table_Matching')}
          </th>
          <th>
            {t('PDP.Position_Type_Table_Experience')}
          </th>
          <th>
            <HelpPopup
              helpKey="tooltip-experience-level"
              placement="right"
              trigger="click"
              content={t('Experience_Level_Help')}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        { renderPositionTypes(filteredExpLevels) }
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2}>
            <SanitizeHTML html={t('Position_Type_Note')} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

export default PreferencesTable;

import { t } from 'i18next';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { Form } from 'react-bootstrap';

// A CLC react hook form component that takes a list of
// States to allow user to register a selected State item

type InputProps = {
  required?: boolean
  data?: any,
  stateSetter: Dispatch<SetStateAction<any>>,
  formLabel: string,
  inputName: string,
  options: any,
  register: any,
  error?: boolean,
} & DefaultProps
type DefaultProps = Partial<typeof defaultProps>

const defaultProps = {
  required: false,
  data: undefined,
  error: false,
};

function CLCFormStateSelect({
  required,
  data,
  stateSetter,
  formLabel,
  inputName,
  options,
  register,
  error,
}: InputProps): JSX.Element {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    stateSetter((x: any) => ({ ...x, [name]: value }));
  };

  const preSetDropDownMenu = options?.map((option: any) => (
    <option key={option.id} value={option.abbreviation}>
      {option.abbreviation}
    </option>
  ));

  return (
    <Form.Group>
      <Form.Label className={`${required ? 'required' : ''} bodyheader`}>
        {formLabel}
      </Form.Label>
      <Form.Select
        value={data[inputName] ? data[inputName] : ''}
        {...register(inputName, { required })}
        isInvalid={error}
        onChange={handleSelectChange}
      >
        <option key="" value="">
          {`-- ${t('Select')} ${formLabel} --`}
        </option>
        {preSetDropDownMenu}
      </Form.Select>
    </Form.Group>
  );
}

CLCFormStateSelect.defaultProps = defaultProps;

export default CLCFormStateSelect;

import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaBan } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

function CancelCallButton({ handler, label, tooltip = '' }: {
  handler: () => void,
  label: string,
  tooltip?: string,
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="left"
      overlay={(
        <Tooltip id="edit-button-tooltip">
          {tooltip || t('Cancel_Call_Description')}
        </Tooltip>
      )}
    >
      <Button variant="link" className="linkactions" onClick={handler}>
        <FaBan />
        {label && (<span>{label}</span>)}
      </Button>
    </OverlayTrigger>
  );
}

CancelCallButton.defaultProps = {
  tooltip: '',
};

export default CancelCallButton;

import {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CLCInput from './generic/CLCInput';
import { validUrl } from '../utils';

function OptionalLinkModal({ show, currentLink, callback }: {
  show: boolean,
  currentLink: any,
  callback: (_result: boolean, _link: any) => void,
}): JSX.Element {
  const { t } = useTranslation();
  const [link, setLink] = useState(currentLink);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    const requiredFields = ['linkTitle', 'linkUrl'];
    if (requiredFields.every((field) => link[field]) && validUrl.test(link.linkUrl)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [link]);

  useEffect(() => {
    setLink(currentLink);
  }, [currentLink]);

  return (
    <Modal
      show={show}
      onHide={() => callback(false, link)}
      animation={false}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {link.id > 0 ? t('PDP.Links_Edit_Link') : t('PDP.Links_Create_New')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <CLCInput
              data={link}
              formLabel={t('PDP.Links_Title')}
              stateSetter={setLink}
              inputName="linkTitle"
            />
            <CLCInput
              data={link}
              formLabel={t('PDP.Links_Description')}
              stateSetter={setLink}
              inputName="linkDescription"
              required={false}
            />
            <CLCInput
              data={link}
              formLabel={t('PDP.Links_URL')}
              stateSetter={setLink}
              inputName="linkUrl"
              helpText={t('PDP.Links_Help_Text')}
              patternMatch={validUrl}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => (callback(false, link))}>
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-3"
          onClick={() => (callback(true, link))}
          disabled={!isValid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OptionalLinkModal;

import { t } from 'i18next';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { Form } from 'react-bootstrap';

type InputProps = {
  required?: boolean,
  data: any,
  formLabel: string,
  stateSetter: Dispatch<SetStateAction<any>>,
  inputName: string,
  options: any,
} & DefaultProps
type DefaultProps = Partial<typeof defaultProps>

const defaultProps = { required: true };

function CLCSelect({
  required,
  data,
  formLabel,
  stateSetter,
  inputName,
  options,
}: InputProps): JSX.Element {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    stateSetter((x: any) => ({ ...x, [name]: value }));
  };

  return (
    <Form.Group
      className="mb-4"
      controlId="formEmploymentTypeSelect"
    >
      <Form.Label className={`${required ? 'required' : ''} bodyheader`}>
        {formLabel}
      </Form.Label>
      <Form.Select
        value={data[inputName] ? data[inputName] : ''}
        onChange={handleSelectChange}
        name={inputName}
      >
        <option key="" value="">
          {`-- ${t('Select')} ${formLabel} --`}
        </option>
        {(options?.map((option: any) => (
          <option key={(option.value ? option.value : option)} value={(option.value ? option.value : option)}>
            {(option.value ? option.value : option)}
          </option>
        )))}
      </Form.Select>
    </Form.Group>
  );
}

CLCSelect.defaultProps = defaultProps;

export default CLCSelect;

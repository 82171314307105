import {
  useState,
  useEffect,
} from 'react';
import { useRecoilValue } from 'recoil';
import {
  currentRoleState,
} from '../services/state.service';
import {
  Role,
} from '../types/user.types';
import api from '../services/api.service';

export interface ResourcesData {
  description: string,
  link: string,
  displayColumn: number,
  displayOrder: number,
}

export interface ResourcesResponseData {
  data: ResourcesData[],
}

function Resources(): JSX.Element {
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [resources, setResources] = useState<ResourcesData[]>([]);
  const resourcesDivs: any = [];
  const resourcesDivs2: any = [];
  const resourcesDivs3: any = [];

  useEffect(() => {
    if (currentRole.id) {
      api.get('/resource/').then(
        (response: ResourcesResponseData) => (setResources(response.data)),
      );
    }
  }, [currentRole.id]);

  resources
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .forEach((resource) => {
      const link = (
        <div key={`resource-${resource.link}-${resource.description}`} className="my-4 mx-3">
          <a
            href={resource.link}
            rel="noreferrer noopener"
            target="_blank"
          >
            {resource.description}
          </a>
        </div>
      );
      switch (resource.displayColumn) {
        case 1:
          resourcesDivs.push(link);
          break;
        case 2:
          resourcesDivs2.push(link);
          break;
        case 3:
          resourcesDivs3.push(link);
          break;
        default:
          break;
      }
    });

  return (
    <div className="mb-3 mx-auto mobiletable col-lg-12">
      <hr className="mdpdivider" />
      <div>
        <div className="row">
          <div className="col-4">
            {resourcesDivs}
          </div>
          <div className="col-4">
            {resourcesDivs2}
          </div>
          <div className="col-4">
            {resourcesDivs3}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;

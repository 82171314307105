import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { InputTags } from 'react-bootstrap-tagsinput';
import 'react-bootstrap-tagsinput/dist/index.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from '../../services/api.service';
import {
  SkillData,
  SkillDefaultData,
  SkillResponseData,
  ProficiencyData,
  ProficiencyResponseData,
} from '../../types/pdp.types';
import SkillsModal from './SkillsModal';
import { FormStepProps } from '../../types/generic.types';
import HelpPopup from '../generic/HelpPopup';
import SanitizeHTML from '../../services/html.service';

function SkillsTemplate({ formSubmitted }: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [skillData, setSkillData] = useState<SkillData>(SkillDefaultData);
  const [showNewEditLanguage, setShowNewEditLanguage] = useState(false);
  const [showNewEditTechnical, setShowNewEditTechnical] = useState(false);
  const [refreshSkillData] = useState(false);
  const [languageTags, setLanguageTags] = useState<string[]>([]);
  const [technicalTags, setTechnicalTags] = useState<string[]>([]);
  const [technicalProficiencyList, setTechnicalProficiencyList] = useState<ProficiencyData[]>([]);
  const [languageProficiencyList, setLanguageProficiencyList] = useState<ProficiencyData[]>([]);
  interface EmptyForm {result: boolean}
  const { handleSubmit } = useForm<EmptyForm>();

  useEffect(() => {
    api.get('profile/skill/').then(
      (response: SkillResponseData) => {
        if (response.data.length > 0) {
          setSkillData(response.data[0]);
          if (response.data[0].language) {
            setLanguageTags(response.data[0].language.split(','));
          }
          if (response.data[0].technical) {
            setTechnicalTags(response.data[0].technical.split(','));
          }
        }
      },
    );
    api.get('langproficiency/').then(
      (response: ProficiencyResponseData) => {
        setLanguageProficiencyList(response.data.options);
      },
    );
    api.get('techproficiency/').then(
      (response: ProficiencyResponseData) => {
        setTechnicalProficiencyList(response.data.options);
      },
    );
  }, [refreshSkillData]);

  const closeNewEditLanguage = useCallback((result: boolean, tag: string[]): void => {
    if (result && tag) {
      setShowNewEditLanguage(false);
      setLanguageTags((prevTag) => [...prevTag, tag[0]]);
    } else {
      setShowNewEditLanguage(false);
    }
  }, []);

  const closeNewEditTechnical = useCallback((result: boolean, tag: string[]): void => {
    if (result && tag) {
      setShowNewEditTechnical(false);
      setTechnicalTags((prevTag) => [...prevTag, tag[0]]);
    } else {
      setShowNewEditTechnical(false);
    }
  }, []);

  const onSubmit: SubmitHandler<EmptyForm> = () => {
    if (skillData.id > 0) {
      api.put(`/profile/skill/${skillData.id}/`, skillData).then(() => {
        formSubmitted();
      });
    } else {
      api.post('/profile/skill/', skillData).then(() => {
        formSubmitted();
      });
    }
  };

  return (
    <div className="mt-3">
      <SkillsModal
        show={showNewEditLanguage}
        proficiencies={languageProficiencyList}
        title="Language_Modal_Title"
        inputLabel="Add_Language_Label"
        callback={closeNewEditLanguage}
      />
      <SkillsModal
        show={showNewEditTechnical}
        proficiencies={technicalProficiencyList}
        title="Skill_Modal_Title"
        inputLabel="Add_Technical_Skill_Label"
        callback={closeNewEditTechnical}
      />
      <div className="title text-center">{t('Skills_Title')}</div>
      <div className="col-12 mb-3 text-center">
        {t('Skills_Description')}
      </div>
      <br />
      <Form id="skills-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 offset-md-3 col-md-6 mb-2 mt-5">
          <div className="row">
            <div className="col-5">
              {t('Languages')}
              <HelpPopup
                helpKey="tooltip-language-skill"
                placement="left"
                trigger="click"
                content={<SanitizeHTML html={t('Language_Skill_Help')} />}
              />
            </div>
            <div className="col-7 text-end">
              <Button
                onClick={() => {
                  setShowNewEditLanguage(true);
                }}
                className="ms-auto createbutton"
                variant="primary"
                size="sm"
                active
              >
                {`+ ${t('Add_Language_Btn')}`}
              </Button>
            </div>
          </div>
          <br />
          <div className="row">
            {languageTags.length > 0 && (
              <div className="input-group tags-readonly">
                <InputTags
                  values={languageTags}
                  onTags={(value) => {
                    setLanguageTags(value.values);
                    setSkillData((p) => ({ ...p, language: value.values.toString() }));
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <br />
        <br />
        <div className="col-12 offset-md-3 col-md-6 mb-2">
          <hr className="mdpdivider my-3" />
        </div>
        <br />
        <br />
        <div className="col-12 offset-md-3 col-md-6 mb-2">
          <div className="row">
            <div className="col-5">
              {t('Technical_Skills')}
              <HelpPopup
                helpKey="tooltip-technical-skill"
                placement="left"
                trigger="click"
                content={<SanitizeHTML html={t('Technical_Skill_Help')} />}
              />
            </div>
            <div className="col-7 text-end">
              <Button
                onClick={() => {
                  setShowNewEditTechnical(true);
                }}
                className="ms-auto createbutton"
                variant="primary"
                size="sm"
                active
              >
                {`+ ${t('Add_Technical_Skill_Btn')}`}
              </Button>
            </div>
          </div>
          <br />
          <div className="row">
            {technicalTags.length > 0 && (
              <div className="input-group tags-readonly">
                <InputTags
                  values={technicalTags}
                  onTags={(value) => {
                    setTechnicalTags(value.values);
                    setSkillData((p) => ({ ...p, technical: value.values.toString() }));
                  }}
                />
              </div>
            )}
          </div>
          <br />
        </div>
      </Form>
      <br />
    </div>
  );
}

export default SkillsTemplate;

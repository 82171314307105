import {
  useEffect,
} from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  FormStepProps,
  SelectOption,
} from '../../types/generic.types';
import {
  SelectOptionsData,
} from '../../types/selectoptions.types';
import api from '../../services/api.service';
import {
  CallingInfoData,
  CallingInfoResponseData,
} from '../../types/profile.types';
import {
  PresbyteryData,
  CongregationData,
} from '../../types/pdp.types';
import {
  eccStatusOptionsState,
  presbyteryOptionsState,
  congregationOptionsState,
  statesListOptionsState,
} from '../../services/state.service';
import SanitizeHTML from '../../services/html.service';
import { ECCSTATUS_CHOICES } from '../../types/constants';

function CallingInfo({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const eccStatusOptions = useRecoilValue<SelectOptionsData[]>(eccStatusOptionsState);
  const presbyteryOptions = useRecoilValue<PresbyteryData[]>(presbyteryOptionsState);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);
  const congregationOptions = useRecoilValue<CongregationData[]>(congregationOptionsState);
  const {
    control,
    setValue,
    setError,
    reset,
    watch,
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<CallingInfoData>();
  const currentEccStatusValue = watch('eccStatusValue');
  const currentCongregation = watch('congregation');
  const currentSeeking = watch('seeking');
  const currentGeoLocation = watch('geoLocation');
  const currentSearchByState = watch('searchByState');
  const currentSearchByPresbytery = watch('searchByPresbytery');

  useEffect(() => {
    api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
      const data = response.data[0];
      if (data.eccStatusValue === null) data.eccStatusValue = '';
      if (data.authorize) {
        data.authorize = 'Yes';
      } else {
        data.authorize = 'No';
      }
      if (data.seeking) {
        data.seeking = 'Yes';
      } else {
        data.seeking = 'No';
      }
      reset(data);
    });
  }, []);

  useEffect(() => {
    if (currentEccStatusValue) {
      switch (currentEccStatusValue) {
        case 'candidate': // Candidate
          setValue('congregation', undefined);
          setValue('ordinationDate', undefined);
          break;
        case 'minister': // Minister
        case 'retired minister': // Retired-PCUSA Minister
          setValue('congregation', undefined);
          setValue('candidacyDate', undefined);
          break;
        case 'undercare': // Non-PCUSA Minister
          setValue('congregation', undefined);
          setValue('ordinationDate', undefined);
          setValue('candidacyDate', undefined);
          break;
        case 'member': // PCUSA Member
        case 'cce':// Christian Educator (Certified)
        case 'ruling elder': // Ruling Elder
          setValue('ordinationDate', undefined);
          setValue('candidacyDate', undefined);
          break;
        default:
          break;
      }
    }
  }, [currentEccStatusValue]);

  useEffect(() => {
    if (currentGeoLocation === 'Search By State') {
      setValue('searchByPresbytery', []);
    }

    if (currentGeoLocation === 'Search By Presbytery') {
      setValue('searchByState', []);
    }
  }, [currentGeoLocation]);

  useEffect(() => {
    if (currentSeeking === 'No') {
      setValue('geoLocation', 'NA');
      setValue('searchByState', []);
      setValue('searchByPresbytery', []);
    }
  }, [currentSeeking]);

  function generatePayload(data: CallingInfoData): CallingInfoData {
    const {
      seeking,
      authorize,
      eccStatus,
      eccStatusValue,
      geoLocation,
      searchByPresbytery,
      searchByState,
      ...payload
    } = data;
    return {
      ...payload,
      eccStatus: eccStatus && eccStatus > -1 ? eccStatus : undefined,
      eccStatusValue: eccStatusValue && eccStatusValue !== '' ? eccStatusValue : undefined,
      seeking,
      authorize,
      geoLocation: geoLocation && geoLocation !== '' ? geoLocation : undefined,
      searchByPresbytery: searchByPresbytery && searchByPresbytery.length > 0 ? searchByPresbytery : [],
      searchByState: searchByState && searchByState.length > 0 ? searchByState : [],
    };
  }

  function getEccStatusId(eccStatusValue: string): number {
    if (eccStatusValue) {
      return eccStatusOptions.filter((p) => p.status === eccStatusValue)[0].id;
    }
    return -1;
  }

  const onSubmit: SubmitHandler<CallingInfoData> = (data: CallingInfoData) => {
    const payload = generatePayload(data);
    api.put(`/profile/callinginfo/${payload.id}/`, payload)
      .then(formSubmitted)
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'id' | 'eccStatusValue' | 'presbytery' | 'congregation' | 'candidacyDate' |
          'ordinationDate' | 'seeking' | 'authorize',
          { type: 'server', message: messages[name][0] },
        ));
      });
  };

  const statesCheckboxes = statesListOptions.map((option: any) => (
    <Form.Check
      type="checkbox"
      checked={currentSearchByState?.includes(option.id)}
      id={`states-option-${option.id}`}
      key={`states-option-${option.id}`}
      label={option.name}
      className="genericbutton ms-3 mt-2"
      onChange={() => (
        currentSearchByState?.includes(option.id)
          ? setValue('searchByState', currentSearchByState.filter((item: any) => item !== option.id))
          : setValue('searchByState', [...currentSearchByState, option.id])
      )}
    />
  ));

  const presbyteryCheckboxes = presbyteryOptions.map((option: any) => (
    <Form.Check
      type="checkbox"
      checked={currentSearchByPresbytery?.includes(option.number)}
      id={`presbytery-option-${option.number}`}
      key={`presbytery-option-${option.number}`}
      label={option.name}
      className="genericbutton ms-3 mt-2"
      onChange={() => (
        currentSearchByPresbytery?.includes(option.number)
          ? setValue('searchByPresbytery', currentSearchByPresbytery.filter((item: any) => item !== option.number))
          : setValue('searchByPresbytery', [...currentSearchByPresbytery, option.number])
      )}
    />
  ));

  return (
    <div className="container-fluid mt-3">
      <div className="col-12 mb-5 text-center">
        <h1>
          {t('CallingInfoHeader')}
        </h1>
        <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
      </div>
      <Form id="callinginfo-form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id')} />
        <div className="row">
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-2"
            controlId="formEccStatus"
          >
            <Form.Label className="required">
              {t('EcclesiasticalStatus')}
            </Form.Label>
            <Form.Select
              isInvalid={!!errors.eccStatus}
              {...register('eccStatusValue', { required: true })}
              onChange={(e) => {
                setValue('eccStatusValue', e.target.value);
                setValue('eccStatus', getEccStatusId(e.target.value));
              }}
            >
              <option key="-1" value="">{t('SelectEccStatus')}</option>
              {(eccStatusOptions?.map((option: any) => (
                <option key={`eccStatus-${option.id}`} value={option.status}>
                  {option.description}
                </option>
              )))}
            </Form.Select>
            <div className="col-12 non-field-error">
              {errors.eccStatus && t('EccStatusRequired')}
            </div>
          </Form.Group>
          {currentEccStatusValue && currentEccStatusValue !== '' && (
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-2"
              controlId="formPresbytery"
            >
              <Form.Label className="required">
                {t('PresbyteryMembership')}
              </Form.Label>
              <Form.Select
                isInvalid={!!errors.presbytery}
                {...register('presbytery', { required: true })}
                onChange={(e) => { setValue('presbytery', e.target.value); }}
              >
                <option key="" value="">{t('SelectPresbytery')}</option>
                {(presbyteryOptions?.map((option: any) => (
                  <option key={`presbyteries-${option.number}`} value={option.number}>
                    {option.name}
                  </option>
                )))}
              </Form.Select>
              <div className="col-12 non-field-error">
                {errors.presbytery && t('PresbyteryRequired')}
              </div>
            </Form.Group>
          )}
          {currentEccStatusValue
          && currentEccStatusValue === ECCSTATUS_CHOICES.candidate && (
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-2"
              controlId="formCandidacyDate"
            >
              <Form.Label className="required">
                {t('CandidacyDate')}
              </Form.Label>
              <Form.Control
                type="date"
                isInvalid={!!errors.candidacyDate}
                {...register('candidacyDate', { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.candidacyDate && errors.candidacyDate.message
                  ? errors.candidacyDate.message
                  : t('CandidacyDateRequired')}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {currentEccStatusValue && (
            (currentEccStatusValue === ECCSTATUS_CHOICES.minister)
            || (currentEccStatusValue === ECCSTATUS_CHOICES.retiredMinister)) && (
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-3"
              controlId="formOrdinationDate"
            >
              <Form.Label className="required">
                {t('OrdinationDate')}
              </Form.Label>
              <Form.Control
                type="date"
                isInvalid={!!errors.ordinationDate}
                {...register('ordinationDate', { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ordinationDate && errors.ordinationDate.message
                  ? errors.ordinationDate.message
                  : t('OrdinationDateRequired')}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {currentEccStatusValue
            && ((currentEccStatusValue === ECCSTATUS_CHOICES.member)
            || (currentEccStatusValue === ECCSTATUS_CHOICES.cce)
            || (currentEccStatusValue === ECCSTATUS_CHOICES.rulingelder)
            ) && (
            <Form.Group
              className="col-12 offset-md-3 col-md-6 mb-2"
              controlId="formCongregation"
            >
              <Form.Label className="required">
                {t('Congregation')}
              </Form.Label>
              <Controller
                name="congregation"
                control={control}
                render={() => {
                  let selectedCongregation: CongregationData[] = [];
                  const found = congregationOptions.find((c) => c.pin === currentCongregation);
                  if (found) selectedCongregation = [found];
                  return (
                    <Typeahead
                      id="congregation"
                      placeholder={t('Congregation')}
                      options={congregationOptions}
                      labelKey={(option: CongregationData) => `${option?.name} (${option?.city}, ${option?.state})`}
                      selected={selectedCongregation}
                      {...register('congregation', { required: true })}
                      onChange={(e) => setValue('congregation', e.length > 0 ? e[0].pin : undefined)}
                    />
                  );
                }}
                rules={{ required: true }}
              />
              <div className="col-12 non-field-error">
                {errors.congregation && t('CongregationRequired')}
              </div>
            </Form.Group>
          )}
          <Form.Group
            className="mb-4 col-12 offset-md-3 col-md-6 mb-3"
            controlId="formSeeking"
          >
            <Form.Label className="bodyheader required">
              {t('Available_To_Match')}
            </Form.Label>
            <Form.Check
              type="radio"
              id="actively-seeking-1"
              label={t('Actively_Yes')}
              className="preferencesradio"
              {...register('seeking', { required: true })}
              value="Yes"
            />
            {currentSeeking === 'Yes' && (
              <div>
                <Form.Group
                  className="mt-3 mb-3 offset-1 col-11"
                >
                  <Form.Label
                    className="col-12 mb-2 bodyheader required"
                  >
                    {t('Geographic_Choices_Instructions')}
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    label={t('Search_By_State')}
                    value="Search By State"
                    {...register('geoLocation', { required: true })}
                  />
                  {currentGeoLocation === 'Search By State' && (
                    <div
                      className="offset-md-1 mt-2 mb-3 border rounded-3 state-checkbox-scroll"
                    >
                      {statesCheckboxes}
                    </div>
                  )}
                  <Form.Check
                    type="radio"
                    label={t('Search_By_Presbytery')}
                    value="Search By Presbytery"
                    {...register('geoLocation', { required: true })}
                  />
                  {currentGeoLocation === 'Search By Presbytery' && (
                    <div
                      className="offset-md-1 mt-2 border rounded-3 state-checkbox-scroll"
                    >
                      {presbyteryCheckboxes}
                    </div>
                  )}
                </Form.Group>
              </div>
            )}
            <Form.Check
              type="radio"
              id="actively-seeking-2"
              label={t('Actively_No')}
              className="preferencesradio"
              {...register('seeking', { required: true })}
              value="No"
            />
          </Form.Group>
          <Form.Group
            className="mb-4 col-12 offset-md-3 col-md-6 mb-3"
            controlId="formAuthorize"
          >
            <Form.Label className="bodyheader required">
              {t('Authorize_CLC')}
            </Form.Label>
            <Form.Check
              type="radio"
              id="authorize-1"
              label={t('Yes_Authorize')}
              className="preferencesradio"
              {...register('authorize', { required: true })}
              value="Yes"
            />
            <Form.Check
              type="radio"
              id="authorize-2"
              label={t('No_Authorize')}
              className="preferencesradio"
              {...register('authorize', { required: true })}
              value="No"
            />
          </Form.Group>
        </div>
      </Form>
    </div>
  );
}

export default CallingInfo;

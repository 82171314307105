import {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { FormStepProps } from '../../types/generic.types';
import api from '../../services/api.service';
import { ContactData, ContactResponseData } from '../../types/profile.types';
import { STATES } from '../../types/constants';

function Contact({
  formSubmitted,
}: FormStepProps): JSX.Element {
  const { t } = useTranslation();
  const [contact, setContact] = useState<ContactData>();
  const {
    setError,
    reset,
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<ContactData>();

  const onSubmit: SubmitHandler<ContactData> = (data: ContactData) => (
    api.put(`/profile/contact/${data.id}/`, data)
      .then(() => {
        formSubmitted();
      })
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'id' | 'phoneOne' | 'phoneTwo',
          { type: 'server', message: messages[name][0] },
        ));
      })
  );

  const statesMenu = STATES?.map((option: string) => (
    <option key={option} value={option}>
      {option}
    </option>
  ));

  useEffect(() => {
    // reset form with user data
    if (contact) reset(contact);
  }, [contact]);

  useEffect(() => {
    api.get('/profile/contact/').then((response: ContactResponseData) => {
      setContact(response.data[0]);
    });
  }, []);

  return (
    <div className="container-fluid mt-3">
      <div className="col-12 mb-5 text-center">
        <h1>
          {t('ContactHeader')}
        </h1>
        <h2>
          {t('ContactDescription')}
        </h2>
      </div>
      <Form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id')} />
        <div className="row">
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('First_Address')}
            </Form.Label>
            <Form.Control
              {...register('addressOne', { required: true })}
              isInvalid={!!errors.addressOne}
            />
            <Form.Control.Feedback type="invalid">
              {errors.addressOne && errors.addressOne.message
                ? errors.addressOne.message
                : t('AddressOneRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3">
              {t('Second_Address')}
            </Form.Label>
            <Form.Control
              {...register('addressTwo')}
            />
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('City')}
            </Form.Label>
            <Form.Control
              {...register('city', { required: true })}
              isInvalid={!!errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {errors.city && errors.city.message
                ? errors.city.message
                : t('CityRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('State')}
            </Form.Label>
            <Form.Select
              {...register('state', { required: true })}
            >
              <option key="" value="">
                {t('Select_State')}
              </option>
              {statesMenu}
            </Form.Select>
            <div className="col-12 non-field-error">
              {errors.state && t('StateRequired')}
            </div>
          </Form.Group>
          <Form.Group className="col-12 offset-md-3 col-md-6 mb-3">
            <Form.Label className="bodyheader mt-3 required">
              {t('Zip_Code')}
            </Form.Label>
            <Form.Control
              {...register('zip', { required: true })}
              isInvalid={!!errors.zip}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zip && errors.zip.message
                ? errors.zip.message
                : t('ZipRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formPhone"
          >
            <Form.Label className="required">
              {t('Phone')}
            </Form.Label>
            <Form.Control
              type="input"
              isInvalid={!!errors.phoneOne}
              {...register('phoneOne', { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneOne && errors.phoneOne.message
                ? errors.phoneOne.message
                : t('PhoneRequired')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formPhone2"
          >
            <Form.Label>
              {t('Phone2')}
            </Form.Label>
            <Form.Control
              type="input"
              isInvalid={!!errors.phoneTwo}
              {...register('phoneTwo')}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneTwo && errors.phoneTwo.message
                ? errors.phoneTwo.message
                : t('Phone2Required')}
            </Form.Control.Feedback>
          </Form.Group>

        </div>
      </Form>
    </div>
  );
}

export default Contact;

import {
  useState,
  useEffect,
  ChangeEvent,
  Suspense,
  useRef,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
} from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import api from '../services/api.service';
import { ReferData } from '../types/mdp.types';

type ReferModalProps = {
  mdp: string,
  callback: (_result: boolean, referral: ReferData) => void,
  show: boolean,
  hide: () => void,
}

function ReferModal({
  mdp,
  callback,
  show,
  hide,
}: ReferModalProps): JSX.Element {
  const { t } = useTranslation();
  const [valid, setValid] = useState<boolean>(false);
  const [lookup, setLookup] = useState<string>('');
  const {
    handleSubmit,
    register,
    watch,
    reset,
  } = useForm<ReferData>();
  const pdpId = watch('pdp_id');

  const submitRef = useRef<HTMLButtonElement>(null);

  const onSubmit: SubmitHandler<ReferData> = () => {
    if (pdpId) {
      callback(true, { pdp_id: pdpId.toString(), mdp_id: mdp });
    }
  };

  useEffect(() => {
    setLookup('');
    setValid(false);
  }, [pdpId]);

  useEffect(() => {
    if (show) {
      setLookup('');
      setValid(false);
      reset();
    }
  }, [show]);

  function lookupPDP(): void {
    if (pdpId) {
      api.get(`/lookup/${pdpId}/`)
        .then(
          (response: any) => {
            if (response.data) {
              setLookup(response.data);
              setValid(true);
            } else {
              setLookup(t('PDP_Not_Found'));
              setValid(false);
            }
          },
        )
        .catch(() => {
          setLookup(t('PDP_Not_Found'));
          setValid(false);
        });
    } else {
      setLookup('');
      setValid(false);
    }
  }

  return (
    <Modal size="lg" show={show} onHide={hide}>
      <Modal.Header>
        <Modal.Title>{t('Refer_PDP')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 mx-auto">
          <div className="mx-auto">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register('mdp_id')} />
              <div className="row">
                <div className="col-12">
                  <Form.Label className="bodyheader required">
                    {t('Enter_PDPID_To_Refer')}
                  </Form.Label>
                </div>
                <div className="col-8">
                  <Form.Control
                    {...register('pdp_id')}
                    type="number"
                  />
                </div>
                <div className="col-4 p-0">
                  <Button variant="link" className="d-block p-0 pt-1" onClick={lookupPDP}>
                    <FaSearch />
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 pt-2 ps-4">
                  {lookup}
                </div>
              </div>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => (callback(false, { pdp_id: '', mdp_id: '' }))}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={() => submitRef.current?.click()}
          disabled={!valid}
        >
          {t('Submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReferModal;

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import {
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import { StatementModalData } from '../types/pdp.types';

type StatementOfFaithTemplateModalProps = {
  statement: StatementModalData,
  show: boolean,
  saveStatement: (_result: boolean, _statement: StatementModalData) => void,
}

function StatementOfFaithTemplateModal({
  statement,
  show,
  saveStatement,
}: StatementOfFaithTemplateModalProps): JSX.Element {
  const { t } = useTranslation();
  const [newStatement, setNewStatement] = useState<StatementModalData>(statement);
  const [confirmError, setConfirmError] = useState<string>('');
  const [validCharCount, setValidCharCount] = useState(true);
  const editorRef = useRef<any>(null);
  const log = (): any => {
    if (editorRef.current !== null) {
      setNewStatement((p: any) => ({
        ...p, text: editorRef.current.getContent(), id: statement.id,
      }));
    }
  };

  function countCharLimit(): void {
    const content = editorRef.current.getContent().replace(/<(.|\n)*?>/g, '').replace(/&nbsp;/g, '');
    if (content.length < 3500) {
      setConfirmError('');
      saveStatement(true, newStatement);
    } else {
      setValidCharCount(false);
      setConfirmError(t('Character_Limit_Exceeded'));
    }
  }

  return (
    <Modal fullscreen show={show} onHide={() => saveStatement(false, statement)}>
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div className="col-12 mb-3 text-center non-field-error">
          {!validCharCount && (confirmError)}
        </div>
        <div className="title">
          {t('Modal_Statement_Of_Faith_Title')}
        </div>
        <div className="col-lg-8 col-12 mx-auto">
          {t('Modal_Statement_Of_Faith_Description')}
        </div>
        <div className="col-lg-8 col-12 mx-auto">
          <Editor
            onInit={(evt: any, editor: any): any => { editorRef.current = editor; }}
            initialValue={statement.text}
            init={{
              height: 300,
              menubar: false,
              statusbar: false,
              branding: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code',
              ],
              toolbar: 'fontselect | formatselect | '
                + 'alignleft aligncenter alignright alignjustify |'
                + 'bold italic strikethrough underline | forecolor backcolor link bullist numlist',
              contextmenu: false,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              init_instance_callback(editor: any) {
                editor.on('blur', () => log());
              },
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex profilemodalfooter border-top-0">
        <Button variant="secondary" onClick={() => { setValidCharCount(true); saveStatement(false, statement); }}>
          {t('PDP.Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => countCharLimit()}
        >
          {t('Panel_Modal_Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default StatementOfFaithTemplateModal;

import Modal from 'react-bootstrap/Modal';
import {
  useState,
  useEffect,
  ChangeEvent,
  Suspense,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Form, Table } from 'react-bootstrap';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { Typeahead } from 'react-bootstrap-typeahead';
import DeleteButton from './generic/DeleteButton';
import ConfirmModal from './generic/ConfirmModal';
import MDPService, {
  RequirementsData,
  RequirementsResponseData,
  IExperienceLevel,
  IPositionTypeList,
  LanguageData,
  LanguageResponseData,
} from '../services/mdp.service';
import {
  MDPData,
  MDPResponseData,
  CertificateData,
  CertificateResponseData,
} from '../types/mdp.types';
import {
  EmploymentTypeData,
  CongregationData,
  CongregationResponseData,
  CongregationDefaultData,
} from '../types/pdp.types';
import api from '../services/api.service';
import SanitizeHTML from '../services/html.service';
import {
  mdpStepState,
  mdpState,
  employmentTypeOptionsState,
  experienceLevelOptionsState,
  congregationOptionsState,
  allPositionTypeListState,
  resubmissionState,
} from '../services/state.service';
import { useStepValid } from '../hooks/useStepValid';
import SuspenseLoading from './generic/SuspenseLoading';
import { MINISTRY_ORG_TYPE } from '../types/constants';

function PositionRequirements(): JSX.Element {
  const initialRequirements = {
    id: undefined,
    mdp: undefined,
    employmentType: '',
    employmentTypeDescription: '',
    positionType: '',
    positionTypeDescription: '',
    statementRequired: false,
    filingCouple: false,
    noMatchingWithinPresbytery: false,
    experienceLevel: '',
    experienceLevelDescription: '',
    title: '',
    deadline: undefined,
    certificate: [],
    othercert: '',
    initial: true,
    language: [],
    otherlang: '',
  };
  const { t } = useTranslation();
  const [mdp, setMDP] = useRecoilState<MDPData>(mdpState);
  const [requirements, setRequirements] = useState<RequirementsData>(initialRequirements);
  const employmentTypes = useRecoilValue<EmploymentTypeData[]>(employmentTypeOptionsState);
  const positionTypeList = useRecoilValue<IPositionTypeList[]>(allPositionTypeListState);
  const [languageOptions, setLanguageOptions] = useState<LanguageData[]>([]);
  const [certificateOptions, setCertificateOptions] = useState<CertificateData[]>([]);
  const [newPositionRequirements, setNewPositionRequirements] = useState<boolean>(true);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const [filteredExpLevels, setFilteredExpLevels] = useState<IExperienceLevel[]>();
  const congregationOptions = useRecoilValue<CongregationData[]>(congregationOptionsState);
  const [yokedMinistryList, setYokedMinistryList] = useState<CongregationData[]>([]);
  const [yokedMinistry, setYokedMinistry] = useState<CongregationData>(CongregationDefaultData);
  const [currentYokedMinistry, setCurrentYokedMinistry] = useState<CongregationData>(yokedMinistry);
  const [show, setShow] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentPosition, setCurrentPosition] = useState('');
  const [currentEmploymentType, setCurrentEmploymentType] = useState('');
  const setResubmission = useSetRecoilState(resubmissionState);
  const requiredFields = useMemo(() => (
    [
      'employmentType',
      'positionType',
      'experienceLevel',
      'language',
      'filingCouple',
      'statementRequired',
    ]
  ), [requirements]);
  const valid = useStepValid('positionRequirements', requirements, requiredFields, mdpStepState);

  useEffect(() => {
    MDPService.GetMDPData(mdp.id).then(
      (response: MDPResponseData) => { setMDP(response.data); },
    );

    MDPService.GetYokedMinistries(mdp.id).then(
      (response: CongregationResponseData) => { setYokedMinistryList(response.data.congregations); },
    );

    api.get('/languages/').then(
      (response: LanguageResponseData) => (setLanguageOptions(response.data.options)),
    );

    api.get('/certificates/').then(
      (response: CertificateResponseData) => (setCertificateOptions(response.data.options)),
    );
  }, [setRequirements, mdp.id, setMDP, refresh]);

  useEffect(() => {
    if (valid) {
      if (newPositionRequirements) {
        api.post(`/mdp/${mdp.id}/requirements/`, { ...requirements, mdp: mdp.id })
          .then(() => {
            setNewPositionRequirements(false);
          });
      }

      if ((currentPosition !== requirements.positionType.toString()
      || currentEmploymentType !== requirements.employmentType.toString())
      && mdp.submitted && mdp.organizationType === MINISTRY_ORG_TYPE.congregation) {
        setShowAlert(true);
      } else {
        api.put(`/mdp/${mdp.id}/requirements/${requirements.id}/`, { ...requirements, mdp: mdp.id });
      }
    }
  }, [valid, requirements]);

  useEffect(() => {
    const list = [...experienceLevels];
    list.splice(list.findIndex((p) => p.value === 'First Ordained Call'), 1);
    list.splice(list.findIndex((p) => p.value === 'No Experience'), 1);
    setFilteredExpLevels(list);
  }, [experienceLevels]);

  useEffect(() => {
    api.get(`/mdp/${mdp.id}/requirements/`).then(
      (response: RequirementsResponseData) => {
        if (response.data.length > 0) {
          setRequirements(response.data[0]);
          if (response.data[0].positionType) {
            setCurrentPosition(response.data[0].positionType.toString());
          }
          if (response.data[0].employmentType) {
            setCurrentEmploymentType(response.data[0].employmentType.toString());
          }
          setNewPositionRequirements(false);
        }
      },
    );
  }, [newPositionRequirements, mdp.id]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    setRequirements({ ...requirements, [name]: value, initial: false });
  };

  const languageCheckboxes = languageOptions.map((option: any, index: any) => (
    <Form.Check
      checked={requirements.language.includes(option.id)}
      type="checkbox"
      id={`language-option-${option.id}`}
      key={`language-option-${option.id}`}
      label={languageOptions[index].description}
      name="mdpclc"
      className="genericbutton ms-3 mt-2"
      onChange={() => {
        if (requirements.language.includes(option.id)) {
          setRequirements((p: any) => ({
            ...p, language: requirements.language.filter((item: any) => item !== option.id),
          }));
        } else {
          setRequirements((p: any) => ({
            ...p, language: [...requirements.language, option.id],
          }));
        }
      }}
    />
  ));

  const certificateCheckboxes = certificateOptions.map((option: any, index: any) => (
    <Form.Check
      checked={requirements.certificate.includes(option.id)}
      type="checkbox"
      id={`certificate-option-${option.id}`}
      key={`certificate-option-${option.id}`}
      label={certificateOptions[index]?.description}
      name={`certificate-${option.id}`}
      className="genericbutton ms-3 mt-2"
      onChange={() => (
        requirements.certificate.includes(option.id)
          ? setRequirements((p: any) => ({
            ...p, certificate: requirements.certificate.filter((item: any) => item !== option.id),
          }))
          : setRequirements((p: any) => ({
            ...p, certificate: [...requirements.certificate, option.id],
          }))
      )}
    />
  ));

  const saveYokedMinistry = (result: boolean, data: CongregationData): void => {
    if (data && result) {
      MDPService.SubmitYokedMinistry(data, mdp.id).then(() => {
        setRefresh((r: boolean) => !r);
      });
    }
    setShow(false);
  };

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentYokedMinistry) {
      MDPService.DeleteYokedMinistry(mdp.id, currentYokedMinistry.pin).then(() => {
        setRefresh((r: boolean) => !r);
      });
    }
    setShowDelete(false);
  }, [currentYokedMinistry]);

  const closeAlert = useCallback((result: boolean): void => {
    if (result) {
      setShowAlert(false);
      api.put(`/mdp/${mdp.id}/requirements/${requirements.id}/`, { ...requirements, mdp: mdp.id });
      setResubmission((r: boolean) => !r);
    } else {
      setRefresh((r: boolean) => !r);
      setShowAlert(false);
    }
  }, [setShowAlert, requirements]);

  function AddYokedMinistry(): JSX.Element {
    const handleShow = (): void => setShow(true);

    return (
      <>
        <div className="d-flex">
          <Form.Label className="bodyheader">
            {t('Yoked_Ministries')}
          </Form.Label>
          {yokedMinistryList.length !== 4 && (
            <Button
              onClick={handleShow}
              className="mb-3 ms-auto createbutton"
              variant="primary"
              size="sm"
              active
            >
              {`+ ${t('Add_Yoked_Ministry')}`}
            </Button>
          )}
        </div>

        <Modal
          show={show}
          fullscreen
          onHide={() => saveYokedMinistry(false, yokedMinistry)}
          animation
          className="modalstyle"
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <div className="mx-auto mt-3">
              <div className="title">{t('Add_Yoked_Ministry')}</div>
            </div>
            <Form className="mx-auto col-8">
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Congregation')}
                </Form.Label>
                <Typeahead
                  id="congregation"
                  placeholder={t('Congregation')}
                  options={congregationOptions}
                  labelKey={(option: CongregationData) => `${option?.name} (${option?.city}, ${option?.state})`}
                  onChange={(e) => setYokedMinistry((p: any) => ({ ...p, pin: e[0].pin, presbytery: e[0].presbytery }))}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="secondary" onClick={() => saveYokedMinistry(false, yokedMinistry)}>
              {t('PDP.Cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => saveYokedMinistry(true, yokedMinistry)}
            >
              {t('Panel_Modal_Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <div className="col-lg-8 col-12 mx-auto">
      <ConfirmModal
        show={showAlert}
        title={t('Alert')}
        description={t('MDP_Reapproval_Info_alert')}
        yesLabel={t('Yes')}
        noLabel={t('PDP.Cancel')}
        callback={closeAlert}
      />
      <ConfirmModal
        show={showDelete}
        title={t('PDP.Confirm')}
        description={t('Delete_Yoked_Ministry_Confirmation')}
        yesLabel={t('PDP.Delete')}
        noLabel={t('PDP.Cancel')}
        callback={closeDelete}
      />
      <div className="mx-auto">
        <div className="title">{t('Position_Requirements')}</div>
        <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
        <SanitizeHTML html={t('Position_Requirements_Description')} />
        <div className="my-3">
          <Form.Group className="text-start col-6">
            <Form.Label className="bodyheader required">
              {t('Position_Type_Title')}
            </Form.Label>
            <Form.Select
              value={requirements.positionType || ''}
              name="positionType"
              onChange={(e: any) => setRequirements((p: any) => ({
                ...p,
                positionType: e.target.value,
                positionTypeDescription: e.target.options[e.target.selectedIndex].text,
              }))}
            >
              <option key="" value="">
                {t('Select_Position')}
              </option>
              {(positionTypeList.map((option: any) => (
                <option key={`position-type-${option.id}`} value={option.id}>
                  {option.positionType}
                </option>
              )))}
            </Form.Select>
          </Form.Group>
        </div>
        {requirements.positionTypeDescription === 'Pastor, Yoked Ministry' && (
          <div className="col-6 my-3">
            {AddYokedMinistry()}
            <Table responsive hover className="mb-3 linktable">
              <thead>
                <tr className="linktableheader">
                  <th>{t('Ministry')}</th>
                  <th>{t('City')}</th>
                  <th>{t('State')}</th>
                  <th>{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {yokedMinistryList?.map((option: CongregationData) => (
                  <tr key={option.pin} className="dividinglines">
                    <td>{option.name ? option.name : ''}</td>
                    <td>{option.city ? option.city : '' }</td>
                    <td>{option.state ? option.state : '' }</td>
                    <td>
                      <DeleteButton
                        label=""
                        handler={() => {
                          setCurrentYokedMinistry((p: any) => ({ ...p, pin: option.pin }));
                          setShowDelete(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <Suspense fallback={<SuspenseLoading />}>
          <div className="my-3">
            <Form.Group className="text-start col-6">
              <Form.Label className="bodyheader required">
                {t('Experience_Required')}
              </Form.Label>
              <Form.Select
                value={requirements.experienceLevel || ''}
                name="experienceLevel"
                onChange={handleSelectChange}
              >
                <option key="" value="">
                  {t('Select_Experience')}
                </option>
                {(filteredExpLevels?.map((option: any) => (
                  <option key={`experience-${option.id}`} value={option.id}>
                    {option.displayName}
                  </option>
                )))}
              </Form.Select>
            </Form.Group>
          </div>
        </Suspense>
        <div className="my-3">
          <Form.Group className="text-start col-6">
            <Form.Label className="bodyheader">
              {t('Specify_Title')}
            </Form.Label>
            <Form.Control
              value={requirements.title || ''}
              name="title"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setRequirements((p: any) => ({
                ...p, title: e.target.value, initial: false,
              }))}
            />
          </Form.Group>
        </div>
        <div className="my-3">
          <Form.Group className="text-start col-6">
            <Form.Label className="bodyheader required">
              {t('Employment_Status')}
            </Form.Label>
            <Form.Select
              value={requirements.employmentType || ''}
              name="employmentType"
              onChange={handleSelectChange}
            >
              <option key="" value="">
                {t('Select_Employment')}
              </option>
              {(employmentTypes.map((option: any) => (
                <option key={`employment-type-${option.id}`} value={option.id}>
                  {option.description}
                </option>
              )))}
            </Form.Select>
          </Form.Group>
        </div>
        <Form.Group className="text-start col-6 my-3">
          <Form.Label className="bodyheader">
            {t('Training_Cert_Req')}
          </Form.Label>
          <div className="border rounded-3">
            {certificateCheckboxes}
          </div>
          <Form.Label className="bodyheader mt-3">
            {t('Other_Training')}
          </Form.Label>
          <Form.Control
            value={requirements.othercert}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setRequirements((p: any) => ({
              ...p, othercert: e.target.value, initial: false,
            }))}
          />
        </Form.Group>
        <Form.Group className="text-start col-6">
          <Form.Label className="bodyheader required">
            {t('Language_Requirements')}
          </Form.Label>
          <div className="border rounded-3">
            {languageCheckboxes}
          </div>
          <Form.Label className="bodyheader mt-3">
            {t('Other_Languages')}
          </Form.Label>
          <Form.Control
            value={requirements.otherlang || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setRequirements((p: any) => ({
              ...p, otherlang: e.target.value, initial: false,
            }))}
          />
        </Form.Group>
        <div className="my-3">
          <Form.Group className="text-start">
            <Form.Label className="bodyheader required">
              {t('Statement_Required')}
            </Form.Label>
            <Form.Check
              checked={requirements.statementRequired}
              type="radio"
              id="intercultural-ministries-1"
              label={t('Yes')}
              name="InterculturalRadio"
              className="preferencesradio"
              onChange={() => setRequirements((p: any) => ({
                ...p, statementRequired: true, initial: false,
              }))}
            />
            <Form.Check
              checked={
                (requirements.statementRequired === false)
                  ? !requirements.statementRequired : false
              }
              type="radio"
              label={t('No')}
              id="intercultural-ministries-2"
              name="InterculturalRadio"
              className="preferencesradio"
              onChange={() => setRequirements((p: any) => ({
                ...p, statementRequired: false, initial: false,
              }))}
            />
          </Form.Group>
        </div>
        <Form.Group className="text-start my-3">
          <Form.Label className="required bodyheader">
            {t('Clergy_Couple')}
          </Form.Label>
          <Form.Check
            checked={requirements.filingCouple}
            type="radio"
            id="filing-couple-1"
            label={t('Yes')}
            name="clergy-radio"
            className="preferencesradio"
            onChange={() => setRequirements((p: any) => ({
              ...p, filingCouple: true, initial: false,
            }))}
          />
          <Form.Check
            checked={(requirements.filingCouple === false) ? !requirements.filingCouple : false}
            type="radio"
            label={t('No')}
            id="filing-couple-2"
            name="clergy-radio"
            className="preferencesradio"
            onChange={() => setRequirements((p: any) => ({
              ...p, filingCouple: false, initial: false,
            }))}
          />
        </Form.Group>
        <div className="my-3">
          <Form.Group className="text-start col-6">
            <Form.Label className="bodyheader">
              {t('Application_Deadline')}
            </Form.Label>
            <Form.Control
              type="date"
              value={requirements.deadline?.toString() || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setRequirements((p: any) => ({
                ...p, deadline: e.target.value || null, initial: false,
              }))}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default PositionRequirements;

import {
  useEffect,
  useState,
  Suspense,
} from 'react';
import { Table, Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import PDPService from '../services/pdp.service';
import {
  IPositionType,
  IPositionTypeResponse,
  IExperienceLevel,
  PDPNarrativeData,
  AttestationPDPProfileData,
  AttestationPDPProfileDefaultData,
} from '../types/pdp.types';
import api from '../services/api.service';
import {
  experienceLevelOptionsState,
  prefixDataState,
  suffixDataState,
} from '../services/state.service';
import {
  UserProfile,
  UserProfileDefaultValue,
  PrefixData,
  SuffixData,
} from '../types/user.types';
import SuspenseLoading from './generic/SuspenseLoading';
import SanitizeHTML from '../services/html.service';
import DisplayField from './generic/DisplayField';

function AttestationPDPDetails(): JSX.Element {
  const params = useParams();
  const { t } = useTranslation();
  const [pdp, setPDP] = useState<AttestationPDPProfileData>(AttestationPDPProfileDefaultData);
  const [positionTypeList, setPositionTypeList] = useState<IPositionType[]>([]);
  const experienceLevels = useRecoilValue<IExperienceLevel[]>(experienceLevelOptionsState);
  const [filteredExpLevels, setFilteredExpLevels] = useState<IExperienceLevel[]>();
  const [userProfile, setUserProfile] = useState<UserProfile>(UserProfileDefaultValue);
  const prefix = useRecoilValue<PrefixData[]>(prefixDataState);
  const suffix = useRecoilValue<SuffixData[]>(suffixDataState);
  const [displayPrint, setDisplayPrint] = useState(false);

  useEffect(() => {
    const list = [...experienceLevels];
    list.splice(list.findIndex((p) => p.value === 'No Experience/First Ordained Call'), 1);
    setFilteredExpLevels(list);
  }, [experienceLevels]);

  useEffect(() => {
    Promise.all([
      api.get(`/attestationpdpdetails/${params.id}/`).then(
        (response: any) => {
          setUserProfile(response.data.options.userProfile);
          setPDP(response.data.options);
        },
      ),
      PDPService.GetPositionTypes(params.id).then(
        (response: IPositionTypeResponse) => { setPositionTypeList(response.data.options); },
      ),
    ]).then(() => {
      setDisplayPrint(true);
    });
  }, [params.id]);

  function displayPrefix(prefixId: number | string): string {
    let displayThisPrefix = '';
    for (let i = 0; i < prefix.length; i += 1) {
      if (prefix[i].id === prefixId) {
        displayThisPrefix = prefix[i].description;
      }
    }
    return displayThisPrefix;
  }

  function displaySuffix(suffixId: number | string): string {
    let displayThisSuffix = '';
    for (let i = 0; i < suffix.length; i += 1) {
      if (suffix[i].id === suffixId) {
        displayThisSuffix = suffix[i].description;
      }
    }
    return displayThisSuffix;
  }

  return (
    <div className="col-10 my-4 mx-auto">
      {displayPrint && (
        <div className="text-end">
          <Button
            className="mb-3 ms-auto printbutton"
            variant="primary"
            size="sm"
            active
            onClick={() => (window.print())}
          >
            <FaEdit />
            {` ${t('Print_Btn')}`}
          </Button>
        </div>
      )}
      <div className="col-12 mx-auto">
        <div className="mx-auto col-12">

          <div className="title text-center">
            {`${t('PDP.PDP_Title')} ID# ${params.id}`}
          </div>
          {/* Profile Information */}
          <div className="title text-center">
            {t('ProfileInformation')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-sm-3 my-4 mx-3">
                <DisplayField
                  label={t('Name')}
                  text={`
                  ${displayPrefix(userProfile?.prefix)}
                  ${userProfile?.firstName}
                  ${userProfile?.middleName}
                  ${userProfile?.lastName}
                  ${displaySuffix(userProfile?.suffix)}
                  `}
                />
              </div>
              <div className="col-sm-3 my-4 mx-3">
                <DisplayField
                  label={t('Email')}
                  text={`${userProfile?.email}`}
                />
              </div>
              <div className="col-sm-3 my-4 mx-3">
                <DisplayField
                  label={t('Pronouns')}
                  text={`${userProfile?.pronouns}`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3 my-4 mx-3">
                <DisplayField
                  label={t('Address')}
                  text={`${userProfile?.fullAddress ? userProfile.fullAddress : ''}`}
                />
              </div>
              <div className="col-sm-3 my-4 mx-3">
                <DisplayField
                  label={t('Phone_Number')}
                  text={userProfile?.phoneOne}
                />
              </div>
              <div className="col-sm-3 my-4 mx-3">
                <DisplayField
                  label={t('Secondary_Phone')}
                  text={userProfile?.phoneTwo}
                />
              </div>
            </div>

          </div>

          {/* Preferences */}
          <div className="title text-center">
            {t('PDP.Preferences')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-6">
                <div className="my-4 mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('PDP.Employment_Type')}
                  </div>
                  {pdp.employmentType}
                </div>
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('PDP.Position_Type_Title')}
                  </div>
                  <Suspense fallback={<SuspenseLoading />}>
                    <div>
                      {pdp.positionTypes?.map((option: any) => (
                        <div key={`postype-${option.positionType}`}>
                          {positionTypeList.map((item: any) => (
                            (item.id === option.positionType)
                              ? (
                                <div key={`postype-item-${item.id}`}>
                                  {item.positionType}
                                </div>
                              )
                              : null))}
                          {filteredExpLevels?.map((level: any) => (
                            (level.id === option.experienceLevel)
                              ? (
                                <div key={`explevel-${level.id}`}>
                                  {level.displayName}
                                </div>
                              )
                              : (
                                null
                              )
                          ))}
                        </div>
                      ))}
                    </div>
                  </Suspense>
                </div>
              </div>
              <div className="col-6">
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('PDP.Min_Expected_Salary')}
                  </div>
                  {pdp.minExpectedSalary}
                </div>
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Housing_Type')}
                  </div>
                  {pdp.housingType?.map((option: any) => (
                    <div key={`housing-type-${option.id}`}>
                      {option.value}
                    </div>
                  ))}

                </div>
                <div className="my-4  mx-3">
                  <div className="mb-2 reviewtitle">
                    {t('Available_To_Match')}
                  </div>
                  <div>
                    <div key="calling-info-actively-seeking">
                      {userProfile.availableToMatch ? (t('Yes')) : (t('No'))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Education Training */}
          <div className="title text-center">
            {t('Education_Title')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-10 my-4 mx-3">
                <div className="mb-2 reviewtitle">
                  {t('Education_Title')}
                </div>
                {pdp.formalEducation?.map((option: any) => (
                  <div key={`formal-education-${option.id}`}>
                    {`${option.credentials} - ${option.school},  (${option.start} ${option.current ? (t('- Current')) : (` - ${option.end}`)})`}
                  </div>
                ))}
                <div className="my-4 mb-2 reviewtitle">
                  {t('Continuing_Education')}
                </div>
                {pdp.continuingEducation?.map((option: any) => (
                  <div key={`continuing-education-${option.id}`}>
                    <SanitizeHTML html={option.text} />
                  </div>
                ))}
                <div className="mb-2 my-4 reviewtitle">
                  {t('Training_Certification_Title')}
                </div>
                <div>
                  {pdp.trainingCertification ? (
                    pdp.trainingCertification?.map((option: any) => (
                      <div key={`training-certification-${option.id}`}>
                        <SanitizeHTML html={`${t(option.weekOne)} ${option.weekOne ? ' - ' : ' '} ${option.weekOneLocation}`} />
                        <SanitizeHTML html={`${t(option.weekTwo)} ${option.weekOne ? ' - ' : ' '} ${option.weekTwoLocation}`} />
                        <SanitizeHTML html={option.cert} />
                      </div>
                    ))
                  ) : (
                    t('NotSpecified')
                  )}
                </div>
                <div className="mb-2 my-4 reviewtitle">
                  {t('Language_Proficiency')}
                </div>
                <div>
                  {pdp.skills?.map((option: any) => (
                    <div key={`language-skill-${option.id}`}>
                      {option.language}
                    </div>
                  ))}
                </div>
                <div className="mb-2 my-4 reviewtitle">
                  {t('Technical_Skills')}
                </div>
                <div>
                  {pdp.skills?.map((option: any) => (
                    <div key={`technical-skill-${option.id}`}>
                      {option.technical}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Work Experience */}
          {pdp.workExperience.length > 0 && (
            <>
              <div className="title text-center">
                {t('PDP.Work_Experience')}
              </div>
              <div className="border border-dark rounded-3 text-start mb-4">
                <div className="row">
                  <div className="col-6">
                    <div className="my-4 mx-3">
                      {pdp.workExperience.map((work: any) => (
                        <div key={`workexp-${work.id}`} className="text-start my-4 mx-3">
                          <b>{new Intl.DateTimeFormat('en-US').format(new Date(work.start))}</b>
                          &nbsp;-&nbsp;
                          <b>{work.end ? new Intl.DateTimeFormat('en-US').format(new Date(work.end)) : t('Present')}</b>
                          <br />
                          {work.organization}
                          &nbsp;&nbsp;&nbsp;
                          {work.position}
                          <br />
                          {work.name}
                          <br />
                          {work.city}
                          ,&nbsp;
                          {work.state}
                          <br />
                          (
                          {work.community}
                          ,&nbsp;
                          {work.size}
                          )
                          <br />
                          <SanitizeHTML html={work.responsibilities} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Service to the Church */}
          {pdp.service.length > 0 && (
            <>
              <div className="title text-center">
                {t('PDP.Service_to_the_Church')}
              </div>
              <div className="border border-dark rounded-3 text-start mb-4">
                <div className="row">
                  <div className="col-6">
                    <div className="my-4  mx-3">
                      {pdp.service.map((option: any) => (
                        <div key={`servicetochurch-${option.id}`} className="text-start my-4 mx-3">
                          <b>{new Intl.DateTimeFormat('en-US').format(new Date(option.start))}</b>
                          &nbsp;-&nbsp;
                          <b>{option.end ? new Intl.DateTimeFormat('en-US').format(new Date(option.end)) : t('Present')}</b>
                          <br />
                          {option.position}
                          <br />
                          {option.organization}
                          {option.congregationname?.length > 0 && (
                            <>
                              <br />
                              {option.congregationname}
                              ,&nbsp;
                            </>
                          )}
                          {option.presbyteryname}
                          {option.description?.length > 0 && (
                            <>
                              <br />
                              <SanitizeHTML html={option.description} />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Statement of Faith */}
          <div className="title text-center">
            {t('PDP.Statement_of_Faith')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-12">
                <div className="my-4  mx-3">
                  <SanitizeHTML html={userProfile.statementOfFaith} />
                </div>
              </div>
            </div>
          </div>

          {/* Narratives */}
          <div className="title text-center">
            {t('PDP.Narratives')}
          </div>
          <div className="border border-dark rounded-3 text-start m b-4">
            <div className="row">
              <div className="col-11 mt-4 ms-auto me-5">
                {pdp.narratives.map((option: PDPNarrativeData) => (
                  <div key={option.id} className="border border-dark rounded-3 mb-3">
                    <Table className="pdp-narrative-panel">
                      <thead>
                        <tr className="linktableheader">
                          <td>{option.question}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="text-center fw-bold">
                              {t('Applies_To_Position')}
                              &nbsp;
                              {option.positionTypes}
                            </div>
                            <hr />
                            <div className="mb-1">
                              <span>Response: </span>
                              <SanitizeHTML html={option.response || ''} />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Optional Links */}
          {pdp.optionalLinks.length > 0 && (
            <>
              <div className="title text-center mt-4">
                {t('PDP.Optional_Links')}
              </div>
              <div className="border border-dark rounded-3 text-start mb-4">
                <div className="row">
                  <div className="col-6">
                    <div className="my-4  mx-3">
                      {pdp.optionalLinks.map((option: any) => (
                        <div key={`optionallinks-${option.id}`} className="text-start my-4 mx-3">
                          <b>{option.linkTitle}</b>
                          &nbsp;-&nbsp;
                          {option.linkDescription}
                          &nbsp;-&nbsp;
                          <a
                            href={(option.linkUrl).startsWith('http') ? option.linkUrl : (`//${option.linkUrl}`)}
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            {option.linkUrl}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* References */}
          <div className="title text-center mt-4">
            {t('PDP.References')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className="col-6">
                <div className="my-4  mx-3">
                  {pdp.reference.map((option: any, index: any) => (
                    <div key={`references-${option.id}`}>
                      <div className="mx-3 mb-2 reviewtitle">
                        {t('PDP.Singular_Reference')}
                        &nbsp;#
                        {index + 1}
                      </div>
                      <div className="text-start my-3 mx-3">
                        <b>{option.name}</b>
                        <br />
                        {option.relation}
                        <br />
                        {option.phone}
                        <br />
                        {option.email}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Sexual Misconduct */}
          <div className="title text-center mt-4">
            {t('PDP.Misconduct_Cert_Title')}
          </div>
          <div className="border border-dark rounded-3 text-start mb-4">
            <div className="row">
              <div className=" my-4  mx-3 col-10">
                {pdp.noComplaint ? (
                  <div>{t('PDP.Misconduct_Certify')}</div>
                ) : (
                  <>
                    <div>{t('PDP.Misconduct_Unable')}</div>
                    <br />
                    <div>{pdp.comments}</div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="my-4  mx-3 col-12">
                {pdp.certification && (
                  <div>{t('PDP.Misconduct_Certification')}</div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default AttestationPDPDetails;

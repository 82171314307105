import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserProfile } from '../types/user.types';
import { CLC_FUNCTION } from '../types/constants';
import withUserAllowed from './layout/withUserAllowed';

function NeedHelpModal({
  show,
  hide,
  organizationLabel,
  organizationName,
  organizationCOM,
  users,
  title,

} : {
  show: boolean,
  hide: () => void,
  organizationLabel: string,
  organizationName: string,
  organizationCOM: string,
  users: UserProfile[],
  title: string,
}): JSX.Element {
  const { t } = useTranslation();

  const ContactMessage: React.FC<{ usersProfile: UserProfile[] }> = ({ usersProfile }) => (
    <div>
      {usersProfile.length > 0 ? (
        usersProfile.map((p: UserProfile) => (
          <div key={p.email}>
            {p.firstName}
            {` ${p.lastName}:`}
            &nbsp;
            &nbsp;
            {p.email}
          </div>
        ))
      ) : (
        <div>
          {t('ContactMessage')}
        </div>
      )}
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={hide}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Form className={users.length > 0 ? '' : 'mdp-contact-us'}>
          <div className="row">
            <Form.Label className="col-12 fw-bold">
              {organizationLabel}
            </Form.Label>
            <div className="col-12">
              {organizationName}
            </div>
          </div>
          <br />
          <Form.Label className="fw-bold">
            {organizationCOM}
          </Form.Label>
          <ContactMessage usersProfile={users} />
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default withUserAllowed(CLC_FUNCTION.manageMDP)(NeedHelpModal);

import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { Typeahead } from 'react-bootstrap-typeahead';
import Accordion from 'react-bootstrap/Accordion';
import {
  Button,
  Modal,
  Form,
  Table,
} from 'react-bootstrap';
import {
  Controller,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { FaPlus, FaUserCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import {
  SessionClerkData,
  SessionClerkResponseData,
  SessionClerkDefaultData,
} from '../types/ministry.types';
import api from '../services/api.service';
import {
  congregationOptionsState,
  currentRoleState,
} from '../services/state.service';
import { Role } from '../types/user.types';
import { CongregationData } from '../types/pdp.types';
import SessionClerkRolesModal from './SessionClerkRolesModal';

type SessionClerkForm = {
  first_name: string,
  last_name: string,
  email: string,
  congregation: number | undefined,
}

const SessionClerkFormDefault: SessionClerkForm = {
  first_name: '',
  last_name: '',
  email: '',
  congregation: 0,
};

function SessionClerksAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [clerks, setClerks] = useState<SessionClerkData[]>([]);
  const [currentClerk, setCurrentClerk] = useState<SessionClerkData>(SessionClerkDefaultData);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const congregationOptions = useRecoilValue<CongregationData[]>(congregationOptionsState);
  const [availableCongregations, setAvailableCongregations] = useState<CongregationData[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showRolesModal, setShowRolesModal] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const {
    handleSubmit,
    setValue,
    register,
    reset,
    watch,
    control,
    formState: {
      errors,
    },
  } = useForm<SessionClerkForm>({
    defaultValues: SessionClerkFormDefault,
  });
  const currentCongregation = watch('congregation');

  useEffect(() => {
    setAvailableCongregations(congregationOptions.filter((c) => c.presbytery === currentRole.presbytery));
  }, [congregationOptions, currentRole.organizationId]);

  useEffect(() => {
    api.get(`/sessionclerks/${currentRole.organizationId}/`)
      .then(
        (response: SessionClerkResponseData) => {
          setClerks(response.data.clerks);
        },
      );
  }, [refresh, currentRole]);

  function getStatus(clerk: SessionClerkData): string {
    if (clerk.account_approved) {
      return t('Active');
    }

    if (clerk.expected) {
      return t('Invited');
    }

    return t('Requested');
  }

  function updateRole(clerk: SessionClerkData, approved: boolean): Promise<void> {
    const payload = new FormData();
    payload.append('id', clerk.id.toString());
    payload.append('approve', approved.toString());
    payload.append('expected', clerk.expected.toString());
    return api.post(`/sessionclerks/${currentRole.organizationId}/`, payload)
      .then(() => (setRefresh((r: boolean) => !r)));
  }

  function closeDelete(result: boolean, clerk: SessionClerkData): void {
    if (result && clerk) {
      updateRole(clerk, false);
      setShowDelete(false);
    } else {
      setShowDelete(false);
    }
  }

  function getActions(clerk: SessionClerkData): JSX.Element {
    if (!clerk.account_approved && !clerk.expected) {
      return (
        <>
          <Button
            variant="secondary"
            className="action-button mb-1"
            onClick={() => { setShowDelete(true); setCurrentClerk(clerk); }}
          >
            {t('Reject')}
          </Button>
          &nbsp;&nbsp;
          <Button variant="primary" className="action-button mb-1" onClick={() => updateRole(clerk, true)}>
            {t('Approve')}
          </Button>
        </>
      );
    }

    return (
      <Button
        variant="secondary"
        className="action-button m-1"
        onClick={() => { setShowRolesModal(true); setCurrentClerk(clerk); }}
      >
        {t('Remove')}
      </Button>
    );
  }

  const removeCOSRoles = useCallback((congreationOptions: number[]): void => {
    api.post(`/removecoscongregations/${currentClerk.id}/`, congreationOptions)
      .then(() => setShowRolesModal(false))
      .then(() => (setRefresh((r: boolean) => !r)));
  }, [currentClerk]);

  function Invite(): JSX.Element {
    const handleClose = (): void => setShow(false);
    const handleShow = (): void => setShow(true);

    const onSubmit: SubmitHandler<SessionClerkForm> = (data: SessionClerkForm) => {
      api.post(`/sessionclerks/${currentRole.organizationId}/`, data)
        .then(() => setShow(false))
        .then(() => setRefresh((r: boolean) => !r))
        .then(() => reset(SessionClerkFormDefault));
    };

    return (
      <>
        <Button
          onClick={handleShow}
          className="mb-3 ms-auto createbutton"
          variant="primary"
          size="sm"
          active
        >
          <FaPlus />
          {` ${t('Invite')}`}
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          className="modalstyle"
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body className="text-center">
            <div className="mx-auto mt-3">
              <div className="title">{t('InviteCOS')}</div>
            </div>
            <Form className="mx-auto col-8" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Congregation')}
                </Form.Label>
                <Controller
                  name="congregation"
                  control={control}
                  render={() => {
                    let selectedCongregation: CongregationData[] = [];
                    const found = availableCongregations.find((c) => c.pin === currentCongregation);
                    if (found) selectedCongregation = [found];
                    return (
                      <Typeahead
                        id="congregation"
                        placeholder={t('Congregation')}
                        options={availableCongregations}
                        labelKey={(option: CongregationData) => `${option?.name} (${option?.city}, ${option?.state})`}
                        selected={selectedCongregation}
                        onChange={(e) => setValue('congregation', e.length > 0 ? e[0].pin : undefined)}
                      />
                    );
                  }}
                  rules={{ required: true }}
                />
                <div className="col-12 non-field-error">
                  {errors.congregation && t('CongregationRequired')}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('First_Name')}
                </Form.Label>
                <Form.Control
                  {...register('first_name', { required: true })}
                  isInvalid={!!errors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name && errors.first_name.message
                    ? errors.first_name.message
                    : t('FirstNameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Last_Name')}
                </Form.Label>
                <Form.Control
                  {...register('last_name', { required: true })}
                  isInvalid={!!errors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name && errors.last_name.message
                    ? errors.last_name.message
                    : t('LastNameRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="bodyheader mt-3 required">
                  {t('Email')}
                </Form.Label>
                <Form.Control
                  {...register('email', { required: true })}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email && errors.email.message
                    ? errors.email.message
                    : t('EmailRequired')}
                </Form.Control.Feedback>
              </Form.Group>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="primary" onClick={() => submitRef.current?.click()}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaUserCircle />
        <span className="accordion-header-label">
          {t('SessionClerks')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="mb-3 mx-auto mobiletable col-lg-12">
          <div className="d-flex">
            {Invite()}
          </div>
          <div className="border border-dark rounded-3">
            <SessionClerkRolesModal
              congregationInfo={currentClerk.congregationInfo}
              show={showRolesModal}
              hide={() => setShowRolesModal(false)}
              callback={removeCOSRoles}
            />
            <Modal
              show={showDelete}
              onHide={() => closeDelete(false, currentClerk)}
              animation={false}
              className="modalstyle"
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {t('PDP.Confirm')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {t('Delete_COS_Confirmation')}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => (closeDelete(true, currentClerk))}>
                  {t('Remove')}
                </Button>
                <Button variant="primary" onClick={() => (closeDelete(false, currentClerk))}>
                  {t('PDP.Cancel')}
                </Button>
              </Modal.Footer>
            </Modal>
            <Table responsive hover className="mb-3 linktable">
              <thead>
                <tr className="linktableheader">
                  <th>
                    {t('Name')}
                  </th>
                  <th>
                    {t('Email')}
                  </th>
                  <th>
                    {t('Congregation')}
                  </th>
                  <th>
                    {t('Status')}
                  </th>
                  <th>
                    {t('Actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {clerks?.map((clerk: SessionClerkData) => (
                  <tr key={clerk.id + clerk.email} className="dividinglines">
                    <td>
                      {clerk.firstName}
                      &nbsp;
                      {clerk.lastName}
                    </td>
                    <td>{clerk.email}</td>
                    <td>{clerk.congregation}</td>
                    <td>{getStatus(clerk)}</td>
                    <td>{getActions(clerk)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed(CLC_FUNCTION.approveCOS)(SessionClerksAccordionItem);

import {
  ChangeEvent,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useRecoilState } from 'recoil';

import MDPService from '../services/mdp.service';
import SanitizeHTML from '../services/html.service';
import { mdpStepState, mdpState } from '../services/state.service';
import {
  MDPData,
  MDPResponseData,
} from '../types/mdp.types';
import { useStepValid } from '../hooks/useStepValid';

function EqualEmploymentOpportunity(): JSX.Element {
  const { t } = useTranslation();
  const [mdp, setMDP] = useRecoilState<MDPData>(mdpState);

  useEffect(() => {
    MDPService.GetMDPData(mdp.id).then(
      (response: MDPResponseData) => (setMDP(response.data)),
    );
  }, [mdp.id, setMDP]);

  useEffect(() => {
    MDPService.Update(mdp);
  }, [mdp.acceptEEO]);

  const requiredFields = useMemo(() => ['acceptEEO=true'], []);
  useStepValid('equalEmploymentOpportunity', mdp, requiredFields, mdpStepState);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    setMDP((p: any) => ({
      ...p,
      [name]: checked,
    }));
  };

  return (
    <div className="col-lg-8 col-12 mx-auto">
      <div className="mx-auto">
        <div className="title">
          {t('EEO')}
        </div>
        <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
        <div className="border border-dark mx-auto my-3 rounded p-3 text-start">
          <div className="misconducttitle text-start my-2 fw-bold">
            {t('EEO_Title')}
          </div>
          <SanitizeHTML html={t('Equal_Employment')} />
        </div>
        <div className="required pdpsurveyheader mx-auto">
          {t('PDP.Please_Accept')}
        </div>
        <Form.Group className="text-start mb-3">
          <Form.Check
            checked={mdp.acceptEEO}
            type="checkbox"
            label={t('EEO_Accept')}
            id="eeo-accept"
            name="acceptEEO"
            className="preferencesradio"
            onChange={handleCheckboxChange}
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default EqualEmploymentOpportunity;

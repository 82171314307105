import {
  useRef,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { useRecoilValue } from 'recoil';
import {
  notifyOptionsState,
} from '../services/state.service';
import api from '../services/api.service';
import { SelectOptionsData } from '../types/selectoptions.types';
import { MDPResultListData } from '../types/mdp.types';

interface NotifyPNCData {
  reason: string,
}

function NotifyPNCModal({
  show,
  mdp,
  updateCallback,
}: {
  show: boolean,
  mdp: MDPResultListData,
  updateCallback: () => void
}): JSX.Element {
  const { t } = useTranslation();
  const notifyPNCOptions = useRecoilValue<SelectOptionsData[]>(notifyOptionsState);
  const {
    setValue,
    watch,
    register,
    reset,
    handleSubmit,
  } = useForm<NotifyPNCData>();
  const currentNotifyOption = watch('reason');
  const submitRef = useRef<HTMLButtonElement>(null);

  const onSubmit: SubmitHandler<NotifyPNCData> = (data: NotifyPNCData) => {
    const payload = new FormData();
    payload.append('mdp', mdp.id.toString());
    payload.append('reason', data.reason.toString());

    api.post('notify/', payload)
      .then(() => {
        updateCallback();
      });
  };

  function handleClose(): void {
    reset();
    updateCallback();
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('NotifyPNC')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="container-fluid mt-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Form.Group
                className="col-12 mb-2"
                controlId="formNotifyPNC"
              >
                <Form.Label className="required">
                  {t('NotifyPNCDescription')}
                </Form.Label>
                <Form.Select
                  {...register('reason', { required: true })}
                  onChange={(e) => setValue('reason', e.target.value)}
                >
                  <option key="reason-0" value="0">{t('SelectReason')}</option>
                  {(notifyPNCOptions?.map((option: any) => (
                    <option key={`reason-${option.id}`} value={option.id}>
                      {option.description}
                    </option>
                  )))}
                </Form.Select>
              </Form.Group>
            </div>
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              aria-label="submit"
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <Button variant="primary" onClick={() => submitRef.current?.click()} disabled={currentNotifyOption?.includes('0')}>
          {t('Submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NotifyPNCModal;

import Modal from 'react-bootstrap/Modal';
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import api from '../services/api.service';
import ServiceEditModal from './ServiceEditModal';
import {
  ServiceModalData,
  ServiceModalResponseData,
  serviceDefault,
} from '../types/pdp.types';
import EditButton from './generic/EditButton';
import DeleteButton from './generic/DeleteButton';
import ConfirmModal from './generic/ConfirmModal';
import { useStepValid } from '../hooks/useStepValid';
import { pdpStepState } from '../services/state.service';
import SanitizeHTML from '../services/html.service';

type ServiceToTheChurchTemplateModalProps = {
  show: boolean,
  hide: () => void,
}

function ServiceToTheChurchTemplateModal({
  show,
  hide,
}: ServiceToTheChurchTemplateModalProps): JSX.Element {
  const { t } = useTranslation();
  const [allServices, setAllServices] = useState<ServiceModalData[]>([]);
  const [refreshServiceData, setRefreshServiceData] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentServiceToChurch, setCurrentServiceToChurch] = useState<ServiceModalData>(serviceDefault);
  const [currentService, setCurrentService] = useState<ServiceModalData>(currentServiceToChurch);
  const [showNewEdit, setShowNewEdit] = useState(false);

  const requiredFields = useMemo(() => [], []);
  useStepValid('servicetothechurch', allServices, requiredFields, pdpStepState);

  useEffect(() => {
    api.get('profile/service/').then(
      (response: ServiceModalResponseData) => {
        if (response.data[0]) {
          setAllServices(response.data);
        } else {
          setAllServices(response.data);
        }
      },
    );
  }, [currentService, refreshServiceData]);

  function CreateService(): void {
    api.get('profile/service/').then(
      (response: ServiceModalResponseData) => {
        if (response.data) {
          setCurrentServiceToChurch(response.data[0]);
          setShowNewEdit(true);
        }
      },
    );
  }

  const closeNewEdit = useCallback((result: boolean, service: ServiceModalData): void => {
    if (result && service) {
      if (service.id > 0) {
        api.put(`/profile/service/${service.id}/`, service).then(() => {
          setShowNewEdit(false);
          setRefreshServiceData((r: boolean) => !r);
        });
      } else {
        api.post('profile/service/', service).then(() => {
          setShowNewEdit(false);
          setRefreshServiceData((r: boolean) => !r);
        });
      }
    } else {
      setShowNewEdit(false);
    }
  }, []);

  const closeDelete = useCallback((result: boolean): void => {
    if (result && currentService) {
      api.delete(`/profile/service/${currentService.id}/`).then(() => {
        setShowDelete(false);
        setRefreshServiceData((r: boolean) => !r);
      });
    } else {
      setShowDelete(false);
    }
  }, [currentService]);

  return (
    <Modal fullscreen show={show} onHide={hide}>
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div>
          <ServiceEditModal
            show={showNewEdit}
            service={currentService}
            setService={setCurrentService}
            saveService={closeNewEdit}
          />
          <ConfirmModal
            show={showDelete}
            title={t('PDP.Confirm')}
            description={t('PDP.Service_Confirmation_Message')}
            yesLabel={t('PDP.Delete')}
            noLabel={t('PDP.Cancel')}
            callback={closeDelete}
          />
          <div>
            <div className="title">{t('Modal_Service_To_The_Church_Title')}</div>
            <div className="col-lg-8 col-12 mx-auto">
              <SanitizeHTML html={t('PDP.Service_Description')} />
            </div>
            <br />
            <div className="d-flex">
              <Button
                onClick={() => {
                  CreateService();
                  setCurrentService(serviceDefault);
                }}
                className="mb-3 ms-auto createbutton"
                variant="primary"
                size="sm"
                active
              >
                {`+ ${t('PDP.Service_Create_New')}`}
              </Button>
            </div>
            <div className="border border-dark rounded-3">
              <Table responsive hover className="mb-3 linktable">
                <thead>
                  <tr className="linktableheader">
                    <th>
                      {t('Work_Exp_Organization')}
                    </th>
                    <th>
                      {t('PDP.Position')}
                    </th>
                    <th>
                      {t('PDP.Start_Date')}
                    </th>
                    <th>
                      {t('PDP.End_Date')}
                    </th>
                    <th>
                      {t('PDP.Links_Table_Actions')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allServices.map((service: ServiceModalData) => (
                    <tr key={`service-${service.id}`} className="dividinglines">
                      <td>{service.organization}</td>
                      <td>{service.position}</td>
                      <td>{service.start?.toString()}</td>
                      <td>{service.end?.toString()}</td>
                      <td>
                        <div>
                          <EditButton
                            label=""
                            handler={() => {
                              setCurrentService(service);
                              setShowNewEdit(true);
                            }}
                          />
                        &nbsp;
                        &nbsp;
                          <DeleteButton
                            label=""
                            handler={() => {
                              setCurrentService(service);
                              setShowDelete(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <br />
            </div>
            <br />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex profilemodalfooter border-top-0">
        <Button variant="primary" onClick={hide}>
          {t('PDP.Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceToTheChurchTemplateModal;

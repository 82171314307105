import {
  useRef,
  useEffect,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import {
  ContinuingEducationData,
} from '../../types/pdp.types';

type ContinuingEducationModalProps = {
  show: boolean,
  selectedEducation: ContinuingEducationData,
  callback: (_result: boolean, _education: ContinuingEducationData,) => void,
}

function ContinuingEducationModal({
  show,
  selectedEducation,
  callback,
}: ContinuingEducationModalProps): JSX.Element {
  const { t } = useTranslation();
  const editorRef = useRef<any>(null);
  const [education, setEducation] = useState<ContinuingEducationData>(selectedEducation);
  const log = (): any => {
    if (editorRef.current !== null) {
      setEducation((p: any) => ({
        ...p, text: editorRef.current.getContent(), id: education.id,
      }));
    }
  };

  useEffect(() => {
    setEducation(selectedEducation);
  }, [selectedEducation]);

  return (
    <Modal
      show={show}
      onHide={() => callback(false, education)}
      animation={false}
      className="modal"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {education.id > 0 ? t('Continuing_Education_Edit') : t('Continuing_Education_New')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="continuingeducation-form">
          <Editor
            onInit={(evt: any, editor: any): any => { editorRef.current = editor; }}
            initialValue={education?.text}
            init={{
              height: 300,
              menubar: false,
              statusbar: false,
              branding: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code',
              ],
              toolbar: 'fontselect | formatselect | '
                + 'alignleft aligncenter alignright alignjustify |'
                + 'bold italic strikethrough underline | forecolor backcolor link bullist numlist',
              contextmenu: false,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              init_instance_callback(editor: any) {
                editor.on('blur', () => log());
              },
            }}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => (callback(false, education))}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-3"
          onClick={() => (callback(true, education))}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ContinuingEducationModal;

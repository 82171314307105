import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function AlertModal({
  show, title, description, closeLabel, callback,
} : {
  show: boolean,
  title: string,
  description: string,
  closeLabel: string,
  callback: () => void,
}): JSX.Element {
  return (
    <Modal
      show={show}
      onHide={callback}
      className="modalstyle"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {description}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={callback}>
          {closeLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlertModal;

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';

type OverlayTriggerType = 'click' | 'hover' | 'focus';
type Placement = 'right' | 'left';

function HelpPopup({
  helpKey,
  placement,
  trigger,
  content,
}: {
  helpKey: string,
  placement: Placement,
  trigger: OverlayTriggerType,
  content: JSX.Element,
}): JSX.Element {
  return (
    <OverlayTrigger
      key={helpKey}
      placement={placement}
      trigger={trigger}
      overlay={(
        <Tooltip id="{key}-tooltip">
          {content}
        </Tooltip>
      )}
    >
      <div className="icon-tooltip">
        <FaQuestionCircle />
      </div>
    </OverlayTrigger>
  );
}

export default HelpPopup;

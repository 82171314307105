import { useState } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import api from '../../services/api.service';
import {
  congregationOptionsState,
} from '../../services/state.service';
import { CongregationData } from '../../types/pdp.types';

type SignUpForm = {
  first_name: string,
  last_name: string,
  account_type: string,
  organization_type: string,
  congregation: number | undefined,
  username: string,
  email: string,
  password1: string,
  password2: string,
}

function SignUp(): JSX.Element {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [nonFieldErrors, setNonFieldErrors] = useState<string>('');
  const {
    setError,
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<SignUpForm>();
  const currentAccountType = watch('account_type');
  const currentOrganizationType = watch('organization_type');
  const currentCongregation = watch('congregation');
  const congregationOptions = useRecoilValue<CongregationData[]>(congregationOptionsState);

  const onSubmit: SubmitHandler<SignUpForm> = (data: SignUpForm) => {
    setNonFieldErrors('');

    return api.post('/register/', data)
      .then(() => setSubmitted(true))
      .catch((e) => {
        const messages = e.response.data;
        Object.keys(messages).forEach((name) => setError(
          name as 'first_name' | 'last_name' | 'account_type' | 'organization_type'
            | 'congregation' | 'username' | 'email' | 'password1' | 'password2',
          { type: 'server', message: messages[name][0] },
        ));
        if (messages.non_field_errors) {
          setNonFieldErrors(messages.non_field_errors[0]);
        }
      });
  };

  function loginInfo(): JSX.Element {
    return (
      <>
        {currentOrganizationType === 'congregation' && (
          <div className="col-12 offset-md-3 col-md-6 mb-3">
            {t('CongregationOrgTypeMessage')}
          </div>
        )}
        <Form.Group
          className="col-12 offset-md-3 col-md-3 mb-3"
          controlId="formFirstname"
        >
          <Form.Label className="required">
            {t('FirstName')}
          </Form.Label>
          <Form.Control
            type="input"
            isInvalid={!!errors.first_name}
            {...register('first_name', { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.first_name && errors.first_name.message
              ? errors.first_name.message
              : t('FirstNameRequired')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="col-12 col-md-3 mb-3"
          controlId="formLastName"
        >
          <Form.Label className="required">
            {t('LastName')}
          </Form.Label>
          <Form.Control
            type="input"
            isInvalid={!!errors.last_name}
            {...register('last_name', { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.last_name && errors.last_name.message
              ? errors.last_name.message
              : t('LastNameRequired')}
          </Form.Control.Feedback>
        </Form.Group>
        <input type="hidden" defaultValue="seeker" {...register('account_type')} />
        <Form.Group
          className="col-12 offset-md-3 col-md-6 mb-5"
          controlId="formEmail"
        >
          <Form.Label className="required">
            {t('Email')}
          </Form.Label>
          <Form.Control
            type="input"
            isInvalid={!!errors.email}
            {...register('email', { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message
              ? errors.email.message
              : t('InvalidEmail')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="col-12 offset-md-3 col-md-6 mb-3"
          controlId="formUsername"
        >
          <Form.Label className="required">
            {t('Username')}
          </Form.Label>
          <Form.Control
            type="input"
            isInvalid={!!errors.username}
            {...register('username', { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.username && errors.username.message
              ? errors.username.message
              : t('InvalidUsername')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="col-12 offset-md-3 col-md-6 mb-3"
          controlId="formPassword"
        >
          <Form.Label className="required">
            {t('Password')}
          </Form.Label>
          <Form.Control
            type="password"
            isInvalid={!!errors.password1}
            {...register('password1', { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password1 && errors.password1.message
              ? errors.password1.message
              : t('Login.PasswordRequired')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="col-12 offset-md-3 col-md-6 mb-3"
          controlId="formPassword2"
        >
          <Form.Label className="required">
            {t('ConfirmPassword')}
          </Form.Label>
          <Form.Control
            type="password"
            isInvalid={!!errors.password2}
            {...register('password2', { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password2 && errors.password2.message
              ? errors.password2.message
              : t('Login.PasswordRequired')}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="col-12 mb-3 text-center non-field-error">
          {nonFieldErrors}
        </div>
        <div className="row text-center">
          <Button
            type="submit"
            variant="primary"
            className="login-submitbutton col-12 col-md-6 mb-3 mx-auto"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner
                animation="border"
                size="sm"
              />
            ) : (
              t('Submit')
            )}
          </Button>
        </div>
      </>
    );
  }

  function orgType(): JSX.Element {
    return (
      <>
        <Form.Group
          className="col-12 offset-md-3 col-md-6 mb-3"
          controlId="formOrganizationType"
        >
          <Form.Label className="required">
            {t('OrganizationType')}
          </Form.Label>
          <Form.Select
            isInvalid={!!errors.organization_type}
            {...register('organization_type', { required: true })}
            onChange={(e) => setValue('organization_type', e.target.value)}
          >
            <option value="">{t('SelectOne')}</option>
            <option value="congregation">{t('Congregation')}</option>
            <option value="midcouncil">{t('MidCouncil')}</option>
            <option value="agency">{t('PCUSAAgency')}</option>
            <option value="other">{t('OtherOrganization')}</option>
          </Form.Select>
          <div className="col-12 non-field-error">
            {errors.organization_type && t('OrganizationTypeRequired')}
          </div>
        </Form.Group>
        {currentOrganizationType === 'congregation' && (
          <Form.Group
            className="col-12 offset-md-3 col-md-6 mb-3"
            controlId="formCongregation"
          >
            <Form.Label className="required">
              {t('Congregation')}
            </Form.Label>
            <Controller
              name="congregation"
              control={control}
              render={() => {
                let selectedCongregation: CongregationData[] = [];
                const found = congregationOptions.find((c) => c.pin === currentCongregation);
                if (found) selectedCongregation = [found];
                return (
                  <Typeahead
                    id="congregation"
                    placeholder={t('Congregation')}
                    options={congregationOptions}
                    labelKey={
                      (option: CongregationData) => `${option?.name} (${option?.city}, ${option?.state})`
                    }
                    selected={selectedCongregation}
                    onChange={(e) => setValue('congregation', e.length > 0 ? e[0].pin : undefined)}
                  />
                );
              }}
              rules={{ required: true }}
            />
          </Form.Group>
        )}
        {currentOrganizationType === 'midcouncil' && (
          <div className="col-12 offset-md-3 col-md-6 mb-3">
            {t('MidCouncilTypeMessage')}
          </div>
        )}
        {currentOrganizationType === 'agency' && (
          <div className="col-12 offset-md-3 col-md-6 mb-3">
            {t('AgencyTypeMessage')}
          </div>
        )}
        {currentOrganizationType === 'other' && (
          <div className="col-12 offset-md-3 col-md-6 mb-3">
            {t('OtherOrgTypeMessage')}
          </div>
        )}
      </>
    );
  }
  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 mb-3 text-center">
          <h1>
            {t('SignUpHeader')}
          </h1>
          {submitted && (
            <h2>
              {t('SignUpSubmitted')}
            </h2>
          )}
        </div>
        {!submitted && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Form.Group
                className="col-12 offset-md-3 col-md-6 mb-3"
                controlId="formAccountType"
              >
                <Form.Label className="required">
                  {t('AccountType')}
                </Form.Label>
                <Form.Select
                  isInvalid={!!errors.account_type}
                  {...register('account_type', { required: true })}
                  onChange={(e) => setValue('account_type', e.target.value)}
                >
                  <option value="">{t('SelectOne')}</option>
                  <option value="seeker">{t('CallSeeker')}</option>
                  <option value="caller">{t('CallingOrganization')}</option>
                </Form.Select>
                <div className="col-12 non-field-error">
                  {errors.account_type && t('AccountTypeRequired')}
                </div>
              </Form.Group>
              {currentAccountType === 'caller' && orgType()}
              {(currentAccountType === 'seeker'
                || (currentOrganizationType === 'congregation' && currentCongregation))
                && loginInfo()}
            </div>
          </Form>
        )}
      </div>
    </div>
  );
}

export default SignUp;

import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

function EditButton({ handler, label, tooltip = '' }: {
  handler: () => void,
  label: string,
  tooltip?: string,
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="left"
      overlay={(
        <Tooltip id="edit-button-tooltip">
          {tooltip || t('Edit_Action_Description')}
        </Tooltip>
      )}
    >
      <Button variant="link" className="linkactions" onClick={handler}>
        <FaEdit />
        {label && (<span>{label}</span>)}
      </Button>
    </OverlayTrigger>
  );
}

EditButton.defaultProps = {
  tooltip: '',
};

export default EditButton;

import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { Form, Table } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import api from '../../services/api.service';
import {
  trainingCertificationState,
} from '../../services/state.service';
import {
  TrainingCertificationData,
} from '../../types/education.types';
import {
  CertificateData,
  CertificateResponseData,
} from '../../types/mdp.types';

interface IData {
  id: number,
  description: string,
  institution: string,
  year: string,
}

type TrainingCertsCredsTableRowProps = {
  data: IData,
  trainingCertification: TrainingCertificationData,
  setTrainingCertification: Dispatch<SetStateAction<TrainingCertificationData>>,
}

function TrainingCertsCredsTableRow({
  data,
  trainingCertification,
  setTrainingCertification,
}: TrainingCertsCredsTableRowProps): JSX.Element {
  const { t } = useTranslation();
  const targetIndex = trainingCertification.trainingCertCredentials.findIndex((pt) => pt.certificate === data.id);
  const position = trainingCertification.trainingCertCredentials[targetIndex];

  const handlePositionClick = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setTrainingCertification((p: any) => ({
        ...p,
        trainingCertCredentials: p.trainingCertCredentials.concat({
          trainingCert: trainingCertification.id,
          certificate: data.id,
        }),
      }));
    } else {
      setTrainingCertification((p: any) => ({
        ...p,
        trainingCertCredentials: trainingCertification.trainingCertCredentials.filter(
          (pt: any) => pt.certificate !== position.certificate,
        ),
      }));
    }
  }, [setTrainingCertification, trainingCertification]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setTrainingCertification((p: any) => ({
      ...p,
      trainingCertCredentials: trainingCertification.trainingCertCredentials.map((pt: any) => (
        pt.certificate === position.certificate ? (
          { ...pt, [name]: value }
        ) : (
          pt
        )
      )),
    }));
  };

  return (
    <tr>
      <td className="align-middle">
        <Form.Check
          checked={targetIndex !== -1}
          label={data.description}
          onChange={handlePositionClick}
        />
      </td>
      <td>
        {targetIndex !== -1 && (
          <div>
            <Form.Group className="text-start">
              <Form.Control
                name="institution"
                placeholder={t('Academic_Institution_Placeholder')}
                value={position?.institution}
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>
        )}
      </td>
      <td>
        {targetIndex !== -1 && (
          <div>
            <Form.Group className="text-start">
              <Form.Control
                name="year"
                placeholder={t('Year_Obtained_placeholder')}
                value={position?.year}
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>
        )}
      </td>
    </tr>
  );
}

function TrainingCertsCredsTable(): JSX.Element {
  const { t } = useTranslation();
  const [trainingCertification, setTrainingCertification] = useRecoilState<TrainingCertificationData>(trainingCertificationState);
  const [certificateOptions, setCertificateOptions] = useState<CertificateData[]>([]);

  useEffect(() => {
    api.get('/certificates/').then(
      (response: CertificateResponseData) => (setCertificateOptions(response.data.options)),
    );
  }, []);

  return (
    <Table responsive className="mb-0">
      <tbody>
        {certificateOptions.map((option: any) => (
          <TrainingCertsCredsTableRow
            key={option.id}
            data={option}
            setTrainingCertification={setTrainingCertification}
            trainingCertification={trainingCertification}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default TrainingCertsCredsTable;

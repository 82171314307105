import {
  useState,
  useEffect,
} from 'react';
import { useRecoilState } from 'recoil';
import { ministryProfileStepsState } from '../services/state.service';
import { FormStepWizardData, DefaultFormStepWizardData } from '../types/generic.types';
import { MinistryProfileSteps } from '../constants/MinistryProfileSteps';
import FormStepWizard from '../components/generic/FormStepWizard';
import { CLC_FUNCTION } from '../types/constants';
import withUserAllowed from '../components/layout/withUserAllowed';

function MinistryProfileWizard(): JSX.Element {
  const [activeStep, setActiveStep] = useState<FormStepWizardData>(DefaultFormStepWizardData);
  const [steps, setSteps] = useRecoilState<FormStepWizardData[]>(ministryProfileStepsState);

  useEffect(() => {
    setSteps(MinistryProfileSteps);
    setActiveStep(MinistryProfileSteps[0]);
  }, []);

  return (
    <FormStepWizard
      steps={steps}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />
  );
}

export default withUserAllowed([CLC_FUNCTION.manageOrganization, CLC_FUNCTION.manageCongregationProfile].join(','))(MinistryProfileWizard);

import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from '../../services/api.service';

type ResetPasswordForm = {
  uid: string,
  token: string,
  new_password1: string,
  new_password2: string,
}

function ResetPassword(): JSX.Element {
  const { uid, token } = useParams();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const {
    setError,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<ResetPasswordForm>();

  const onSubmit: SubmitHandler<ResetPasswordForm> = (data: ResetPasswordForm) => (
    api.post('/password/reset/confirm/', data)
      .then(() => setSubmitted(true))
      .catch((e) => {
        const messages = e.response.data;
        if (messages.new_password1) {
          setError('new_password1', {
            type: 'server',
            message: messages.new_password1[0],
          });
        }
        if (messages.new_password2) {
          setError('new_password2', {
            type: 'server',
            message: messages.new_password2[0],
          });
        }
      })
  );

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 mb-5 text-center">
          <h1>
            {t('ResetPasswordHeader')}
          </h1>
          {submitted ? (
            <div className="row text-center">
              <h2>
                {t('ResetPasswordSubmitted')}
              </h2>
              <Link to="/">
                {t('ReturnToLogin')}
              </Link>
            </div>
          ) : (
            <h2>
              {t('ResetPasswordDescription')}
            </h2>
          )}
        </div>
        {!submitted && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" defaultValue={uid} {...register('uid')} />
            <input type="hidden" defaultValue={token} {...register('token')} />
            <div className="row">
              <Form.Group
                className="col-12 col-md-6 mb-3 mx-auto"
                controlId="formPassword"
              >
                <Form.Label className="required">
                  {t('NewPassword')}
                </Form.Label>
                <Form.Control
                  type="password"
                  isInvalid={!!errors.new_password1}
                  {...register('new_password1', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.new_password1 && errors.new_password1.message
                    ? errors.new_password1.message
                    : t('Login.PasswordRequired')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group
                className="col-12 col-md-6 mb-3 mx-auto"
                controlId="formPassword2"
              >
                <Form.Label className="required">
                  {t('ConfirmNewPassword')}
                </Form.Label>
                <Form.Control
                  type="password"
                  isInvalid={!!errors.new_password2}
                  {...register('new_password2', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.new_password2 && errors.new_password2.message
                    ? errors.new_password2.message
                    : t('Login.PasswordRequired')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="row text-center">
              <Button
                type="submit"
                variant="primary"
                className="login-submitbutton col-12 col-md-6 mb-3 mx-auto"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                ) : (
                  t('Submit')
                )}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;

import { Suspense, useEffect, useState } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import {
  appLoadingState,
  currentRoleState,
  presbyteryInfoState,
  searchOrganizationState,
  userProfileState,
} from '../services/state.service';
import { Role } from '../types/user.types';
import SystemNotificationAccordionItem from '../components/SystemNotificationAccordionItem';
import PersonalProfileAccordionItem from '../components/PersonalProfileAccordionItem';
import MinistryProfileAccordionItem from '../components/MinistryProfileAccordionItem';
import PersonalDiscernmentProfileAccordionItem from '../components/PersonalDiscernmentProfileAccordionItem';
import OrganizationMembersAccordionItem from '../components/OrganizationMembersAccordionItem';
import PDPFormAccordionItem from '../components/PDPFormAccordionItem';
import MDPFormAccordionItem from '../components/MDPFormAccordionItem';
import ResourceAccordionItem from '../components/ResourceAccordionItem';
import EcclesiasticalAccordionItem from '../components/EcclesiasticalAccordionItem';
import SessionClerksAccordionItem from '../components/SessionClerksAccordionItem';
import MDPApprovalAccordionItem from '../components/MDPApprovalAccordionItem';
import SuspenseLoading from '../components/generic/SuspenseLoading';
import SearchCommitteesAccordionItem from '../components/SearchCommitteesAccordionItem';
import OpportunityReferralsAccordionItem from '../components/OpportunityReferralsAccordionItem';
import CallSeekerAttestationAccordionItem from '../components/CallSeekerAttestationAccordionItem';
import SavedOpportunitiesAccordionItem from '../components/SavedOpportunitiesAccordionItem';
import api from '../services/api.service';
import {
  PresbyteryInfoData,
  PresbyteryInfoResponseData,
} from '../types/mdp.types';
import { SearchOrganizationData, SearchOrganizationResponseData } from '../types/ministry.types';

function Home(): JSX.Element {
  const appLoading = useRecoilValue(appLoadingState);
  const userProfile = useRecoilValue(userProfileState);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [pendingApproval, setPendingApproval] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [, setPresbyteryInfo] = useRecoilState<PresbyteryInfoData>(presbyteryInfoState);
  const [, setOrganizations] = useRecoilState<SearchOrganizationData[]>(searchOrganizationState);

  useEffect(() => {
    setPendingApproval(currentRole.abbreviation === 'COS' && userProfile?.cos_account_approved === false);
  }, [currentRole.abbreviation]);

  useEffect(() => {
    if (currentRole.organizationType === 'presbytery' || currentRole.organizationType === 'congregation') {
      api.get(`presbyteryinfo/${currentRole.presbytery}/`).then(
        (response: PresbyteryInfoResponseData) => {
          if (response.data.options) {
            setPresbyteryInfo(response.data.options);
          }
        },
      );
      api.get(`/presbyteryorganizations/${currentRole.presbytery}/`).then(
        (response: SearchOrganizationResponseData) => {
          if (response.data.options.length > 0) {
            setOrganizations(response.data.options);
          } else {
            setOrganizations([]);
          }
        },
      );
    }

    if (currentRole.organizationType === 'synod') {
      api.get(`/synodorganizations/${currentRole.synod}/`).then(
        (response: SearchOrganizationResponseData) => {
          if (response.data.options.length > 0) {
            setOrganizations(response.data.options);
          } else {
            setOrganizations([]);
          }
        },
      );
    }
  }, [currentRole]);

  useEffect(() => {
    if (appLoading === false && userProfile?.isLoggedIn === false) {
      navigate('/login');
    }
  }, [userProfile?.isLoggedIn]);

  if (appLoading) {
    return (
      <div className="container-fluid">
        <SuspenseLoading />
      </div>
    );
  }

  // check if COS account has been approved yet
  if (currentRole.abbreviation === 'COS' && pendingApproval) {
    return (
      <div className="container-fluid">
        <h1 className="text-center mt-3">{t('Pending_Approval')}</h1>
      </div>
    );
  }

  // redirect to profile wizard if user hasn't completed profile
  const isCallSeeker = userProfile?.roles.filter((p) => p.functions.indexOf('Call Seeker') > -1).length > 0;
  if (isCallSeeker && !userProfile?.profileCompleted) {
    return (
      <Navigate to="/profilewizard" />
    );
  }

  return currentRole && (
    <div className="container-fluid">
      <Accordion
        className="profile-panel accordion mb-3 mx-auto col-lg-11"
        defaultActiveKey={['0', '1', '2', '3', '4', '5', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G']}
        alwaysOpen
      >
        <SystemNotificationAccordionItem eventKey="0" />
        <Suspense fallback={<SuspenseLoading />}>
          <PersonalProfileAccordionItem eventKey="1" />
        </Suspense>
        <MinistryProfileAccordionItem eventKey="2" />
        <OrganizationMembersAccordionItem eventKey="4" />
        <CallSeekerAttestationAccordionItem eventKey="F" />
        <MDPApprovalAccordionItem eventKey="5" />
        <Suspense fallback={<SuspenseLoading />}>
          <SessionClerksAccordionItem eventKey="7" />
        </Suspense>
        <SearchCommitteesAccordionItem eventKey="8" />
        <EcclesiasticalAccordionItem eventKey="9" />
        <PersonalDiscernmentProfileAccordionItem eventKey="A" />
        <PDPFormAccordionItem eventKey="C" />
        <SavedOpportunitiesAccordionItem eventKey="G" />
        <OpportunityReferralsAccordionItem eventKey="B" />
        <MDPFormAccordionItem eventKey="D" />
        <ResourceAccordionItem eventKey="E" />
      </Accordion>
    </div>
  );
}

export default Home;

import { t } from 'i18next';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { Form, InputGroup } from 'react-bootstrap';

type InputProps = {
  data: any,
  formLabel: string,
  stateSetter: Dispatch<SetStateAction<any>>,
  inputName: string,
  inputType?: string,
  maxLength?: number,
  helpText?: string,
  required?: boolean,
  patternMatch?: any,
} & DefaultProps
type DefaultProps = Partial<typeof defaultProps>

const defaultProps = {
  inputType: 'text',
  maxLength: 500,
  helpText: '',
  required: true,
  patternMatch: '',
};

function CLCInput({
  data,
  formLabel,
  stateSetter,
  inputName,
  inputType,
  maxLength,
  helpText,
  required,
  patternMatch,
}: InputProps): JSX.Element {
  const [error, setError] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event?.target;
    const name = target?.name;
    const value = target?.type === 'checkbox' ? target.checked : target.value;

    stateSetter((x: any) => ({ ...x, [name]: value }));
  };

  const handleBlur = useCallback((): any => {
    const text = data[inputName];

    if (!text && required) {
      setValid(false);
      setError(`${formLabel} ${t('IsRequired')}`);
    } else if (patternMatch && !patternMatch.test(text)) {
      setValid(false);
      setError(`${t('Does_Not_Match_Pattern')}`);
    } else {
      setValid(true);
      setError('');
    }
  }, [data, patternMatch]);

  return (
    <div className="mb-4">
      <InputGroup>
        <Form.Group>
          <Form.Label className={`fw-bold${required ? ' required' : ''}`}>
            {formLabel}
          </Form.Label>
          <Form.Control
            type={inputType}
            value={data[inputName] ? data[inputName] : ''}
            name={inputName}
            maxLength={maxLength}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className={`input input--text${error ? ' input--error' : ''}`}
            isInvalid={!valid}
          />
          <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
          {helpText && (
            <Form.Text
              muted
              className="d-block"
            >
              {helpText}
            </Form.Text>
          )}
        </Form.Group>
      </InputGroup>
    </div>
  );
}

CLCInput.defaultProps = defaultProps;

export default CLCInput;

import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaFileMedical } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

function AddButton({ handler, label }: { handler: () => void, label: string }): JSX.Element {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="left"
      overlay={(
        <Tooltip id="{key}-tooltip">
          {t('Add_Matching_Criteria_Action_Description')}
        </Tooltip>
      )}
    >
      <Button variant="link" className="linkactions" onClick={handler}>
        <FaFileMedical />
        {label && (<span>{label}</span>)}
      </Button>
    </OverlayTrigger>
  );
}

export default AddButton;

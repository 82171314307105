import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from '../services/api.service';

export interface FAQData {
  id: number,
  displayOrder: number,
  question: string,
  answer: string,
}

export interface FAQResponseData {
  data: {
    faqs: FAQData[],
  }
}

function Faqs(): JSX.Element {
  const { t, i18n } = useTranslation();
  const [faq, setFaq] = useState<FAQData[]>([]);

  useEffect(() => {
    api.get('/faqs/', { headers: { 'Accept-Language': i18n.language } }).then(
      (response: FAQResponseData) => {
        setFaq(response.data.faqs);
      },
    );
  }, [i18n.language]);

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 mb-5 text-center">
          <h1>
            {t('FAQHeader')}
          </h1>
        </div>
        <Accordion>
          {faq
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((item) => (
              <Accordion.Item key={item.id} eventKey={item.id.toString()}>
                <Accordion.Header>
                  <span>{item.question}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <span>{item.answer}</span>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </div>
  );
}

export default Faqs;

import {
  useState,
  useEffect,
  ChangeEvent,
  Suspense,
  useRef,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
} from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import MDPPositionTypeTable from './MDPPositionTypeTable';
import ManageMatchingModalSliders from './ManageMatchingModalSliders';
import {
  EmploymentTypeData,
} from '../types/pdp.types';
import {
  MatchData,
} from '../types/mdp.types';
import {
  MIN_SALARY,
  MAX_SALARY,
  GAP_SALARY,
  CLC_FUNCTION,
} from '../types/constants';
import { tryParseInt } from '../utils';
import { currentRoleState, employmentTypeOptionsState, matchState } from '../services/state.service';
import SuspenseLoading from './generic/SuspenseLoading';
import { Role } from '../types/user.types';

type ManageMatchingModalProps = {
  callback: (_result: boolean, _match: MatchData) => void,
  show: boolean,
  hide: () => void,
}

function ManageMatchingModal({
  callback,
  show,
  hide,
}: ManageMatchingModalProps): JSX.Element {
  const { t } = useTranslation();
  const [match, setMatch] = useRecoilState<MatchData>(matchState);
  const employmentTypes = useRecoilValue<EmploymentTypeData[]>(employmentTypeOptionsState);
  const [valid, setValid] = useState<boolean>(false);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
  } = useForm<MatchData>();

  const submitRef = useRef<HTMLButtonElement>(null);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    setMatch({ ...match, [name]: value, mdp: match.mdp });
  };

  const onSubmit: SubmitHandler<MatchData> = () => {
    callback(true, match);
  };

  useEffect(() => {
    setAdvancedSearch(currentRole.functions.indexOf(CLC_FUNCTION.advancedSearch) > -1);
  }, [currentRole]);

  useEffect(() => {
    // reset form with user data
    if (match.id) reset(match);
  }, [match.id]);

  useEffect(() => {
    if (
      match.employmentType
      && match.positionTypes
      && match.maximumSalary
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [match]);

  return (
    <Modal fullscreen show={show} onHide={hide}>
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div className="col-lg-8 col-12 mx-auto">
          <div className="mx-auto">
            <div className="title">{t('Match_Criteria')}</div>
            <div className="col-lg-8 col-12 mx-auto">{t('Match_Description')}</div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="my-3">
                  <Form.Group className="text-start col-6">
                    <Form.Label className="bodyheader required">
                      {t('Employment_Status')}
                    </Form.Label>
                    <Form.Select
                      {...register('employmentType')}
                      onChange={handleSelectChange}
                      disabled={!advancedSearch}
                    >
                      <option key="" value="">
                        {t('Select_Employment')}
                      </option>
                      {(employmentTypes.map((option: any) => (
                        <option key={`employment-type-${option.id}`} value={option.id}>
                          {option.description}
                        </option>
                      )))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="my-3">
                  <Form.Group className="text-start">
                    <div className="mb-2 bodyheader required">
                      {t('Match_Position_Types')}
                    </div>
                    <div className="border border-bottom-0 rounded">
                      <Suspense fallback={<SuspenseLoading />}>
                        <MDPPositionTypeTable />
                      </Suspense>
                    </div>
                  </Form.Group>
                </div>
                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <Form.Label className="bodyheader required">
                      {t('Match_Maximum_Salary')}
                    </Form.Label>
                    <Form.Label className="bodyheader">
                      {`$${match.maximumSalary}`}
                    </Form.Label>
                    <Form.Range
                      value={match.maximumSalary}
                      min={MIN_SALARY}
                      max={MAX_SALARY}
                      step={GAP_SALARY}
                      {...register('maximumSalary', { required: true, valueAsNumber: true })}
                      onChange={(e: any) => setMatch((p: any) => ({
                        ...p, maximumSalary: tryParseInt(e.target.value),
                      }))}
                    />
                  </Form.Group>
                </div>
                <div className="my-3">
                  <Form.Group className="text-start col-12">
                    <Form.Check
                      type="checkbox"
                      id="no-matching"
                      label={t('No_Matching_Within_Presbytery')}
                      {...register('noMatchingWithinPresbytery')}
                      defaultChecked={match.noMatchingWithinPresbytery}
                      onChange={() => setMatch((p: any) => ({
                        ...p, noMatchingWithinPresbytery: !p.noMatchingWithinPresbytery,
                      }))}
                    />
                  </Form.Group>
                </div>
                <div>
                  <ManageMatchingModalSliders />
                </div>
              </div>
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                aria-label="submit"
              />
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => (callback(false, match))}
        >
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-2"
          onClick={() => submitRef.current?.click()}
          disabled={!valid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ManageMatchingModal;

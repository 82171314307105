import {
  useEffect,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import {
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  Table,
} from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { IData } from '../types/generic.types';
import {
  AttestationNoteData,
  AttestationHistoryData,
  AttestationHistoryResponseData,
} from '../types/ministry.types';
import { attestationNoteDataState } from '../services/state.service';
import api from '../services/api.service';

interface AttestationModalData extends IData {
  userId: number,
  status: string,
  note: string,
}

const defaultAttestationModalData: AttestationModalData = {
  userId: 0,
  status: '',
  note: '',
};

function AttestationModal({
  show,
  userId,
  callback,
}: {
  show: boolean,
  userId: number,
  callback: (_result: boolean) => void,
}): JSX.Element {
  const { t } = useTranslation();
  const noteOptions = useRecoilValue<AttestationNoteData[]>(attestationNoteDataState);
  const [isValid, setIsValid] = useState<boolean>(false);
  const {
    setValue,
    reset,
    watch,
    register,
    formState: {
      errors,
    },
  } = useForm<AttestationModalData>();
  const currentStatus = watch('status');
  const currentNote = watch('note');
  const [attestationHistory, setAttestationHistory] = useState<AttestationHistoryData[]>([]);

  useEffect(() => {
    // Call API to get latest attestation data if it exists
    if (userId > 0) {
      api.get(`/attestationhistory/${userId}/`)
        .then(
          (response: AttestationHistoryResponseData) => setAttestationHistory(response.data.attestations),
        );
    }
    reset(defaultAttestationModalData);
  }, [userId]);

  useEffect(() => {
    setIsValid(() => currentStatus === 'Yes' || (currentStatus === 'No' && currentNote !== ''));
  }, [currentStatus, currentNote]);

  function handleClose(result: boolean): void {
    if (result) {
      const payload = new FormData();
      payload.append('status', currentStatus === 'Yes' ? 'true' : 'false');
      payload.append('note', currentNote);
      api.post(`/attestationhistory/${userId}/`, payload)
        .then(() => callback(result));
    } else {
      callback(result);
    }
  }

  function renderAttestationHistory(): JSX.Element {
    return attestationHistory.length > 0 ? (
      <>
        <hr />
        <div className="title">{t('AttestationHistory')}</div>
        <Table responsive hover className="mb-3 linktable">
          <thead>
            <tr className="linktableheader">
              <th>
                {t('AttestationDate')}
              </th>
              <th>
                {t('EcclesiasticalStatusAtAttestation')}
              </th>
              <th>
                {t('AttestationStatus')}
              </th>
              <th>
                {t('AttestationNote')}
              </th>
              <th>
                {t('AttestedBy')}
              </th>
            </tr>
          </thead>
          <tbody>
            {attestationHistory?.map((atl: AttestationHistoryData) => (
              <tr key={`attestations-${atl.id}`} className="dividinglines">
                <td>{atl.attestedDate}</td>
                <td>{atl.ecclesiasticalStatus}</td>
                <td>{atl.attestationStatus}</td>
                <td>{atl.attestationNote}</td>
                <td>{atl.attestedBy}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    ) : (
      <div>&nbsp;</div>
    );
  }

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      className="modalstyle"
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('AttestUser')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate className="mb-3">
          <Form.Group className="text-center">
            <div>{t('AttestationStatement')}</div>
            <div className="my-3">
              <Form.Check
                type="radio"
                id="attestation-status-1"
                label={t('Yes')}
                className="form-check-inline"
                {...register('status', { required: true })}
                value="Yes"
              />
              <Form.Check
                type="radio"
                id="attestation-status-2"
                label={t('No')}
                className="form-check-inline"
                {...register('status', { required: true })}
                value="No"
              />
              {currentStatus === 'No' && (
                <Form.Group
                  className="mt-4"
                >
                  <Form.Label
                    className="mb-3 bodyheader required"
                  >
                    {t('Comments')}
                  </Form.Label>
                  <Form.Select
                    isInvalid={!!errors.note}
                    {...register('note', { required: true })}
                    onChange={(e) => setValue('note', e.target.value)}
                  >
                    <option key="" value="">{t('SelectReason')}</option>
                    {(noteOptions?.map((option: any) => (
                      <option key={`note-${option.note}`} value={option.note}>
                        {option.description}
                      </option>
                    )))}
                  </Form.Select>
                </Form.Group>
              )}
            </div>
          </Form.Group>
        </Form>
        { renderAttestationHistory() }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handleClose(false)}>
          {t('PDP.Cancel')}
        </Button>
        <Button
          type="submit"
          variant="primary"
          className="col-3"
          onClick={() => handleClose(true)}
          disabled={!isValid}
        >
          {t('PDP.Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AttestationModal;

import Accordion from 'react-bootstrap/Accordion';
import { FaFolderOpen } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import { currentRoleState } from '../services/state.service';
import { Role } from '../types/user.types';
import MDPMidCouncilPanel from '../pages/MDPMidCouncilPanel';

function MDPFormAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const currentRole = useRecoilValue<Role>(currentRoleState);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaFolderOpen />
        <span className="accordion-header-label">
          {t('MDP._Accordion_Title')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="row">
          <MDPMidCouncilPanel />
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed(CLC_FUNCTION.approveMDP)(MDPFormAccordionItem);

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from '../../services/api.service';

type ForgotPasswordForm = {
  email: string,
}

function ForgotPassword(): JSX.Element {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const {
    setError,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    },
  } = useForm<ForgotPasswordForm>();

  const onSubmit: SubmitHandler<ForgotPasswordForm> = (data: ForgotPasswordForm) => (
    api.post('/password/reset/', data)
      .then(() => setSubmitted(true))
      .catch((e) => {
        const messages = e.response.data;
        if (messages.email) {
          setError('email', {
            type: 'server',
            message: messages.email[0],
          });
        }
      })
  );

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 mb-5 text-center">
          <h1>
            {t('ForgotPasswordHeader')}
          </h1>
          {submitted ? (
            <div className="row text-center">
              <h2>
                {t('ForgotPasswordSubmitted')}
              </h2>
              <Link to="/">
                {t('ReturnToLogin')}
              </Link>
            </div>
          ) : (
            <h2>
              {t('ForgotPasswordDescription')}
            </h2>
          )}
        </div>
        {!submitted && (
          <div className="col-12 mb-5 text-center">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <Form.Group
                  className="col-12 col-md-6 mb-3 mx-auto"
                  controlId="formEmail"
                >
                  <Form.Label className="required">
                    {t('Email')}
                  </Form.Label>
                  <Form.Control
                    type="input"
                    placeholder={t('EmailPlaceholder')}
                    isInvalid={!!errors.email}
                    {...register('email', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email && errors.email.message
                      ? errors.email.message
                      : t('InvalidEmail')}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="row text-center">
                <Button
                  type="submit"
                  className="login-submitbutton col-12 col-md-6 mb-3 mx-auto"
                  variant="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      animation="border"
                      size="sm"
                    />
                  ) : (
                    t('Submit')
                  )}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;

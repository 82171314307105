import {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useRecoilState } from 'recoil';
import { Editor } from '@tinymce/tinymce-react';
import MDPService from '../services/mdp.service';
import {
  MDPData,
  MDPResponseData,
  MinistryRequirementsData,
  MinistryRequirementsResponseData,
} from '../types/mdp.types';
import api from '../services/api.service';
import SanitizeHTML from '../services/html.service';
import { mdpStepState, mdpState } from '../services/state.service';
import { useStepValid } from '../hooks/useStepValid';
import AlertModal from './generic/AlertModal';
import { cleanText } from '../utils';

function MinistryRequirements(): JSX.Element {
  const initialData = {
    mdp: undefined,
    id: undefined,
    vision: '',
    tasks: '',
    initial: true,
  };

  const { t } = useTranslation();
  const editorRefVision = useRef<any>(null);
  const editorRefTask = useRef<any>(null);
  const [mdp, setMDP] = useRecoilState<MDPData>(mdpState);
  const [ministryRequirements, setMinistryRequirements] = useState<MinistryRequirementsData>(initialData);
  const [newRequirements, setNewRequirements] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    MDPService.GetMDPData(mdp.id).then(
      (response: MDPResponseData) => (setMDP(response.data)),
    );

    api.get(`/mdp/${mdp.id}/ministryrequirements/`).then(
      (response: MinistryRequirementsResponseData) => {
        if (response.data.length > 0) {
          setMinistryRequirements(response.data[0]);
          setNewRequirements(false);
        }
      },
    );
  }, [setMinistryRequirements, mdp.id, setMDP]);

  const requiredFields = useMemo(() => ['vision', 'tasks'], []);
  const valid = useStepValid('ministryRequirements', ministryRequirements, requiredFields, mdpStepState);

  const saveRequirements = (): void => {
    if (valid) {
      if (newRequirements) {
        api.post(`/mdp/${mdp.id}/ministryrequirements/`, ministryRequirements);
      } else {
        api.put(`/mdp/${mdp.id}/ministryrequirements/${ministryRequirements.id}/`, ministryRequirements);
      }
    }
  };

  const log = (statement: string): any => {
    const characterLimit = 2000;

    if (editorRefTask.current !== null) {
      if (statement === 'tasks') {
        const cleanedText = cleanText(editorRefTask.current.getContent());
        if (cleanedText.length > characterLimit) {
          setShowAlert(true);
        }
        setMinistryRequirements((p: any) => ({
          ...p, tasks: editorRefTask.current.getContent(),
        }));
      }
    }

    if (editorRefVision.current !== null) {
      if (statement === 'vision') {
        const cleanedText = cleanText(editorRefVision.current.getContent());
        if (cleanedText.length > characterLimit) {
          setShowAlert(true);
        }
        setMinistryRequirements((p: any) => ({
          ...p, vision: editorRefVision.current.getContent(),
        }));
      }
    }
  };

  useEffect(() => {
    saveRequirements();
  }, [ministryRequirements, valid]);

  return (
    <div className="col-lg-8 col-12 mx-auto">
      <AlertModal
        show={showAlert}
        title={t('Character_Limit_Exceeded')}
        description={t('1500_Char_Limit_Description')}
        closeLabel={t('OK')}
        callback={() => setShowAlert(!showAlert)}
      />
      <div className="mx-auto">
        <div className="title">{t('Ministry_Requirements')}</div>
        <SanitizeHTML html={t('PDP.Required_Fields_Notice_HTML')} />
      </div>
      <div className="my-4 col-12">
        <Form.Group className="text-start">
          <Form.Label className="bodyheader required">
            {t('Church_Mission')}
          </Form.Label>
          <Form.Label>
            {t('Character_Limit')}
          </Form.Label>
          <Form>
            <Editor
              onInit={(evt: any, editor: any): any => { editorRefVision.current = editor; }}
              initialValue={ministryRequirements?.vision}
              init={{
                menubar: false,
                statusbar: false,
                branding: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code autoresize',
                ],
                toolbar: 'fontselect | formatselect | '
                  + 'alignleft aligncenter alignright alignjustify |'
                  + 'bold italic strikethrough underline | forecolor backcolor link bullist numlist',
                contextmenu: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                init_instance_callback(editor: any) {
                  editor.on('blur', () => log('vision'));
                },
              }}
            />
          </Form>
        </Form.Group>
      </div>
      <div className="my-4 col-12">
        <Form.Group className="text-start">
          <Form.Label className="bodyheader required">
            {t('Tasks_Expectations')}
          </Form.Label>
          <Form.Label>
            {t('Character_Limit')}
          </Form.Label>
          <Form>
            <Editor
              onInit={(evt: any, editor: any): any => { editorRefTask.current = editor; }}
              initialValue={ministryRequirements?.tasks}
              init={{
                menubar: false,
                statusbar: false,
                branding: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code autoresize',
                ],
                toolbar: 'fontselect | formatselect | '
                  + 'alignleft aligncenter alignright alignjustify |'
                  + 'bold italic strikethrough underline | forecolor backcolor link bullist numlist',
                contextmenu: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                init_instance_callback(editor: any) {
                  editor.on('blur', () => log('tasks'));
                },
              }}
            />
          </Form>
        </Form.Group>
      </div>
    </div>
  );
}

export default MinistryRequirements;

import {
  useEffect,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  Link,
} from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import {
  Col,
  Form,
  Row,
  Button,
  Modal,
  Card,
} from 'react-bootstrap';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import api from '../services/api.service';
import { tryParseInt } from '../utils';
import {
  MDPResultListData,
  MDPDetailsResponseData,
  MDPResultListDefaultData,
  MDPResultListResponseData,
  OpportunitySearchQueryData,
  ViewMDPResponseData,
  FavoriteData,
  FavoriteResponseData,
  PDPMatchData,
  PDPMatchListResponseData,
} from '../types/mdp.types';
import MDPService, {
  IPositionTypeList,
  IPositionTypeListResponse,
  RequirementsData,
  LanguageData,
  LanguageResponseData,
} from '../services/mdp.service';
import PDPService from '../services/pdp.service';
import {
  MinistryProfile,
} from '../types/ministry.types';
import {
  ministryProfileState,
  userProfileState,
  statesListOptionsState,
} from '../services/state.service';
import {
  PDPData,
  ActivePDPListResponseData,
  AppliedPDPDefault,
  AppliedPDP,
} from '../types/pdp.types';
import { UserProfile } from '../types/user.types';
import StarButton from '../components/generic/StarButton';
import AlertModal from '../components/generic/AlertModal';
import Pagination from '../components/Pagination';
import { SelectOption } from '../types/generic.types';

function OpportunitySearch(): JSX.Element {
  const PositionTypeDefaultData = {
    id: 0,
    value: '',
    positionType: '',
  };
  const { t } = useTranslation();
  const [mdpDetails, setMDPDetails] = useState<MDPResultListData>(MDPResultListDefaultData);
  const [mdpResults, setMDPResults] = useState<MDPResultListData[]>([]);
  const [positionTypes, setPositionTypes] = useState<IPositionTypeList[]>([]);
  const [currentPositionType, setCurrentPositionType] = useState<IPositionTypeList>(PositionTypeDefaultData);
  const [ministryProfile, setMinistryProfileState] = useRecoilState<MinistryProfile>(ministryProfileState);
  const [requirements, setRequirements] = useState<RequirementsData>();
  const [languageOptions, setLanguageOptions] = useState<LanguageData[]>([]);
  const [favorites, setFavorites] = useState<FavoriteData[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [starRefresh, setStarRefresh] = useState(false);
  const [list, setList] = useState<PDPData[]>([]);
  const [sort, setSort] = useState<boolean>(true);
  const [pdpMatchList, setPDPMatchList] = useState<PDPMatchData[]>([]);
  const [appliedPDP, setAppliedPDP] = useState<AppliedPDP>(AppliedPDPDefault);
  const location = useLocation();
  const history = useNavigate();
  const navigate = useNavigate();
  const [results, setResults] = useState<number>(0);
  const [active, setActive] = useState('');
  const [showNotLoggedInDialogue, setShowNotLoggedInDialogue] = useState(false);
  const [showLoggedInDialogue, setShowLoggedInDialogue] = useState(false);
  const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
  const [showEccRestrictedDialogue, setShowEccRestrictedDialogue] = useState(false);
  const [showStatementOfFaithDialogue, setShowStatementOfFaithDialogue] = useState(false);
  const [showPositionTypeDialogue, setShowPositionTypeDialogue] = useState(false);
  const [statementOfFaithError, setStatementOfFaithError] = useState<boolean>(false);
  const [showDeadlineDialogue, setShowDeadlineDialogue] = useState(false);
  const statesListOptions = useRecoilValue<SelectOption[]>(statesListOptionsState);
  const userProfile = useRecoilValue<UserProfile>(userProfileState);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const currency = Intl.NumberFormat('en-US');
  const {
    reset,
    register,
    handleSubmit,
  } = useForm<OpportunitySearchQueryData>();

  const handleSelectChange = (id: number | undefined, value: string): void => {
    setCurrentPositionType({ ...currentPositionType, id, value });
  };

  const renderPagination = (): JSX.Element[] => {
    const pages = [];
    const maxVisiblePages = 3;
    for (let i = 1; i <= totalPages; i += 1) {
      if (i === 1 || totalPages === 1
          || (i >= currentPage - maxVisiblePages && i <= currentPage + maxVisiblePages)
      ) {
        pages.push(
          <Button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={i === currentPage ? 'active' : ''}
          >
            {i}
          </Button>,
        );
      } else if (i === currentPage - maxVisiblePages - 1 || i === currentPage + maxVisiblePages + 1) {
        pages.push(<Button key={`ellipsis-${i}`}>...</Button>);
      }
    }
    return pages;
  };

  const onSubmit: SubmitHandler<OpportunitySearchQueryData> = (data: OpportunitySearchQueryData) => {
    let url = '?';

    if (data.positionType > 0) {
      url = url.concat(`positionType=${data.positionType}&`);
    }
    if (data.city !== '') {
      url = url.concat(`city=${data.city}&`);
    }
    if (data.state !== '') {
      url = url.concat(`state=${data.state}&`);
    }
    if (data.presbytery !== '') {
      url = url.concat(`presbytery=${data.presbytery}&`);
    }
    if (data.church !== '') {
      url = url.concat(`church=${data.church}&`);
    }
    if (data.intercultural !== '') {
      url = url.concat(`intercultural=${data.intercultural}&`);
    }

    history(url.slice(0, -1));
  };

  const statesMenu = statesListOptions?.map((option: SelectOption) => (
    <option key={option.id} value={option.id}>
      {option.value}
    </option>
  ));

  const positionTypeMenu = positionTypes.map((option: any) => (
    <option key={`position-type-${option.id}`} value={option.id}>
      {option.value}
    </option>
  ));

  function sortByRelasedDate(): void {
    setSort(!sort);
    setRefresh((r: boolean) => !r);
  }

  function getMDPDetail(id: string): void {
    MDPService.GetMDPDetails(id).then(
      (response: MDPDetailsResponseData) => {
        if (response.data) {
          setMDPDetails(response.data[0]);
        }
      },
    );
    api.get(`/viewmdp/${id}/`).then(
      (response: ViewMDPResponseData) => {
        if (response.data) {
          setRequirements(response.data.requirements);
          setMinistryProfileState(response.data.ministryProfile);
        }
      },
    );
    api.get('/languages/').then(
      (response: LanguageResponseData) => (setLanguageOptions(response.data.options)),
    );
  }

  function saveOpportunity(mdp: MDPResultListData): void {
    MDPService.SubmitOpportunity(mdp).then(
      () => setStarRefresh((r: boolean) => !r),
    );
  }

  function removeOpportunity(mdp: MDPResultListData): void {
    MDPService.DeleteOpportunity(mdp.id).then(
      () => setStarRefresh((r: boolean) => !r),
    );
  }

  function applyToMDP(mdpId: string, deadline: string): void {
    const mdpDeadline = new Date(deadline);
    mdpDeadline.setMinutes(mdpDeadline.getMinutes() + mdpDeadline.getTimezoneOffset());
    const current = new Date();
    if (current < mdpDeadline || deadline === '') {
      if (userProfile.isLoggedIn) {
        setShowLoggedInDialogue(true);
        PDPService.GetActivePDPList().then(
          (response: ActivePDPListResponseData) => {
            setList(response.data.options);
            setAppliedPDP((p) => ({
              ...p,
              mdp: mdpId,
            }));
          },
        );
      } else {
        setShowNotLoggedInDialogue(true);
      }
    } else {
      setShowDeadlineDialogue(true);
    }
  }

  function submitAppliedPDP(): void {
    if (appliedPDP.pdp) {
      MDPService.SubmitAppliedPDPs(appliedPDP).then(
        (response: any) => {
          setRefresh((r: boolean) => !r);
          if (response.data.toString() === '-1') {
            setShowEccRestrictedDialogue(true);
          } else if (response.data.toString() === '-2') {
            setStatementOfFaithError(true);
            setShowStatementOfFaithDialogue(true);
          } else if (response.data.toString() === '-3') {
            setShowPositionTypeDialogue(true);
          } else {
            setStatementOfFaithError(false);
            setShowSuccessDialogue(true);
          }
        },
      );
    }
    setShowLoggedInDialogue(false);
    setAppliedPDP((p) => ({ ...p, pdp: '' }));
  }

  useEffect(() => {
    if (userProfile?.isLoggedIn) {
      api.get('savedopportunity/').then(
        (response: FavoriteResponseData) => {
          setFavorites(response.data);
        },
      );
    }
  }, [starRefresh, userProfile]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const data = {
      positionType: tryParseInt(params.get('positionType') || '') || 0,
      city: params.get('city') || '',
      state: params.get('state') || '',
      presbytery: params.get('presbytery') || '',
      church: params.get('church') || '',
      intercultural: params.get('intercultural') || '',
      pageNumber: currentPage || 1,
      sortMDPs: sort,
    };

    reset(data);
    setCurrentPositionType({ ...currentPositionType, id: data.positionType });

    MDPService.SubmitMDPQuery(data)
      .then((response: MDPResultListResponseData) => {
        setMDPResults(response.data.options);
        setResults(response.data.options.length);
        if (response.data.options.length > 0) {
          getMDPDetail(response.data.options[0].id);
          setActive(response.data.options[0].id);
        }
        setTotalPages(response.data.totalPages);
        setTotalResults(response.data.totalResults);
      });

    api.get('/positiontypelist/').then(
      (response: IPositionTypeListResponse) => (setPositionTypes(response.data.options)),
    );

    if (userProfile?.isLoggedIn) {
      MDPService.GetAppliedPDPs()
        .then((response: PDPMatchListResponseData) => {
          setPDPMatchList(response.data.options);
        });
    }
  }, [refresh, location, userProfile, currentPage]);

  return (
    <div className="container-fluid mb-5">
      <AlertModal
        show={showStatementOfFaithDialogue}
        title={t('Statement_Of_Faith_Required_Title')}
        description={t('Statement_Of_Faith_Required_Dialogue')}
        closeLabel={t('OK')}
        callback={() => setShowStatementOfFaithDialogue(!showStatementOfFaithDialogue)}
      />
      <AlertModal
        show={showEccRestrictedDialogue}
        title={t('Restricted_Position_Title')}
        description={t('Restricted_Position_Description')}
        closeLabel={t('OK')}
        callback={() => setShowEccRestrictedDialogue(!showEccRestrictedDialogue)}
      />
      <AlertModal
        show={showDeadlineDialogue}
        title={t('Application_Deadline_Title')}
        description={t('Application_Deadline_Passed')}
        closeLabel={t('OK')}
        callback={() => setShowDeadlineDialogue(!showDeadlineDialogue)}
      />
      <AlertModal
        show={showPositionTypeDialogue}
        title={t('Position_Type_Required_Title')}
        description={t('Position_Type_RequiredDialogue')}
        closeLabel={t('OK')}
        callback={() => setShowPositionTypeDialogue(!showPositionTypeDialogue)}
      />
      <AlertModal
        show={showSuccessDialogue}
        title={t('Applied_Success_Title')}
        description={t('Applied_Success_Description')}
        closeLabel={t('OK')}
        callback={() => setShowSuccessDialogue(!showSuccessDialogue)}
      />
      { userProfile.isLoggedIn ? (
        <Modal
          show={showLoggedInDialogue}
          onHide={() => setShowLoggedInDialogue(false)}
          animation={false}
          className="modalstyle"
          backdrop="static"
        >
          {list.length > 0 ? (
            <>
              <Modal.Header closeButton />
              <Modal.Body>
                <div className="col-md-12 text-center my-5">
                  <div className="title">{t('Active_PDPs')}</div>
                </div>
                <div className="col-md-12 text-center my-5">
                  <Form.Select
                    onChange={(e) => setAppliedPDP((p) => ({
                      ...p,
                      pdp: e.target.value.toString(),
                    }))}
                  >
                    <option key="" value="">{t('Select_PDP')}</option>
                    {(list?.map((pdp: PDPData) => (
                      <option key={`${pdp.id}-active-pdp`} value={pdp.id}>
                        {`${pdp.id}: ${pdp.positionTypeDescription}`}
                      </option>
                    )))}
                  </Form.Select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="col-md-12 text-center">
                  <Button
                    className="px-5 "
                    variant="primary"
                    onClick={() => submitAppliedPDP()}
                  >
                    {t('OK')}
                  </Button>
                </div>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header closeButton />
              <Modal.Body>
                <div className="col-md-12 text-center my-5">
                  <div className="title">{t('No_Active_PDPs')}</div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="col-md-12 text-center">
                  <Button
                    className="px-5 "
                    variant="primary"
                    onClick={() => submitAppliedPDP()}
                  >
                    {t('OK')}
                  </Button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        <Modal
          show={showNotLoggedInDialogue}
          onHide={() => setShowNotLoggedInDialogue(false)}
          animation={false}
          className="modalstyle"
          backdrop="static"
        >
          <Modal.Body>
            <div className="col-md-12 text-center my-5">
              {t('Opportunity_Search_Not_Logged_In')}
            </div>
            <div className="col-md-12 text-center my-5">
              <a href="/signup">
                {t('Click_Here')}
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12 text-center">
              <Button
                className="px-5 "
                variant="primary"
                onClick={() => (setShowNotLoggedInDialogue(false))}
              >
                {t('OK')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <div className="profile-panel accordion mb-3 mx-auto col-lg-11">
        <Link to="/" className="dashboard-link">
          <FaLongArrowAltLeft />
          <span>{t('Back_to_Dashboard')}</span>
        </Link>
        <div className="title text-center">
          {t('Opportunity_Search_Title')}
        </div>
        <Row className="justify-content-center text-center">
          <Col xs="12" md="8" className="mb-4">{t('Opportunity_Search_Instructions')}</Col>
        </Row>
        <Row className="justify-content-center text-center">
          <Col xs="12" md="8" className="mb-4">
            {t('Opportunity_Search_Link')}
            <a
              href="https://oga.pcusa.org/section/ecclesial-and-ecumenical-ministries/ecumenical-and-agency-relationships/about-us/"
            >
              {t('Ecumenical_Relations_Office')}
            </a>
          </Col>
        </Row>
        <div className="mb-3 my-3 mx-auto mobiletable col-lg-12">
          {/* Church/Organization Filters */}
          <div>
            <Form id="opportunitysearch-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row my-4">
                <div className="col-5">
                  <div className="text-start fw-bold h6">
                    {t('PDP.Position_Type_Title')}
                  </div>
                  <Form.Group className="text-start col-12">
                    <Form.Select
                      value={currentPositionType?.id || ''}
                      {...register('positionType')}
                      onChange={(e) => handleSelectChange(
                        tryParseInt(e.target.value),
                        e.target.options[e.target.selectedIndex].text,
                      )}
                    >
                      <option key="" value="">
                        {t('Select_Position')}
                      </option>
                      {positionTypeMenu}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-2">
                  <div className="text-start fw-bold h6">
                    {t('City')}
                  </div>
                  <Form.Group>
                    <Form.Control
                      {...register('city')}
                    />
                  </Form.Group>
                </div>
                <div className="col-2">
                  <div className="text-start fw-bold h6">
                    {t('State')}
                  </div>
                  <Form.Group>
                    <Form.Select
                      {...register('state')}
                    >
                      <option key="" value="">
                        {t('Select_State')}
                      </option>
                      {statesMenu}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-3 mt-auto">
                  {results > 0
                    ? (
                      <Button
                        type="submit"
                        variant="primary"
                      >
                        {t('Update_Search')}
                      </Button>
                    )
                    : (
                      <Button
                        type="submit"
                        variant="primary"
                      >
                        {t('Search')}
                      </Button>
                    )}
                </div>
              </div>
              <div className="row my-4">
                <div className="col-3">
                  <div className="text-start fw-bold h6">
                    {t('Presbytery')}
                  </div>
                  <Form.Group>
                    <Form.Control
                      {...register('presbytery')}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <div className="text-start fw-bold h6">
                    {t('Church')}
                  </div>
                  <Form.Group>
                    <Form.Control
                      {...register('church')}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <div className="text-start fw-bold h6">
                    {t('InterculturalMinistry')}
                  </div>
                  <Form.Group>
                    <Form.Select
                      {...register('intercultural')}
                    >
                      <option key="" value="">
                        {t('SelectOne')}
                      </option>
                      <option key="yes" value="true">
                        {t('Yes')}
                      </option>
                      <option key="no" value="false">
                        {t('No')}
                      </option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-3 sortbutton">
                  <Form.Check
                    checked={sort}
                    type="switch"
                    id="custom-switch"
                    label={t('Sort_By_Relased_Date')}
                    onChange={() => sortByRelasedDate()}
                  />
                </div>
              </div>
              <Pagination
                totalResults={totalResults}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                renderPagination={renderPagination}
              />
              <div>
                <hr className="mdpdivider my-3" />
              </div>
            </Form>
          </div>
          {/* MDP Results */}
          <div className="row">
            <div className="col-md-4">
              {mdpResults?.map((option: MDPResultListData) => (
                <Card
                  key={option.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => { getMDPDetail(option.id); setActive(option.id); }}
                  className={`${option.id === active ? 'active-card' : ''} no-radius`}
                >
                  <Card.Body>
                    <Card.Title className="bodyheader">
                      {option.positionType ? option.positionType : ''}
                      {option.positionTitle ? ` (${option.positionTitle})` : ''}
                    </Card.Title>
                    <Card.Text className="mt-3 mb-0 fw-bold">
                      {option.organizationName}
                    </Card.Text>
                    <Card.Text className="fw-bold">
                      {`${option.city}, ${option.state}`}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </div>
            {mdpResults.length > 0 && mdpDetails.id ? (
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-9">
                    <h5 className="ms-3 mb-3 bodyheader">
                      {mdpDetails.positionType ? mdpDetails.positionType : ''}
                      {mdpDetails.positionTitle ? ` (${mdpDetails.positionTitle})` : ''}
                    </h5>
                    <h5 className="ms-3 mb-2 fw-bold">{mdpDetails.organizationName}</h5>
                    <h5 className="ms-3 mb-2 fw-bold">{`${mdpDetails.city}, ${mdpDetails.state}`}</h5>
                  </div>
                  {userProfile?.isLoggedIn && (
                    <div className="col-md-1">
                      <StarButton
                        handler={() => (favorites.filter((p) => p.id === mdpDetails.id).length > 0 ? (
                          removeOpportunity(mdpDetails)
                        ) : (
                          saveOpportunity(mdpDetails)
                        )
                        )}
                        label=""
                        isSaved={favorites.filter((p) => p.id === mdpDetails.id).length > 0}
                      />
                    </div>
                  )}
                  <div className="col-md-2">
                    <Button
                      variant="primary"
                      onClick={() => applyToMDP(mdpDetails.id, mdpDetails.deadline)}
                      disabled={
                        pdpMatchList.filter((p) => p.mdpId.toString() === mdpDetails.id.toString()
                          && p.selfReferred).length > 0 && !statementOfFaithError
                        }
                    >
                      {pdpMatchList.filter((p) => p.mdpId.toString() === mdpDetails.id.toString()
                        && p.selfReferred).length > 0 && !statementOfFaithError ? (
                          t('Applied')
                        ) : (
                          t('Apply')
                        )}
                    </Button>
                  </div>
                </div>
                <div className="mt-5 mb-4 mdp-details-block text-start">
                  <div className="mb-3 row">
                    <div className="col-6">
                      <div className="bodyheader">
                        {t('Ministry_Name')}
                      </div>
                      <div>
                        {ministryProfile.ministryName}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="bodyheader">
                        {t('Released_Date')}
                      </div>
                      <div>
                        {mdpDetails.released ? new Intl.DateTimeFormat('en-US').format(new Date(mdpDetails.released)) : ''}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-6">
                      <div className="bodyheader">
                        {t('MDP.Experience_Required')}
                      </div>
                      <div>
                        {mdpDetails.experienceLevel}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bodyheader">
                        {t('PDP.Community')}
                      </div>
                      <div>
                        {mdpDetails.communityType}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="bodyheader">
                        {t('Congregation_Size')}
                      </div>
                      <div>
                        {mdpDetails.congregationSize}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bodyheader">
                        {t('PDP.Min_Expected_Salary')}
                      </div>
                      <div>
                        {mdpDetails.minimumExpectedSalary ? (
                          `$${currency.format(mdpDetails.minimumExpectedSalary)}`
                        ) : (
                          t('NotSpecified')
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <h5 className="ms-3 bodyheader">
                  {t('Information_About_Position')}
                </h5>
                {/* Position Requirements */}
                <div className="border border-dark rounded-3 text-start mb-3">
                  <div className="row">
                    <div className="col-6">
                      <div className="my-4  mx-3">
                        <div className="mb-2 reviewtitle">
                          {t('Position_Types')}
                        </div>
                        {requirements?.positionTypeDescription}
                      </div>
                      <div className="my-4  mx-3">
                        <div className="mb-2 reviewtitle">
                          {t('Employment_Status')}
                        </div>
                        {requirements?.employmentTypeDescription}
                      </div>
                    </div>
                    <div className="col-6">
                      {requirements?.othercert && (
                        <div className="my-4  mx-3">
                          <div className="mb-2 reviewtitle">
                            {t('Other_Training')}
                          </div>
                          {requirements?.othercert}
                        </div>
                      )}
                      <div className="my-4  mx-3">
                        <div className="mb-2 reviewtitle">
                          {t('Language_Requirements')}
                        </div>
                        <div>
                          {requirements?.language.map((option: any) => (
                            <div key={`language-${option}`}>
                              {languageOptions.map((item: any) => (
                                (item.id === option)
                                  ? (
                                    <div key={item.description}>
                                      {item.description}
                                    </div>
                                  )
                                  : null))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  onClick={() => (
                    navigate(`/mdp/${mdpDetails.id}/view/`)
                  )}
                >
                  {t('View_MDP')}
                </Button>

              </div>
            ) : (
              <div className="col-md-12 text-center">
                <div>{t('NoDetails')}</div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12 my-2">
              <Pagination
                totalResults={totalResults}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                renderPagination={renderPagination}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpportunitySearch;

import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from '../../services/api.service';

type ConfirmEmailForm = {
  key: string,
}

function ConfirmEmail(): JSX.Element {
  const { key } = useParams();
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [confirmError, setConfirmError] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: {
      isSubmitting,
    },
  } = useForm<ConfirmEmailForm>();

  const onSubmit: SubmitHandler<ConfirmEmailForm> = (data: ConfirmEmailForm) => (
    api.post('/register/verify-email/', { key: data.key })
      .then(() => setConfirmed(true))
      .catch(() => setConfirmError(t('UnableToVerifyEmail')))
  );

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 mb-5 text-center">
          <h1>
            {t('ConfirmEmailHeader')}
          </h1>
          {confirmed ? (
            <div className="row text-center">
              <h2>
                {t('ConfirmEmailSubmitted')}
              </h2>
              <Link to="/">
                {t('LoginCLC')}
              </Link>
            </div>
          ) : (
            <h2>
              {t('ConfirmEmailDescription')}
            </h2>
          )}
        </div>
        <div className="col-12 mb-3 text-center non-field-error">
          {confirmError}
        </div>
        {!confirmed && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" defaultValue={key} {...register('key')} />
            <div className="row text-center">
              <Button
                type="submit"
                variant="primary"
                className="login-submitbutton col-12 col-md-6 mb-3 mx-auto"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    animation="border"
                    size="sm"
                  />
                ) : (
                  t('Submit')
                )}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
}

export default ConfirmEmail;

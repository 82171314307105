import { useEffect, Suspense, useRef } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Home from './Home';
import Header from '../components/layout/Header';
import Faqs from './Faqs';
import Survey from './Survey';
import PDPEdit from './PDPEdit';
import MDPEdit from './MDPEdit';
import Matches from './Matches';
import ViewMDPForm from '../components/ViewMDPForm';
import ViewPDPForm from '../components/ViewPDPForm';
import ApplicantsPositions from './ApplicantsPositions';
import OpportunitySearch from './OpportunitySearch';
import SignUp from './auth/SignUp';
import Expected from './auth/Expected';
import Login from './auth/Login';
import ConfirmEmail from './auth/ConfirmEmail';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
import PDPMatchDetails from '../components/PDPMatchDetails';
import AttestationPDPDetails from '../components/AttestationPDPDetails';
import PresbyteryPermissions from './PresbyteryPermissions';
import api from '../services/api.service';
import {
  appLoadingState,
  isPortalLoginState,
  userProfileState,
  currentRoleState,
} from '../services/state.service';
import SuspenseLoading from '../components/generic/SuspenseLoading';
import {
  UserProfile,
  UserProfileDefaultValue,
  Role,
  RoleDefaultValue,
  LoginResponse,
} from '../types/user.types';
import ProfileWizard from './ProfileWizard';
import MinistryProfileWizard from './MinistryProfileWizard';
import EducationWizard from './EducationWizard';
import Footer from '../components/layout/Footer';

function App(): JSX.Element {
  const setIsPortalLoginState = useSetRecoilState(isPortalLoginState);
  const setAppLoadingState = useSetRecoilState(appLoadingState);
  const setUserProfileState = useSetRecoilState(userProfileState);
  const [currentRole, setCurrentRoleState] = useRecoilState<Role>(currentRoleState);
  const { i18n } = useTranslation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const didMount = useRef(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const LoadProfile = (): Promise<void> => (
    UserService.GetProfile().then(
      (response: UserProfile) => {
        if (!response.isLoggedIn) {
          if (pathname !== '/login') {
            navigate('/login', { replace: true });
          }
          setAppLoadingState(false);
          return;
        }

        const isCallSeeker = response.roles.filter((p) => p.functions.indexOf('Call Seeker') > -1).length > 0;

        if (isCallSeeker && !response.profileCompleted && pathname !== '/profilewizard') {
          navigate('/profilewizard', { replace: true });
          setAppLoadingState(false);
          return;
        }

        setUserProfileState(response);

        if (response.middleName === null) {
          setUserProfileState((p) => ({ ...p, middleName: '' }));
        }

        if (currentRole.name === '' && response.roles.length > 0) {
          setCurrentRoleState(response.roles[0]);
        }

        i18n.changeLanguage(response.language);

        api.interceptors.request.use(
          (config: any) => {
            const replacedConfig = config;
            replacedConfig.headers['Accept-Language'] = response.language;
            return replacedConfig;
          },
          (error: any) => (
            Promise.reject(error)
          ),
        );

        setAppLoadingState(false);
      },
    )
  );

  useEffect(() => {
    // Ignore double run of useEffect in strict mode
    if (didMount.current) {
      return;
    }
    didMount.current = true;

    const currentToken = localStorage.getItem('token');

    // Check for impersonated user token
    const params = new URLSearchParams(search);
    const hijackToken = params.get('hijack_token');
    const hijackReturnUrl = localStorage.getItem('hijack_return_url');
    if (hijackToken) {
      const decodedHijackToken = atob(hijackToken);
      if (currentToken !== decodedHijackToken) {
        localStorage.setItem('hijack_return_url', params.get('return_url') || '');
        localStorage.setItem('token', decodedHijackToken || '');
        LoadProfile();

        navigate('/', { replace: true });
        return;
      }
    }

    if (hijackReturnUrl) {
      const localToken = AuthService.GetLocalAccessToken();
      if (localToken) {
        LoadProfile();
        return;
      }
    }

    // Always check for portal user token since it could have changed
    AuthService.GetOgaAuthToken().then((response: LoginResponse) => {
      if (response.data.access_token) {
        // We have a valid portal session
        setIsPortalLoginState(true);
        localStorage.setItem('token', JSON.stringify(response.data));
      }

      // Load profile regarless of how we got a token
      const localToken = AuthService.GetLocalAccessToken();
      if (localToken) {
        LoadProfile();
      }
    });

    // reset profile and role to empty state
    setUserProfileState(UserProfileDefaultValue);
    setCurrentRoleState(RoleDefaultValue);
    setAppLoadingState(false);
    // Skip redirects for email confirmation page
    if (pathname.includes('/confirm-email/')
      || pathname.includes('/signup')
      || pathname.includes('/forgot')
      || pathname.includes('/reset-password/')
      || pathname.endsWith('/view/')
      || pathname.includes('/opportunity-search')
      || pathname.includes('/faqs')
      || pathname.includes('/attestation')
      || pathname.includes('/presbytery-permissions')
      || pathname.includes('/expected/')) {
      return;
    }
    navigate('/login', { replace: true });
  }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Suspense fallback={<SuspenseLoading />}><SignUp /></Suspense>} />
        <Route path="/expected/:code" element={<Expected />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/profilewizard" element={<ProfileWizard />} />
        <Route path="/ministryprofilewizard" element={<MinistryProfileWizard />} />
        <Route path="/educationwizard" element={<EducationWizard />} />
        <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
        <Route path="/confirm-email/:key" element={<ConfirmEmail />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/pdp/:id" element={<PDPEdit />} />
        <Route path="/pdp/:id/view/" element={<ViewPDPForm />} />
        <Route path="/attestation/:id/pdpdetails/" element={<AttestationPDPDetails />} />
        <Route path="/mdp/:id" element={<MDPEdit />} />
        <Route path="/mdp/:id/matches" element={<Matches />} />
        <Route path="/mdp/:id/view/" element={<ViewMDPForm />} />
        <Route path="/mdp/:id/pdpdetails" element={<Suspense fallback={<SuspenseLoading />}><PDPMatchDetails /></Suspense>} />
        <Route path="/opportunity-search" element={<Suspense fallback={<SuspenseLoading />}><OpportunitySearch /></Suspense>} />
        <Route path="/applicants-positions" element={<ApplicantsPositions />} />
        <Route path="/presbytery-permissions/" element={<PresbyteryPermissions />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;

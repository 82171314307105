import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import {
  Button,
  Table,
} from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import withUserAllowed from './layout/withUserAllowed';
import { CLC_FUNCTION } from '../types/constants';
import {
  MDPResultListDefaultData,
  MDPResultListData,
  MDPResultListResponseData,
} from '../types/mdp.types';
import api from '../services/api.service';
import {
  currentRoleState,
  mdpPanelState,
} from '../services/state.service';
import { Role } from '../types/user.types';
import NotifyPNCModal from './NotifyPNCModal';

function MDPApprovalAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<MDPResultListData[]>([]);
  const currentRole = useRecoilValue<Role>(currentRoleState);
  const [showNotifyPNC, setShowNotifyPNC] = useState(false);
  const [selectedMDP, setSelectedMDP] = useState<MDPResultListData>(MDPResultListDefaultData);
  const setRefreshPanel = useSetRecoilState(mdpPanelState);
  const [refreshList, setRefreshList] = useState(false);
  const approveMDPAbbreviations = ['COM', 'EP', 'Adm', 'SC'];

  useEffect(() => {
    api.get(`/submittedmdps/${currentRole.organizationId}/`)
      .then(
        (response: MDPResultListResponseData) => { setSubmitted(response.data.options); },
      );
    setRefreshPanel((r: boolean) => !r);
  }, [currentRole, refreshList]);

  function approveMDP(mdp: MDPResultListData): Promise<void> {
    const payload = new FormData();
    payload.append('id', mdp.id.toString());
    if (currentRole.abbreviation === 'COS') {
      payload.append('clerk_approval', 'true');
    } else if (approveMDPAbbreviations.includes(currentRole.abbreviation)) {
      payload.append('com_approval', 'true');
      payload.append('approve', 'true');
    }
    return api.post(`/submittedmdps/${currentRole.organizationId}/`, payload)
      .then(() => (setRefreshList((r: boolean) => !r)));
  }

  return (submitted.length > 0) ? (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaUserCircle />
        <span className="accordion-header-label">
          {t('MDPsSubmittedApproval')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="mb-3 mx-auto mobiletable col-lg-12">
          <div className="border border-dark rounded-3">
            <NotifyPNCModal
              show={showNotifyPNC}
              mdp={selectedMDP}
              updateCallback={() => setShowNotifyPNC(false)}
            />
            <Table responsive hover className="mb-3 linktable">
              <thead>
                <tr className="linktableheader">
                  <th>
                    {t('MDP ID')}
                  </th>
                  <th>
                    {t('Position Type')}
                  </th>
                  <th>
                    {t('Employment Type')}
                  </th>
                  <th>
                    {t('Congregation')}
                  </th>
                  {(currentRole.abbreviation === 'COS' || currentRole.abbreviation === 'COM') && (
                    <>
                      <th>
                        {t('Approved_By_COS')}
                      </th>
                      <th>
                        {t('Approved_By_COM')}
                      </th>
                    </>
                  )}
                  <th>
                    {t('Actions')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {submitted?.map((mdp: MDPResultListData) => (
                  <tr key={mdp.id} className="dividinglines">
                    <td>
                      <Link to={`/mdp/${mdp.id}/view/`}>
                        {mdp.id}
                      </Link>
                    </td>
                    <td>
                      {mdp.positionType ? mdp.positionType : ''}
                      {mdp.positionTitle ? ` (${mdp.positionTitle})` : ''}
                    </td>
                    <td>{mdp.employmentType}</td>
                    <td>{mdp.organizationName}</td>
                    {(currentRole.abbreviation === 'COS' || currentRole.abbreviation === 'COM') && (
                      <>
                        <td>{mdp.clerkApproval ? t('Yes') : t('No')}</td>
                        <td>{mdp.comApproval ? t('Yes') : t('No')}</td>
                      </>
                    )}
                    <td>
                      {((currentRole.abbreviation === 'COS' && !mdp.clerkApproval)
                        || (approveMDPAbbreviations.includes(currentRole.abbreviation) && mdp.clerkApproval && !mdp.comApproval)
                        || (currentRole.functions.indexOf(CLC_FUNCTION.approveMDP) > 1 && mdp.organizationType !== 'congregation')) && (
                        <Button variant="primary" className="action-button mb-1 me-1" onClick={() => approveMDP(mdp)}>
                          {t('Approve')}
                        </Button>
                      )}
                      {(approveMDPAbbreviations.includes(currentRole.abbreviation)) && (
                        <Button variant="primary" className="action-button mb-1" onClick={() => { setSelectedMDP(mdp); setShowNotifyPNC(true); }}>
                          {t('NotifyPNC')}
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
    : <div />;
}

export default withUserAllowed(CLC_FUNCTION.approveMDP)(MDPApprovalAccordionItem);

import { LoginResponse } from '../types/user.types';
import api from './api.service';

const Login = (username: string, password: string): Promise<void> => (
  api
    .post('/login/', {
      username,
      password,
    })
    .then((response: LoginResponse) => {
      if (response.data.access_token) {
        localStorage.setItem('token', JSON.stringify(response.data));
      }
    })
);

const GetOgaAuthToken = (): Promise<LoginResponse> => api.get('/oga-auth/', { withCredentials: true });

const Logout = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('hijack_return_url');
};

const GetLocalRefreshToken = (): any => {
  const user = localStorage.getItem('token');
  if (user) {
    return JSON.parse(user).refresh_token;
  }

  return '';
};

const GetLocalAccessToken = (): any => {
  const user = localStorage.getItem('token');
  if (user) {
    return JSON.parse(user).access_token;
  }

  return '';
};

const UpdateLocalAccessToken = (token: string): void => {
  const user = localStorage.getItem('token');
  if (user) {
    const userObject = JSON.parse(user);
    userObject.access_token = token;
    localStorage.setItem('token', JSON.stringify(userObject));
  }
};

const exports = {
  Login,
  GetOgaAuthToken,
  Logout,
  GetLocalRefreshToken,
  GetLocalAccessToken,
  UpdateLocalAccessToken,
};

export default exports;

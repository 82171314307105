import React, {
  useEffect,
  useState,
  Suspense,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FaCross } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {
  CallingInfoData,
  CallingInfoResponseData,
} from '../types/profile.types';
import {
  callingInfoState,
  userProfileState,
} from '../services/state.service';
import api from '../services/api.service';
import DisplayField from './generic/DisplayField';
import SuspenseLoading from './generic/SuspenseLoading';
import { CLC_FUNCTION } from '../types/constants';
import withUserAllowed from './layout/withUserAllowed';
import UserService from '../services/user.service';
import {
  UserProfile,
} from '../types/user.types';

const EcclesiasticalStatusModal = React.lazy(() => import('./EcclesiasticalStatusModal'));
const MisconductCertificationModal = React.lazy(() => import('./MisconductCertificationModal'));

function EcclesiasticalAccordionItem({
  eventKey,
}: {
  eventKey: string
}): JSX.Element {
  const { t } = useTranslation();
  const [eccInformation, setECCInformation] = useRecoilState<CallingInfoData>(callingInfoState);
  const setUserProfileState = useSetRecoilState<UserProfile>(userProfileState);
  const [refresh, setRefresh] = useState(false);
  const [showMisconduct, setShowMisconduct] = useState<boolean>(false);

  useEffect(() => {
    api.get('/profile/callinginfo/').then((response: CallingInfoResponseData) => {
      setECCInformation(response.data[0]);
    });
    UserService.GetProfile().then((profile: UserProfile) => {
      setUserProfileState(profile);
    });
  }, [refresh]);

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <FaCross />
        <span className="accordion-header-label">
          {t('Ecclesiastical_Information')}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <Suspense fallback={<SuspenseLoading />}>
          <div className="row">
            <div className="col-sm-2" />
            <div className="col-sm-3">
              <DisplayField
                label={t('EcclesiasticalStatus')}
                text={`${eccInformation?.eccStatusDescription}`}
              />
              <MisconductCertificationModal
                show={showMisconduct}
                updateCallback={() => { setShowMisconduct(false); setRefresh((r: boolean) => !r); }}
              />
              {eccInformation.eccStatusValue !== 'member' && (
                <Button
                  type="button"
                  className="p-0 text-start text-primary bg-white border-0"
                  onClick={() => { setShowMisconduct(true); }}
                >
                  {t('SexualMisconductSelfCertification')}
                </Button>
              )}
            </div>
            <div className="col-sm-3">
              <DisplayField
                label={t('Available_To_Match')}
                text={`${eccInformation?.seeking ? 'Yes' : 'No'}`}
              />
            </div>
            <div className="col-sm-3">
              <DisplayField
                label={t('Advanced_Search_Matching')}
                text={`${eccInformation?.authorize ? 'Yes' : 'No'}`}
              />
            </div>
            <div className="col-sm-1">
              <EcclesiasticalStatusModal
                updateCallback={() => setRefresh((r: boolean) => !r)}
              />
            </div>
          </div>
          <div className="col-sm-3">
            &nbsp;
          </div>
        </Suspense>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default withUserAllowed(CLC_FUNCTION.callSeeker)(EcclesiasticalAccordionItem);
